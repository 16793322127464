import React, { useState } from "react";
import Card from "../components/card/Card";
import Flex from "../components/flex/Flex";
import Image from "../components/image/Image";
import Line from "../components/line/Line";
import Space from "../components/space/Space";
import { ContainerMain, ContainerStyled } from "../components/styles/Container";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { loginData } from "../constants/loginData";
import CredentialCard from "./CardCredential";

const ForgetScreen = () => {
    const [emailSent, setEmailSent] = useState(false);
    return (
        <ContainerMain
            bg={loginData.background.bg}
            justifyContent="center"
            alignItems="center"
        >
            <Flex.Item width="70%" tabletWidth="100%" mobileWidth="100%">
                <Space
                    bigtabletpadding={["0px 5% 0px 0px"]}
                    tabletpadding={["0px 10% 0px 0px"]}
                >
                    <Text size="xl" weight="bold">
                        {loginData.subHeadings[0]}
                        <InlineText textTransform="uppercase" color="primary">
                            {loginData.inlineText}{" "}
                        </InlineText>
                        {loginData.subHeadings[1]}
                        <InlineText
                            textTransform="uppercase"
                            color="primary"
                            weight="bold"
                        >
                            {loginData.inlineText}
                        </InlineText>
                    </Text>
                </Space>
                <Space
                    bigtabletpadding={["0px 5% 0px 0px"]}
                    tabletpadding={["0px 10% 0px 0px"]}
                >
                    <Text size="xl">{loginData.subHeadings[2]}</Text>
                </Space>

                <ContainerStyled
                    displayMobile="none"
                    justifyMobile="center"
                    justifyTablet="center"
                >
                    <Space
                        margin={["5% 0px"]}
                        tabletmargin={["8% 0"]}
                        mobilemargin={["10% 0px"]}
                    >
                        <Image
                            src={loginData.background.image}
                            borderRadius="5%"
                            alt="banner_image"
                            width="60%"
                            tabletWidth="100%"
                        />
                    </Space>
                </ContainerStyled>
            </Flex.Item>
            <Flex.Item width="30%" tabletWidth="100%" mobileWidth="100%">
                <Space mobilemargin={["50px 0px 0px 0px"]}>
                    <Card
                        borderRadius="10px"
                        backgroundColor="white"
                        shadow="rgba(17, 17, 26, 0.2) 0px 0px 16px"
                    >
                        <ContainerStyled
                            justifyContent="space-between"
                            alignItems="center"
                            paddingTop="20px"
                            paddingLeft="20px"
                            paddingRight="20px"
                        >
                            <Flex.Item>
                                <Text>Reset Password</Text>
                            </Flex.Item>
                        </ContainerStyled>
                        <Line
                            height="1px"
                            backgroundColor="lightGrey"
                            margin="10px 0"
                            borderRadius="5px"
                        />
                        <ContainerStyled
                            paddingBottom="20px"
                            paddingLeft="20px"
                            paddingRight="20px"
                        >
                            <CredentialCard
                                setEmailSent={setEmailSent}
                                emailSent={emailSent}
                            />
                        </ContainerStyled>
                    </Card>
                </Space>
            </Flex.Item>
        </ContainerMain>
    );
};

export default ForgetScreen;
