import { motion } from "framer-motion";
import React from "react";
import cn from "../../../lib/cn";
/* @TODO => Fix this @habib610 Fri September 06,2024 */

const h1BaseStyles =
    "text-gray font-semibold text-5xl sm:text-5.6xl xl:text-8.0xl 2xl:text-8.8xl 3xl:text-9xl ";

const h2baseStyles =
    " font-light xl:font-extralight text-2xl md:text-3xl xl:text-4.0xl 2xl:text-4xl text-sora gap-x-0.5 sm:gap-x-1 flex flex-wrap text-gray";

const SplitText = ({ children, className, variants, heading = "h1" }) => {
    const renderBlockText = children.split(" ").map((item, i) => (
        <span
            className="overflow-y-hidden"
            style={{
                lineHeight: "initial",
            }}
            key={i}
        >
            <motion.span variants={variants} className="inline-block">
                {item}
            </motion.span>
        </span>
    ));

    if (heading === "h1") {
        return (
            <h1 className={cn(h1BaseStyles, className)}>{renderBlockText}</h1>
        );
    } else {
        return (
            <h2 className={cn(h2baseStyles, className)}>{renderBlockText}</h2>
        );
    }
};
export default SplitText;
