import React from "react";
import Flex from "../../../components/flex/Flex";
import Input from "../../../components/input/Input";
import Space from "../../../components/space/Space";
import Text from "../../../components/text/Text";

const DetailsFiled = ({
    label,
    onChange,
    margin = "0 0 30px ",
    tabletmargin = "0 0 25px",
    mobilemargin = "0 0 10px",
    icon,
    onIconClick,
    ...rest
}) => {
    return (
        <Space
            margin={[margin]}
            tabletmargin={[tabletmargin]}
            mobilemargin={[mobilemargin]}
        >
            <Flex.Container justifyContent="space-between" alignItems="center">
                <Flex.Item width="25%" tabletWidth="100%" mobileWidth="100%">
                    <Text color="white" weight="bold" size="m">
                        {label}
                    </Text>
                </Flex.Item>
                <Flex.Item width="70%" tabletWidth="100%" mobileWidth="100%">
                    <Input
                        onChange={onChange}
                        backgroundColor="white"
                        errorType="alert"
                        icon={icon}
                        onIconClick={icon ? onIconClick : null}
                        {...rest}
                    />
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};

export default DetailsFiled;
