import React, { useCallback } from "react";
import OTPBOX from "react-otp-input";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import theme from "../../theme";
import Flex from "../flex/Flex";
import Text from "../text/Text";

const ErrorText = styled(Text)`
    color: ${(props) => props.theme.color.error};
    padding: ${(props) =>
        props.errorType === "alert" ? "1px 10px" : "10px 0 0"};
    background-color: ${(props) =>
        props.errorType === "alert" ? props.theme.color.danger : "transparent"};
`;
const commonOtpStyle = {
    // padding: "10px 5px",
    lineHeight: "1.6em",
    color: theme.color.black,
    border: `1px solid ${theme.color.lighterGrey}`,
    fontSize: theme.fontSize.default.m,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: "400",
    background: theme.color.white,
    borderRadius: "5px",
    boxShadow: "rgba(17, 17, 26, 0.25) 0px 0px 6px",
    margin: "0px 3px 10px 3px",
    boxSizing: "border-box",
    maxWidth: "40px",
};
const otpStyleSmall = {
    ...commonOtpStyle,
    width: "30px",
    maxWidth: "35px",
};
const otpStyleRegular = {
    ...commonOtpStyle,
    width: "40px",
    maxWidth: "40px",
};
const OtpInput = ({
    label,
    value,
    onChange,
    errorMessage = "",
    size,
    errorType = "",
    ...rest
}) => {
    const onChangeHandler = useCallback(
        (value) => {
            onChange({
                target: {
                    value,
                    name: "otp",
                },
            });
        },
        [onChange]
    );
    const { width } = useWindowDimensions();

    let otpStyle = width > 350 ? otpStyleRegular : otpStyleSmall;
    const containerStyle = {
        justifyContent: "space-between",
        margin: "0px 0px 0px 0px",
        flexWrap: "wrap",
        boxSizing: "border-box",
    };
    const focusStyle = {
        outlineColor: theme.color.primary,
        border: `1px solid ${theme.color.grey}`,
    };
    return (
        <div>
            {label && (
                <Flex.Container justifyContent="center">
                    <Text size="s" color="lightGrey">
                        Enter OTP
                    </Text>
                </Flex.Container>
            )}
            <OTPBOX
                value={value}
                onChange={onChangeHandler}
                numInputs={size}
                containerStyle={containerStyle}
                focusStyle={focusStyle}
                inputStyle={{
                    ...otpStyle,
                    borderColor: errorMessage
                        ? theme.color.error
                        : theme.color.lightGrey,
                }}
                {...rest}
            />

            {errorMessage ? (
                <ErrorText size="xs" align="center" errorType={errorType}>
                    {errorMessage}
                </ErrorText>
            ) : null}
        </div>
    );
};

export default OtpInput;
