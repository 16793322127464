import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { offsetOTP } from "../../components/animation/offsetAnimation";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ModalAlt from "../../components/modal/ModalAlt";
import OtpInput from "../../components/otp-input/OtpInput";
import Space from "../../components/space/Space";
import { InlineText } from "../../components/text/HeadingText";
import Text from "../../components/text/Text";
import { ERROR_MSG, LOGIN_SUCCESS_MSG } from "../../constants/common";
import { SET_ERROR, UPDATE_QUICK_USER } from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import {
    DEFAULT_REF_CODE,
    QUICK_SIGNUP_ENDPOINT,
    RESEND_OTP_ENDPOINT,
    VALIDATE_API_ENDPOINT,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { validateNameEmailPhone } from "../../helpers/fieldValidation";
import { getURLQueryParams } from "../../helpers/helpers";
import request from "../../services/request";

import DetailsFiled from "./DetailsField";
import OffsetSummary from "./OffsetSummary";

const OTP_LENGTH = 6;
const COUNTER_TIME = 30;

const PersonalDetails = ({
    isOpen,
    onClose,
    carbonEmission = 0,
    isNewUser = null,
    onChangeNewUser,
    onExit,
}) => {
    const [isOTP, setIsOTP] = useState(false);
    const [isSummary, setIsSummary] = useState(false);
    const { state } = useContextState();
    const [loading, setLoading] = useState(false);
    const { dispatch } = useContextState();

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [formError, setFormError] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });
    const [checkEmail, setCheckEmail] = useState(false);
    const [checkPhone, setCheckPhone] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [existingPhone, setExistingPhone] = useState(false);
    const [emailErr, setEmailErr] = useState(null);
    const [phoneErr, setPhoneErr] = useState(null);

    const location = useLocation();
    const referral = getURLQueryParams(location, "referral");
    // const referral = DEFAULT_REF_CODE;

    // RESENT OTP FILEDS
    const [resendCount, setResendCount] = useState(0);
    const [counter, setCounter] = useState(0);

    /* @DESC::  close the modal and reset form  */
    const closeModalHandler = () => {
        onClose();
        setIsOTP(false);
        setIsSummary(false);
        setFormData({
            name: "",
            email: "",
            phone: "",
        });
        setOtp("");
        setCheckEmail(false);
        setCheckPhone(false);
        setExistingEmail(false);
        setExistingPhone(false);
        setEmailErr(null);
        setPhoneErr(null);
        if (isNewUser && state.quickUser !== null) {
            onChangeNewUser(false);
            setTimeout(() => {
                document
                    .getElementById("offset-carboon-footer")
                    .scrollIntoView({
                        behavior: "smooth",
                    });
            }, 800);
        }
    };

    const hanldleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    /* @DESC:: first call get OTP   */
    const handleGetOTP = (e) => {
        e.preventDefault();
        const getValidFormData = validateNameEmailPhone(formData);
        setFormError(getValidFormData);
        /* @TODO ==> carboonEmission value validation  Fri Sep 23  */
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            const reqData = {
                carbonEmission,
                name: formData.name,
                email: formData.email,
                phone: `91${formData.phone}`,
            };
            request
                .post({
                    endpoint: QUICK_SIGNUP_ENDPOINT,
                    body: reqData,
                })
                .then((response) => {
                    toast.success("OTP has been sent!");
                    setResendCount((prev) => prev + 1);
                    setCounter(COUNTER_TIME);
                    setLoading(false);
                    setIsOTP(true);
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err || ERROR_MSG);
                });
        }
    };
    useEffect(() => {
        const updateCounter = () => {
            if (counter === 0) return;
            else {
                let temp = counter - 1;
                setCounter(temp);
            }
        };
        let timer = setInterval(updateCounter, 1000);
        if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [counter]);

    /* @DESC::  Resend OTP   */
    const handleResentOTP = (type) => {
        request
            .get({
                endpoint: `${RESEND_OTP_ENDPOINT}?mobile=91${formData.phone}&type=${type}`,
            })
            .then((res) => {
                if (res?.status === "SUCCESS") {
                    toast.success(
                        res?.message && typeof res?.message === "string"
                            ? res?.message
                            : type === "voice"
                            ? "Voice call has been sent!"
                            : "OTP has sent successfully!"
                    );
                    setLoading(false);
                    setResendCount((prev) => prev + 1);
                    setCounter(COUNTER_TIME * (resendCount + 1));
                }
            })
            .catch((error) => {
                setLoading(false);

                if (error === "otp_expired") {
                    setResendCount(0);
                    setCounter(0);
                    closeModalHandler();
                }
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            });
    };

    const validedOtp = () => {
        if (!otp) {
            setOtpError("OTP is required!");
            return false;
        } else if (otp.length !== OTP_LENGTH) {
            setOtpError("OTP is incomplete!");
            return false;
        }
        setOtpError("");
        return true;
    };

    /* @DESC::  validate and check existing email and phone  */
    const handleBlur = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "email") {
            if (!emailRegEx.test(value.toLowerCase())) {
                setEmailErr("Enter a valid email!");
                setCheckEmail(false);
                setExistingEmail(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            email: value,
                        },
                    })
                    .then((res) => {
                        setCheckEmail(true);
                        setExistingEmail(false);
                        setEmailErr("");
                    })
                    .catch((err) => {
                        setCheckEmail(false);
                        setExistingEmail(true);
                        setEmailErr(
                            typeof err === "string"
                                ? err
                                : "Email is already in use. Click "
                        );
                    });
            }
        } else {
            /* @TODO ==>  Phone validation Mon Oct 03  */
            if (!phoneRegEx.test(value)) {
                setPhoneErr("Enter a valid phone number!");
                setCheckPhone(false);
                setExistingPhone(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            phone: `91${value}`,
                        },
                    })
                    .then((res) => {
                        setCheckPhone(true);
                        setExistingPhone(false);
                        setPhoneErr("");
                    })
                    .catch((err) => {
                        setCheckPhone(false);
                        setExistingPhone(true);
                        setPhoneErr(
                            typeof err === "string"
                                ? err
                                : "Phone is already in use. Click "
                        );
                    });
            }
        }
    };

    /* @DESC::  Close Modal and open Existing user login Modal  */
    const hanldleUserLogin = (e) => {
        e.preventDefault();
        closeModalHandler();
        onExit();
    };

    const handleCheckRefferelCode = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (referral) {
                    await request.post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            referralCode: referral,
                        },
                    });
                    return resolve(referral);
                } else {
                    return resolve(DEFAULT_REF_CODE);
                }
            } catch (error) {
                return resolve(DEFAULT_REF_CODE);
            }
        });
    };

    /* @DESC::  final call submit with carboon and OTP  */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const getValidFormData = validateNameEmailPhone(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0 && validedOtp()) {
            setIsOTP(true);
            setLoading(true);

            try {
                let refCode = await handleCheckRefferelCode(referral);
                const reqData = {
                    carbonEmission,
                    name: formData.name,
                    email: formData.email,
                    phone: `91${formData.phone}`,
                    referralCode: refCode,
                    otp,
                };
                const res = await request.post({
                    endpoint: QUICK_SIGNUP_ENDPOINT,
                    body: reqData,
                });
                dispatch({
                    type: UPDATE_QUICK_USER,
                    payload: res?.data?.investor_profile,
                });
                setLoading(false);
                toast.success(LOGIN_SUCCESS_MSG);
                setIsSummary(true);
            } catch (err) {
                setLoading(false);
                toast.error(err || ERROR_MSG);
            }
        }
    };

    return (
        <div>
            <ModalAlt
                times={false}
                onClose={closeModalHandler}
                isOpen={isOpen}
                backgroundColor="primary"
            >
                {isSummary ? (
                    <OffsetSummary
                        isNewUser={isNewUser}
                        onClose={closeModalHandler}
                    />
                ) : (
                    <Space padding={["2.5rem 0 0"]}>
                        <form>
                            <DetailsFiled
                                label="Full Name"
                                placeholder="Enter your full name"
                                name="name"
                                errorMessage={formError.nameError}
                                value={formData.name}
                                onChange={hanldleChange}
                            />

                            <DetailsFiled
                                label="Email Id"
                                placeholder="Enter Your Email"
                                name="email"
                                // errorMessage={formError.emailError}
                                errorMessage={
                                    formError.emailError || emailErr ? (
                                        <>
                                            {formError.emailError || emailErr}
                                            {existingEmail && (
                                                <ExistingBtn
                                                    size="s"
                                                    color="white"
                                                    weight="700"
                                                    onClick={hanldleUserLogin}
                                                >
                                                    Login
                                                </ExistingBtn>
                                            )}
                                        </>
                                    ) : null
                                }
                                value={formData.email}
                                onChange={hanldleChange}
                                onBlur={handleBlur}
                            />

                            <DetailsFiled
                                label="Phone No."
                                placeholder=" Enter your phone number"
                                name="phone"
                                value={formData.phone}
                                onChange={hanldleChange}
                                onBlur={handleBlur}
                                errorMessage={
                                    formError.phoneError || phoneErr ? (
                                        <>
                                            {formError.emailError || phoneErr}
                                            {existingPhone && (
                                                <ExistingBtn
                                                    size="s"
                                                    color="white"
                                                    weight="700"
                                                    onClick={hanldleUserLogin}
                                                >
                                                    Login
                                                </ExistingBtn>
                                            )}
                                        </>
                                    ) : null
                                }
                            />

                            {isOTP && (
                                <AnimatePresence>
                                    <Space
                                        margin={["0 0 30px "]}
                                        tabletmargin={["0 0 25px"]}
                                        mobilemargin={["0 0 10px"]}
                                    >
                                        <Flex.Container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            as={motion.div}
                                            variants={offsetOTP}
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                        >
                                            <Flex.Item
                                                width="40%"
                                                tabletWidth="100%"
                                                mobileWidth="100%"
                                            >
                                                <Text
                                                    color="white"
                                                    weight="bold"
                                                    size="m"
                                                >
                                                    Enter OTP
                                                </Text>
                                            </Flex.Item>
                                            <Flex.Item
                                                width="50%"
                                                tabletWidth="100%"
                                                mobileWidth="100%"
                                            >
                                                <OtpInput
                                                    label={false}
                                                    value={otp}
                                                    errorMessage={otpError}
                                                    onChange={(e) =>
                                                        setOtp(e.target.value)
                                                    }
                                                    size={OTP_LENGTH}
                                                    isInputNum={true}
                                                />
                                                <Flex.Container
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    {counter === 0 ? (
                                                        <>
                                                            <OTPButton
                                                                onClick={() =>
                                                                    handleResentOTP(
                                                                        "text"
                                                                    )
                                                                }
                                                                role="button"
                                                                size="s"
                                                                color="white"
                                                                weight="700"
                                                            >
                                                                Get OTP Text
                                                            </OTPButton>
                                                            <OTPButton
                                                                color="white"
                                                                onClick={() =>
                                                                    handleResentOTP(
                                                                        "voice"
                                                                    )
                                                                }
                                                                role="button"
                                                                weight="700"
                                                                size="s"
                                                            >
                                                                Get OTP on Call
                                                            </OTPButton>
                                                        </>
                                                    ) : (
                                                        <Text
                                                            weight="700"
                                                            color="white"
                                                            size="s"
                                                        >
                                                            Resend OTP in{" "}
                                                            {counter}s
                                                        </Text>
                                                    )}
                                                </Flex.Container>
                                            </Flex.Item>
                                        </Flex.Container>
                                    </Space>
                                </AnimatePresence>
                            )}

                            <Flex.Container justifyContent="center">
                                {!isOTP ? (
                                    <Button
                                        // type="solid"
                                        fontSize="m"
                                        backgroundColor="white"
                                        hoverColor="primary"
                                        color="white"
                                        onClick={handleGetOTP}
                                        style={{ borderRadius: "50px" }}
                                        disabled={
                                            loading ||
                                            !checkEmail ||
                                            !checkPhone
                                        }
                                        isLoading={loading}
                                    >
                                        GET OTP
                                    </Button>
                                ) : (
                                    <Button
                                        // type="solid"
                                        onClick={handleSubmit}
                                        fontSize="m"
                                        backgroundColor="white"
                                        hoverColor="primary"
                                        color="white"
                                        style={{ borderRadius: "50px" }}
                                        disabled={
                                            loading ||
                                            !checkEmail ||
                                            !checkPhone
                                        }
                                        isLoading={loading}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </Flex.Container>
                        </form>
                    </Space>
                )}
            </ModalAlt>
        </div>
    );
};

export default PersonalDetails;

export const ExistingBtn = styled(InlineText)`
    text-decoration: underline;
    margin-left: 3px;
    :hover {
        cursor: pointer;
        color: ${(props) => props.theme.color.pureWhite};
    }
`;

export const OTPButton = styled(Text)`
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
