import React from "react";
import { arrowCircle, coin } from "../../../resources/js/icons";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import OfferCarousel from "../../home/ExistingUser/OfferCarousel";

const ProfileCard = () => {
    return (
        <div className="bg-black-600 p-2 rounded-[3rem] gap-2  flex justify-between ">
            <div className="w-[50%] flex flex-col items-start">
                <div className="flex gap-0.6 pt-2.8  items-center ">
                    <P className="text-gray-550 text-nowrap md:text-base xl:text-base">
                        Good Morning,
                    </P>
                    <H4 className="text-white text-nowrap md:text-lg xl:text-lg">
                        Jhone Doe
                    </H4>
                </div>
                <div className="flex flex-wrap lg:flex-nowrap items-stretch  justify-between mt-4 xl:items-center gap-3">
                    <div className=" flex flex-col gap-2 bg-radial-gold p-2 rounded-2xl">
                        <P className="md:text-sm xl:text-sm">
                            Total reward points
                        </P>

                        <div className="flex gap-1 w-40 py-0.2 px-1 items-center bg-orange rounded-2xl ">
                            <img src={coin} />
                            <P className="md:text-sm xl:text-sm">1500</P>
                        </div>
                    </div>
                    <div className="bg-linear-blue-white flex flex-col gap-2 p-2 rounded-2xl">
                        <P className="text-white md:text-sm xl:text-sm">
                            Earn on every referral
                        </P>
                        <div className="flex gap-2 items-center">
                            <div className="flex items-center gap-1 px-1 rounded-2xl py-0.2  bg-linear-blue-white">
                                <img src={coin} />
                                <P className="md:text-sm xl:text-sm text-white">
                                    500
                                </P>
                            </div>
                            <img src={arrowCircle} />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" w-[70%] xl:w-[50%]">
                <OfferCarousel />
            </div>
        </div>
    );
};

export default ProfileCard;
