import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const PaginateWrapper = styled.div`
    .container {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;

        margin-bottom: 2rem;
        color: ${(props) => props.theme.color.black800};
        font-size: ${(props) => props.theme.fontSize.default.m};
    }
    .page,
    .next,
    .prev {
        border: 1px solid ${(props) => props.theme.color.grey400};
        border-radius: 0.5rem;
        padding: 0.2rem 0.4rem;
        cursor: pointer;
    }
    .pageLink {
        padding: 0.5rem 1rem;
    }
    .active {
        border-color: ${(props) => props.theme.color.primary};
        background-color: ${(props) => props.theme.color.primary};
        color: ${(props) => props.theme.color.white};
    }
    .prevLink,
    .nextLink {
        padding: 0.5rem 1rem;
        font-weight: ${(props) => props.theme.fontWeight.b};
    }
    .next {
        display: ${(props) => (props.last ? "none" : "")};
    }
    .prev {
        display: ${(props) => (props.first ? "none" : "")};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        .container {
            padding: 0 4rem;
            margin-top: 4rem;
        }
    }
`;

const Pagination = ({ isFirst, isLast, pageCount, onChange }) => {
    return (
        <PaginateWrapper first={isFirst} last={isLast}>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={onChange}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="container"
                previousLinkClassName="prevLink"
                previousClassName="prev"
                nextLinkClassName="nextLink"
                nextClassName="next"
                activeClassName="active"
                pageClassName="page"
                pageLinkClassName="pageLink"
            />
        </PaginateWrapper>
    );
};

export default Pagination;
