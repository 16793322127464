export const ERROR_MSG = "Something went wrong, please try again later!";
export const LOGIN_SUCCESS_MSG = "User logged-in successfully!";
export const SIGNUP_SUCCESS_MSG = "Account created successfully!";
export const OPPORTUNITIES_SUCCESS = "Opportunities fetched successfully!";
export const OPPORTUNITY_DETAILS_SUCCESS = "Listing fetched succcessfully!";
export const COMMON_SUCCESS_MSG = "Data fetched successfully!";
export const PROFILE_UPDATE_SUCCESS_MSG = "Profile updated successfully";
export const BANK_VERIFICATION_POST_MSG = "Bank details submitted!";
export const DOCMENT_UPLOAD_MSG = "Document uploaded successfylly!";
export const ASSET_CANCELLED = "Investment cancellation success!";
export const ASSET_INTEREST_CANCELLED = "Interest cancellation success!";
export const OPPORTUNITY_INTEREST_SUCCESS =
    "Thanks for showing Interest in Opportunity";
export const PASSWORD_UPDATE_SUCCESS_MSG =
    "Password has been updated successfully!";
export const INVESTOR_QUESTIONNAIRE_STATUS =
    "Questionnaire status fetched successfully!";
export const INVESTOR_QUESTIONNAIRE_UPDATE =
    "Questionnaire updated successfully!";
export const INVESTOR_OTP_SENT_MSG = "Sending OTP to user!";

/* @DESC::  new constants  */

export const INVESTMENT_OPPRTUNITIES_SUCCESS =
    "Investement opportunites fetched successfully!";
export const VIRTUAL_SOLAR_INTEREST_SUCCESS =
    "Virtual Solar Interest saved successfully";
export const ALREADY_JOINED_WAITING_LIST = "You have already joined!!";

export const DEFAULT_TENURE_PERIOD = 20;
export const ONE_KWP = 1.39;
export const MINIMUM_INVESTMENT_AMOUNT = 10000;

export const OTP_LENGTH = 6;
export const COUNTER_TIME = 30;
export const TYPE_PASSWORD = "password";
export const TYPE_TEXT = "text";

export const ROLE = {
    INVESTOR: "INVESTOR",
    CONSUMER_USER: "CONSUMER_USER",
    CONSUMER_ADMIN: "CONSUMER_ADMIN",
    CONSUMER_OWNER: "CONSUMER_OWNER",
    PARTNER: "PARTNER",
    SUPER_ADMIN: "SUPER_ADMIN",
    SALES_MANAGER: "SALES_MANAGER",
    OPERATION_MANAGER: "OPERATION_MANAGER",
};
//  60 months = 60mo * 30d * 24h  * 60s* 60s   => 155,520,000;
export const SIXTY_MONTHS_TO_SECONDS = 155520000;

export const validFileTypes = [
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "webp",
    "heic",
    "doc",
    "pdf",
    "docx",
];

export const BROADCAST_ACTION = {
    JOIN: "JOIN",
    LEAVE: "LEAVE",
    INVESTED: "INVESTED",
    INTERESTED: "INTERESTED",
};
export const SROT_SOCKET_COOKIES_NAME = "_srot_ws_user";
