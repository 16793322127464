import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { P } from "../styles/Typography.styles";
import Text from "../text/Text";

export const InputComponent = styled.input`
    width: 100%;
    line-height: 1.3;
    min-height: ${(props) => (props.size === "xs" ? "35px" : "40px")};
    height: 100%;
    color: ${(props) => props.theme.color.darkGrey};
    box-sizing: border-box;
    border: solid 2px
        ${(props) =>
            props.disabled
                ? props.theme.color.grey300
                : props.isError
                ? props.theme.color.error
                : props.theme.color.lightGrey};

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    background: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.disabled
            ? props.theme.color.grey300
            : props.theme.color.white};
    box-shadow: ${(props) =>
        props.shadow ? "rgba(17, 17, 26, 0.20) 0px 0px 12px" : ""};
    box-sizing: border-box;
    transition: ${(props) => props.theme.transition.xs};
    &:hover,
    &:focus {
        outline: none;
    }
    &:focus {
        outline: none;
        border-color: ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.theme.color.primary};
    }
    &::placeholder {
        color: ${(props) => props.theme.color.grey700};
    }
    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.smallMobile[props.xxs]
            : props.theme.fontSize.default.m};
    font-family: "Montserrat", sans-serif;
    font-weight: ${(props) => props.theme.fontWeight[props.fontWeight || "rg"]};
    padding: ${(props) =>
        props.size === "s"
            ? ".7rem 1.8rem"
            : props.size === "xs"
            ? ".5rem 1.4rem"
            : "1.2rem 1.8rem"};
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
`;
export const TextAreaComponent = styled.textarea`
    width: 100%;
    line-height: 1.3;
    min-height: 40px;
    height: 100%;
    color: ${(props) => props.theme.color.darkGrey};
    box-sizing: border-box;
    border: solid 2px
        ${(props) =>
            props.disabled
                ? props.theme.color.grey300
                : props.isError
                ? props.theme.color.error
                : props.theme.color.lightGrey};

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    background: ${(props) =>
        props.disabled ? props.theme.color.grey300 : props.theme.color.white};
    box-shadow: ${(props) =>
        props.shadow ? "rgba(17, 17, 26, 0.20) 0px 0px 12px" : ""};
    box-sizing: border-box;
    transition: ${(props) => props.theme.transition.xs};
    &:hover,
    &:focus {
        outline: none;
    }
    &:focus {
        outline: none;
        border-color: ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.theme.color.primary};
    }
    &::placeholder {
        color: ${(props) => props.theme.color.grey700};
    }
    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.smallMobile[props.xxs]
            : props.theme.fontSize.default.m};
    font-family: "Montserrat", sans-serif;
    font-weight: ${(props) => props.theme.fontWeight[props.fontWeight || "rg"]};
    padding: ${(props) =>
        props.size === "s"
            ? ".7rem 1.8rem"
            : props.size === "xs"
            ? ".5rem 1.4rem"
            : "1.2rem 1.8rem"};
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
`;

export const DefaultInputComponent = styled(InputComponent)`
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: right;
    appearance: none;
    &::placeholder {
        color: ${(props) => props.theme.color.lightGrey};
    }
`;
export const DefaultTextAreaComponent = styled(TextAreaComponent)`
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: right;
    appearance: none;

    &::placeholder {
        color: ${(props) => props.theme.color.lightGrey};
    }
`;

export const ErrorText = styled(Text)`
    color: ${(props) => props.theme.color.error};
    padding: ${(props) =>
        props.errorType === "alert" ? "1px 10px" : "10px 0 0"};
    border-radius: 3px;
    background-color: ${(props) =>
        props.errorType === "alert" ? props.theme.color.danger : "transparent"};
    display: inline-flex;
`;

const InputWrapper = styled.div`
    margin: ${(props) => props.wrapperMargin || ""};
    width: 100%;
    box-sizing: border-box;
    label {
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;
    }
    .sup {
        color: ${(props) => props.theme.color.red100};
        font-size: 2.5rem;
        margin-left: -0.3rem;
        margin-top: 1rem;
    }
`;
const InnerWrapper = styled.div`
    margin: ${(props) => props.wrapperMargin || ""};
    width: 100%;
    position: relative;
    box-sizing: border-box;
`;
const RightIcon = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    padding: 0 15px;
    box-sizing: border-box;
    background: ${(props) => props.theme.color.iconBgGrey};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    color: ${(props) => props.theme.color.darkGrey};
    font-size: ${(props) => props.theme.fontSize.default.s};
    cursor: pointer;
`;
const Input = ({
    name,
    label,
    onChange,
    value,
    type,
    shadow = false,
    placeholder,
    errorMessage = "",
    margin,
    icon = null,
    onIconClick,
    iconSize = "1x",
    errorType = "",
    required,
    componentType = "input",
    ...rest
}) => {
    let InputArea =
        componentType === "input" ? InputComponent : TextAreaComponent;
    return (
        <InputWrapper wrapperMargin={margin}>
            <InnerWrapper>
                {label && (
                    <label>
                        <P>
                            {label}
                            {required && <span className="sup">*</span>}
                        </P>
                    </label>
                )}
                <InputArea
                    shadow={shadow}
                    name={name}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    isError={!!errorMessage}
                    type={type}
                    required
                    {...rest}
                />
                {icon && (
                    <RightIcon role="button" tabIndex={0} onClick={onIconClick}>
                        <FontAwesomeIcon size={iconSize} icon={icon} />
                    </RightIcon>
                )}
            </InnerWrapper>

            {errorMessage ? (
                <ErrorText errorType={errorType} size="xs">
                    {errorMessage}
                </ErrorText>
            ) : null}
        </InputWrapper>
    );
};

export default Input;
