import React from "react";

import H2 from "../../../../shared/typography/H2";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import OfferCarousel from "../../../home/ExistingUser/OfferCarousel";

const ProfileCard = () => {
    return (
        <div className="bg-black-600 mb-3 p-2 rounded-[3rem] gap-2  flex justify-between ">
            <div className="w-[40%] flex flex-col items-start">
                <div className="flex gap-0.6  items-center ">
                    <P className="text-gray-550 md:text-base xl:text-base">
                        Good Morning,
                    </P>
                    <H4 className="text-white md:text-lg xl:text-lg">
                        Jhone Doe
                    </H4>
                </div>
                <H2 className="text-white mt-2 xl:text-3xl">
                    Tata Communications Pvt Limited
                </H2>

                <span className="bg-blue-600 rounded-2xl p-0.5 mt-2">
                    <li className="text-blue list-disc">Telecom Industry</li>
                </span>
            </div>
            <div className="w-[60%]">
                <OfferCarousel />
            </div>
        </div>
    );
};

export default ProfileCard;
