import { faEdit, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ErrorText } from "../components/input/Input";
import Loading from "../components/loading/Loading";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { ERROR_MSG } from "../constants/common";
import { emailRegEx } from "../constants/regularExpression";
import { EMAIL_VERIFICATION_CODE_ENDPOINT } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import request from "../services/request";
import theme from "../theme";
import { BottomContainer, BottomInputWrapper } from "./BottomLineInput";

const BottomLineEmailInput = ({
    label,
    email,
    onChange,
    onClick,
    oldEmail,
    margin = "0px 0 40px 0",
    tabletmargin = " 0 0 20px 0",
}) => {
    const [error, setError] = useState("");
    const emailRef = useRef();
    const [loader, setLoader] = useState(false);

    const { state } = useContextState();

    const handleChange = (e) => {
        const emailValue = e.target.value;
        onChange(e);
        if (!emailRegEx.test(emailValue.toLowerCase())) {
            setError("Enter a valid email!");
        } else {
            setError("");
        }
    };

    const [isCodeSent, setIsCodeSent] = useState(false);

    const handleSentCode = () => {
        setLoader(true);
        request
            .authGet({ endpoint: EMAIL_VERIFICATION_CODE_ENDPOINT })
            .then((res) => {
                setLoader(false);
                setIsCodeSent(true);
                toast.success("Email verification code has been sent!");
                setTimeout(() => setIsCodeSent(false), 20000);
            })
            .catch((err) => {
                setLoader(false);
                toast.success(typeof err === "string" ? err : ERROR_MSG);
                setIsCodeSent(false);
            });
    };
    return (
        <>
            <BottomContainer margin={margin} tabletmargin={tabletmargin}>
                {label && (
                    <Text color="darkSecondary" size="m">
                        Email Id
                    </Text>
                )}

                <BottomInputWrapper margin="0px 0 40px 0" size="m">
                    <input
                        onChange={handleChange}
                        value={email}
                        autoComplete="off"
                        style={{ paddingRight: "30px" }}
                        placeholder="divy346@gmail.com"
                        name="email"
                        ref={emailRef}
                    />
                    {oldEmail !== email ? (
                        <SendBtn
                            color="white"
                            role="button"
                            onClick={error === "" ? onClick : null}
                            size="m"
                            disabled={error !== ""}
                        >
                            <FontAwesomeIcon
                                color={theme.color.primary}
                                icon={faPaperPlane}
                            />
                        </SendBtn>
                    ) : (
                        <EditBtn
                            onClick={() => emailRef.current.focus()}
                            color="white"
                            role="button"
                            size="m"
                        >
                            <FontAwesomeIcon
                                color={theme.color.black}
                                icon={faEdit}
                            />
                        </EditBtn>
                    )}
                </BottomInputWrapper>
                {error && <ErrorText size="s">{error}</ErrorText>}
                {!state?.user?.isEmailConfirmed && (
                    <>
                        {loader ? (
                            <Text
                                size="s"
                                style={{ marginTop: "5px" }}
                                align="center"
                                color="primary"
                            >
                                <Loading />
                            </Text>
                        ) : isCodeSent ? (
                            <ResentButton size="s" color="primary">
                                Email verification code has been sent!
                            </ResentButton>
                        ) : email === oldEmail ? (
                            <ResentButton
                                onClick={handleSentCode}
                                hover
                                size="s"
                                color="primary"
                            >
                                Resend Verification Code
                            </ResentButton>
                        ) : null}
                    </>
                )}
            </BottomContainer>
        </>
    );
};

export const EditBtn = styled(InlineText)`
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.disabled ? "0.7" : "1")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    color: ${(props) => props.theme.color.black};
`;
export const SendBtn = styled(EditBtn)`
    transition: all ease 0.4s;
    opacity: ${(props) => (props.disabled ? "0.7" : "1")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const ResentButton = styled(Text)`
    cursor: pointer;
    margin-top: 4px;
    :hover {
        text-decoration: ${(props) => (props.hover ? "underline" : "none")};
    }
`;

export default BottomLineEmailInput;
