import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MSG, PROFILE_UPDATE_SUCCESS_MSG } from "../constants/common";
import { UPDATE_QUICK_USER, UPDATE_USER } from "../constants/contexConstant";
import { UPDATE_PROFILE_ENDPOINT } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";

import request from "../services/request";
import CalculateCarbonFootprint from "./CalculateCarbonFootprint";
// import OffsetCarbon from "./offset-carbon/OffsetCarbon";
import ExistingUserLogin from "./offset-modal/ExistingUserLogin";
import PersonalDetails from "./offset-modal/PersonalDetails";

const CarbonOffsetContainer = ({ id, visibilityHandler }) => {
    const calculateCarbonFootprintRef = useRef();

    useEffect(() => {
        let target = calculateCarbonFootprintRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [calculateCarbonFootprintRef, visibilityHandler]);

    const [showPersonalDetails, setShowPersonalDetails] = useState(false);
    const { state, dispatch } = useContextState();

    const [carbonLevel, setCarbonLevel] = useState(0);
    const [isQuickUser, setIsQuickUser] = useState(
        state.quickUser === null ? true : false
    );
    const [loading, setLoading] = useState(false);

    const [existingUser, setExistingUser] = useState(false);
    /* @DESC::  decide what user will do. create new/update carbonEmission  */
    const handleCarbon = async () => {
        /* @DESC::  already logged in update the carboon  */
        if (state.user) {
            setLoading(true);
            try {
                let body = {
                    carbonEmission: carbonLevel,
                };
                let res = await request.authPut({
                    endpoint: UPDATE_PROFILE_ENDPOINT,
                    body,
                });
                dispatch({
                    type: UPDATE_USER,
                    payload: res?.data?.investor_data,
                });
                dispatch({
                    type: UPDATE_QUICK_USER,
                    payload: res?.data?.investor_data,
                });

                toast.success(PROFILE_UPDATE_SUCCESS_MSG);
                setIsQuickUser(false);
                setLoading(false);
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                setLoading(false);
            }
        } else {
            /* @DESC::  not logged in open the personal details form  */
            setShowPersonalDetails(true);
        }
    };
    const handleOnComplete = (value) => {
        setCarbonLevel(value);
    };
    return (
        <div
            id={id}
            ref={calculateCarbonFootprintRef}
            data-title="CARBON FOOTPRINT CALCULATOR"
        >
            <CalculateCarbonFootprint
                margin="5% 0 30px"
                onComplete={handleOnComplete}
                fireAction={handleCarbon}
                loading={loading}
            />
            {/* @DESC::  New User Regestration Modal   */}
            <PersonalDetails
                isOpen={showPersonalDetails}
                carbonEmission={carbonLevel}
                onClose={() => setShowPersonalDetails(false)}
                isNewUser={isQuickUser}
                onChangeNewUser={setIsQuickUser}
                onExit={() => setExistingUser(true)}
            />

            {/*  @DESC::  Offset carboon card  */}
            {/* {!isQuickUser && <OffsetCarbon />} */}

            {/* @DESC::  Existing User Login Modal   */}
            <ExistingUserLogin
                isOpen={existingUser}
                onClose={() => setExistingUser(false)}
                carbonEmission={carbonLevel}
                onSuccess={() => setIsQuickUser(false)}
            />
        </div>
    );
};

export default CarbonOffsetContainer;
