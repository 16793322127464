import React from "react";
import H4 from "../../../shared/typography/H4";
import SubHeading from "../../../shared/typography/SubHeading";

const CardComponent = ({ title, description, buttonText, onButtonClick }) => {
    return (
        <div className="bg-white   border border-gray-400 rounded-lg md:rounded-xl p-2">
            <H4 className="font-medium text-nowrap mb-1">{title}</H4>
            <SubHeading className="text-black mb-2 md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                {description}
            </SubHeading>
            <div
                onClick={() => onButtonClick()}
                className=" bg-white text-black border text-center border-blue-700 font-normal py-0.5 px-1 w-full md:w-1/2 rounded-3xl "
            >
                {buttonText}
            </div>
        </div>
    );
};

export default CardComponent;
