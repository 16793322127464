import React, { useState } from "react";
import P from "../../../../shared/typography/P";
import SubHeading from "../../../../shared/typography/SubHeading";
import CalculatorRangeSlider from "../../../project/mobile/project-details/CalculatorRangeSlider";

const InvestmentCalculator = () => {
    const [amount, setAmount] = useState(50000);
    const glance = [
        {
            name: "Monthly Payouts",
            value: "₹433",
        },
        {
            name: "Non taxable payouts",
            value: "₹ 320000",
        },
    ];
    const rangeValue = {
        min: 0,
        max: 100,
    };
    // Function to calculate the left position of the amount text
    const calculatePosition = (value) => {
        // Get percentage position of the slider value
        const percentage =
            ((value - rangeValue.min) / (rangeValue.max - rangeValue.min)) *
            100;
        return percentage; // returns value from 0 to 100
    };

    const handleSliderChange = (e) => {
        setAmount(e.target.value);
    };

    return (
        <div className="my-5">
            <div className="bg-gray-50  border border-gray-400 rounded-2xl mx-3 px-2 md:mx-0 py-1 relative">
                <SubHeading className="text-black-600 font-medium md:!text-base xl:!text-lg md:pb-1 xl:font-semibold">
                    Calculate your earnings with SROT
                </SubHeading>
                <p className="text-gray-600 mb-4 md:text-sm">
                    Enter Investment amount
                </p>

                <div className="relative">
                    <CalculatorRangeSlider
                        min={rangeValue.min}
                        max={rangeValue.max}
                        value={amount}
                        onChange={handleSliderChange}
                    />

                    <p
                        className="text-sm text-blue-500 text-center absolute"
                        style={{
                            left: `calc(${calculatePosition(amount)}% - 0px)`, // Adjust as necessary to align with the slider thumb
                            top: "-30px", // Position above the slider
                            transform: "translateX(-50%)", // Center align the text
                            whiteSpace: "nowrap", // Prevent wrapping
                        }}
                    >
                        ₹ {amount.toLocaleString()}
                    </p>
                </div>
                <div className="flex justify-between">
                    <P className="text-gray-550 ">{rangeValue.min}</P>
                    <P className="text-gray-550">{rangeValue.max} Cr</P>
                </div>

                <div className="flex justify-between items-center mt-3">
                    {glance.map((item, i) => (
                        <div key={i}>
                            <P className="text-gray-550  text-xs xl:text-sm">
                                {item.name}
                            </P>
                            <P className="text-gray font-medium font-vietnam ">
                                {item.value}
                            </P>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InvestmentCalculator;
