import React from "react";

/* @TODO => Fix styles @habib610 Fri September 06,2024 */
const Tab = ({
    text,
    active,
    onClick,
    classname,
    activeClass = "bg-gray-100 text-primary border-blue-300",
    inactiveClass = "bg-white text-gray-600 border-gray-600",
}) => {
    return (
        <div
            onClick={onClick}
            className={`inline-block font-nunito font-normal cursor-pointer border-0.5 rounded-full transition-colors duration-300 py-0.6 px-1.2 xl:py-0.8 xl:px-1.6 ${classname} ${
                active ? activeClass : inactiveClass
            }`}
        >
            {/* @TODO => fix fonts sizes @habib610 Mon September 09,2024 */}

            <span className="text-sm sm:text-base xl:text-base 2xl:text-btn-s font-regular text-nowrap">
                {text}
            </span>
        </div>
    );
};

export default Tab;
