import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PROFILE } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { InlineText } from "../text/HeadingText";
import Text from "../text/Text";

const ClickText = styled(Text)`
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

const WarningMessage = () => {
    const { state } = useContextState();
    const [active, setActive] = useState(true);
    const history = useHistory();
    return (
        active &&
        state?.user?.isEmailConfirmed === false && (
            <Wraper>
                <ClickText
                    role="button"
                    onClick={() => history.push(`${PROFILE}/user`)}
                    align="center"
                    size="s"
                    color="error"
                >
                    Verify your email!
                </ClickText>
                <InlineText
                    type="button"
                    onClick={() => setActive(false)}
                    size="s"
                    color="error"
                    style={{ cursor: "pointer" }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </InlineText>
            </Wraper>
        )
    );
};

export default WarningMessage;

const Wraper = styled.div`
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    /* transform: translateX(-50%); */
    background: #f8d7da;
    padding: 0px 15px;
    border: 1px solid #f5c2c7;
    border-radius: 3px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    line-height: normal;
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        top: 85px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        top: 85px;
    }
`;
