import React from "react";
import cn from "../../../lib/cn";
import P from "../../typography/P";
import TabButton from "../TabButton";

const BorderedTab = ({
    options,
    currentTab,
    onTabClick,
    className,
    tabClass,
    activeClass = " bg-gray-400 text-primary  border-blue-50 ",
    inActiveClass = "text-gray-secondary bg-gray-50",
}) => {
    return (
        <div
            className={cn(
                `flex justify-between gap-1.6 bg-gray-50 rounded-full`,
                className
            )}
        >
            {options.map((option, index) => (
                <TabButton
                    key={option}
                    className={cn(
                        `rounded-full py-0.5 px-1.2   transition-colors duration-300 text-xs font-normal flex items-start justify-center border border-solid border-transparent gap-0.5
                                  ${
                                      currentTab === index
                                          ? activeClass
                                          : inActiveClass
                                  }
                    `,
                        tabClass
                    )}
                    onClick={() => onTabClick(index)}
                    showHoverEffect={false}
                >
                    {typeof option === "string" ? (
                        option
                    ) : (
                        <>
                            {option?.name}
                            <P
                                className={cn(
                                    "px-0.4 border border-gray-550 text-gray-550 inline-block rounded-full ",
                                    {
                                        "bg-primary text-white border-primary":
                                            currentTab === index,
                                    }
                                )}
                            >
                                {option?.value}
                            </P>
                        </>
                    )}
                </TabButton>
            ))}
        </div>
    );
};

export default BorderedTab;
