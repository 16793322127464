import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { logo } from "../../assets";

import { SHOW_LOGIN_FORM } from "../../constants/contexConstant";
import {
    CONTACTUS,
    FAQ,
    LANDING,
    PORTFOLIO,
    PROFILE,
    PROJECTS,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import useScroll from "../../hooks/useScroll";

import auth from "../../services/auth";
import Button from "../button/Button";

import useClickOutside from "../../hooks/useClickOutside";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Flex from "../flex/Flex";
import Image from "../image/Image";
import CustomLink from "../link/Link";
import Container from "../styles/Container";
import Text from "../text/Text";
import Burger from "./BurgerIcon";
import Menu, { UserAvatar } from "./BurgerMenu";

export const HeaderHeight = {
    default: "75px",
    smallLaptop: "70px",
    tablet: "60px",
};

const HeaderFixed = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 110;
    box-sizing: border-box;
    transition: all ease-in 0.3s;
    background: ${(props) =>
        props.isVisible ? props.theme.color.white : "transparent"};
    box-shadow: ${(props) =>
        props.isVisible ? "0 1px 6px 0 rgb(0 0 0 / 15%)" : "none"};
`;
const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        height: 70px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: 85px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 0 20px;
        height: 70px;
    }
`;

const LogoImage = styled(Image)`
    height: 40px;
    width: auto;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: 35px;
        width: auto;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        height: 30px;
        width: auto;
    }
`;

const HeaderLinkSection = styled(Text)`
    display: flex;
    align-items: center;
    height: 100%;
    .links__wrap {
        display: flex;
        align-items: center;
        height: 100%;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        .links__wrap {
            display: none;
        }
    }
`;

const BurgerMenu = styled.div`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: unset;
    }
`;

const Avatar = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    position: relative;
    cursor: pointer;
`;

const ProfileDrop = styled.div`
    display: flex;
    flex-direction: column;
    transform: ${({ profileDropOpen }) =>
        profileDropOpen ? "translateY(0)" : "translateY(-180%)"};
    align-items: center;
    padding: 25px;
    width: 200px;
    background-color: ${(props) => props.theme.color.main};
    position: absolute;
    right: 0px;
    top: ${HeaderHeight.default};
    transition: transform 0.3s ease-in-out;
    margin-top: -1px;
    z-index: 10;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        top: ${HeaderHeight.smallLaptop};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        top: ${HeaderHeight.smallLaptop};
    }
`;
const LinkDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;
const styles = { marginBottom: "20px" };

const HeaderLinkContainer = styled.div`
    white-space: nowrap;
    position: relative;
    transition: all ease 0.5s;
    transform: ${(props) => (props.isActive ? "scale(1.1)" : "")};
    display: flex;
    align-items: center;
    height: 100%;
`;
/* @TODO ==> change active link color  Tue Oct 04  */
const NavLink = styled(Link)`
    color: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.primary};
    text-decoration: none;
    transition: all linear 0.2s;
    font-size: ${(props) => props.theme.fontSize.default[props.size]};
    font-weight: 700;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px;
    line-height: ${(props) => props.lineHeight || "1.6em"};
    transition: 0.3s;

    :hover {
        color: ${(props) => props.theme.color.primary};
    }
    ::after {
        content: "";
        background-color: ${(props) =>
            props.isActive ? props.theme.color.primary : ""};
        height: 4px;
        position: absolute;
        bottom: 3px;
        left: 0;
        transition: all ease-in 0.5s;
        display: block;
        width: ${(props) => (props.isActive ? "100%" : "0")};
    }

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.tablet[props.size]};
    }

    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.mobile[props.size]};
    }
`;

export const HeaderBtn = styled(Button)`
    padding: 0.3rem;
    margin-left: 10px;
    border-radius: 100px;
    padding: 5px 20px;
`;
const LinkWrap = styled(Link)`
    margin-left: 0px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        margin-left: 0px;
    }
`;
const ThumbnailBox = styled(Flex.Container)`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: flex;
    }
`;
const HeaderLink = ({ route }) => {
    const isActive = window.location.pathname === route.path;
    return (
        <HeaderLinkContainer isActive={isActive}>
            <NavLink isActive={isActive} to={route.path} size="s">
                {route.name}
            </NavLink>
        </HeaderLinkContainer>
    );
};

const Header = (props) => {
    const { width } = useWindowDimensions();
    const profileRef = useRef(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { state, dispatch } = useContextState();
    const [profileDropOpen, setDrop] = useState(false);

    const handleDrop = useCallback(() => {
        setDrop(!profileDropOpen);
    }, [setDrop, profileDropOpen]);

    useClickOutside(profileRef, () => {
        setDrop(false);
    });
    const toggleBurgerMenu = useCallback(
        (isOpen) => {
            if (isOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "";
            }
            setOpen(isOpen);
        },
        [setOpen]
    );

    const handleLogout = (e) => {
        e.preventDefault();

        auth.logout(dispatch)
            .then(() => {
                history.push(LANDING);
            })
            .catch(() => {});
    };

    const { scrollY } = useScroll();

    const handleLoginForm = (e) => {
        e.preventDefault();
        dispatch({ type: SHOW_LOGIN_FORM });
    };
    const isVisible =
        location.pathname === PROJECTS ||
        location.pathname === PORTFOLIO ||
        scrollY > 50;
    return (
        <>
            <HeaderFixed isVisible={isVisible}>
                <Container>
                    <HeaderContainer>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <BurgerMenu>
                                <Burger
                                    isBurgerMenuOpen={open}
                                    toggleBurgerMenu={toggleBurgerMenu}
                                />
                                <Menu
                                    links={props.burgerLinks}
                                    isAuthenticated={props.isAuthenticated}
                                    isBurgerMenuOpen={open}
                                    toggleBurgerMenu={toggleBurgerMenu}
                                />
                            </BurgerMenu>

                            <LinkWrap
                                to={LANDING}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <LogoImage src={logo} />
                            </LinkWrap>
                        </div>

                        <HeaderLinkSection>
                            <div className="links__wrap">
                                {props.links.map((route, index) => (
                                    <HeaderLink route={route} key={index} />
                                ))}
                            </div>

                            {!state?.user && (
                                <Text weight="bold" size="s" color="white">
                                    <HeaderBtn
                                        type="solid"
                                        color="white"
                                        backgroundColor="primary"
                                        fontSize="s"
                                        fontWeight="normal"
                                        onClick={handleLoginForm}
                                    >
                                        Login
                                    </HeaderBtn>
                                </Text>
                            )}

                            {state.user && (
                                <Avatar onClick={handleDrop}>
                                    <UserAvatar size="45px" fontSize="m" />
                                    <ProfileDrop
                                        id="profiledrop"
                                        profileDropOpen={profileDropOpen}
                                        onClick={handleDrop}
                                        ref={profileRef}
                                    >
                                        <LinkDiv>
                                            <CustomLink
                                                color="white"
                                                size="s"
                                                to={PROFILE}
                                                isInteractive={false}
                                                style={styles}
                                            >
                                                My Profile
                                            </CustomLink>
                                        </LinkDiv>
                                        <Button
                                            type="solid"
                                            backgroundColor="primary"
                                            color="white"
                                            size="s"
                                            style={{ width: "100%" }}
                                            onClick={handleLogout}
                                        >
                                            Log Out
                                        </Button>
                                    </ProfileDrop>
                                </Avatar>
                            )}
                        </HeaderLinkSection>
                    </HeaderContainer>
                </Container>
            </HeaderFixed>
            {/* <Countdown /> */}
        </>
    );
};

const ProfileThumb = ({ onClick, isOpen, ref, onLogOut, size }) => {
    return (
        <Avatar onClick={onClick}>
            <UserAvatar size={size} fontSize="m" />
            <ProfileDrop
                id="profiledrop"
                profileDropOpen={isOpen}
                onClick={onClick}
                ref={ref}
            >
                <LinkDiv>
                    <CustomLink
                        color="white"
                        size="s"
                        to={PROFILE}
                        isInteractive={false}
                        style={styles}
                    >
                        My Profile
                    </CustomLink>
                    <CustomLink
                        color="white"
                        size="s"
                        to={CONTACTUS}
                        isInteractive={false}
                        style={styles}
                    >
                        Contact Us
                    </CustomLink>
                    <CustomLink
                        color="white"
                        size="s"
                        to={FAQ}
                        isInteractive={false}
                        style={styles}
                    >
                        FAQs
                    </CustomLink>
                </LinkDiv>
                <Button
                    type="solid"
                    backgroundColor="primary"
                    color="white"
                    size="s"
                    style={{ width: "100%" }}
                    onClick={onLogOut}
                >
                    Log Out
                </Button>
            </ProfileDrop>
        </Avatar>
    );
};

Header.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string,
        })
    ),
    homeLink: PropTypes.shape({
        icon: PropTypes.string,
        path: PropTypes.string,
    }),
};

export default Header;
