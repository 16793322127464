import React from "react";
import { termsOfService } from "../../resources/js/images";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";

import Section from "../../shared/Section";
import Hero from "../contact-us/Hero";
import Terms from "./terms";

const termContent = (
    <Section
        className={" flex justify-center items-center w-full sm:py-[2rem]  "}
    >
        <Container className="flex items-center justify-start ">
            <H1 className="text-white font-semibold ">Terms of Service</H1>
        </Container>
    </Section>
);
const TermsAndConditions = () => {
    return (
        <div>
            <Hero
                url={termsOfService}
                content={termContent}
                containerClass={"bg-custom-gradient-1"}
                wrapperClass={
                    "min-h-[10vh] h-[300px] xl:min-h-[600px] 2xl:h-[600px] "
                }
            />
            <Terms />
        </div>
    );
};

export default TermsAndConditions;
