import moment from "moment";
import { useMemo } from "react";

const generatePayouts = (firstPayoutDate, installments, amount) => {
    let firstPayoutDateMs = firstPayoutDate * 1000;
    const currentDateMs = new Date().getTime();
    let completedPayouts = [];
    let upcomingPayouts = [];

    for (let index = 0; index < installments; index++) {
        let nextMoment = moment(firstPayoutDateMs).add(index, "M").valueOf();
        if (currentDateMs < nextMoment) {
            upcomingPayouts.push({
                no: `${index + 1}/${installments}`,
                date: moment(nextMoment).format("DD/MM/yyyy"),
                amount: amount,
            });
        } else {
            completedPayouts.push({
                no: `${index + 1}/${installments}`,
                date: moment(nextMoment).format("DD/MM/yyyy"),
                amount: amount,
            });
        }
    }

    return { completedPayouts, upcomingPayouts };
};
/**
 *
 * @param {which is first payouts date should be in Epoch Seconds E.X: 1720253256} firstPayoutDate
 * @param {the total number of installments} installments
 * @param {the amount needs to pay} amount
 * @returns
 */
const usePayouts = (firstPayoutDate, installments, amount) => {
    return useMemo(
        () => generatePayouts(firstPayoutDate, installments, amount),
        [amount, firstPayoutDate, installments]
    );
};

export default usePayouts;
