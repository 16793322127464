import React from "react";

import EarnRewards from "./EarnRewards";
import ProfileCard from "./ProfileCard";
import RewardHistory from "./RewardHistory";

const LeftSide = () => {
    return (
        <div>
            <ProfileCard />
            <EarnRewards />
            <RewardHistory />
        </div>
    );
};

export default LeftSide;
