import React from "react";

import Container from "../../shared/Container";
import H2 from "../../shared/typography/H2";
import SubHeading from "../../shared/typography/SubHeading";
import Tag from "../../shared/ui/tag/Tag";

const OurStory = () => {
    return (
        <Container className="mb-2 xl:mb-4">
            <div className={`flex flex-col  gap-1.6`}>
                <div>
                    <Tag
                        className={
                            "bg-white border-2 border-blue-500 rounded-[3rem]"
                        }
                        textClass={"text-gray-secondary"}
                        text={"Our Story"}
                    />
                </div>
                <H2 className={"text-start xl:font-normal"}>
                    Building a better tomorrow.
                </H2>

                <SubHeading>
                    Dedicated to creating sustainable solutions and innovations
                    that ensure a brighter, more sustainable future for all.
                </SubHeading>
            </div>
        </Container>
    );
};

export default OurStory;
