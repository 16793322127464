import React, { useState } from "react";

import Container from "../../../../shared/Container";
import Section from "../../../../shared/Section";
import InvestStepper from "../project-details/InvestStepper";
import InvestDetails from "./InvestDetails";
import PaymentMessage from "./PaymentMessage";

const InvestProcess = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [success, setSuccess] = useState(null);

    const handleUpdate = (success, data) => {
        if (success) {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
        setActiveStep(2);
    };
    return (
        <Section>
            <div className="bg-black-600 h-13 m-0 lg:hidden"></div>
            <div className="rounded-t-4 -mt-5 bg-white pt-4 lg:mt-13 max-w-[700px] mx-auto">
                <Container className="h-full">
                    <InvestStepper activeStep={activeStep} />
                    {activeStep === 1 ? (
                        <InvestDetails
                            activeStep={activeStep}
                            onUpdate={handleUpdate}
                        />
                    ) : (
                        <PaymentMessage
                            success={success}
                            onClick={setActiveStep}
                        />
                    )}
                </Container>
            </div>
        </Section>
    );
};

export default InvestProcess;
