import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { FaRegImage } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import { TbCameraPlus } from "react-icons/tb";
import ReactWebcam from "react-webcam";
import cn from "../../../lib/cn";
import P from "../../typography/P";

const CameraInput = forwardRef(
    (
        {
            label = "",
            onImageSelected,
            className = "",
            errorMsg = "",
            required = false,
            disabled = false,
        },
        ref
    ) => {
        const fileInputRef = useRef(null);
        const webcamRef = useRef(null);
        const [imageUrl, setImageUrl] = useState(null);
        const [isCameraOpen, setIsCameraOpen] = useState(false);
        const [resetDisabled, setResetDisabled] = useState(true);

        const capture = useCallback(() => {
            if (disabled) return;
            const imageSrc = webcamRef.current.getScreenshot();
            setImageUrl(imageSrc);
            if (onImageSelected) {
                onImageSelected(imageSrc);
            }
            setIsCameraOpen(false);
            setResetDisabled(false);
        }, [onImageSelected, disabled]);

        const handleUploadClick = () => {
            if (!disabled) fileInputRef.current.click();
        };

        const handleChange = (event) => {
            if (disabled) return;
            const file = event.target.files[0];
            if (file) {
                const url = URL.createObjectURL(file);
                setImageUrl(url);
                if (onImageSelected) {
                    onImageSelected(file);
                }
                setResetDisabled(false);
            }
        };

        const handleReset = () => {
            setImageUrl(null);
            setIsCameraOpen(false);
            onImageSelected(null);
            setResetDisabled(true);
        };

        useImperativeHandle(ref, () => ({
            resetImage: handleReset,
        }));

        return (
            <div className="flex flex-col">
                <P className="mb-1">
                    {label} {required && <span className="text-red">*</span>}
                </P>
                <div
                    className={cn(
                        "bg-gray-450 p-3 text-center flex flex-col items-center justify-center rounded-2xl",
                        errorMsg
                            ? "border border-red focus:ring-2 focus:ring-red-500"
                            : disabled
                            ? "border border-gray-100 focus:ring-2 bg-white focus:ring-gray-100"
                            : "border border-blue focus:ring-2 focus:ring-blue-500",
                        className
                    )}
                >
                    {imageUrl && (
                        <div className="w-full flex justify-end">
                            <P
                                className="text-blue cursor-pointer"
                                onClick={() => {
                                    setImageUrl(null);
                                    onImageSelected(null);
                                }}
                            >
                                <GrPowerReset />
                            </P>
                        </div>
                    )}
                    {!imageUrl && !isCameraOpen && !disabled && (
                        <>
                            <div className="flex gap-4 items-center justify-center">
                                {/* Capture live photo */}
                                <div
                                    className="flex flex-col items-center justify-center cursor-pointer"
                                    onClick={() => setIsCameraOpen(true)}
                                >
                                    <TbCameraPlus
                                        className="h-3 w-3 lg:h-4 lg:w-4"
                                        aria-label="Capture live photo"
                                    />
                                    <span className="mt-1 text-gray-secondary">
                                        Capture live photo
                                    </span>
                                </div>
                                <P>or</P>

                                <div
                                    className="flex flex-col items-center justify-center cursor-pointer"
                                    onClick={handleUploadClick}
                                >
                                    <FiUpload
                                        className="h-3 w-3 lg:h-4 lg:w-4"
                                        aria-label="Upload a file"
                                    />
                                    <span className="mt-1 text-gray-secondary">
                                        Upload a file
                                    </span>
                                </div>
                            </div>

                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={handleChange}
                                disabled={disabled}
                                className="hidden"
                            />
                        </>
                    )}

                    {isCameraOpen && !disabled && (
                        <div className="flex flex-col items-center">
                            <ReactWebcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={350}
                                height={300}
                                disabled={disabled}
                                className="rounded-xl"
                            />
                            <button
                                onClick={capture}
                                className="mt-3 bg-blue-500 text-white py-1 px-4 rounded"
                            >
                                Capture Photo
                            </button>
                            <button
                                onClick={() => setIsCameraOpen(false)}
                                className="mt-2 text-red-500"
                            >
                                Cancel
                            </button>
                        </div>
                    )}

                    {imageUrl && (
                        <img
                            src={imageUrl}
                            alt="Captured or Uploaded"
                            className="mt-1 w-44 h-auto rounded"
                        />
                    )}
                    {disabled && (
                        <FaRegImage
                            className="text-gray-500 h-24 w-24"
                            aria-label="an image"
                        />
                    )}
                </div>
                {errorMsg && (
                    <span className="text-red text-sm mt-1">{errorMsg}</span>
                )}
            </div>
        );
    }
);

export default CameraInput;
