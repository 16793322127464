import React from "react";

import moment from "moment";
import P from "../../../../shared/typography/P";
import Distribution from "../Distribution";
import DetailsPayout from "./DetailsPayouts";

const getPayoutData = (investment, listing, investmentTerms) => {
    let taxableObj = investmentTerms?.investmentTermDetailsDisplays.reduce(
        (objVal, el) => {
            return {
                remunerationPayout:
                    objVal.remunerationPayout + el.remunerationPayout,
                profitCapital:
                    el.capitalPayout + el.profitPayout + objVal.profitCapital,
            };
        },
        { remunerationPayout: 0, profitCapital: 0 }
    );

    let startDate = listing?.activationDate
        ? listing?.activationDate * 1000
        : moment(investment?.investment_date * 1000)
              .add(2, "M")
              .valueOf();

    return [
        {
            id: 1,
            name: "Start Date",
            value: moment(startDate).format("DD/MM/yyyy"),
        },
        {
            id: 2,
            name: "Taxable",
            value: `₹ ${Math.round(
                (taxableObj.remunerationPayout *
                    investment?.investment_amount) /
                    100000
            )}`,
        },
        {
            id: 3,
            name: "Tenure",
            value: `${
                investment?.paid_installments + investment?.pending_installments
            } Months`,
        },
        {
            id: 4,
            name: "Closure Date",
            value: moment(startDate)
                .add(
                    investment?.paid_installments +
                        investment?.pending_installments,
                    "M"
                )
                .format("DD/MM/yyyy"),
        },
        {
            id: 5,
            name: "Non-Taxable",
            value: `₹ ${Math.round(
                (taxableObj.profitCapital * investment?.investment_amount) /
                    100000
            )}`,
        },
        {
            id: 6,
            name: "Monthly Payout",
            value: "₹ " + investment?.monthly_gross_payout,
        },
    ];
};

const InvestmentDetails = (props) => {
    let investment = props?.investment;
    let listing = props?.listing;

    let distribution = Math.round(
        (investment?.paid_installments * 100) / investment?.pending_installments
    );

    let startDate = listing?.activationDate
        ? listing?.activationDate
        : moment(investment?.investment_date).add(2, "M").valueOf();
    return (
        <div>
            <div className="flex justify-between items-center mb-1.6 font-vietnam">
                <P className="font-vietnam">Repayment Schedule</P>
                <P className="text-gray font-vietnam">
                    {investment?.paid_installments}/
                    {investment?.pending_installments}
                </P>
            </div>
            <Distribution percentage={distribution} className="mb-2.4" />
            <div className="grid grid-cols-3 gap-x-3.2 gap-y-2.4 mb-4  ">
                {getPayoutData(investment, listing, props?.investmentTerms).map(
                    (payout) => (
                        <div key={payout.id}>
                            <P className="text-xs text-gray-550 mb-1.6 font-vietnam">
                                {payout.name}
                            </P>
                            <P className="text-sm text-gray font-vietnam">
                                {payout.value}
                            </P>
                        </div>
                    )
                )}
            </div>

            <DetailsPayout
                startDate={startDate}
                installments={props?.investmentTerms?.installments}
                investment={investment?.investment_amount}
            />
        </div>
    );
};

export default InvestmentDetails;
