import React from "react";
import styled from "styled-components";
import Text from "../text/Text";

const Form = styled.form`
    width: ${(props) => props.width || "100%"};
`;
const LabelStyled = styled(Text)`
    position: relative;
    margin-bottom: ${(props) => props.marginBottom || ""};
`;

const Required = styled.span`
    font-size: ${(props) => props.theme.fontSize.default.s};
`;

const Label = ({ label, required, size, marginBottom }) => {
    return (
        <LabelStyled size={size} marginBottom={marginBottom}>
            {label}
            {required ? <Required>*</Required> : null}
        </LabelStyled>
    );
};

export { Form, Label };
