import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Space from "../components/space/Space";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import {
    DOCMENT_UPLOAD_MSG,
    ERROR_MSG,
    validFileTypes,
} from "../constants/common";
import { SET_ERROR } from "../constants/contexConstant";
import {
    aadharRegEx,
    panRegEx,
    pinRegEx,
} from "../constants/regularExpression";
import { DOCUMENT_UPLOAD_ENDPOINT } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import auth from "../services/auth";
import request from "../services/request";
import BottomLineInput from "./BottomLineInput";
import UploadImage from "./UploadImage";

export const CheckBox = styled.input`
    height: 50px;
    cursor: pointer;
    margin-right: 10px;
`;
const DocumentInfo = () => {
    const { state, dispatch } = useContextState();
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState({
        panFront: "",
        aadharFront: "",
        aadharBack: "",
    });
    const [error, setError] = useState({});
    const [formValues, setFormValues] = useState({
        panNumber: "",
        aadharNumber: "",
        address: "",
        city: "",
        state: "",
        pin: "",
        panFront: null,
        aadharFront: null,
        aadharBack: null,
        tos: false,
    });

    const validate = (values) => {
        const errors = {};
        if (!values.panNumber) {
            errors.panNumber = "PAN is  required!";
        } else if (!panRegEx.test(values.panNumber)) {
            errors.panNumber = "Enter valid PAN number!";
        }
        if (!values.aadharNumber) {
            errors.aadharNumber = "Aadhar is  required!";
        } else if (!aadharRegEx.test(values.aadharNumber.replace(/\s/g, ""))) {
            errors.aadharNumber = "Enter valid Aadhar number!";
        }
        if (!values.address) {
            errors.address = "Address is  required!";
        }
        if (!values.city) {
            errors.city = "City is  required!";
        }
        if (!values.state) {
            errors.state = "State is  required!";
        }
        if (!values.pin) {
            errors.pin = "PIN is  required!";
        } else if (!pinRegEx.test(values.pin.replace(/\s/g, ""))) {
            errors.pin = "Enter valid pincode!";
        }
        if (!state.user.kycTableDisplay.isPanUploaded) {
            if (!values.panFront) {
                errors.panFront = "PAN Front is  required!";
            } else if (typeof values.panFront !== "object") {
                errors.panFront = "Invalid PAN Image";
            }
        }
        if (!state.user.kycTableDisplay.isAadharUploaded) {
            if (!values.aadharFront) {
                errors.aadharFront = "Aadhar Front is  required!";
            } else if (typeof values.aadharFront !== "object") {
                errors.aadharFront = "Invalid Aadhar Image";
            }
            if (!values.aadharBack) {
                errors.aadharBack = "Aadhar Back is  required!";
            } else if (typeof values.aadharBack !== "object") {
                errors.aadharBack = "Invalid Back Image";
            }
        }
        return errors;
    };

    const handleChangeDetails = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormValues({ ...formValues, [name]: value });
    };
    const clearInputFile = (name) => {
        setPreview({ ...preview, [name]: "" });
        setFormValues({ ...formValues, [name]: "" });
    };

    const handleOnChange = (e) => {
        const selectedFile = e.target.files[0];
        const fileName = selectedFile.name;
        const name = e.target.name;
        const addDot = fileName.length > 10 ? "..." : "";
        var extName = fileName.substring(fileName.lastIndexOf(".") + 1);
        if (validFileTypes.includes(extName)) {
            setPreview({
                ...preview,
                [name]: `${fileName.slice(0, 5)}${addDot}.${extName}`,
            });
            setError({ ...error, [name]: "" });
            setFormValues({ ...formValues, [name]: selectedFile });
        } else {
            setError({ ...error, [name]: "Invalid File Types!" });
            setPreview({ ...preview, [name]: "" });
            setFormValues({ ...formValues, [name]: "" });
        }
    };
    const handleUpload = async (e) => {
        e.preventDefault();
        const getValidFormData = validate(formValues);
        setError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            const formData = new FormData();

            setLoading(true);

            formData.append("pan", formValues.panFront);
            formData.append("aadhar_front", formValues.aadharFront);
            formData.append("aadhar_back", formValues.aadharBack);
            formData.append("aadhar_number", formValues.aadharNumber);
            formData.append("pan_number", formValues.panNumber);
            formData.append("address", formValues.address);
            formData.append("city", formValues.city);
            formData.append("state", formValues.state);
            formData.append("pinCode", formValues.pin);
            try {
                await request.authPost({
                    endpoint: DOCUMENT_UPLOAD_ENDPOINT,
                    body: formData,
                    isFormData: true,
                });

                auth.getUserProfile(dispatch);

                setLoading(false);
                toast.success(DOCMENT_UPLOAD_MSG);
            } catch (error) {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: error || ERROR_MSG,
                });
            }
        }
    };

    return (
        <Flex.Container
            style={{
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
            }}
        >
            <form action="" onSubmit={handleUpload}>
                <Flex.Container style={{ marginBottom: "20px" }}>
                    <Flex.Item width="100%" style={{ marginBottom: "20px" }}>
                        <Flex.Container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Flex.Item
                                width="45%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <BottomLineInput
                                    margin="0 0 10px"
                                    placeholder="Enter your PAN"
                                    label="PAN Number"
                                    value={formValues.panNumber}
                                    name="panNumber"
                                    onChange={handleChangeDetails}
                                    error={error.panNumber}
                                />
                                <Text
                                    style={{ marginBottom: "10px" }}
                                    size="xs"
                                >
                                    "Supported file formats jpg, jpeg, png, bmp,
                                    webp, heic, doc, docx, pdf"
                                </Text>
                                <Space
                                    tabletmargin={["0 0 20px"]}
                                    mobilemargin={["0 0 20px"]}
                                >
                                    <div>
                                        <UploadImage
                                            onChange={handleOnChange}
                                            name="panFront"
                                            label="PAN Image"
                                            media={preview.panFront}
                                            onClick={clearInputFile}
                                            errorMessage={error.panFront}
                                        />
                                    </div>
                                </Space>
                            </Flex.Item>
                            <Flex.Item
                                width="45%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <BottomLineInput
                                    margin="0 0 10px"
                                    placeholder="Enter your Aadhar"
                                    value={formValues.aadharNumber}
                                    name="aadharNumber"
                                    label=" Aadhar Number"
                                    onChange={handleChangeDetails}
                                    error={error.aadharNumber}
                                />
                                <Text
                                    style={{ marginBottom: "10px" }}
                                    size="xs"
                                >
                                    "Supported file formats jpg, jpeg, png, bmp,
                                    webp, heic, doc, docx, pdf"
                                </Text>

                                <Flex.Container
                                    style={{ gap: ".5rem" }}
                                    justifyContent="space-between"
                                >
                                    <Flex.Item>
                                        <UploadImage
                                            onChange={handleOnChange}
                                            name="aadharFront"
                                            media={preview.aadharFront}
                                            onClick={clearInputFile}
                                            label="Aadhar Front"
                                            errorMessage={error.aadharFront}
                                        />
                                    </Flex.Item>

                                    <Flex.Item>
                                        <UploadImage
                                            onChange={handleOnChange}
                                            name="aadharBack"
                                            media={preview.aadharBack}
                                            onClick={clearInputFile}
                                            label="Aadhar Back"
                                            errorMessage={error.aadharBack}
                                        />
                                    </Flex.Item>
                                </Flex.Container>
                            </Flex.Item>
                        </Flex.Container>
                    </Flex.Item>
                </Flex.Container>

                <Space margin={["0px 0px 0px 0px"]}>
                    <Flex.Container justifyContent="space-between">
                        <Flex.Item
                            width="45%"
                            tabletWidth="100%"
                            mobileWidth="100%"
                        >
                            <BottomLineInput
                                margin={error.address ? "0px" : "0 0 40px 0"}
                                // Component="textarea"
                                rows="1"
                                label="Address"
                                placeholder="Enter Your Address"
                                value={formValues.address}
                                name="address"
                                onChange={handleChangeDetails}
                                error={error.address}
                            />
                            <BottomLineInput
                                margin={error.city ? "0" : "0 0 40px 0"}
                                placeholder="City"
                                value={formValues.city}
                                name="city"
                                error={error.city}
                                onChange={handleChangeDetails}
                            />
                            <Flex.Container justifyContent="space-between">
                                <Flex.Item
                                    width="45%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                >
                                    <BottomLineInput
                                        margin={
                                            error.state ? "0px" : "0 0 0px 0"
                                        }
                                        placeholder="State"
                                        value={formValues.state}
                                        name="state"
                                        onChange={handleChangeDetails}
                                        error={error.state}
                                    />
                                </Flex.Item>
                                <Flex.Item
                                    width="45%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                >
                                    <BottomLineInput
                                        margin={error.pin ? "0px" : "0 0 0px 0"}
                                        placeholder="PIN"
                                        value={formValues.pin}
                                        name="pin"
                                        onChange={handleChangeDetails}
                                        error={error.pin}
                                    />
                                </Flex.Item>
                            </Flex.Container>
                        </Flex.Item>
                        <Space margin={["30px 0px 0px 0px"]}>
                            <Flex.Item
                                width="45%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Flex.Container
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Flex.Item width="100%">
                                        <Flex.Container
                                            style={{
                                                flexWrap: "nowrap",
                                            }}
                                        >
                                            <Flex.Item>
                                                <CheckBox
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                    checked={formValues.tos}
                                                    type="checkbox"
                                                    name="tos"
                                                    id="tos"
                                                    error={error.tos}
                                                    value={formValues.tos}
                                                    onChange={(e) =>
                                                        setFormValues({
                                                            ...formValues,
                                                            tos: !formValues.tos,
                                                        })
                                                    }
                                                />
                                            </Flex.Item>
                                            <Flex.Item>
                                                <Text size="m">
                                                    <InlineText color="primary">
                                                        I agree.{" "}
                                                    </InlineText>
                                                    Please upload non-password
                                                    protected file only Max size
                                                    3MB
                                                </Text>
                                            </Flex.Item>
                                        </Flex.Container>
                                    </Flex.Item>
                                    {error.tos && (
                                        <Flex.Item
                                            width="100%"
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <Text size="s" color="error">
                                                Required!
                                            </Text>
                                        </Flex.Item>
                                    )}
                                </Flex.Container>

                                <Button
                                    onClick={handleUpload}
                                    fontSize="m"
                                    backgroundColor="primary"
                                    type="solid"
                                    disabled={!formValues.tos}
                                    style={{ width: "100%", marginTop: "20px" }}
                                    isLoading={loading}
                                >
                                    Upload
                                </Button>
                            </Flex.Item>
                        </Space>
                    </Flex.Container>
                </Space>
            </form>
        </Flex.Container>
    );
};

export default DocumentInfo;
