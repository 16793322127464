import React from "react";

const VerticalPillSlider = ({
    currentSlide,
    data,
    className,
    handleSlideChange,
    length,
}) => {
    return (
        <div className={`flex items-center justify-center  ${className}`}>
            <div>
                <div className="h-90 bg-transparent border border-gray-secondary rounded-t-full rounded-b-full">
                    <div className="flex flex-col   w-full ">
                        {Array.from({ length: data?.length | length }).map(
                            (_, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleSlideChange(index)}
                                    className={`w-0.3 h-9 border-b cursor-pointer border-b-gray-secondary  ${
                                        index === data?.length - 1 ||
                                        index === length - 1
                                            ? "rounded-b-full w-1"
                                            : index == 0
                                            ? "rounded-t-full w-1"
                                            : "w-1"
                                    }  ${
                                        currentSlide === index
                                            ? "bg-linear-blue-white"
                                            : "bg-transparent "
                                    }
                                ${
                                    currentSlide === index - 1 || index === 0
                                        ? "border-none"
                                        : "border-b border-b-gray-secondary"
                                }
                                  transition duration-200`}
                                ></div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerticalPillSlider;
