import React from "react";
import styled from "styled-components";
import Text from "./Text";

export const InlineText = styled.span`
    font-size: ${(props) => props.theme.fontSize.default[props.size]};
    color: ${(props) =>
        props.isHighlighted
            ? props.theme.color.primary
            : props.color
            ? props.theme.color[props.color]
            : ""};
    opacity: ${(props) => props.opacity || ""};
    text-align: ${(props) => props.align || ""};
    text-transform: ${(props) => props.textTransform || ""};
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-weight: ${(props) => props.weight || ""};
    text-decoration: ${(props) => props.decoration || ""};
    line-height: ${(props) => props.lineHeight || "1.6em"};
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.tablet[props.size]};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.mobile[props.size]};
        line-height: ${(props) => props.smobileLineHeight || "1.3em"};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) => props.theme.fontSize.smallMobile[props.size]};
        line-height: ${(props) => props.smobileLineHeight || "1.3em"};
    }
`;

export default ({ children, ...props }) => (
    <Text {...props}>
        {children &&
            children.map(({ text, ...inlineTextProps }) => (
                <InlineText {...inlineTextProps}>{text}</InlineText>
            ))}
    </Text>
);
