import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Alert from "../alert/Alert";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import Section from "../section/Section";
import Text from "../text/Text";

const AlertIcon = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.theme.fontSize.default.xml};
    width: 100px;
    height: 100px;
    border-radius: 100%;
    color: ${(props) => props.theme.color.error};
    opacity: 0.8;
`;

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const history = useHistory();
    return (
        <Section style={{ paddingTop: "80px" }}>
            <Flex.Container
                justifyContent="center"
                alignItems="center"
                style={{
                    height: "100%",
                    flexDirection: "column",
                }}
            >
                <Alert
                    margin="0px 0 20px 0"
                    backgroundColor="error"
                    message={error?.message}
                    width="80%"
                />

                <AlertIcon>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                </AlertIcon>
                <Text color="black" size="xl">
                    We are sorry!
                </Text>
                <Button
                    size="s"
                    fontSize="m"
                    type="solid"
                    style={{ marginTop: "20px" }}
                    onClick={resetErrorBoundary}
                >
                    Try Again
                </Button>
            </Flex.Container>
        </Section>
    );
};

export default ErrorFallback;
