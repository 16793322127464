import React from "react";
import cn from "../../../../lib/cn";
import P from "../../../../shared/typography/P";

const overview = [
    {
        name: "Min. Investment",
        value: "₹5000",
    },
    {
        name: "Tenure",
        value: "5 years",
    },
    {
        name: "IRR",
        value: "20.88%",
    },
];
const Overview = ({ data = overview, className }) => {
    return (
        <div className={cn("flex justify-between", className)}>
            {data.map((item, i) => (
                <div key={i}>
                    <P className="text-gray-550 mb-1.6 text-xs md:text-xs xl:text-xs font-vietnam">
                        {item.name}
                    </P>
                    <P className="text-gray font-medium font-vietnam xl:text-base font-vietnam">
                        {item.value}
                    </P>
                </div>
            ))}
        </div>
    );
};

export default Overview;
