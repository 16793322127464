import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import { LOGIN } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import useAuth from "../hooks/useAuth";
/**
 *
 * @param {}
 * @returns
 * Auth thakle component return kora lagbe
 * Auth na thakle login e redirect korte hobe
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
    const { state, dispatch } = useContextState();
    const location = useLocation();
    const { isAuth } = useAuth(location?.pathname, dispatch);

    const isAccessible = isAuth && state?.user;

    if (isAuth === null || (isAuth && state.user === null)) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    if (isAccessible) {
        return <Route {...rest}>{(props) => <Component {...props} />}</Route>;
    } else
        return (
            <Redirect
                to={{
                    pathname: LOGIN,
                    state: { from: location },
                }}
            />
        );
};

export default PrivateRoute;
