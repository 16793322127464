import {
    DIGIO_SCRIPT_END_POINT,
    RAZORPAY_LOGO_ENDPOINT,
} from "../constants/routes";
import { isProdEnv } from "../helpers/helpers";

const loadDigio = () => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = DIGIO_SCRIPT_END_POINT;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

export const signDocument = async (
    documentId,
    investmentIdentifier,
    callback
) => {
    const isDigioLoaded = await loadDigio();
    // eslint-disable-next-line no-undef
    if (isDigioLoaded && Digio) {
        var options = {
            environment: isProdEnv() ? "production" : "sandbox",
            callback: function (response) {
                if (response.hasOwnProperty("error_code")) {
                    return console.log(
                        "ERROR has occurred, ERROR:" +
                            response.error_code +
                            " " +
                            response.message
                    );
                } else if (response?.txn_id) {
                    callback && callback();
                }
                return;
            },
            logo: RAZORPAY_LOGO_ENDPOINT,
            theme: {
                primaryColor: "#062124",
                secondaryColor: "#062124",
            },
        };
        // eslint-disable-next-line no-undef
        var digio = new Digio(options);
        digio.init();
        digio.submit(documentId, investmentIdentifier);
    } else {
        // TODO @prachi : Handle error
    }
};
