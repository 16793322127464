import React, { useEffect, useMemo, useState } from "react";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams,
    useRouteMatch,
} from "react-router-dom";
import { ERROR_MSG } from "../../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../../constants/contexConstant";
import {
    INVEST,
    OPPORTUNITY_DETAILS_ENDPOINT,
    PROJECTS,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { getCurrencyAmount } from "../../../helpers/helpers";
import useHeaderScroll from "../../../hooks/useHeaderScroll";
import cn from "../../../lib/cn";
import request from "../../../services/request";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import Tag from "../../../shared/ui/tag/Tag";
import Calculator from "../mobile/project-details/Calculator";
import Documents from "../mobile/project-details/Documents";
import Faq from "../mobile/project-details/Faq";
import Summary from "../mobile/project-details/Summary";
import LeftPart from "./LeftSide";
import RightPart from "./RightSide";

const detailsTabs = ["Summary", "Documents", "Calculator", "FAQS"];

let HEADER_HEIGHT = 60;

const ProjectDetails = () => {
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();

    const [detailsLoading, setDetailsLoading] = useState(false);

    const { dispatch } = useContextState();
    const { id } = useParams();

    const [body] = useState({
        id,
    });

    const { path, url } = useRouteMatch();
    const [listing, setListing] = useState(null);

    useEffect(() => {
        let getDetails = async () => {
            dispatch({ type: SET_LOADER });
            setDetailsLoading(true);
            try {
                let res = await request.authPost({
                    endpoint: OPPORTUNITY_DETAILS_ENDPOINT,
                    body,
                });

                setListing(res?.data?.listing);
                dispatch({ type: RESET_LOADER });
                setDetailsLoading(false);
            } catch (error) {
                dispatch({ type: RESET_LOADER });
                setDetailsLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        };
        getDetails();
    }, [body, dispatch]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        history.push(`${url}/${tab.toLowerCase()}`);
    };

    const [isScrollDisabled, setScrollDisabled] = useState(true);
    const { visible } = useHeaderScroll();

    const tabAndHeaderHeight = useMemo(
        () => (visible ? HEADER_HEIGHT : 0),
        [visible]
    );

    useEffect(() => {
        const onIntersection = (entries, observer) => {
            if (entries[0].isIntersecting) {
                setScrollDisabled(false);
            } else {
                setScrollDisabled(true);
            }
        };
        const observer = new IntersectionObserver(onIntersection, {
            rootMargin: `0px 0px -${
                window.innerHeight - tabAndHeaderHeight
            }px 0px`,
        });
        if (document.getElementById("second-container")) {
            observer.observe(document.getElementById("second-container"));
        }

        return () => {
            observer.disconnect();
        };
    }, [tabAndHeaderHeight]);

    const handleInvestNow = () => {
        history.push(`${INVEST}/${listing?.listingId}`);
    };

    /* @DESC:: ending invest now @habib610 Fri November 29,2024 */
    if (detailsLoading || listing === null) {
        return null;
    }
    return (
        <Section className="relative  mb-0">
            <div className="lg:container">
                {" "}
                <div className="lg:mt-5 lg:pt-5 lg:flex font-vietnam justify-between items-start">
                    <div className="lg:w-[65%]">
                        <div className="hidden lg:block">
                            <LeftPart />
                        </div>
                        <div className=" bg-black-600 pt-7 lg:bg-white lg:pt-1   lg:py-2 lg:border lg:border-gray-400 lg:rounded-3xl lg:mx-2 ">
                            <Container className="lg:hidden">
                                <div className="py-1.6 px-1.2 bg-blue-600 rounded-2 mb-1.6">
                                    <H3 className="text-lg mb-0.8 text-white">
                                        {listing?.plants[0]?.name}
                                    </H3>
                                    <div className="flex justify-between mb-2">
                                        <Tag
                                            text={listing?.connectionType}
                                            className="inline-flex items-center text-xs py-0 rounded-full bg-black-600 "
                                            textClass="px-0 text-xs text-gray-500"
                                        />
                                        <Tag
                                            text={`${
                                                listing?.investmentTerms[0]
                                                    ?.investorCount
                                            } ${
                                                listing?.investmentTerms[0]
                                                    ?.investorCount > 1
                                                    ? "Investors"
                                                    : "Investor"
                                            }`}
                                            className="inline-flex items-center text-xs py-0 rounded-full bg-green-50"
                                            textClass="px-0 text-xs text-green-400"
                                        />
                                    </div>

                                    <div className="bg-black-600 p-1.6 rounded-sm relative mb-1.6">
                                        <div className="flex justify-between items-center mb-1.6 gap-2">
                                            <P className="text-gray-550 ">
                                                {listing?.listingStatus}
                                            </P>
                                            {/* @TODO => fix @habib610 Sat November 30,2024 */}
                                            <P className="text-orange-300 ">
                                                Ends in 12 days
                                            </P>
                                        </div>
                                        <div className="w-full h-[28px] bg-blue-600 rounded-3xl relative mb-1.6">
                                            <div
                                                className={cn(
                                                    ` h-full rounded-3xl bg-linear-blue-white`
                                                )}
                                                style={{
                                                    width: `${(
                                                        (listing?.fundRaised *
                                                            100) /
                                                        listing?.fundLimit
                                                    ).toFixed(2)}%`,
                                                }}
                                            ></div>
                                            <div className="absolute top-[50%] -translate-y-[50%] right-2">
                                                <P className="text-white">{`${(
                                                    (listing?.fundRaised *
                                                        100) /
                                                    listing?.fundLimit
                                                ).toFixed(1)}%`}</P>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <P className="text-gray-500">
                                                {getCurrencyAmount(
                                                    listing?.fundRaised
                                                )}{" "}
                                                Raised
                                            </P>
                                            <P className="text-gray-500">
                                                Raising{" "}
                                                {getCurrencyAmount(
                                                    listing?.fundLimit
                                                )}
                                            </P>
                                        </div>
                                    </div>
                                </div>
                            </Container>

                            <div
                                id="second-container"
                                style={{
                                    height: `calc(100vh - ${tabAndHeaderHeight}px)`,
                                    top: tabAndHeaderHeight,
                                    zIndex: 100,
                                }}
                                className="bg-white rounded-t-[4rem]
                    transition-all ease-in-out duration-500 lg:!h-full lg:!top-0 sticky lg:static overflow-hidden lg:overflow-auto "
                            >
                                <div className="h-full flex flex-col">
                                    <Container>
                                        <div className="flex justify-between items-center pt-2.4 lg:hidden">
                                            <P className="text-gray mb-2.4 mt-2 text-base font-vietnam font-medium ">
                                                Project details
                                            </P>
                                            {/* @TODO => Invest now @habib610 Sun November 17,2024 */}
                                            <Button
                                                onClick={handleInvestNow}
                                                className="bg-gray rounded-full px-2 py-0.8 font-vietnam"
                                            >
                                                Invest Now
                                            </Button>
                                        </div>

                                        <div className="lg:flex lg:justify-between lg:items-cente lg:border-b lg:border-b-blue-50 lg:mt-2  lg:mb-2.4">
                                            <div className="flex  justify-between lg:justify-start  lg:gap-2 border-b border-b-blue-50 lg:border-none mb-2.4 lg:mb-0 ">
                                                {detailsTabs.map((tab, i) => (
                                                    <div
                                                        key={tab}
                                                        onClick={() =>
                                                            handleTabChange(tab)
                                                        }
                                                        className={`flex items-center   gap-1   ${
                                                            activeTab === i
                                                                ? "text-blue-500  "
                                                                : "text-gray-500"
                                                        }`}
                                                    >
                                                        <P
                                                            className={` font-vietnam border-b-2 md:text-sm xl:text-sm pb-1 lg:pb-3  ${
                                                                activeTab ===
                                                                tab
                                                                    ? "text-blue-500  border-b-blue-500 "
                                                                    : "text-gray-500 border-b-transparent"
                                                            }`}
                                                        >
                                                            {tab}
                                                        </P>
                                                    </div>
                                                ))}
                                            </div>
                                            <Button
                                                onClick={handleInvestNow}
                                                className=" hidden lg:flex lg:items-center bg-black-600 mb-1 !rounded-[3rem] text-sm md:text-sm xl:text-sm"
                                            >
                                                Invest Now
                                            </Button>
                                        </div>
                                    </Container>
                                    <Container
                                        className={`flex-1 pb-9 ${
                                            isScrollDisabled
                                                ? ""
                                                : "overflow-scroll lg:overflow-auto"
                                        }`}
                                    >
                                        <Switch>
                                            <Route
                                                path={`${PROJECTS}/${id}/summary`}
                                                component={() => (
                                                    <Summary
                                                        listing={listing}
                                                        isScrollDisabled={
                                                            isScrollDisabled
                                                        }
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={`${path}/documents`}
                                                component={() => (
                                                    <Documents
                                                        documents={
                                                            listing?.documents
                                                        }
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={`${path}/calculator`}
                                                component={Calculator}
                                            />
                                            <Route
                                                exact
                                                path={`${path}/faqs`}
                                                component={Faq}
                                            />
                                            <Route
                                                exact
                                                path={`${PROJECTS}/${id}`}
                                            >
                                                <Redirect
                                                    to={`${PROJECTS}/${id}/summary`}
                                                />
                                            </Route>
                                        </Switch>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" hidden lg:block lg:w-[35%]">
                        <div className="sticky top-5 lg:top-5">
                            <RightPart />
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default ProjectDetails;
