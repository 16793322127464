import React from "react";
import Container from "../../Container";
import Section from "../../Section";

const AppLayout = ({ topContent, bottomContent }) => {
    return (
        <Section>
            <div className="bg-black-600 mt-6 m-0 pb-6">
                <Container>
                    <div className="rounded-2 bg-blue-600 p-0.8">
                        {topContent}
                    </div>
                </Container>
            </div>
            <div className="rounded-t-4 -mt-5 bg-white pt-4">
                <Container className="">{bottomContent}</Container>
            </div>
        </Section>
    );
};

export default AppLayout;
