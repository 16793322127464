import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ProfileTitle } from ".";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import { Label } from "../components/form/Form";
import Input from "../components/input/Input";
import { ERROR_MSG, PASSWORD_UPDATE_SUCCESS_MSG } from "../constants/common";
import { SET_ERROR } from "../constants/contexConstant";
import { passRegEX } from "../constants/regularExpression";
import { CHANGE_PASSWORD_ENDPOINT } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import request from "../services/request";

export const ButtonStyled = styled(Button)`
    display: ${(props) => (props.fullWidth ? "block" : "inline-block")};
    width: 100%;
    margin-top: ${(props) => props.marginTop || ""};
    text-transform: ${(props) => props.textTransform || ""}; ;
`;

const ChangePassword = ({ title }) => {
    const { dispatch } = useContextState();
    const [password, setPassword] = useState({
        current_password: "",
        new_password: "",
        confirmPassword: "",
    });
    const [passwordError, setPasswordError] = useState({
        current_password: "",
        new_password: "",
        confirmPassword: "",
    });
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPassword({ ...password, [name]: value });
    };

    const [showCurrPass, setShowCurrPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);
    const [loading, setLoading] = useState(false);

    const validate = (values) => {
        const errors = {};

        if (!values.current_password) {
            errors.current_password = "Current password is required!";
        }

        if (!values.new_password) {
            errors.new_password = "New password is required!";
        } else if (values.current_password === values.new_password) {
            errors.new_password =
                "Current password and new password shouldn't be same!";
        } else if (!passRegEX.test(values.new_password)) {
            errors.new_password =
                "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!";
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = "New password is required!";
        } else if (values.confirmPassword !== values.new_password) {
            errors.confirmPassword =
                "New password and confirm new password doesn't matched!";
        }

        return errors;
    };

    const endpoint = CHANGE_PASSWORD_ENDPOINT;
    const handleUpdatePassWord = (e) => {
        e.preventDefault();

        const getValidFormData = validate(password);
        setPasswordError(getValidFormData);

        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            const body = {
                current_password: password.current_password,
                new_password: password.new_password,
            };

            request
                .authPut({ endpoint, body })
                .then((res) => {
                    if (res.status === "SUCCESS") {
                        setLoading(false);
                        toast.success(PASSWORD_UPDATE_SUCCESS_MSG);
                        let temp = {
                            current_password: "",
                            new_password: "",
                            confirmPassword: "",
                        };
                        setPassword(temp);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    dispatch({
                        type: SET_ERROR,
                        payload: error || ERROR_MSG,
                    });
                });
        }
    };

    return (
        <div>
            <ProfileTitle title={title} />

            <Flex.Container justifyContent="center">
                <Flex.Item width="70%" tabletWidth="90%" mobileWidth="100%">
                    <Label
                        label="Current password"
                        required
                        size="m"
                        marginBottom="5px"
                    />
                    <Input
                        name="current_password"
                        value={password.current_password}
                        onChange={handleChange}
                        margin="0 0 20px 0"
                        placeholder="Current password"
                        errorMessage={passwordError.current_password}
                        type={showCurrPass ? "text" : "password"}
                        onIconClick={() => setShowCurrPass((prev) => !prev)}
                        icon={showCurrPass ? faEyeSlash : faEye}
                    />
                    <Label
                        label="New password"
                        required
                        size="m"
                        marginBottom="5px"
                    />
                    <Input
                        name="new_password"
                        value={password.new_password}
                        onChange={handleChange}
                        margin="0 0 20px 0"
                        placeholder="New password"
                        errorMessage={passwordError.new_password}
                        type={showNewPass ? "text" : "password"}
                        onIconClick={() => setShowNewPass((prev) => !prev)}
                        icon={showNewPass ? faEyeSlash : faEye}
                    />
                    <Label
                        label="Confirm new password"
                        required
                        size="m"
                        marginBottom="5px"
                    />
                    <Input
                        name="confirmPassword"
                        value={password.confirmPassword}
                        onChange={handleChange}
                        margin="0 0 20px 0"
                        placeholder="Confirm new password"
                        errorMessage={passwordError.confirmPassword}
                        type={showConfPass ? "text" : "password"}
                        onIconClick={() => setShowConfPass((prev) => !prev)}
                        icon={showConfPass ? faEyeSlash : faEye}
                    />

                    <ButtonStyled
                        color="white"
                        backgroundColor="primary"
                        type="solid"
                        size="s"
                        style={{ marginTop: "10px" }}
                        onClick={handleUpdatePassWord}
                        isLoading={loading}
                    >
                        Submit
                    </ButtonStyled>
                </Flex.Item>
            </Flex.Container>
        </div>
    );
};

export default ChangePassword;
