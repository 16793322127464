import React from "react";
import cn from "../../../lib/cn";
import P from "../../typography/P";

const LinedTab = ({ data, activeTab, onClick, className }) => {
    return (
        <div
            className={cn(
                `flex  bg-black-600 lg:bg-white  justify-between lg:justify-start lg:gap-2 items-center  lg:mt-1 py-2.4 lg:py-0  transition-all ease-in-out duration-500  lg:border-b lg:border-blue-50`,
                className
            )}
        >
            {data.map((tab, i) => (
                <div
                    key={tab}
                    onClick={() => onClick(i)}
                    className={`flex items-center hover:cursor-pointer border-b-2 gap-1 pb-2 mt-1   ${
                        activeTab === i
                            ? "text-blue-500  border-b-blue-500"
                            : "text-gray-500 border-b-transparent"
                    }`}
                >
                    <P
                        className={` md:text-sm  xl:text-sm font-vietnam ${
                            activeTab === i
                                ? "text-blue-500   "
                                : "text-gray-500"
                        }`}
                    >
                        {tab}
                    </P>
                </div>
            ))}
        </div>
    );
};

export default LinedTab;
