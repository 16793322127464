import React, { useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import Dropdown from "../../../../shared/ui/Dropdown/Dropdown";
import Tab from "../../../../shared/ui/tab/Tab";
import ListItem from "./ListItem";

const TicketList = ({ onOpenTicket }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState("This month");

    const tickets = [
        {
            title: "Ticket #PR-27738",
            date: "March 20 • 19:05 PM",
            description:
                "Lorem ipsum dolor sit amet consectetur. Arcu risus dolor sit amet consectetur.",
            status: "In progress",
        },
        {
            title: "Ticket #PR-27739",
            date: "March 21 • 19:05 PM",
            description:
                "Lorem ipsum dolor sit amet consectetur. Arcu risus dolor sit amet consectetur.",
            status: "In progress",
        },
        {
            title: "Ticket #PR-27740",
            date: "March 22 • 19:05 PM",
            description:
                "Lorem ipsum dolor sit amet consectetur. Arcu risus dolor sit amet consectetur.",
            status: "Completed",
        },
        {
            title: "Ticket #PR-27741",
            date: "March 23 • 19:05 PM",
            description:
                "Lorem ipsum dolor sit amet consectetur. Arcu risus dolor sit amet consectetur.",
            status: "Completed",
        },
    ];

    const filterOptions = ["All", "In progress", "Completed"];
    const monthOptions = ["This Month", "Previous Month", "This Year"];

    const filteredTickets = tickets.filter((ticket) => {
        if (currentTab === 0) return true; // All
        if (currentTab === 1) return ticket.status === "In progress";
        if (currentTab === 2) return ticket.status === "Completed";
        return true;
    });

    const handleMonthSelect = (month) => {
        setSelectedMonth(month);
        setIsDropdownOpen(false);
    };

    return (
        <div>
            <div className="flex flex-wrap gap-1.5 my-2">
                {filterOptions.map((option, index) => (
                    <Tab
                        key={index}
                        classname={"rounded-xl bg-white"}
                        text={option}
                        active={currentTab === index}
                        onClick={() => {
                            setCurrentTab(index);
                            setIsDropdownOpen(false); // Close dropdown when changing tabs
                        }}
                    />
                ))}
                <Dropdown
                    selected={selectedMonth}
                    onSelect={handleMonthSelect}
                    isSelected={isDropdownOpen}
                    setIsSelected={setIsDropdownOpen}
                    wrapperClass={"bg-white border-gray-secondary"}
                    data={monthOptions}
                    className={"border border-gray-600/55  py-0.4 rounded-3xl"}
                    icon={<MdOutlineKeyboardArrowDown />} // You can use an icon here
                />
            </div>
            <div className="h-[350px] overflow-y-scroll">
                {filteredTickets.map((ticket, index) => (
                    <ListItem
                        key={index}
                        ticket={ticket}
                        onClick={() => onOpenTicket(ticket)}
                    />
                ))}
            </div>
        </div>
    );
};

export default TicketList;
