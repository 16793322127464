// Banner.js

import React from "react";
import styled from "styled-components";
import Flex from "../flex/Flex";
import Image from "../image/Image";
import Space from "../space/Space";
import Text from "../text/Text";

const BannerContainer = styled.div`
    width: 100%;
    background-size: cover;
    background-position: center;
`;

const ImageBanner = ({
    backgroundImage,
    foregroundImage,
    heading,
    subHead,
    mobile = false,
}) => {
    return (
        <BannerContainer style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Flex.Container justifyContent="space-between" alignItems="center">
                <Space margin={mobile ? ["3rem 30px"] : ["50px 30px"]}>
                    <Flex.Item
                        width="40%"
                        tabletWidth={mobile ? "60%" : "40%"}
                        mobileWidth="100%"
                    >
                        <Space margin={["1rem 0"]}>
                            <Text
                                lineHeight="1.2em"
                                size="xml"
                                color="balck"
                                fontFamily="Montserrat"
                                weight="700"
                            >
                                {heading}
                            </Text>
                        </Space>
                        <Space margin={["1rem 0"]}>
                            <Text
                                lineHeight="1.2em"
                                size="s"
                                fontFamily="Montserrat"
                            >
                                {subHead}
                            </Text>
                        </Space>
                    </Flex.Item>
                </Space>

                <Flex.Item
                    width="50%"
                    tabletWidth={mobile ? "100%" : "30%"}
                    mobileWidth="100"
                >
                    <Flex.Container justifyContent={"flex-end"}>
                        <Flex.Item
                            width="55%"
                            tabletWidth={mobile ? "40%" : "95%"}
                            mobileWidth="40%"
                        >
                            <Image src={foregroundImage} alt="Foreground" />
                        </Flex.Item>
                    </Flex.Container>
                </Flex.Item>
            </Flex.Container>
        </BannerContainer>
    );
};

export default ImageBanner;
