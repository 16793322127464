import styled from "styled-components";

const Card = styled.div`
    width: ${(props) => props.width || "auto"};
    max-width: ${(props) => props.maxWidth || "auto"};
    height: ${(props) => props.height || "auto"};
    min-height: ${(props) => props.minHeight || "auto"};
    background: ${(props) => `url(${props.background})` || ""};
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    background-size: ${(props) => props.backgroundSize || "cover"};
    background-repeat: no-repeat;
    border-radius: ${(props) => props.borderRadius || "3px"};
    padding: ${(props) => props.padding || ""};
    box-shadow: ${(props) => props.shadow || ""};
    box-sizing: border-box;
`;

export default Card;
