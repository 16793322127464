import React from "react";
import cn from "../../../../lib/cn";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import SubHeading from "../../../../shared/typography/SubHeading";
import StreamlineTree from "../../mobile/StreamlineTree";

const LedgerDash = ({ onClick, data }) => {
    const getValues = (data) => {
        return [
            { label: "Portfolio Value", amount: data?.portfolioValue },
            { label: "Invested Capital", amount: data?.investedCapital },
        ];
    };
    return (
        <div>
            <div className="w-full">
                <div className=" w-full gap-x-4 gap-y-2  flex-wrap xl:flex-nowrap  flex xl:gap-1 2xl:gap-5 items-stretch">
                    {getValues(data).map((value, index) => (
                        <div
                            key={index}
                            className="border border-blue-50 rounded-lg p-1.5 gap-y-1.6 text-center flex flex-col"
                        >
                            <H4 className="font-normal font-vietnam text-nowrap md:text-base xl:text-base">
                                {value.label}
                            </H4>
                            <P className="font-semibold  font-vietnam md:text-lg xl:text-lg text-nowrap">
                                ₹ {value.amount}
                            </P>
                        </div>
                    ))}
                    <StreamlineTree
                        className="font-vietnam xl:w-full 2xl:w-full bg-white rounded-xl border-blue-50 border"
                        headingText="text-black text-nowrap md:text-lg xl:text-lg font-semibold font-vietnam"
                        subText="text-black text-nowrap  mb-0.5 md:text-base xl:text-base"
                    />
                </div>
                <div className="flex flex-wrap justify-between items-stretch gap-3 mt-3">
                    <div className="w-full lg:w-4/6 xl:w-3/6 border border-blue-60 p-1.5 bg-gray-700 rounded-[2rem] ">
                        <div className="flex flex-col gap-2 mb-1 p-1">
                            <div className="flex items-center ">
                                <div className="w-2 h-2  bg-gray-400  rounded-0.7 mr-1"></div>
                                <H4 className="text-gray-secondary font-medium md:text-base xl:text-base  font-vietnam">
                                    Total Payout
                                </H4>
                                <H4 className="text-gray-secondary ml-auto md:text-lg xl:text-lg font-vietnam">
                                    ₹ {data?.totalPayout}
                                </H4>
                            </div>
                            <div className="flex items-center">
                                <div className="w-2 h-2 bg-blue  rounded-0.7 mr-1"></div>
                                <H4 className="text-gray-secondary font-medium md:text-base xl:text-base font-vietnam">
                                    Distributed Payout
                                </H4>
                                <H4 className="text-gray-secondary ml-auto md:text-lg xl:text-lg font-vietnam">
                                    ₹ {data?.distributedPayout}
                                </H4>
                            </div>
                        </div>
                        <div className="w-full h-[15px] bg-gray-400  rounded-3xl relative ">
                            <div
                                className={cn(
                                    ` h-full rounded-3xl bg-linear-blue-white`
                                )}
                                style={{
                                    width: `${
                                        (data?.distributedPayout * 100) /
                                        data?.totalPayout
                                    }%`,
                                }}
                            ></div>
                        </div>
                    </div>

                    {/* @TODO => Fix upcoming payouts @habib610 Wed December 11,2024 */}
                    {data?.upcomingPayouts?.length > 0 && (
                        <div className="flex flex-col w-1/3   gap-1  border-green-300  bg-linear-radial-yellow-green border p-1.6 rounded-2">
                            <P className="text-gray-500 md:text-base xl:text-base font-vietnam">
                                Upcoming Payout
                            </P>
                            <P className="text-black  md:text-base xl:text-base font-vietnam">
                                04/10/24
                            </P>

                            <SubHeading className="text-black md:text-xl lg:!text-xl xl:font-semibold font-vietnam">
                                ₹ 122351
                            </SubHeading>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LedgerDash;
