import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ERROR_MSG } from "../../constants/common";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import Modal from "../modal/Modal";
import Text from "../text/Text";

const Container = styled.div`
    background: ${(props) => props.theme.color.white};
    border-radius: 8px;
`;

const Header = styled.div`
    background: ${(props) => props.theme.color.red};
    border-radius: 8px 8px 0 0;
    padding: 1em 2em;
`;

const Content = styled(Flex.Container)`
    padding: 1em 2em;
`;

const ErrorDialog = ({ onClose, error }) => {
    const [shouldShow, setShouldShow] = useState(!!error);

    const dismissErrorDialog = useCallback(() => {
        onClose && onClose();
        setShouldShow(false);
    }, [onClose, setShouldShow]);

    const errorMsg = typeof error === "string" ? error : ERROR_MSG;
    return (
        <Modal
            isOpen={shouldShow}
            onClose={dismissErrorDialog}
            contentBg={false}
        >
            <Container>
                <Header>
                    <Text color="white" weight="400">
                        Error
                    </Text>
                </Header>
                <Content direction="column" alignItems="center">
                    <Text style={{ paddingBottom: "1em" }}>{errorMsg}</Text>
                    <Button onClick={dismissErrorDialog}>Okay</Button>
                </Content>
            </Container>
        </Modal>
    );
};

export default ErrorDialog;
