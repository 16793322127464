import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { offsetOTP } from "../../components/animation/offsetAnimation";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ModalAlt from "../../components/modal/ModalAlt";
import OtpInput from "../../components/otp-input/OtpInput";
import Space from "../../components/space/Space";
import Text from "../../components/text/Text";
import {
    ERROR_MSG,
    LOGIN_SUCCESS_MSG,
    PROFILE_UPDATE_SUCCESS_MSG,
} from "../../constants/common";
import {
    SET_ERROR,
    UPDATE_QUICK_USER,
    UPDATE_USER,
} from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import { UPDATE_PROFILE_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import auth from "../../services/auth";
import request from "../../services/request";
import DetailsFiled from "./DetailsField";
import { OTPButton } from "./PersonalDetails";

const OTP_LENGTH = 6;
const COUNTER_TIME = 30;

const ExistingUserLogin = ({ isOpen, onClose, onSuccess, carbonEmission }) => {
    const [isOTP, setIsOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const { dispatch } = useContextState();

    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({
        userId: "",
        otp: "",
    });

    const [resendCount, setResendCount] = useState(0);
    const [counter, setCounter] = useState(0);

    const closeModalHandler = () => {
        setFormData({ userId: "", otp: "" });
        setFormError({});
        setLoading(false);
        setIsOTP(false);
        onClose();
    };

    const validate = (values) => {
        const errors = {};
        if (!values.userId) {
            errors.userIdError = "User ID  is required!";
        } else if (
            !phoneRegEx.test(values.userId) &&
            !emailRegEx.test(values.userId)
        ) {
            errors.userIdError = "Enter valid email or phone number!";
        }
        return errors;
    };

    useEffect(() => {
        const updateCounter = () => {
            if (counter === 0) return;
            else {
                let temp = counter - 1;
                setCounter(temp);
            }
        };
        let timer = setInterval(updateCounter, 1000);
        if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [counter]);

    /* @DESC:: first call get OTP   */
    const handleGetOTP = async (e) => {
        e.preventDefault();
        const getValidFormData = validate(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            let val = emailRegEx.test(formData.userId)
                ? formData.userId
                : `91${formData.userId}`;
            try {
                await auth.loginOTP({
                    username: val,
                });
                setIsOTP(true);
                toast.success("OTP has been sent");
                setResendCount((prev) => prev + 1);
                setCounter(COUNTER_TIME);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };

    /* @TODO ==> GET OTP MORE TIMES  Tue Sep 20  */
    const handleResentOTP = async (mode) => {
        const getValidFormData = validate(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            let val = emailRegEx.test(formData.userId)
                ? formData.userId
                : `91${formData.userId}`;
            try {
                await auth.loginOTP({
                    username: val,
                    type: "retry",
                    mode,
                });
                if (mode === "text") {
                    toast.success("OTP has been sent");
                } else {
                    toast.success("Call has been sent");
                }

                setResendCount((prev) => prev + 1);
                setCounter(COUNTER_TIME * (resendCount + 1));
                setLoading(false);
            } catch (error) {
                setLoading(false);

                if (error === "otp_expired") {
                    setResendCount(0);
                    setCounter(0);
                }
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        } else {
        }
    };

    const validateWithOtp = (values) => {
        const errors = {};
        if (!values.userId) {
            errors.userIdError = "User ID  is required!";
        } else if (
            !phoneRegEx.test(values.userId) &&
            !emailRegEx.test(values.userId)
        ) {
            errors.userIdError = "Enter valid email or phone number!";
        }
        if (!values.otp) {
            errors.otpError = "OTP is required!";
        } else if (values.otp.length !== OTP_LENGTH) {
            errors.otpError = "OTP is incomplete!";
        }
        return errors;
    };

    /* @DESC::  final call submit for login  */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const getValidFormData = validateWithOtp(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);

            try {
                let val = emailRegEx.test(formData.userId)
                    ? formData.userId
                    : `91${formData.userId}`;
                await auth.login({
                    username: val,
                    password: formData.otp,
                    otpEnable: true,
                });
                await auth.getUserProfile(dispatch);
                toast.success(LOGIN_SUCCESS_MSG);

                let res = await request.authPut({
                    endpoint: UPDATE_PROFILE_ENDPOINT,
                    body: { carbonEmission },
                });
                dispatch({
                    type: UPDATE_USER,
                    payload: res?.data?.investor_data,
                });
                dispatch({
                    type: UPDATE_QUICK_USER,
                    payload: res?.data?.investor_data,
                });

                toast.success(PROFILE_UPDATE_SUCCESS_MSG);
                setLoading(false);
                onSuccess();

                closeModalHandler();
                setTimeout(() => {
                    document
                        .getElementById("offset-carboon-footer")
                        .scrollIntoView({
                            behavior: "smooth",
                        });
                }, 800);
            } catch (error) {
                setLoading(false);

                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };

    const handleChangeFunction = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div>
            <ModalAlt
                times={false}
                onClose={closeModalHandler}
                isOpen={isOpen}
                backgroundColor="primary"
            >
                <Space padding={["2.5rem 0 0"]}>
                    <form>
                        <Space margin={["0 0 20px "]}>
                            <Text
                                align="center"
                                weight="700"
                                size="xl"
                                color="white"
                            >
                                Login Form
                            </Text>
                        </Space>
                        <DetailsFiled
                            label="User Id"
                            placeholder="Enter Your User Id"
                            name="userId"
                            errorMessage={formError.userIdError}
                            value={formData.userId}
                            onChange={handleChangeFunction}
                        />

                        {isOTP && (
                            <AnimatePresence>
                                <Space
                                    margin={["0 0 30px "]}
                                    tabletmargin={["0 0 25px"]}
                                    mobilemargin={["0 0 10px"]}
                                >
                                    <Flex.Container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        as={motion.div}
                                        variants={offsetOTP}
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                    >
                                        <Flex.Item
                                            width="40%"
                                            tabletWidth="100%"
                                            mobileWidth="100%"
                                        >
                                            <Text
                                                color="white"
                                                weight="bold"
                                                size="m"
                                            >
                                                Enter OTP
                                            </Text>
                                        </Flex.Item>
                                        <Flex.Item
                                            width="50%"
                                            tabletWidth="100%"
                                            mobileWidth="100%"
                                        >
                                            <OtpInput
                                                label={false}
                                                value={formData.otp}
                                                errorMessage={
                                                    formError.otpError
                                                }
                                                onChange={handleChangeFunction}
                                                size={OTP_LENGTH}
                                                isInputNum={true}
                                            />
                                            <Flex.Container
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                {counter === 0 ? (
                                                    <>
                                                        <OTPButton
                                                            onClick={() =>
                                                                handleResentOTP(
                                                                    "text"
                                                                )
                                                            }
                                                            role="button"
                                                            size="s"
                                                            color="white"
                                                            weight="700"
                                                        >
                                                            Get OTP Text
                                                        </OTPButton>
                                                        <OTPButton
                                                            color="white"
                                                            onClick={() =>
                                                                handleResentOTP(
                                                                    "voice"
                                                                )
                                                            }
                                                            role="button"
                                                            weight="700"
                                                            size="s"
                                                        >
                                                            Get OTP on Call
                                                        </OTPButton>
                                                    </>
                                                ) : (
                                                    <Text
                                                        weight="700"
                                                        color="white"
                                                        size="s"
                                                    >
                                                        Resend OTP in {counter}s
                                                    </Text>
                                                )}
                                            </Flex.Container>
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </AnimatePresence>
                        )}

                        <Flex.Container justifyContent="center">
                            {!isOTP ? (
                                <Button
                                    // type="solid"
                                    fontSize="m"
                                    backgroundColor="white"
                                    hoverColor="primary"
                                    color="white"
                                    onClick={handleGetOTP}
                                    style={{ borderRadius: "50px" }}
                                    disabled={loading}
                                    isLoading={loading}
                                >
                                    GET OTP
                                </Button>
                            ) : (
                                <Button
                                    // type="solid"
                                    onClick={handleSubmit}
                                    fontSize="m"
                                    backgroundColor="white"
                                    hoverColor="primary"
                                    color="white"
                                    style={{ borderRadius: "50px" }}
                                    disabled={loading}
                                    isLoading={loading}
                                >
                                    Submit
                                </Button>
                            )}
                        </Flex.Container>
                    </form>
                </Space>
            </ModalAlt>
        </div>
    );
};

export default ExistingUserLogin;
