import React from "react";
import MobileCustomerSupport from "./mobile";
import Tickets from "./mobile/tickets";
const CutomerSupport = () => {
    return (
        <div className="md:pt-5 ">
            <div className="md:hidden">
                <MobileCustomerSupport />
            </div>
            <div className="hidden md:flex pt-5 mt-5 justify-center items-center w-full">
                <Tickets />
            </div>
        </div>
    );
};

export default CutomerSupport;
