import { motion } from "framer-motion";
import React from "react";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import P from "../../../shared/typography/P";
import VideoCards from "../../../shared/ui/Cards/VideoCards";
import { Carousel } from "../../../shared/ui/Carousel";

const CustomerFeedbackVideo = () => {
    const videoUrl = [
        "https://www.youtube.com/embed/md2bbLlXoks?si=ramRl8NLNmu0Bv0O",
        "https://www.youtube.com/embed/ZjvXRNjtldE?si=Y4gstQgQ_HvSp9nN",
    ];

    const renderCustomerVideos = () => {
        const insightCards = videoUrl.map((url, index) => (
            <VideoCards url={url} key={index} />
        ));

        return insightCards;
    };

    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="flex flex-col lg:flex-row justify-between items-center 2xl:mt-6"
                >
                    <div className=" w-full flex flex-col gap-0.8 lg:w-[40%] xl:w-[40%] 2xl:w-[40%]">
                        <SplitText
                            variants={wavyHeadings}
                            heading="h2"
                            className="text-title-xl text-gray font-semibold"
                        >
                            Hear from our satisfied customers
                        </SplitText>
                        <motion.div variants={opacity}>
                            <P className="text-gray-secondary text-base">
                                Here's what our clients love about us. Their
                                words resonate with our commitment to brighten
                                their lives through our services.
                            </P>
                        </motion.div>
                    </div>
                    <div className=" w-full mt-3.2  lg:mt-0 lg:w-[55%] xl:w-[50%] ">
                        <Carousel
                            slides={renderCustomerVideos()}
                            headclass="h-full"
                            autoSlide={true}
                            flexPos="justify-start lg:justify-center"
                            autoSlideInterval={3000}
                        />
                    </div>
                </motion.div>
            </Container>
        </Section>
    );
};

export default CustomerFeedbackVideo;
