import React from "react";
import cn from "../../lib/cn";
/* @TODO => fix responsive fonts @habib610 Thu October 10,2024 */
const LinkButton = ({ className, children, ...rest }) => {
    return (
        <button
            {...rest}
            className={cn(
                "text-sm text-primary hover:cursor-pointer underline flex items-center",
                className
            )}
        >
            {children}
        </button>
    );
};

export default LinkButton;
