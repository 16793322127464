import React, { useState } from "react";
import styled from "styled-components";
import Space from "../components/Spacing/Space";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import ModalAlt from "../components/modal/ModalAlt";
import Text from "../components/text/Text";
import {
    CLOSE_LOGIN_FORM,
    SHOW_SIGNUP_FORM,
} from "../constants/contexConstant";
import { useContextState } from "../context/ContextProvider";
import LoginWithEmailPass from "./LoginWithEmailPass";
import LoginWithOTP from "./LoginWithOTP";

const SignupButtonContainer = styled(Flex.Container)`
    position: absolute;
    top: 17px;
`;

const SignupButton = styled(Button)`
    border: solid 1px ${(props) => props.theme.color.white};
    border-radius: 4px;
    padding: 0.2rem 0.8em;
    margin-left: 10px;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        margin-left: 10px;
        padding: 0.5rem 1em;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    .buttonWrapper {
        width: 80%;
        background: ${(props) => props.theme.color.grey200};
        border-radius: 10px;
        /* width: min-content; */
        padding: 5px;
        height: auto;
        display: flex;
        justify-content: space-between;
        border-radius: 50px;
        button {
            height: 100%;
            border-radius: 50px;
        }
    }
`;
const LoginSliderBtn = styled(Button)`
    transition: ease-in-out 0.5s;
    border-color: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.grey200};
    background-color: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.grey200};
    color: ${(props) =>
        props.isActive ? props.theme.color.white : props.theme.color.black800};
    :hover {
        box-shadow: none;
    }
`;
const LOGIN_TYPE_OTP = "LOGIN_TYPE_OTP";
const LOGIN_TYPE_EMAIL = "LOGIN_TYPE_EMAIL";

const LoginModal = () => {
    const [activeLoginType, setActiveLoginType] = useState(LOGIN_TYPE_OTP);

    const { dispatch, state } = useContextState();
    const { loginForm } = state;

    const closeModalHandler = () => {
        setActiveLoginType(LOGIN_TYPE_OTP);
        dispatch({ type: CLOSE_LOGIN_FORM });
    };

    /* @DESC::  final call submit for login  */

    // TODO : add signup functionality
    const handleSignupFunction = () => {
        closeModalHandler();

        // history.push(LANDING);
        dispatch({ type: SHOW_SIGNUP_FORM });
    };

    return (
        <ModalAlt
            times={false}
            onClose={closeModalHandler}
            isOpen={loginForm}
            backgroundColor="primary"
            width={["100%", "600px", "600px"]}
        >
            <Space xxsp={["3.5rem 0 0"]}>
                <form>
                    <AuthenticationHead
                        type="New User"
                        actionText="Signup here"
                        onClick={handleSignupFunction}
                    />

                    <Space xxsm={["2rem 0 "]}>
                        <Text
                            align="center"
                            weight="700"
                            size="xl"
                            color="white"
                        >
                            Login With
                        </Text>
                    </Space>
                    <ButtonWrapper>
                        <div className="buttonWrapper">
                            <LoginSliderBtn
                                type="solid"
                                size="s"
                                backgroundColor="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveLoginType(LOGIN_TYPE_OTP);
                                }}
                                isActive={activeLoginType === LOGIN_TYPE_OTP}
                            >
                                OTP
                            </LoginSliderBtn>
                            <LoginSliderBtn
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveLoginType(LOGIN_TYPE_EMAIL);
                                }}
                                isActive={activeLoginType === LOGIN_TYPE_EMAIL}
                                background="primary"
                                size="s"
                            >
                                Password
                            </LoginSliderBtn>
                        </div>
                    </ButtonWrapper>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        {activeLoginType === LOGIN_TYPE_OTP ? (
                            <LoginWithOTP onClose={closeModalHandler} />
                        ) : (
                            <LoginWithEmailPass onClose={closeModalHandler} />
                        )}
                    </form>
                </form>
            </Space>
        </ModalAlt>
    );
};

export default LoginModal;

export const AuthenticationHead = ({ onClick, type, actionText }) => {
    return (
        <SignupButtonContainer alignItems="center">
            <Text size="s" color="white" weight="600">
                {type}
            </Text>
            <SignupButton type="button" color="white" onClick={onClick}>
                {actionText}
            </SignupButton>
        </SignupButtonContainer>
    );
};
