import React, { useState } from "react";
import H4 from "../../../../../shared/typography/H4";
import P from "../../../../../shared/typography/P";

const PayoutHead = () => {
    const [payouts, setPayouts] = useState([
        { label: "Total Payouts (23)", amount: 12234395.49 },
        { label: "Pending Payouts (23)", amount: 12234 },
        { label: "Completed Payouts (23)", amount: 12234 },
    ]);
    return (
        <>
            <div className=" hidden lg:flex flex-wrap items-center gap-1 xl:gap-3 mb-4">
                {payouts.map((value, index) => (
                    <div
                        key={index}
                        className="border  border-blue-50 rounded-lg p-1.5 gap-1.6 flex flex-col text-center "
                    >
                        <H4 className="font-normal text-nowrap md:text-base xl:text-base font-vietnam">
                            {value.label}
                        </H4>
                        <P className="font-medium md:text-lg xl:text-lg font-vietnam">
                            ₹ {value.amount.toFixed(2)}
                        </P>
                    </div>
                ))}
            </div>
            <div className="bg-blue-600 rounded-2 mb-2.4 lg:hidden">
                <P className="text-gray-500 mb-0.8 font-vietnam">
                    Total Payouts (50)
                </P>
                <P className="text-white text-xl mb-1.6 font-vietnam">
                    ₹ 12234395.49
                </P>

                <div className="flex justify-between gap-2 text-left mb-2.4">
                    <div className="w-1/2 bg-black-600 rounded-t-2 p-1.6 ">
                        <P className="text-sm text-gray-500 font-vietnam">
                            {" "}
                            Pending Payouts (23)
                        </P>
                        <P className="font-semibold text-base text-white font-vietnam">
                            ₹ 12234
                        </P>
                    </div>{" "}
                    <div className="w-1/2 bg-black-600 rounded-t-2 p-1.6 ">
                        <P className="text-sm  text-gray-500 font-vietnam">
                            Completed Payouts (23)
                        </P>
                        <P className="font-semibold text-base text-white font-vietnam">
                            ₹ 12234
                        </P>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PayoutHead;
