import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Space from "../components/Spacing/Space";
import { ButtonAlt } from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Input from "../components/input/Input";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { ERROR_MSG } from "../constants/common";
import { REMOVE_REDIRECT_PATH, SET_ERROR } from "../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../constants/regularExpression";
import { FORGET, PROJECTS } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import auth from "../services/auth";

const TYPE_PASSWORD = "password";
const TYPE_TEXT = "text";

const LoginWithEmailPass = ({ onClose }) => {
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        password: "",
        userId: "",
    });
    const [passType, setPassType] = useState(TYPE_PASSWORD);
    const history = useHistory();

    const handleChangeFunction = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const { state, dispatch } = useContextState();
    const validate = (values) => {
        const errors = {};
        if (!values.userId) {
            errors.userId = "User Id is required!";
        } else if (
            !phoneRegEx.test(values.userId) &&
            !emailRegEx.test(values.userId)
        ) {
            errors.userId = "Enter valid email or phone number!";
        }

        if (!values.password) {
            errors.password = "Password is required!";
        }
        return errors;
    };

    const closeModalHandler = () => {
        setFormData({ userId: "", otp: "" });
        setFormError({});
        setLoading(false);
        onClose();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const getValidFormData = validate(formData);

        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);

            try {
                let val = emailRegEx.test(formData.userId)
                    ? formData.userId
                    : `91${formData.userId}`;
                await auth.login({
                    username: val,
                    password: formData.password,
                    otpEnable: false,
                });

                await auth.getUserProfile(dispatch);

                setLoading(false);

                if (state.redirectPath) {
                    history.push(state.redirectPath);
                } else {
                    history.push(PROJECTS);
                }
                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });

                closeModalHandler();
            } catch (error) {
                setLoading(false);

                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };
    const updatePasswordFiledType = () => {
        setPassType(passType === TYPE_PASSWORD ? TYPE_TEXT : TYPE_PASSWORD);
    };

    const handleReset = () => {
        closeModalHandler();
        history.push(FORGET);
    };
    return (
        <div>
            <Space xlm={["2rem 0"]} stm={["1.5rem 0"]} xxsm={["1.5rem 0"]}>
                <Flex.Container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex.Item
                        width="25%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Text color="white" weight="bold" size="m">
                            User Id
                        </Text>
                    </Flex.Item>
                    <Flex.Item
                        width="70%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Input
                            placeholder="Enter your email or phone number"
                            name="userId"
                            errorMessage={formError.userId}
                            value={formData.userId}
                            onChange={handleChangeFunction}
                            backgroundColor="white"
                        />
                    </Flex.Item>
                </Flex.Container>
            </Space>
            <Space xlm={["2rem 0"]} stm={["1.5rem 0"]} xxsm={["1.5rem 0"]}>
                <Flex.Container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex.Item
                        width="25%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Text color="white" weight="bold" size="m">
                            Password
                        </Text>
                    </Flex.Item>
                    <Flex.Item
                        width="70%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Input
                            placeholder="Enter your password"
                            name="password"
                            errorMessage={formError.password}
                            value={formData.password}
                            onChange={handleChangeFunction}
                            backgroundColor="white"
                            type={passType}
                            icon={
                                passType === TYPE_PASSWORD ? faEyeSlash : faEye
                            }
                            onIconClick={() =>
                                updatePasswordFiledType(TYPE_PASSWORD)
                            }
                        />
                    </Flex.Item>
                </Flex.Container>
            </Space>

            <Flex.Container justifyContent="center">
                <ButtonAlt
                    onClick={handleSubmit}
                    xxs="m"
                    st="m"
                    weight="b"
                    color="white"
                    backgroundColor="white"
                    style={{ borderRadius: "50px", padding: "1rem 3rem" }}
                    disabled={loading}
                    isLoading={loading}
                >
                    Submit
                </ButtonAlt>
            </Flex.Container>

            <Space xxsm={["1.2rem 0"]}>
                <Flex.Container justifyContent="center">
                    <Text onClick={handleSubmit} size="s" color="white">
                        Forget Password? Click
                        <ResetBtn
                            role="button"
                            onClick={handleReset}
                            weight="bold"
                        >
                            Reset
                        </ResetBtn>
                    </Text>
                </Flex.Container>
            </Space>
        </div>
    );
};

export default LoginWithEmailPass;

const ResetBtn = styled(InlineText)`
    margin-left: 4px;
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
