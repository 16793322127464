import React from "react";

import cn from "../../../lib/cn";
import { streamLine } from "../../../resources/js/icons";
import P from "../../../shared/typography/P";

const StreamlineTree = ({
    carbon = "2.3 Tonnes",
    tree = "72 Trees",
    className = "",
    headingText = "",
    subText = "",
}) => {
    return (
        <div
            className={cn(
                "flex items-center gap-1.6  bg-black-600 p-1.6 rounded-2",
                className
            )}
        >
            <div className="h-4">
                <img src={streamLine} alt="stream_line" />
            </div>
            <div className="flex justify-between items-center gap-0.2 flex-1 ">
                <div>
                    <P
                        className={cn(
                            " font-vietnam text-gray-550 text-xs",
                            subText
                        )}
                    >
                        Co2 Offset
                    </P>
                    <P
                        className={cn(
                            " font-vietnam text-white text-sm font-medium",
                            headingText
                        )}
                    >
                        {carbon}
                    </P>
                </div>
                <div>
                    <P
                        className={cn(
                            " font-vietnam text-xs text-gray-550",
                            subText
                        )}
                    >
                        Equivalent to planting
                    </P>
                    <P
                        className={cn(
                            " font-vietnam text-white text-sm font-medium",
                            headingText
                        )}
                    >
                        {tree}
                    </P>
                </div>
            </div>
        </div>
    );
};

export default StreamlineTree;
