const generateRandomColors = (count) => {
    // Define base colors for blue, cyan, and light green
    const baseColors = ["#0052FF", "#00CCFF", "#4CAF50"]; // Base colors
    const colors = new Set();

    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    };

    const rgbToHex = (r, g, b) => {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b)
            .toString(16)
            .slice(1)
            .toUpperCase()}`;
    };

    const generateDistinctColor = (baseColor, index) => {
        const baseRgb = hexToRgb(baseColor);
        const r = Math.min(255, baseRgb.r + ((index * 30) % 100));
        const g = Math.min(255, baseRgb.g + ((index * 20) % 100));
        const b = Math.min(255, baseRgb.b + ((index * 10) % 100));
        return { r, g, b };
    };

    while (colors.size < count) {
        // Cycle through base colors
        for (let i = 0; i < baseColors.length; i++) {
            const baseColor = baseColors[i];
            // Generate a color with a specific index for variation
            const distinctColor = generateDistinctColor(baseColor, colors.size);
            colors.add(
                rgbToHex(distinctColor.r, distinctColor.g, distinctColor.b)
            );
            // Stop if we have enough colors
            if (colors.size === count) break;
        }
    }

    return Array.from(colors);
};

export default generateRandomColors;
