import React, { useCallback, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AlertNew from "../../../../components/alert/AlertNew";
import LoaderBox from "../../../../components/loading/LoaderBox";
import ModalAlt from "../../../../components/modal/ModalAlt";
import {
    ERROR_MSG,
    OPPORTUNITY_DETAILS_SUCCESS,
} from "../../../../constants/common";
import {
    INVESTOR_CREATE_ORDER_WALLET_ENDPOINT,
    MAKE_INVESTMENT_API_ENDPOINT,
    OPPORTUNITY_DETAILS_ENDPOINT,
} from "../../../../constants/routes";
import { useContextState } from "../../../../context/ContextProvider";
import { twoDecimalLocal } from "../../../../helpers/helpers";
import useNetwork from "../../../../hooks/useNetwork";
import { createRazorPayOrder } from "../../../../services/payment";
import request from "../../../../services/request";
import H2 from "../../../../shared/typography/H2";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";
import LabeledInput from "../../../../shared/ui/Form/LabeledInput";
import Line from "../../../../shared/ui/Line";

const InvestDetails = ({ onUpdate }) => {
    const { listingId } = useParams();

    const [body] = useState({ id: listingId });

    const { error, data, loading } = useNetwork(
        OPPORTUNITY_DETAILS_ENDPOINT,
        "POST",
        body,
        OPPORTUNITY_DETAILS_SUCCESS
    );
    // const [openCoupon, setOpenCoupon] = useState(false);

    const [paymentAmount, setPaymentAmount] = useState(50000);
    const [paymentAmountError, setPaymentAmountError] = useState("");

    const [showPaymentSummary, setShowPaymentSummary] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);

    const { state } = useContextState();

    let kyc = state?.user?.kycSummary;

    // const showKycAlert =
    //     kyc?.panVerificationStatus !== "VERIFIED" ||
    //     kyc?.aadharVerificationStatus !== "VERIFIED" ||
    //     kyc?.bankVerificationStatus !== "VERIFIED";

    const showKycAlert = false;

    // const findMoreCoupons = () => {
    //     setOpenCoupon((openCoupon) => !openCoupon);
    // };

    /* @DESC:: Migration 4th Sat June 15,2024 */
    const makePaymentHandler = useCallback(
        async (paymentId, orderId, signature) => {
            try {
                const endpoint = MAKE_INVESTMENT_API_ENDPOINT;

                let term =
                    data?.data?.listing?.investmentTerms[0]?.installments;
                let res = await request.authPost({
                    endpoint,
                    body: {
                        paymentId: paymentId,
                        orderId: orderId,
                        signature: signature,
                        term,
                    },
                });
                setShowPaymentSummary(false);
                onUpdate({
                    term: res?.data?.investment_term / 12,
                    invested: res?.data?.investment_amount,
                });
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
            }
        },
        [data?.data?.listing?.investmentTerms, onUpdate]
    );

    /* @DESC:: Migration 3rd Sat June 15,2024 */
    const createOrderInvestorWallet = useCallback(async () => {
        if (paymentAmount) {
            try {
                setPaymentLoading(true);

                const res = await request.authPost({
                    endpoint: INVESTOR_CREATE_ORDER_WALLET_ENDPOINT,
                    body: {
                        listingId: data?.data?.listing?.listingId,
                        amount: paymentAmount,
                        term: data?.data?.listing?.investmentTerms[0]
                            .installments,
                    },
                });
                await createRazorPayOrder(
                    res?.data?.order_display?.razorpayOrderId,
                    state.user,
                    makePaymentHandler
                );

                setPaymentLoading(false);
                return;
            } catch (error) {
                /* @TODO => Handle Sat June 15,2024 */
                setPaymentLoading(false);
                // const maxLimit =
                //     data?.data?.listing?.listingMaxLimit === undefined ||
                //     isNaN(data?.data?.listing?.listingMaxLimit)
                //         ? 0
                //         : Number(data?.data?.listing?.listingMaxLimit);

                // if (error?.data?.code === "EXCEEDED") {
                //     toast.error(
                //         `Exceeded MAX Investment Limit, you can max invest ₹${(
                //             maxLimit - Number(error?.data?.amountInvested)
                //         ).toLocaleString()} only`
                //     );

                //     toast.info(
                //         `Want to invest above ${maxLimit}, drop a mail to founders@srot.io`
                //     );
                //     return;
                // }
                toast.error(
                    error?.message && typeof error?.message === "string"
                        ? error?.message
                        : ERROR_MSG
                );
                return;
            }
        }
    }, [
        data?.data?.listing?.investmentTerms,
        data?.data?.listing?.listingId,
        makePaymentHandler,
        paymentAmount,
        state.user,
    ]);

    /* @DESC:: Migration 2nd Sat June 15,2024 */
    const handlePlaceOrderHandler = useCallback((amount) => {
        setShowPaymentSummary(true);
        setPaymentAmount(amount);
    }, []);

    /* @DESC:: Migration 1st call Sat June 15,2024 */
    const createOrderHandler = useCallback(() => {
        let listing = data?.data?.listing;
        if (
            !paymentAmount ||
            Number(listing?.listingMinLimit) > Number(paymentAmount)
        ) {
            setPaymentAmountError(
                `Minimum investment amount is ${listing?.listingMinLimit}.`
            );
            return;
        } else if (Number(listing?.listingMaxLimit) < Number(paymentAmount)) {
            setPaymentAmountError(
                `Maximum investment amount is ${listing?.listingMaxLimit}.`
            );
            return;
        }

        handlePlaceOrderHandler(paymentAmount);
    }, [data?.data?.listing, handlePlaceOrderHandler, paymentAmount]);

    if (data === null) return null;

    let content = null;
    if (loading) {
        content = (
            <div className="flex justify-center my-4">
                <LoaderBox />
            </div>
        );
    }

    if (!loading && error) {
        content = (
            <div className="flex justify-center my-4">
                <AlertNew color="error" backgroundColor="danger">
                    {error}
                </AlertNew>
            </div>
        );
    }

    if (!loading && !error && !data?.data?.listing?.listingId) {
        content = (
            <div className="flex justify-center my-4">
                <AlertNew color="error" backgroundColor="danger">
                    Projects Details Not Available
                </AlertNew>
            </div>
        );
    }

    if (!loading && !error && data?.data?.listing?.listingId) {
        const listing = data?.data?.listing || {};

        let plants = listing?.plants || [];
        let investmentTerms = listing?.investmentTerms || [];

        let isInvestmentAvailable = listing?.listingStatus === "FUNDING";

        let capacity = Number(plants[0]?.capacity) / 1000 || 0;

        let solarAsset =
            Number(paymentAmount) / (Number(listing?.fundLimit) / capacity);

        let grossPayoutMonthlyPerLakh =
            Number(investmentTerms[0]?.grossPayoutMonthlyPerLakh) || 0;

        let monthlyPayout =
            (grossPayoutMonthlyPerLakh * paymentAmount) / 100000;

        let installments = Number(investmentTerms[0]?.installments) || 0;

        let netPayout = monthlyPayout;

        const handlePaymentAmountChange = (e) => {
            let amount = e.target.value;

            setPaymentAmount(amount);
            if (isNaN(amount)) {
                setPaymentAmountError("Amount must be numeric values");
            } else if (Number(listing?.listingMinLimit) > Number(amount)) {
                setPaymentAmountError(
                    `Minimum investment amount is ${listing?.listingMinLimit}.`
                );
            } else if (Number(listing?.listingMaxLimit) < Number(amount)) {
                setPaymentAmountError(
                    `Maximum investment amount is ${listing?.listingMaxLimit}.`
                );
            } else {
                setPaymentAmountError("");
            }
        };

        content = (
            <>
                <div className="pb-4 h-full">
                    <>
                        <H2 className="text-xl  xl:text-3xl 2xl:text-3xl  mb-0.8 text-gray mt-6 font-vietnam">
                            We’re glad you’re choosing to invest with Srot!
                        </H2>
                        <P className="mb-3.2 font-vietnam">
                            Let’s double check your details & continue!
                        </P>
                        <LabeledInput
                            errorMessage={paymentAmountError}
                            label={" Enter Investment amount"}
                            placeholder="50,000"
                            value={paymentAmount}
                            onChange={handlePaymentAmountChange}
                        />
                        {/* <LabeledInput
                 errorMessage=""
                 wrapperClassName="mb-1.6"
                 placeholder="10%-REFERRALOFF"
                 label={
                     <>
                         Apply coupon to add discount{" "}
                         <img src={infoCircle} alt="info" />
                     </>
                 }
             /> */}
                        {/* <LinkButton onClick={findMoreCoupons} className="mb-3.2">
                 Find more Coupon
             </LinkButton>

             <div className="px-1.6 py-0.8 flex justify-between items-center gap-0.8 rounded-2 bg-radial-brown mb-3.2">
                 <div>
                     <P className="text-gray mb-0.8 font-vietnam">
                         Apply coupon to add discount
                     </P>
                     <Coin value={10000} />
                 </div>

                 <LinkButton>Redeem Now</LinkButton>
             </div>

             <LabeledInput
                 errorMessage=""
                 wrapperClassName="mb-1.6"
                 placeholder="48,320"
                 label={
                     <>
                         Effective Amount
                         <img src={infoCircle} alt="info" />
                     </>
                 }
             /> */}
                        <Button
                            disabled={
                                paymentAmountError ||
                                !isInvestmentAvailable ||
                                showKycAlert
                            }
                            onClick={createOrderHandler}
                            className="bg-gray w-full rounded-full"
                        >
                            Continue
                        </Button>

                        {/* <MoreCoupons isOpen={openCoupon} onClick={findMoreCoupons} /> */}

                        {showKycAlert && (
                            <div className="bg-red-100  py-1 text-center my-1 rounded shadow-sm">
                                <P className="text-red-200 text-nowrap flex justify-center">
                                    Before make invest, Complete
                                    <Link
                                        className="underline mx-0.5 cursor-pointer font-bold text-primary"
                                        to="/kyc/summary"
                                    >
                                        KYC
                                    </Link>{" "}
                                    to make invest
                                </P>
                            </div>
                        )}
                    </>
                </div>
                <ModalAlt
                    topPadding="20px"
                    isOpen={showPaymentSummary}
                    onClose={() => setShowPaymentSummary((prev) => !prev)}
                    times={false}
                >
                    <H4 className="mb-2 font-vietnam font-medium">
                        Payment Summary
                    </H4>
                    <div>
                        <div className="flex justify-between pb-1">
                            <P className="font-vietnam">Amount</P>
                            <P className="font-vietnam">
                                {twoDecimalLocal(Number(paymentAmount))}
                            </P>
                        </div>
                        <div className="flex justify-between pb-1">
                            <P className="font-vietnam">Duration</P>
                            <P className="font-vietnam">
                                {Number(installments) / 12} Years
                            </P>
                        </div>
                        <div className="flex justify-between pb-1">
                            <P className="font-vietnam">Solar Asset</P>
                            <P className="font-vietnam">
                                {twoDecimalLocal(Number(solarAsset))} KWp
                            </P>
                        </div>
                        <div className="flex justify-between">
                            <P className="font-vietnam">Monthly Payout</P>
                            <P className="font-vietnam">
                                {twoDecimalLocal(Number(monthlyPayout))}
                            </P>
                        </div>

                        <Line className="bg-gray-500" />
                        <div className="flex justify-between ">
                            <P size="m" color="black" weight="500">
                                Net Payout
                            </P>
                            <P>{twoDecimalLocal(Number(netPayout))}</P>
                        </div>

                        <Line className="bg-gray-500" />
                        <div className="flex justify-between mb-2">
                            <P className="font-vietnam font-medium">
                                Amount to be paid
                            </P>
                            <P className="font-vietnam font-medium">
                                {twoDecimalLocal(Number(paymentAmount))}
                            </P>
                        </div>
                    </div>

                    <Button
                        className="w-full"
                        disabled={paymentLoading}
                        isLoading={paymentLoading}
                        onClick={createOrderInvestorWallet}
                    >
                        PAY NOW
                    </Button>
                </ModalAlt>
            </>
        );
    }

    return content;
};

export default InvestDetails;
