import React, { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { COMMON_SUCCESS_MSG } from "../../constants/common";
import { KYC_OCR_AADHAR } from "../../constants/routes";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import CameraInput from "../../shared/ui/Form/CameraInput";
import TextField from "../../shared/ui/Form/TextField";

const Adhaar = ({ handleNext, Aadhar, form }) => {
    const [disabled, setDisabled] = useState(
        Aadhar?.status === "VERIFIED" || Aadhar?.status === "FAILED"
    );

    const [formData, setFormData] = useState({
        adhaarNumber: Aadhar?.userAadharNumber || "",
        frontphoto: form?.frontphoto || null,
        backside: form?.backside || null,
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState(false);
    const topRef = useRef(null);
    const adhaarRef = useRef(null);

    useEffect(() => {
        scrollToTop();
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleFrontImageSelected = (file) => {
        setFormData({ ...formData, frontphoto: file });
        setErrors({ ...errors, frontphoto: "" });
    };

    const handleBackImageSelected = (file) => {
        setFormData({ ...formData, backside: file });
        setErrors({ ...errors, backside: "" });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "", api: "" });
    };

    const handleSubmit = async () => {
        if (!disabled) {
            const { adhaarNumber, frontphoto, backside } = formData;

            if (!adhaarNumber || !frontphoto || !backside) {
                setErrors({
                    adhaarNumber: !adhaarNumber
                        ? "Aadhar number is required"
                        : "",
                    frontphoto: !frontphoto
                        ? "Front side of Aadhar card is required"
                        : "",
                    backside: !backside
                        ? "Back side of Aadhar card is required"
                        : "",
                });
                return;
            }

            const data = new FormData();
            data.append("aadharNumber", adhaarNumber);
            data.append("aadharFrontDocument", frontphoto);
            data.append("aadharBackDocument", backside);

            setLoading(true);

            try {
                const response = await request.authPost({
                    endpoint: KYC_OCR_AADHAR,
                    isFormData: true,
                    body: data,
                });
                if (response?.data?.data?.verificationStatus === "VERIFIED") {
                    toast.success(response.message || COMMON_SUCCESS_MSG);
                    setSucess(true);
                    handleNext(formData, "bank");
                }
            } catch (error) {
                setErrors({
                    ...errors,
                    api: error,
                });
            } finally {
                setLoading(false);
                setDisabled((prev) => !prev);
                console.log("disable in", disabled);
            }
        }
    };

    const handleFocusScroll = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };
    const frontCameraInputRef = useRef(null);
    const backCameraInputRef = useRef(null);

    const handleReset = () => {
        frontCameraInputRef.current.resetImage();
        backCameraInputRef.current.resetImage();
        setErrors({});
        setDisabled(false);
    };
    console.log("disable", disabled);
    return (
        <div>
            <H4 className="font-bold my-2">Aadhar Card</H4>

            <CameraInput
                onImageSelected={handleFrontImageSelected}
                label="Front side of Aadhar Card"
                className="mb-3"
                errorMsg={errors.frontphoto}
                disabled={disabled}
                ref={frontCameraInputRef}
                required={true}
            />
            <CameraInput
                onImageSelected={handleBackImageSelected}
                label="Back side of Aadhar Card"
                className="mb-3"
                errorMsg={errors.backside}
                disabled={disabled}
                ref={backCameraInputRef}
                required={true}
            />
            <TextField
                label={"Enter your Aadhar Card Number"}
                value={formData?.adhaarNumber}
                onChange={handleChange}
                inputWrapper="!placeholder-gray-500 w-full rounded-md"
                name={"adhaarNumber"}
                placeholder="9999 8888 4444"
                type={"text"}
                required={true}
                ref={adhaarRef}
                errorMsg={errors.adhaarNumber}
                onFocus={() => handleFocusScroll(adhaarRef)}
                disabled={disabled}
            />

            {((disabled && Aadhar?.status === "FAILED") || errors.api) && (
                <>
                    <div className="flex gap-1 items-center mt-1.5">
                        <P className="bg-red-200 text-sm md:text-sm xl:text-sm text-white rounded-full px-0.4 py-0.4 ">
                            <RxCross1 />
                        </P>
                        <P>{errors.api ? errors.api : Aadhar?.message}</P>
                    </div>
                    <P
                        className="text-blue underline cursor-pointer"
                        onClick={() => {
                            handleReset();
                            setFormData({
                                adhaarNumber: "",
                                frontphoto: null,
                                backside: null,
                            });
                        }}
                    >
                        Retry to upload Aadhar Document
                    </P>
                </>
            )}
            {(sucess || Aadhar?.status === "VERIFIED") && (
                <div className="flex gap-1 items-center mt-1.5">
                    <P className="bg-green-400 text-sm md:text-sm xl:text-sm text-white rounded-full px-0.4 py-0.4 ">
                        <FaCheck />
                    </P>
                    <P>Aadhar card details are confirmed</P>
                </div>
            )}
            {!disabled && Aadhar?.status !== "VERIFIED" && (
                <Button
                    className="w-full mt-5 mb-[10rem] md:mb-3 bg-black text-white !rounded-[3rem]"
                    onClick={handleSubmit}
                    disabled={loading || disabled}
                    isLoading={sucess}
                >
                    Proceed
                </Button>
            )}
        </div>
    );
};

export default Adhaar;
