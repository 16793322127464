import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Space from "../components/Spacing/Space";
import { virtualOTP } from "../components/animation/virtualSolarAnimation";
import { ButtonAlt } from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Input from "../components/input/Input";
import OtpInput from "../components/otp-input/OtpInput";
import Text from "../components/text/Text";
import { ERROR_MSG } from "../constants/common";
import { REMOVE_REDIRECT_PATH, SET_ERROR } from "../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../constants/regularExpression";
import { PROJECTS } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import auth from "../services/auth";
import { OTPButton } from "./SignupModal";

const OTP_LENGTH = 6;
const COUNTER_TIME = 30;
const LoginWithOTP = ({ onClose }) => {
    const [isOTP, setIsOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState({});

    const { state, dispatch } = useContextState();
    const history = useHistory();
    const [formData, setFormData] = useState({
        userId: "",
        otp: "",
    });

    const [resendCount, setResendCount] = useState(0);
    const [counter, setCounter] = useState(0);
    const validate = (values) => {
        const errors = {};
        if (!values.userId) {
            errors.userId = "User ID  is required!";
        } else if (
            !phoneRegEx.test(values.userId) &&
            !emailRegEx.test(values.userId)
        ) {
            errors.userId = "Enter valid email or phone number!";
        }
        return errors;
    };

    useEffect(() => {
        const updateCounter = () => {
            if (counter === 0) return;
            else {
                let temp = counter - 1;
                setCounter(temp);
            }
        };
        let timer = setInterval(updateCounter, 1000);
        if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [counter]);

    const closeModalHandler = () => {
        setFormData({ userId: "", otp: "" });
        setFormError({});
        setLoading(false);
        setIsOTP(false);
        setResendCount(0);
        setCounter(0);
        onClose();
    };
    /* @DESC:: first call get OTP   */
    const handleGetOTP = async (e) => {
        e.preventDefault();
        const getValidFormData = validate(formData);

        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            let val = emailRegEx.test(formData.userId)
                ? formData.userId
                : `91${formData.userId}`;
            try {
                await auth.loginOTP({
                    username: val,
                });
                setIsOTP(true);
                toast.success("OTP has been sent");
                setResendCount((prev) => prev + 1);
                setCounter(COUNTER_TIME);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };

    /* @TODO ==> GET OTP MORE TIMES  Tue Sep 20  */
    const handleResentOTP = async (mode) => {
        const getValidFormData = validate(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            let val = emailRegEx.test(formData.userId)
                ? formData.userId
                : `91${formData.userId}`;
            try {
                await auth.loginOTP({
                    username: val,
                    type: "retry",
                    mode,
                });
                if (mode === "text") {
                    toast.success("OTP has been sent");
                } else {
                    toast.success("Call has been sent");
                }

                setResendCount((prev) => prev + 1);
                setCounter(COUNTER_TIME * (resendCount + 1));
                setLoading(false);
            } catch (error) {
                setLoading(false);

                if (error === "otp_expired") {
                    setResendCount(0);
                    setCounter(0);
                }
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        } else {
        }
    };
    const validateWithOtp = (values) => {
        const errors = {};
        if (!values.userId) {
            errors.userIdError = "User ID  is required!";
        } else if (
            !phoneRegEx.test(values.userId) &&
            !emailRegEx.test(values.userId)
        ) {
            errors.userIdError = "Enter valid email or phone number!";
        }
        if (!values.otp) {
            errors.otpError = "OTP is required!";
        } else if (values.otp.length !== OTP_LENGTH) {
            errors.otpError = "OTP is incomplete!";
        }
        return errors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const getValidFormData = validateWithOtp(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);

            try {
                let val = emailRegEx.test(formData.userId)
                    ? formData.userId
                    : `91${formData.userId}`;
                await auth.login({
                    username: val,
                    password: formData.otp,
                    otpEnable: true,
                });
                await auth.getUserProfile(dispatch);

                setLoading(false);

                if (state.redirectPath) {
                    history.push(state.redirectPath);
                } else {
                    history.push(PROJECTS);
                }
                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });

                closeModalHandler();
            } catch (error) {
                setLoading(false);

                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };

    const handleChangeFunction = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    return (
        <div>
            <Space xlm={["2rem 0"]} stm={["1.5rem 0"]} xxsm={["1.5rem 0"]}>
                <Flex.Container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex.Item
                        width="25%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Text color="white" weight="bold" size="m">
                            User Id
                        </Text>
                    </Flex.Item>
                    <Flex.Item
                        width="70%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Input
                            placeholder="Enter your userId"
                            name="userId"
                            errorMessage={formError.userId}
                            value={formData.userId}
                            onChange={handleChangeFunction}
                            backgroundColor="white"
                        />
                    </Flex.Item>
                </Flex.Container>
            </Space>

            {isOTP && (
                <AnimatePresence>
                    <Space
                        xlm={["0 0 30px "]}
                        stm={["0 0 25px"]}
                        xxsm={["0 0 10px"]}
                    >
                        <Flex.Container
                            justifyContent="space-between"
                            alignItems="center"
                            as={motion.div}
                            variants={virtualOTP}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            <Flex.Item
                                width="25%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <Text color="white" weight="bold" size="m">
                                    Enter OTP
                                </Text>
                            </Flex.Item>
                            <Flex.Item
                                width="70%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <OtpInput
                                    label={false}
                                    value={formData.otp}
                                    errorMessage={formError.otpError}
                                    onChange={handleChangeFunction}
                                    size={OTP_LENGTH}
                                    isInputNum={true}
                                />
                                <Flex.Container
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    {counter === 0 ? (
                                        <>
                                            <OTPButton
                                                onClick={() =>
                                                    handleResentOTP("text")
                                                }
                                                role="button"
                                                size="s"
                                                color="white"
                                                weight="700"
                                            >
                                                Get OTP Text
                                            </OTPButton>
                                            <OTPButton
                                                color="white"
                                                onClick={() =>
                                                    handleResentOTP("voice")
                                                }
                                                role="button"
                                                weight="700"
                                                size="s"
                                            >
                                                Get OTP on Call
                                            </OTPButton>
                                        </>
                                    ) : (
                                        <Text
                                            weight="700"
                                            color="white"
                                            size="s"
                                        >
                                            Resend OTP in {counter}s
                                        </Text>
                                    )}
                                </Flex.Container>
                            </Flex.Item>
                        </Flex.Container>
                    </Space>
                </AnimatePresence>
            )}

            <Flex.Container justifyContent="center">
                {!isOTP ? (
                    <ButtonAlt
                        xxs="m"
                        st="m"
                        backgroundColor="white"
                        color="white"
                        weight="b"
                        onClick={handleGetOTP}
                        style={{ borderRadius: "50px", padding: "1rem 3rem" }}
                        disabled={loading}
                        isLoading={loading}
                    >
                        GET OTP
                    </ButtonAlt>
                ) : (
                    <ButtonAlt
                        onClick={handleSubmit}
                        xxs="m"
                        st="m"
                        weight="b"
                        color="white"
                        backgroundColor="white"
                        style={{ borderRadius: "50px", padding: "1rem 3rem" }}
                        disabled={loading}
                        isLoading={loading}
                    >
                        Submit
                    </ButtonAlt>
                )}
            </Flex.Container>
        </div>
    );
};

export default LoginWithOTP;
