import React from "react";
import { torch } from "../../../../resources/js/icons";
import SubHeading from "../../../../shared/typography/SubHeading";
import RangeInput from "./RangeInput";

const Points = () => {
    return (
        <div className="bg-radial-gold  rounded-3xl px-1 py-1  my-1 mx-2 xl:mx-0  flex gap-2 ">
            <div className="flex flex-col gap-1.6 py-1 w-full">
                <SubHeading className="font-semibold text-sm text-nowrap xl:!text-sm md:text-sm">
                    You're on a winning spree !!!
                </SubHeading>
                <RangeInput />
                <p className="text-gray-600 text-xs text-nowrap lg:text-xs font-vietnam">
                    2000 points till ECO Warrior Award!!!
                </p>
            </div>

            <img src={torch} alt="torch" className="w-56 xl:w-52" />
        </div>
    );
};

export default Points;
