import React, { useCallback, useState } from "react";
import Space from "../components/space/Space";
import { StepContainer } from "../components/stepper/Stepper";
import { H2, P, Span } from "../components/styles/Typography.styles";
import questionnaire, {
    defaultQuessionareAnswer,
} from "../constants/questionnaire";

const CalculateCarbonFootprint = ({
    showFooter,
    margin = "10% 0px 25px 0px",
    onComplete,
    fireAction = null,
    loading = false,
}) => {
    const [carbonDioxideLevel, setCarbonDioxideLevel] = useState(0);
    const [activeStep, setActiveStep] = useState(1);
    const [answerMap, setAnswerMap] = useState({ ...defaultQuessionareAnswer });

    // updating answers on every selection
    const updateQuestionnarireAnswers = useCallback(
        (questionId, value, inputType, checked, reset) => {
            const newAnswerMap = { ...answerMap };
            if (reset) {
                delete newAnswerMap[questionId];
            } else {
                let newVal = value;
                if (inputType === "checkbox" && answerMap[questionId]) {
                    checked
                        ? (newVal =
                              Number(answerMap[questionId]) + Number(value))
                        : (newVal =
                              Number(answerMap[questionId]) - Number(value));
                }
                newAnswerMap[questionId] = newVal;
            }

            setAnswerMap(newAnswerMap);
        },
        [answerMap, setAnswerMap]
    );

    //    this is the final result coLevel calculation
    const calculateCarbonFootprint = useCallback(() => {
        const coLevel = Object.values(answerMap).reduce(
            (acc, val) => Number(acc) + Number(val),
            0
        );
        setCarbonDioxideLevel(coLevel);
        onComplete(parseFloat(coLevel).toFixed(2));

        document.getElementById("carbon-calculator-results").scrollIntoView({
            behavior: "smooth",
        });
    }, [answerMap, onComplete]);

    return (
        <div>
            <Space margin={[margin]}>
                <H2
                    color="grey"
                    textTransform="capitalize"
                    weight="bold"
                    size="xl"
                >
                    Calculate {"&"} Offset{" "}
                    <Span color="lightGrey">Your Carbon Footprint</Span>
                </H2>
            </Space>
            <P color="lightGrey">
                <Span weight="b" color="sharkGrey">
                    It’s the first step towards change.
                </Span>{" "}
                The carbon footprint is the total amount of green-house gas
                emitted due to our daily activities. The carbon footprint
                calculator measures our impact on the environment and climate
                change.
            </P>

            {/* entire step container */}
            <StepContainer
                currentStep={activeStep}
                data={questionnaire}
                calculate={calculateCarbonFootprint}
                carbonDioxideLevel={carbonDioxideLevel}
                onAnswerSelect={updateQuestionnarireAnswers}
                updateCurrStep={setActiveStep}
                answers={answerMap}
                fireAction={fireAction}
                loading={loading}
            />

            {/* the footer which shows the result */}
            {carbonDioxideLevel ? (
                <Space margin={["20px 0 0 0"]}>
                    <div>
                        <P color="lightGrey">
                            You are emitting{" "}
                            <Span
                                color="sharkGrey"
                                fontFamily="Montserrat"
                                weight="m"
                            >
                                {carbonDioxideLevel.toFixed(2)} Tons of CO2 per
                                Year,
                            </Span>{" "}
                            to offset you need to plant and grow{" "}
                            <Span
                                color="sharkGrey"
                                fontFamily="Montserrat"
                                weight="m"
                            >
                                {Math.ceil((carbonDioxideLevel / 1.39) * 67.2)}{" "}
                                Trees
                            </Span>{" "}
                            or you can offset these emissions by Investing into{" "}
                            <Span
                                color="sharkGrey"
                                fontFamily="Montserrat"
                                weight="m"
                            >
                                Solar Assets of capacity{" "}
                                {(carbonDioxideLevel / 1.39).toFixed(2)} KWp.
                            </Span>
                        </P>

                        {showFooter && (
                            <Space margin={[" 5px 0 0 0"]}>
                                <P color="sharkGrey" weight="b" size="m">
                                    Start investing NOW to earn good Returns and
                                    Save environment.
                                </P>
                            </Space>
                        )}
                    </div>
                </Space>
            ) : null}
        </div>
    );
};

export default CalculateCarbonFootprint;
