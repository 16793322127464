import React, { useState } from "react";
import { shop } from "../../../resources/js/icons";
import P from "../../../shared/typography/P";
import { CheckedItem, rewardsList } from "../mobile/EarnRewards";

const EarnRewards = () => {
    const [showRewardsStep, setShowRewardsStep] = useState(false);
    return (
        <div className="border mt-5 border-gray-60 rounded-2xl p-2">
            <P className="text-base font-semibold text-gray mb-3.2">
                Unlock rewards
            </P>
            <div className=" flex gap-3 w-full overflow-y-auto no-scrollbar ">
                <CheckedItem className="h-2 w-2 p-0.1" />
                <CheckedItem className="h-2 w-2 p-0.1" />
                <CheckedItem className="h-2 w-2 p-0.1" />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <CheckedItem className="bg-green-300 h-2 w-2 p-0.1" rounded />
                <img src={shop} alt="rewards" className="h-2 w-2 p-0.1" />
                <img src={shop} alt="rewards" className="h-2 w-2 p-0.1" />
                <img src={shop} alt="rewards" className="h-2 w-2 p-0.1" />
                <span className="p-1 w-2 h-2 bg-gray-400 rounded-full"></span>
                <span className="p-1 w-2 h-2 bg-gray-400 rounded-full"></span>
                <span className="p-1 w-2 h-2 bg-gray-400 rounded-full"></span>
            </div>
            <div className=" flex-1 grid grid-cols-5 mb-2   gap-x-2.4 gap-y-3.2">
                {rewardsList.map((item, i) => (
                    <div
                        role="button"
                        onClick={() => setShowRewardsStep(true)}
                        key={i}
                        className="w-full h-[124px] relative"
                    >
                        <img
                            src={item}
                            alt="rewards"
                            className="w-full h-full"
                        />
                        <div className="text-[1rem] bg-gray-400 absolute left-[50%] -translate-x-[50%] -bottom-1 text-primary inline-block px-1 text-nowrap py-0.2 rounded-full">
                            2 days left
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EarnRewards;
