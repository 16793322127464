import React from "react";
import { edit2 } from "../../resources/js/icons";
import Container from "../../shared/Container";
import P from "../../shared/typography/P";
const UserInfo = ({ name, referralCode }) => {
    return (
        <Container className="md:w-1/2 lg:w-[60%] 2xl:w-1/3 mb-2">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-1.6">
                    <div className="w-6 h-6 bg-linear-yellow-green rounded-full flex items-center justify-center">
                        <h2 className="text-xxl font-medium text-white md:text-black">
                            {name?.split("")?.[0].toUpperCase()}
                        </h2>
                    </div>
                    <div>
                        <P className="text-white mb-0.8  md:text-black font-vietnam">
                            {name}
                        </P>
                        <P className="text-gray-550">#{referralCode} </P>
                    </div>
                </div>
                <div
                    className="w-4 h-4 rounded-full border border-gray-550 flex justify-center items-center hover:cursor-pointer"
                    role="button"
                >
                    <img src={edit2} alt="edit_icon" className="w-2 h-2" />
                </div>
            </div>
        </Container>
    );
};

export default UserInfo;
