import React, { useState } from "react";

import {
    PORTFOLIO_INVESTMENTS_ENDPOINT,
    PORTFOLIO_TRANSACTION_ENDPOINT,
} from "../../../constants/routes";
import useNetwork from "../../../hooks/useNetwork";
import BottomDrawer from "../../../shared/ui/Drawer/BottomDrawer";
import RightPart from "../../project/ProjectDetails/RightSide";
import Portfolio, { DrawerContainer } from "../mobile";
import FilterTransactions from "./LeftSide/FilterTransactions";

const PortfolioDash = () => {
    const [params, setParams] = useState("");
    const [transactionFilter, setTransactionFilter] = useState(false);
    const { data: investmentData } = useNetwork(PORTFOLIO_INVESTMENTS_ENDPOINT);
    const { data: transactionData } = useNetwork(
        PORTFOLIO_TRANSACTION_ENDPOINT + params
    );
    const [activeTab, setActiveTab] = useState(0);
    const onApplyFilter = (filter) => {
        if (filter !== params) {
            setParams(filter);
        }
        setTransactionFilter(false);
    };
    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div className="lg:container">
            <div className="mt-5 lg:pt-5 bg-black-600 lg:bg-white lg:flex justify-between items-start">
                <div className=" w-full lg:w-[65%]">
                    <Portfolio
                        activeTab={activeTab}
                        handleActiveTab={handleActiveTab}
                        onClick={() => setTransactionFilter(true)}
                        investmentData={investmentData}
                        transactionData={transactionData}
                    />
                </div>
                <div className="hidden lg:block lg:w-[35%]">
                    <div className="sticky top-5 lg:top-5">
                        <RightPart
                            investmentData={investmentData}
                            transactionData={transactionData}
                            onClick={() => setTransactionFilter(true)}
                        />
                    </div>
                </div>
            </div>
            <BottomDrawer
                isOpen={transactionFilter}
                onClick={() => setTransactionFilter(false)}
            >
                <DrawerContainer>
                    <div className=" w-full xl:w-3/6 mx-auto">
                        <FilterTransactions onApply={onApplyFilter} />
                    </div>
                </DrawerContainer>
            </BottomDrawer>
        </div>
    );
};

export default PortfolioDash;
