import React from "react";
import styled from "styled-components";
import { calculateMinMaxPercentage } from "../../../../helpers/helpers";

const SliderInput = styled.div`
    position: relative;
    input[type="range"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: 0;
        height: 12px;
        width: 100%;
        border-radius: 100px;
        position: relative;
        background: ${(props) =>
            `linear-gradient(90deg, ${props.theme.color.primaryUi}   ${props.width}%, transparent 0%)`};
        /* background-color: #deeafb; */
        transition: all 0.2s ease-in;

        ::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 25px;
            height: 25px;
            background-color: ${(props) => props.theme.color.primaryUi};
            border-radius: 50%;
            z-index: 60;
            border: solid 2px;
            border-color: ${(props) => props.theme.color.white};
        }

        ::-moz-range-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 25px;
            height: 25px;
            background-color: ${(props) => props.theme.color.primaryUi};
            border-radius: 50%;
            z-index: 60;
            border: solid 2px;
            border-color: ${(props) => props.theme.color.white};
        }
    }
`;
const CalculatorRangeSlider = ({ value, min, max, onChange }) => {
    const calculateWidth = () => {
        return calculateMinMaxPercentage(value, min, max);
    };
    return (
        <SliderInput
            width={calculateWidth()}
            className="relative flex items-center  mb-1.6 "
        >
            <div className="h-0.4 w-full absolute inset-x-0 top-[50%] -translate-y-[50%] bg-gray-400"></div>
            <input
                type="range"
                className="w-full m-0 p-0 static z-10 appearance-none bg-gray-400 h-1.2 font-vietnam"
                name=""
                min={min}
                max={max}
                id=""
                value={value}
                onChange={onChange}
            />
            <span className="h-2 w-2 absolute -left-0.2 top-[50%] -translate-y-[50%] bottom-0  bg-gray-400 rounded-full z-0"></span>
            <span className="h-2 w-2 absolute -right-0.2 top-[50%] -translate-y-[50%] bottom-0  bg-gray-400 rounded-full z-0"></span>
        </SliderInput>
    );
};

export default CalculatorRangeSlider;
