import React from "react";
import styled from "styled-components";
import { ProfileTitle } from ".";
import { kyc_done, kyc_rejected, kyc_underprocess } from "../assets";
import Space from "../components/Spacing/Space";
import AlertNew from "../components/alert/AlertNew";
import Circle from "../components/circle/Circle";
import Flex from "../components/flex/Flex";
import Image from "../components/image/Image";
import Line from "../components/line/Line";
import Text from "../components/text/Text";
import { useContextState } from "../context/ContextProvider";
import BankInfo from "./BankInfo";
import DocumentInfo from "./DocumentInfo";

const KYCVerification = ({ title }) => {
    const { state } = useContextState();

    const isDocument =
        state?.user?.kycTableDisplay?.isAadharUploaded === false &&
        state?.user?.kycTableDisplay?.isPanUploaded === false;

    const isBankDetails =
        !isDocument &&
        state?.user?.kycTableDisplay?.aadharNumber !== null &&
        state?.user?.kycTableDisplay?.panNumber !== null &&
        state?.user?.bankDisplay?.kycStatus !== "COMPLETED";

    const isVarified =
        state?.user?.kycTableDisplay?.isAadharUploaded === true &&
        state?.user?.kycTableDisplay?.isPanUploaded === true &&
        state?.user?.kycTableDisplay?.bankKYCStatus === "COMPLETED" &&
        state?.user?.kycTableDisplay?.panKYCStatus === "COMPLETED" &&
        state?.user?.kycTableDisplay?.aadharKYCStatus === "COMPLETED";

    const isFailed =
        (state?.user?.kycTableDisplay?.isAadharUploaded === true &&
            state?.user?.kycTableDisplay?.isPanUploaded === true &&
            state?.user?.kycTableDisplay?.bankKYCStatus === "COMPLETED" &&
            state?.user?.kycTableDisplay?.panKYCStatus === "FAILED") ||
        state?.user?.kycTableDisplay?.aadharKYCStatus === "FAILED";

    const kycComment = state?.user?.kycTableDisplay?.comment;
    return (
        <Space
        // xlm={["0px 20px 0px 5%"]} xxsm={["0rem"]} stm={["0 2rem"]}
        >
            <div>
                <ProfileTitle title={title} />

                {isDocument && (
                    <Space xxsm={["30px 0 20px 0"]}>
                        <Text size="m" color="lightGrey">
                            As per regulatory requirements we need to verify you
                            PAN and Aadhar Card
                        </Text>
                    </Space>
                )}

                {/* Displaying the upper progress steps only status !COMPLETED */}
                {isDocument || isBankDetails ? (
                    <Flex.Container justifyContent="center">
                        <Flex.Item
                            mobileWidth="100%"
                            tabletWidth="60%"
                            width="80%"
                        >
                            <UpdateSteps step={isBankDetails ? 2 : 1} />
                        </Flex.Item>
                    </Flex.Container>
                ) : null}

                {isDocument ? (
                    <Space xlm={["50px 0 0 0"]} xxsm={["30px 0 0 0 "]}>
                        <div>
                            <DocumentInfo />
                        </div>
                    </Space>
                ) : isBankDetails ? (
                    <BankInfo />
                ) : (
                    <KYCStatus
                        status={
                            isVarified ? "VERIFIED" : isFailed ? "FAILED" : null
                        }
                        comment={kycComment}
                    />
                )}
            </div>
        </Space>
    );
};

export default KYCVerification;

const UpdateSteps = ({ step }) => {
    return (
        <div>
            <Flex.Container justifyContent="center">
                <Space xxsm={["5px 20px"]}>
                    <Flex.Item
                        mobileWidth="100%"
                        tabletWidth="100%"
                        width="100%"
                    >
                        <Flex.Container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Circle
                                fontSize="m"
                                size="30px"
                                backgroundColor="primary"
                            >
                                1
                            </Circle>
                            <Line
                                backgroundColor={
                                    step > 1 ? "primary" : "lightGrey"
                                }
                                height="3px"
                                width="100%"
                                flex="1"
                            />
                            <Circle
                                fontSize="m"
                                size="30px"
                                backgroundColor={
                                    step > 1 ? "primary" : "lightGrey"
                                }
                            >
                                2
                            </Circle>
                        </Flex.Container>
                    </Flex.Item>
                </Space>
            </Flex.Container>

            <Flex.Container justifyContent="space-between" alignItems="center">
                <Text size="xs">Documents</Text>
                <Text size="xs">Bank Details</Text>
            </Flex.Container>
        </div>
    );
};

const KYCImage = styled(Image)`
    width: 50%;
    padding: 20px;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        width: 60%;
    }
    @media (max-width: 1200px) {
        width: 77%;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 70%;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: 75%;
    }
`;

const KYCStatus = ({ status, comment }) => {
    const verified = status === "VERIFIED";
    const failed = status === "FAILED";
    return (
        <div>
            <Flex.Container justifyContent="center">
                <Flex.Item
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    width="80%"
                >
                    <KYCImage
                        src={
                            verified
                                ? kyc_done
                                : failed
                                ? kyc_rejected
                                : kyc_underprocess
                        }
                    />
                </Flex.Item>
            </Flex.Container>
            <Space xxsm={["10px 0"]}>
                <AlertNew
                    backgroundColor={failed ? "danger" : "success"}
                    borderColor={failed ? "dangerBorder" : "successBorder"}
                >
                    {verified
                        ? `${comment}`
                        : failed
                        ? `Your KYC status is ${comment}. Please send mail to invest@srot.app`
                        : `Your KYC is document is uploaded, you can start Investing now.`}
                </AlertNew>
            </Space>
        </div>
    );
};
