import React from "react";
import P from "../../../../shared/typography/P";

const Gallery = ({ images = [] }) => {
    if (images.length === 0) {
        return (
            <div className="my-2 bg-yellow/5 text-red-300 text-center py-2">
                <P className="text-yellow">No Images Available</P>
            </div>
        );
    }
    return (
        <div className="columns-xs md:columns-xs xl:columns-[15rem] 2xl:columns-xs">
            {images.map((item, i) => (
                <img
                    className="w-full mb-1"
                    src={item}
                    key={i}
                    alt="project_images"
                />
            ))}
        </div>
    );
};

export default Gallery;
