import { useState } from "react";
import Flex from "../components/flex/Flex";
import { Form, Label } from "../components/form/Form";
import Input, { ErrorText } from "../components/input/Input";
import Space from "../components/space/Space";
import Text from "../components/text/Text";
import { emailRegEx, phoneRegEx } from "../constants/regularExpression";
import { FORGET_PASSWORD_ENDPOINT } from "../constants/routes";
import { ButtonStyled } from "../profile/ChangePassword";
import request from "../services/request";

const CredentialCard = ({ emailSent, setEmailSent }) => {
    const [formError, setFormError] = useState({});
    const [formData, setFormData] = useState({ emailPhone: "" });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const validate = (values) => {
        const errors = {};
        if (!values.emailPhone) {
            errors.emailPhoneError = "Email/Phone  is required!";
        } else if (
            !phoneRegEx.test(values.emailPhone) &&
            !emailRegEx.test(values.emailPhone)
        ) {
            errors.emailPhoneError = "Enter valid email or phone number!";
        }
        return errors;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const getValidFormData = validate(formData);
        setFormError(getValidFormData);

        if (Object.keys(getValidFormData).length === 0) {
            const endpoint = FORGET_PASSWORD_ENDPOINT;
            const credentials = phoneRegEx.test(formData.emailPhone)
                ? `91${formData.emailPhone}`
                : formData.emailPhone;

            const body = {
                credentials,
            };
            setError("");
            setLoading(true);
            setEmailSent(false);
            request
                .put({ endpoint, body })
                .then((data) => {
                    if (data.status === "SUCCESS") {
                        setEmailSent(true);
                        setLoading(false);
                        setError("");
                    }
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleChangeFunction = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <Form width="100% ">
                <Label
                    label="Email Id/Phone no"
                    required
                    size="s"
                    marginBottom="5px"
                />
                <Input
                    name="emailPhone"
                    value={formData.emailPhone}
                    onChange={handleChangeFunction}
                    type="text"
                    margin="0 0 20px 0"
                    errorMessage={formError.emailPhoneError}
                />
            </Form>
            <ButtonStyled
                onClick={handleSubmit}
                type="solid"
                color="white"
                backgroundColor="primary"
                size="s"
                fullWidth
                textTransform="uppercase"
                marginTop="20px"
                isLoading={loading}
            >
                Get Reset Link
            </ButtonStyled>

            {error && <ErrorText size="s">{error}</ErrorText>}
            {emailSent && (
                <Flex.Item width="100%">
                    <Space margin={["20px 0 0 0"]}>
                        <Text size="s" align="center" color="primary">
                            Link has been sent to your email. Please check your
                            email
                        </Text>
                    </Space>
                </Flex.Item>
            )}
        </>
    );
};

export default CredentialCard;
