import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import { InlineText } from "../text/HeadingText";
import Text from "../text/Text";

const AlertBox = styled.div`
    background: ${(props) => props.theme.color.pureWhite};
    padding: 7px 20px;
    border-left: 5px solid
        ${(props) =>
            props.error
                ? props.theme.color.error
                : props.theme.color.lightPrimary};
    border-radius: 5px;
    margin: ${(props) => props.margin};
    box-shadow: rgba(99, 99, 99, 0.2) 1px 3px 6px 2px;
`;
const ButtonStyled = styled(Button)`
    border: none;
    padding: 5px 10px;
    height: 100%;
    :hover,
    :focus,
    :active {
        box-shadow: none;
    }
`;

const AlertMessage = ({
    message,
    error = false,
    onClick,
    margin = "30px 0px",
}) => {
    return (
        <AlertBox margin={margin} error={error}>
            <Flex.Container alignItems="center">
                <Flex.Item>
                    <InlineText
                        size="xl"
                        color={error ? "red" : "lightPrimary"}
                    >
                        <FontAwesomeIcon
                            icon={error ? faTimesCircle : faCheckCircle}
                        />
                    </InlineText>
                </Flex.Item>
                <Flex.Item style={{ margin: " 0px 20px", flex: "1" }}>
                    <Text weight="bold" color="sharkGrey" size="m">
                        {error ? "Error" : "Success"}
                    </Text>
                    <Text color="lightGrey" size="s">
                        {message}
                    </Text>
                </Flex.Item>
                <Flex.Item>
                    <ButtonStyled onClick={onClick} color="lightGrey">
                        Close
                    </ButtonStyled>
                </Flex.Item>
            </Flex.Container>
        </AlertBox>
    );
};

export const NoDataMessage = styled.div`
    padding: 1rem 3rem;
    margin: 3rem 0;
    border-radius: 0.8rem;
    border: 3px solid ${(props) => props.theme.color.black800};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .link {
        text-decoration: underline;
        text-underline-offset: 2px;
        :hover {
            color: ${(props) => props.theme.color.primary};
            cursor: pointer;
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 0.5rem 1rem;
    }
`;

export default AlertMessage;
