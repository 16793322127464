import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";

const filterList = ["All", "Referrals", "Blogs"];
const RewardHistory = () => {
    const [activeFilter, setActiveFilter] = useState(filterList[0]);
    return (
        <div className="bg-gray-60 p-2 rounded-3xl mt-3">
            <div className="flex flex-wrap justify-between items-center">
                <P className="text-gray w-1/4 font-semibold text-base mb-2.4 ">
                    Rewards History
                </P>
                <div className="flex justify-between flex-wrap xl:flex-nowrap items-center mb-3.2  gap-3">
                    <div className=" w-3/4 relative flex items-center justify-between border border-gray-secondary py-0.8 px-1 gap-0.5 text-sm rounded-full focus-within:border-primary ">
                        <FaSearch className="text-gray-550" />
                        <input
                            type="search"
                            className="outline-none bg-transparent border-none text-sm flex-1"
                        />
                    </div>
                    <div className="flex gap-1.8  overflow-x-auto no-scrollbar w-full">
                        {filterList.map((item, i) => (
                            <div
                                key={i}
                                role="button"
                                onClick={() => setActiveFilter(item)}
                                className={cn(
                                    "border p-0.8 bg-white rounded-sm border-gray-550 cursor-pointer",
                                    {
                                        "border-primary": item === activeFilter,
                                    }
                                )}
                            >
                                <P
                                    className={`md:text-xs xl:text-xs
                                        ${
                                            item === activeFilter
                                                ? "text-primary"
                                                : "text-gray-secondary"
                                        }`}
                                >
                                    {item}
                                </P>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {[...Array(10).keys()].map((item) => (
                <div className="flex justify-between gap-2 mb-1.6">
                    <div>
                        <P className="text-gray mb-0.8 md:text-sm xl:text-sm">
                            Referral
                        </P>
                        <P className="text-xs md:text-xs xl:text-xs">
                            March 20, 19:05pm
                        </P>
                    </div>
                    <P className="font-semibold text-gray">+ 500</P>
                </div>
            ))}
        </div>
    );
};

export default RewardHistory;
