import React from "react";
import cn from "../../../../lib/cn";
import P from "../../../../shared/typography/P";

const data = [
    {
        id: 1,
        name: "Details",
    },
    {
        id: 2,
        name: "Payment",
    },
    {
        id: 3,
        name: "Confirmation",
    },
];
const InvestStepper = ({ activeStep = 2 }) => {
    return (
        <div>
            <div className="flex items-center justify-between mx-4 gap-1.6 ">
                {data.map((item, i) => (
                    <>
                        <div
                            key={item.id}
                            className={cn("w-1.6 h-1.6 rounded-full  ", {
                                "bg-green-400": i < activeStep,
                                "bg-blue-300": i > activeStep,
                                "border-2 border-primary bg-transparent":
                                    i === activeStep,
                            })}
                        >
                            <P className="  text-center  translate-y-3 -translate-x-[28px] w-[60px] font-vietnam">
                                {item.name}
                            </P>
                        </div>
                        {i < data.length - 1 && (
                            <div
                                className={cn("w-full h-0.2 ", {
                                    "bg-green-400": i < activeStep,
                                    "bg-blue-300": i >= activeStep,
                                })}
                            ></div>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
};

export default InvestStepper;
