import React from "react";
import Flex from "../flex/Flex";
import Section from "../section/Section";
import Text from "../text/Text";
import Loading from "./Loading";

const LoaderBox = ({ height = "auto" }) => {
    return (
        <Section height="100vh">
            <Flex.Container
                style={{ height: height }}
                alignItems="center"
                justifyContent="center"
            >
                <Text size="xml" color="primary">
                    <Loading />
                </Text>
            </Flex.Container>
        </Section>
    );
};

export default LoaderBox;
