import React from "react";
import Section from "../../../../shared/Section";

import NewOpportunities from "../NewOpportunities";
import ProfileCard from "./ProfileCard";

const LeftSide = ({ investmentHave, portfolioData, profileData }) => {
    return (
        <Section className={" lg:mb-3 xl:mb-3"}>
            <div>
                <ProfileCard
                    profileData={profileData}
                    portfolioData={portfolioData}
                    investmentHave={investmentHave}
                />
                <NewOpportunities />
            </div>
        </Section>
    );
};

export default LeftSide;
