import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";

import { AboutCard } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";

import P from "../../shared/typography/P";
import ImageTextCard from "../../shared/ui/Cards/ImageTextCard";
import SliderButton from "../../shared/ui/Slider/SliderButton";

const CarouselSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const Content = (
        <div className=" mx-2 sm:ml-5 mb-2 lg:mb-0">
            <div className="flex flex-col justify-start gap-2">
                <H2 className={"text-start  text-primary"}>The SROT Promise</H2>

                <P className="text-justify">
                    To offset carbon emissions through sustainable Green Energy
                    Solutions & overcome the constraints of money, space &
                    technology for Green Energy Adoption.
                </P>
            </div>
        </div>
    );

    const data = [
        {
            id: 1,
            content: Content,
            img: AboutCard,
        },
        {
            id: 2,
            content: Content,
            img: AboutCard,
        },
    ];
    const previousSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? data.length - 1 : prev - 1));
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
    };

    return (
        <Section>
            <Container>
                <CarouselComponent
                    selectedItem={currentSlide}
                    onChange={setCurrentSlide}
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    stopOnHover={true}
                    transitionTime={500}
                    swipeable={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    emulateTouch={true}
                >
                    {data.map((item, index) => (
                        <div className="border border-opacity-5 bg-gray-100 [border-image:linear-gradient(21.48deg,_#A4CEFF_17.11%,_#DCDCDC_113.64%)_1]">
                            <ImageTextCard
                                url={item.img}
                                content={item.content}
                                wrapperClass="flex-wrap flex-col-reverse sm:flex-row"
                                width="sm:w-[50%]"
                                contentWidth="w-full  sm:w-[45%]  xl:w-[40%] 2xl:w-[40%]"
                                col="flex-col-reverse sm:flex-row"
                            />
                        </div>
                    ))}
                </CarouselComponent>

                <SliderButton
                    currentSlide={currentSlide}
                    onPrev={previousSlide}
                    onNext={nextSlide}
                    data={data}
                    className={"justify-start"}
                />
            </Container>
        </Section>
    );
};

export default CarouselSection;
