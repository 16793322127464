import React from "react";
import cn from "../../../../../lib/cn";
import P from "../../../../../shared/typography/P";

const ChatBoxItem = ({ name, date, subject, message, sender }) => {
    return (
        <div
            className={cn(" flex  ", {
                " justify-start  ": !sender,
                "justify-end": sender,
            })}
        >
            <div
                className={cn("  w-[90%]  p-2 mb-2 rounded-sm", {
                    " bg-gray-50 ": sender,
                    "bg-gray-50": !sender,
                })}
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-0.5 xl:gap-0.8 mb-0.8">
                        <div className="w-[25px] xl:w-[30px]  h-[25px] xl:h-[30px] rounded-full bg-black text-base flex justify-center items-center font-bold text-white">
                            {name.charAt(0)}
                        </div>
                        <P className="2xl:text-base">{name}</P>
                    </div>
                    <p className="text-xs  text-gray-550 mb-0.8">{date}</p>
                </div>
                <P className=" font-medium mb-1">{subject}</P>
                <P className="text-xs text-gray-secondary text-justify">
                    {message}
                </P>
            </div>
        </div>
    );
};

export default ChatBoxItem;
