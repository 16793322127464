import React from "react";
import { hillClouds } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";

const AboutCarbonFootPrint = () => {
    return (
        <Section>
            <Container>
                <div className="flex flex-wrap gap-y-4 items-center justify-between  ">
                    <div className="w-full md:w-6/12 xl:w-6/12">
                        <H2 className="mb-1.8">What is Carbon Footprint?</H2>

                        <P className="xl:text-3xl font-thin mb-2.4 xl:mb-3.2">
                            Your actions contribute to climate change. Your
                            carbon footprint is like the size of the waves you
                            create. The bigger the waves, the more you
                            contribute. By understanding your carbon footprint,
                            you can make smaller waves and help create a
                            healthier planet
                        </P>
                        <Button>Calculate your Footprint</Button>
                    </div>
                    <div className="w-full md:w-5/12 xl:w-5/12">
                        <img
                            src={hillClouds}
                            className="w-full"
                            alt="carbon_footprint"
                        />
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default AboutCarbonFootPrint;
