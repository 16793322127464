import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PROJECTS } from "../../../constants/routes";
import cn from "../../../lib/cn";
import { mapMarker } from "../../../resources/js/icons";
import Container from "../../../shared/Container";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import { ACTIVE, COMPLETED, UPCOMING } from "../mobile/index";

const LeftSide = ({ cardData, tabData }) => {
    useEffect(() => {
        setDisplayedProjects(
            cardData.filter(
                (item) => item.category.toLowerCase() === ACTIVE.toLowerCase()
            )
        );
    }, [cardData]);
    const [activeTab, setActiveTab] = useState(ACTIVE);
    const [displayedProjects, setDisplayedProjects] = useState(
        cardData.filter(
            (item) => item.category.toLowerCase() === ACTIVE.toLowerCase()
        )
    );
    const handleActiveTab = (tabIndex) => {
        setActiveTab(tabIndex);
        let newList = cardData.filter((item) => item.category === tabIndex);
        setDisplayedProjects(newList);
    };
    const history = useHistory();
    return (
        <Container>
            <div className="flex justify-between font-vietnam items-start border-b border-blue-50 ">
                <div className="flex gap-4 items-center  mt-1">
                    {tabData.map((tab) => (
                        <div
                            className={`pb-2 flex gap-0.5 items-center font-vietnam  ${
                                activeTab === tab.name
                                    ? "text-blue  border-b-3 border-blue"
                                    : "text-gray-550"
                            }`}
                        >
                            <P
                                key={tab}
                                onClick={() => handleActiveTab(tab.name)}
                                className={`cursor-pointer font-vietnam  ${
                                    activeTab === tab.name
                                        ? "text-blue  "
                                        : "text-gray-550"
                                }`}
                            >
                                {tab.name.charAt(0).toUpperCase() +
                                    tab.name.slice(1)}
                            </P>
                            <P
                                className={cn(
                                    "px-2 py-0.5 border border-gray-550 text-gray-550 inline-block rounded-full font-vietnam",
                                    {
                                        "bg-primary text-white border-primary":
                                            activeTab === tab.name,
                                    }
                                )}
                            >
                                {tab?.value}
                            </P>
                        </div>
                    ))}
                </div>
            </div>
            <div className="grid grid-cols-1  xl:grid-cols-2 justify-between gap-3 mx-2 my-2 ">
                {displayedProjects.map((item) => (
                    <div className="p-2 w-full 2xl:w-auto bg-gray-60 rounded-2 mb-1.6">
                        <H4 className="text-lg mb-0.8 font-sora md:text-base xl:text-base lg:font-vietnam">
                            {item.name}
                        </H4>
                        <P className="inline-flex py-0.4 px-1 rounded-lg gap-1 items-center justify-center bg-gray-400 text-primary mb-1.6">
                            <span className="text-sm">{item.type}</span>
                        </P>

                        <div className=" p-1 rounded-sm relative mb-1.6">
                            <div className="bg-white flex items-center gap-3 w-full justify-between px-1 py-1  rounded-sm mb-1.6 font-vietnam">
                                {item.category === UPCOMING ? (
                                    <>
                                        <P className="">Plant Size</P>
                                        <P className=" font-medium text-base md:text-base xl:text-base">
                                            {item.plantSize}
                                        </P>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex-1">
                                            <P className=" mb-0.8 md:text-base xl:text-base font-vietnam font-semibold ">
                                                {item.plantSize}
                                            </P>
                                            <P className="md:text-sm xl:text-sm text-nowrap text-gray-500">
                                                Plant Size
                                            </P>
                                        </div>
                                        <div className="flex justify-end items-center flex-nowrap gap-0.5 w-1/3">
                                            <img
                                                src={mapMarker}
                                                alt="project location"
                                            />
                                            <P className="text-xs text-gray-550 text-wrap md:text-sm xl:text-nowrap xl:text-sm font-vietnam">
                                                {item.location}
                                            </P>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="flex justify-between items-center mb-1.6 gap-2">
                                <P className="text-gray-550 md:text-sm xl:text-sm font-vietnam">
                                    {item.status}
                                </P>
                                {item.category === ACTIVE && (
                                    <P className="text-orange-300 md:text-sm xl:text-sm ">
                                        Ends in {item.remains} days
                                    </P>
                                )}
                                {item.category === UPCOMING && (
                                    <P
                                        className="bg-linear-yellow-green
                                        text-transparent bg-clip-text
                                        "
                                    >
                                        Coming Soon
                                    </P>
                                )}
                            </div>
                            <div className="w-full h-[28px] bg-white  rounded-3xl relative mb-1.6">
                                <div
                                    className={cn(
                                        ` h-full rounded-3xl bg-linear-blue-white`
                                    )}
                                    style={{
                                        width: `${item.progress}%`,
                                    }}
                                ></div>
                                <div className="absolute top-[50%] -translate-y-[50%] right-2">
                                    <P className="font-semibold md:text-base xl:text-base font-vietnam">
                                        {item.progress}%
                                    </P>
                                </div>
                                <div></div>
                            </div>
                            {item.category === UPCOMING && (
                                <P className=" text-end md:text-base xl:text-base font-vietnam">
                                    24 investors showed interest
                                </P>
                            )}
                        </div>

                        <div className="flex justify-between gap-2 mb-1.6">
                            {item.details.map((detail, i) => (
                                <div className="" key={i}>
                                    <P className="text-gray-550 text-xs mb-1.6  md:text-xs xl:text-xs font-vietnam">
                                        {detail.name}
                                    </P>
                                    <P className="font-medium xl:text-nowrap md:text-sm xl:text-sm font-vietnam">
                                        {detail.value}
                                    </P>
                                </div>
                            ))}
                        </div>
                        {item.category !== COMPLETED && (
                            <>
                                {item.category !== UPCOMING ? (
                                    <Button
                                        onClick={() => {
                                            history.push(
                                                `${PROJECTS}/${item.id}`
                                            );
                                        }}
                                        className=" md:text-sm xl:text-sm bg-transparent border mt-3 border-gray-550 w-full border-solid text-gray-550 !rounded-[3rem]"
                                    >
                                        Show Details
                                    </Button>
                                ) : (
                                    <div className="flex items-center justify-between gap-2">
                                        <Button
                                            className=" md:text-sm xl:text-sm bg-transparent border border-gray-550 w-full border-solid text-gray-550 rounded-full"
                                            onClick={() => {
                                                history.push(
                                                    `${PROJECTS}/${item.id}`
                                                );
                                            }}
                                        >
                                            Details
                                        </Button>
                                        <Button className="bg-white md:text-sm xl:text-sm  w-full  text-gray-550 rounded-full">
                                            Show Interest
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default LeftSide;
