import React from "react";
import styled from "styled-components";
import { InlineText } from "../text/HeadingText";

const Wraper = styled(InlineText)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
const Icon = ({ component: Component, ...rest }) => {
    return (
        <Wraper {...rest}>
            <Component />
        </Wraper>
    );
};

export default Icon;
