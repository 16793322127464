import React from "react";
import {
    AiFillFacebook,
    AiOutlineTwitter,
    AiOutlineWhatsApp,
} from "react-icons/ai";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import styled from "styled-components";
import { greetings, offsetModal } from "../../assets";

import Flex from "../../components/flex/Flex";
import Icon from "../../components/Icon/Icon";
import Image from "../../components/image/Image";
import Space from "../../components/space/Space";
import { InlineText } from "../../components/text/HeadingText";
import Text from "../../components/text/Text";
import { REF_URL } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";

const FlexCenter = styled(Flex.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    background: url(${offsetModal}) no-repeat center;
    background-size: cover;
    padding: 1rem;
    border-radius: 10px;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 0.5rem;
    }
`;

const Coupon = styled(InlineText)`
    background-color: ${(props) => props.theme.color.secondary};
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 10px;
    line-height: 1.3em;
`;
const Greeting = styled(Image)`
    height: 200px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: 170px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        height: 100px;
    }
`;

const OffsetSummary = ({ isNewUser, onClose, carbonReduced = 0 }) => {
    const { state } = useContextState();

    const REF_CODE = state?.quickUser?.referralCode;

    const carbonEmission = state?.quickUser?.carbonEmission;

    return (
        <Flex.Container>
            <Flex.Item width="100%">
                <Space
                    padding={[".5rem 2rem .8rem"]}
                    mobilepadding={[".5rem 1.5rem"]}
                >
                    <Title
                        color="white"
                        size="m"
                        align="center"
                        lineHeight="1.3em"
                        weight="700"
                    >
                        {isNewUser
                            ? "We have credited ₹ 100 in your wallet to help you offset your carbon footprint"
                            : `I’m Offsetting ${parseFloat(
                                  carbonReduced
                              ).toFixed(2)} tons of Carbon Emssion with SROT`}
                        {/* @TODO ==> Co2 Reduced   Sat Oct 08  */}
                    </Title>
                </Space>
            </Flex.Item>
            <FlexCenter width="100%">
                {isNewUser && (
                    <TitleInner
                        weight="700"
                        align="center"
                        size="m"
                        color="black"
                    >
                        {`I’m Offsetting ${carbonEmission} tons of My Carbon with SROT`}
                    </TitleInner>
                )}
                <Greeting height="200px" src={greetings} alt="greeting" />
                <Coupon size="m" weight="700" color="white">
                    Use My Code {REF_CODE}
                </Coupon>
                <TitleInner weight="700" align="center" size="m" color="black">
                    Calculate {"&"} Offset your Carbon Footprint
                </TitleInner>
            </FlexCenter>
            {/* <Flex.Container
                justifyContent="center"
                style={{ width: "100%", marginTop: "1rem" }}
            >
                <ButtonStyled
              
                    fontSize="m"
                    backgroundColor="white"
                    hoverColor="primary"
                    color="white"
                    onClick={onClose}
                    style={{ borderRadius: "50px" }}
                >
                 
                    {isNewUser ? "Continue" : "Done"}
                </ButtonStyled>
            </Flex.Container> */}

            <Flex.Container
                style={{ width: "100%", marginTop: ".5rem" }}
                aligItems="center"
                justifyContent="space-between"
            >
                <Flex.Item flex="1">
                    <Space margin={["0 1rem 0 0"]}>
                        <Title
                            weight="700"
                            align="center"
                            size="m"
                            color="white"
                        >
                            Refer {"&"} Earn 100, Share with your friends
                        </Title>
                    </Space>
                </Flex.Item>
                <Space mobilemargin={["5px 0 0 "]}>
                    <Flex.Item
                        mobileWidth="100%"
                        style={{ textAlign: "center" }}
                    >
                        <FacebookShareButton
                            url={REF_URL}
                            quote={`Use Code ${REF_CODE} and Get ₹ 100 as Joining Bonus`}
                            hashtag="#srot"
                        >
                            <Icon
                                component={AiFillFacebook}
                                color="white"
                                size="xl"
                            />
                        </FacebookShareButton>
                        <Space margin={["0 .5rem"]}>
                            <WhatsappShareButton
                                url={REF_URL}
                                title={`Use Code ${REF_CODE} and Get ₹ 100 as Joining Bonus`}
                                separator="-"
                            >
                                <Icon
                                    component={AiOutlineWhatsApp}
                                    color="white"
                                    size="xl"
                                />
                            </WhatsappShareButton>
                        </Space>
                        <TwitterShareButton
                            url={REF_URL}
                            title={`Use Code ${REF_CODE} and Get ₹ 100 as Joining Bonus`}
                            hashtags={["SAVECLIMATE", "SROT", "GOSOLAR"]}
                        >
                            <Icon
                                component={AiOutlineTwitter}
                                color="white"
                                size="xl"
                            />
                        </TwitterShareButton>
                    </Flex.Item>
                </Space>
            </Flex.Container>
        </Flex.Container>
    );
};

export default OffsetSummary;

const Title = styled(Text)`
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.mobile.m};
        line-height: 20px;
        text-align: center;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) => props.theme.fontSize.smallMobile.s};
    }
`;
const TitleInner = styled(Text)`
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.mobile.m};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) => props.theme.fontSize.smallMobile.s};
    }
`;
