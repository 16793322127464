import React from "react";
import { useHistory } from "react-router-dom";
import cn from "../../lib/cn";
import P from "../../shared/typography/P";
import Line from "../../shared/ui/Line";

const Stepper = ({ type, handleType, Pan, Aadhar, handeleStart }) => {
    const history = useHistory();

    const handleNavigation = (selectedType) => {
        handleType(selectedType);
        if (selectedType) {
            history.push(`/kyc/summary/${selectedType}`);
        } else {
            history.push(`/kyc/summary`);
            handeleStart();
        }
    };
    return (
        <div className="flex gap-1 items-baseline w-full">
            <div
                className="flex flex-col gap-1 items-center"
                role="button"
                onClick={() => handleNavigation("")}
            >
                <div
                    className={cn(
                        "h-[10px] w-[10px] rounded-full bg-green-400"
                    )}
                ></div>
                <P>Summary</P>
            </div>
            <Line
                className={cn("w-2/5", {
                    "bg-green-400":
                        type === "pan" || type === "aadhar" || type === "bank",
                })}
            />
            <div
                className="flex flex-col gap-1 items-center"
                role="button"
                onClick={() => handleNavigation("pan")}
            >
                <div
                    className={cn(
                        "h-[10px] w-[10px] rounded-full bg-gray-400",
                        {
                            "bg-green-400":
                                type === "pan" ||
                                type === "aadhar" ||
                                type === "bank",
                        }
                    )}
                ></div>
                <P>PAN</P>
            </div>
            <Line
                className={cn("w-2/5", {
                    "bg-green-400": type === "aadhar" || type === "bank",
                })}
            />
            <div
                className="flex flex-col gap-1 items-center"
                role="button"
                onClick={() =>
                    Pan?.status === "VERIFIED" && handleNavigation("aadhar")
                }
            >
                <div
                    className={cn(
                        "h-[10px] w-[10px] rounded-full bg-gray-400",
                        {
                            "!bg-green-400":
                                type === "aadhar" || type === "bank",
                        }
                    )}
                ></div>
                <P>Aadhar</P>
            </div>
            <Line
                className={cn("w-2/5", { "!bg-green-400": type === "bank" })}
            />
            <div
                className="flex flex-col gap-1 items-center"
                role="button"
                onClick={() => {
                    Aadhar?.status === "VERIFIED" && handleNavigation("bank");
                }}
            >
                <div
                    className={cn(
                        "h-[10px] w-[10px] rounded-full bg-gray-400",
                        { "bg-green-400": type === "bank" }
                    )}
                ></div>
                <P>Bank</P>
            </div>
        </div>
    );
};

export default Stepper;
