import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";

const baseStyles =
    "text-gray-secondary font-normal text-sm  md:text-base xl:text-lg font-nunito";

const P = ({ className = "", children, ...rest }) => {
    return (
        <p className={cn(baseStyles, className)} {...rest}>
            {children}
        </p>
    );
};

P.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default P;
