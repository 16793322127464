import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { PROJECTS } from "../../../constants/routes";
import useHeaderScroll from "../../../hooks/useHeaderScroll";
import cn from "../../../lib/cn";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import LinedTab from "../../../shared/ui/tab/LinedTab";
import Tag from "../../../shared/ui/tag/Tag";
export const ACTIVE = "Active";
export const UPCOMING = "Upcoming";
export const COMPLETED = "Completed";

export const projectsList = [
    {
        id: 1,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 197,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 1677,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 1221,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 14567,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 1445,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 123,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 11,
        name: "Tata Communications Pvt Limited",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        remains: "10",
        progress: "55%",
        category: ACTIVE,
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 2,
        name: "Tata Communications Completed",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        category: COMPLETED,
        remains: "10",
        progress: "55%",
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 22,
        name: "Tata Communications Completed",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        category: COMPLETED,
        remains: "10",
        progress: "55%",
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
    {
        id: 3,
        name: "Tata Communications Pvt Upcoming",
        type: "Telecom Industry",
        plantSize: "15KWp",
        location: "Whitefield, Bangalore",
        status: "Funding",
        category: UPCOMING,
        remains: "10",
        progress: "55%",
        details: [
            { name: "Carbon impact", value: "243 tons" },
            { name: "Tenure", value: "5 years" },
            { name: "IRR", value: "20.88%" },
        ],
    },
];

let HEADER_HEIGHT = 60;

const Project = ({ cardData, tabData }) => {
    const ref = useRef(null);
    const [currentTab, setCurrentTab] = useState(0);

    const [displayedProjects, setDisplayedProjects] = useState(
        cardData.filter(
            (item) => item.category.toLowerCase() === ACTIVE.toLowerCase()
        )
    );
    useEffect(() => {
        setDisplayedProjects(
            cardData.filter(
                (item) => item.category.toLowerCase() === ACTIVE.toLowerCase()
            )
        );
    }, [cardData]);
    const handleActiveTab = (tabIndex) => {
        setCurrentTab(tabIndex);
        let newList = cardData.filter(
            (item) =>
                item.category.toLowerCase() ===
                tabData[tabIndex].name.toLowerCase()
        );
        setDisplayedProjects(newList);
    };

    const history = useHistory();
    /* @TODO => Fix style here @habib610 Tue November 12,2024 */
    const { visible } = useHeaderScroll();

    return (
        <>
            <Section className="bg-black-600 m-0  pb-2 pt-7 flex flex-col min-h-screen">
                <Container
                    className={cn(
                        "sticky z-100 transition-all ease-in-out duration-500"
                    )}
                    style={{
                        top: `${visible ? HEADER_HEIGHT : 0}px`,
                    }}
                >
                    <LinedTab
                        currentTab={currentTab}
                        onClick={handleActiveTab}
                        data={tabData}
                        ref={ref}
                    />
                </Container>

                <Container className=" bg-black-600 pb-[80px] flex-1">
                    <div>
                        {displayedProjects.map((item) => (
                            <div className="py-1.6 px-1.2 bg-blue-600 rounded-2 mb-1.6">
                                <H3 className="text-lg mb-0.8 text-white">
                                    {item.name}
                                </H3>
                                <div className="flex justify-between mb-2">
                                    <Tag
                                        text={item.type}
                                        className="inline-flex items-center text-xs py-0 rounded-full bg-black-600 "
                                        textClass="px-0 text-xs text-gray-500"
                                    />
                                    <Tag
                                        text="17 Investors"
                                        className="inline-flex items-center text-xs py-0 rounded-full bg-green-50"
                                        textClass="px-0 text-xs text-green-400"
                                    />
                                </div>

                                <div className="bg-black-600 p-1.6 rounded-sm relative mb-1.6">
                                    <div className="bg-blue-600 flex items-center gap-2 justify-between p-0.8 rounded-sm mb-1.6">
                                        <>
                                            <P className="text-white ">
                                                Plant Size
                                            </P>
                                            <P className="text-white font-medium text-base">
                                                {item.plantSize}
                                            </P>
                                        </>
                                    </div>

                                    <div className="flex justify-between items-center mb-1.6 gap-2">
                                        <P className="text-gray-550 ">
                                            {item.status}
                                        </P>
                                        {item.category === ACTIVE && (
                                            <P className="text-orange-300 ">
                                                Ends in {item.remains} days
                                            </P>
                                        )}
                                        {item.category === UPCOMING && (
                                            <P
                                                className="bg-linear-yellow-green
                                        text-transparent bg-clip-text
                                        "
                                            >
                                                Coming Soon
                                            </P>
                                        )}
                                    </div>
                                    <div className="w-full h-[28px] bg-blue-600 rounded-3xl relative mb-1.6">
                                        <div
                                            className={cn(
                                                ` h-full rounded-3xl bg-linear-blue-white`
                                            )}
                                            style={{
                                                width: `${item.progress}%`,
                                            }}
                                        ></div>
                                        <div className="absolute top-[50%] -translate-y-[50%] right-2">
                                            <P className="text-white">
                                                {item.progress}%
                                            </P>
                                        </div>
                                    </div>
                                    {item.category === UPCOMING && (
                                        <P className="text-white text-end ">
                                            24 investors showed interest
                                        </P>
                                    )}
                                </div>

                                <div className="flex justify-between mb-1.6">
                                    {item.details.map((detail, i) => (
                                        <div className="" key={i}>
                                            <P className="text-gray-550 text-xs mb-1.6">
                                                {detail.name}
                                            </P>
                                            <P className="text-white font-medium">
                                                {detail.value}
                                            </P>
                                        </div>
                                    ))}
                                </div>
                                {item.category !== COMPLETED && (
                                    <>
                                        {item.category !== UPCOMING ? (
                                            <Button
                                                onClick={() =>
                                                    history.push(
                                                        `${PROJECTS}/${item.id}`
                                                    )
                                                }
                                                className="bg-transparent border border-gray-550 w-full border-solid text-gray-550 rounded-full"
                                            >
                                                Show Details
                                            </Button>
                                        ) : (
                                            <div className="flex items-center justify-between gap-2">
                                                <Button className="bg-transparent border border-gray-550 w-full border-solid text-gray-550 rounded-full">
                                                    Details
                                                </Button>{" "}
                                                <Button className="bg-white  w-full  text-gray-550 rounded-full">
                                                    Show Interest
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </Container>
            </Section>
        </>
    );
};

export default Project;
