import {
    IFSCRegEx,
    bankAccRegEx,
    emailRegEx,
    passRegEX,
    phoneRegEx,
} from "../constants/regularExpression";

export const validateNameEmailPhone = (values) => {
    const errors = {};
    if (!values.email) {
        errors.emailError = "Email is required!";
    } else if (!emailRegEx.test(values.email.toLowerCase())) {
        errors.emailError = "Enter a valid email!";
    }
    if (!values.phone) {
        errors.phoneError = "Phone No. is required!";
    } else if (!phoneRegEx.test(values.phone)) {
        errors.phoneError = "Enter a valid phone number!";
    }
    if (!values.name) {
        errors.nameError = "Full Name is required!";
    } else if (values.name.length < 3) {
        errors.nameError = "Full Name must be greater than 3 characters!";
    }
    return errors;
};
export const validateUserId = (userId) => {
    const errors = {};
    if (!userId) {
        errors.userId = "User ID  is required!";
    } else if (!phoneRegEx.test(userId) && !emailRegEx.test(userId)) {
        errors.userId = "Enter valid email or phone number!";
    }
    return errors;
};
export const validatePasswordDobOTP = (
    values,
    otpLength = 6,
    checkOtp = true
) => {
    const errors = {};
    // if (!values.dateOfBirth) {
    //     errors.dateOfBirthError = "D.O.B is required!";
    // } else if (validAdultAge(values.dateOfBirth) < 18) {
    //     errors.dateOfBirthError = "User must be 18+!";
    // }
    if (!values.password) {
        errors.passwordError = "Password is required!";
    } else if (!passRegEX.test(values.password)) {
        errors.passwordError =
            "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!";
    }
    if (!values.confirmPassword) {
        errors.confirmPassError = "Confirm password is required!";
    } else if (
        values.confirmPassword &&
        values.confirmPassword !== values.password
    ) {
        errors.confirmPassError = "Password doesn't matched!";
    }
    if (checkOtp) {
        if (!values.otp) {
            errors.otpError = "OTP is required!";
        } else if (values.otp.length !== otpLength) {
            errors.otpError = "OTP is incomplete!";
        }
    }

    return errors;
};

export const validBankInfo = (values) => {
    const errors = {};
    if (!values.fullName) {
        errors.fullName = "Full name required!";
    } else if (values.fullName.length < 3) {
        errors.fullName = "Full name cann't be less than 3 char!";
    }
    if (!values.ifsc) {
        errors.ifsc = "IFSC required!";
    } else if (!IFSCRegEx.test(values.ifsc)) {
        errors.ifsc = "Enter valid IFSC code!";
    }
    if (!values.accNumber) {
        errors.accNumber = "Account number required!";
    } else if (isNaN(values.accNumber)) {
        errors.accNumber = "Account number should be numbers!";
    } else if (!bankAccRegEx.test(values.accNumber)) {
        errors.accNumber = "Enter valid bank account number!";
    }
    if (!values.tos) {
        errors.tos = "This field is required!";
    }
    return errors;
};

export const validateContactUsEnquiry = (
    values,
    checkMessage,
    isOtp,
    otpLength = 6
) => {
    const errors = {};
    if (values.email && !emailRegEx.test(values.email.toLowerCase())) {
        errors.emailError = "Enter a valid email!";
    }
    if (!values.phone) {
        errors.phoneError = "Phone No. is required!";
    } else if (!phoneRegEx.test(values.phone)) {
        errors.phoneError = "Enter a valid phone number!";
    }
    if (!values.firstName) {
        errors.firstNameError = "First name is required!";
    } else if (values.firstName.length < 3) {
        errors.firstNameError = "First name must be greater than 3 characters!";
    }
    if (!values.lastName) {
        errors.lastNameError = "Last name is required!";
    } else if (values.lastName.length < 3) {
        errors.lastNameError = "Last name must be greater than 3 characters!";
    }

    if (checkMessage) {
        if (!values.message) {
            errors.messageError = "Message is required!";
        } else if (values.message.length < 3 || values.message.length > 20000) {
            errors.messageError = "Message must be between 2 to 20,000 chars";
        }
    }
    if (isOtp) {
        if (!values.otp) {
            errors.otpError = "OTP is required!";
        } else if (values.otp.length !== otpLength) {
            errors.otpError = "OTP is incomplete!";
        }
    }
    return errors;
};
export const validate = (formData, rules) => {
    const errors = {};

    Object.keys(rules).forEach((field) => {
        const value = formData[field];
        const rule = rules[field];

        if (rule.required && !value.trim()) {
            errors[field] = `${
                field.charAt(0).toUpperCase() + field.slice(1)
            } is required`;
        } else if (!rule.required && !value.trim()) {
            errors[field] = "";
        } else if (rule.pattern && !rule.pattern.test(value)) {
            errors[field] = `${
                field.charAt(0).toUpperCase() + field.slice(1)
            } is invalid`;
        }
    });

    return errors;
};
