import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import { OPPORTUNITY_ENDPOINT } from "../../constants/routes";
import cn from "../../lib/cn";
import { solarCard } from "../../resources/js/images";
import request from "../../services/request";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import Counter from "../../shared/animation/typography/Counter";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import SubHeading from "../../shared/typography/SubHeading";
import SliderButton from "../../shared/ui/Slider/SliderButton";
import ProjectCard from "./Sections/ProjectCard";

const projects = [
    {
        id: 1,
        name: "Capacity installed",
        unit: "+ MW",
        value: 20,
    },
    {
        id: 2,
        name: "Projects executed",
        unit: "+",
        value: 20,
    },
    {
        id: 3,
        name: "Projects executed",
        unit: "+ Tons",
        value: 480,
    },
    {
        id: 4,
        name: "Communities benefitting",
        unit: "+",
        value: 100,
    },
];
const cardData = [
    {
        id: 1,
        tag: "Telecom Industry",
        headings: "Tata Communications Bangalore",
        name: "Mohar Pratima",
        location: "Whitefield,Banglore",
        solarSize: "15 KWp",
        progress: 43,
        thumbnail: solarCard,
        facilities: [
            {
                id: 1,
                name: "Returns (IRR)",
                value: "19%",
            },
            {
                id: 2,
                name: "Minimum Investment",
                value: "₹5000",
            },
            {
                id: 3,
                name: "Investment term",
                value: "5 years",
            },
        ],
    },
    {
        id: 2,
        tag: "Telecom Industry",
        headings: "Tata Communications Bangalore",
        name: "Mohar Pratima",
        location: "Whitefield,Banglore",
        solarSize: "15 KWp",
        progress: 49,
        thumbnail: solarCard,
        facilities: [
            {
                id: 1,
                name: "Returns (IRR)",
                value: "19%",
            },
            {
                id: 2,
                name: "Minimum Investment",
                value: "₹5000",
            },
            {
                id: 3,
                name: "Investment term",
                value: "5 years",
            },
        ],
    },
    {
        id: 3,
        tag: "Telecom Industry",
        headings: "Tata Communications Bangalore",
        name: "Mohar Pratima",
        location: "Whitefield,Banglore",
        solarSize: "15 KWp",
        progress: 20,
        thumbnail: solarCard,
        facilities: [
            {
                id: 1,
                name: "Returns (IRR)",
                value: "19%",
            },
            {
                id: 2,
                name: "Minimum Investment",
                value: "₹5000",
            },
            {
                id: 3,
                name: "Investment term",
                value: "5 years",
            },
        ],
    },
];

const tags = ["Active", "Upcoming", "Completed"];
const SolarProjects = () => {
    const [cardData, setCardData] = useState([]);

    const [active, setActive] = useState(tags[0]);
    const [currentSlide, setCurrentSlide] = useState(0);
    useEffect(() => {
        fetchProjects();
    }, []);

    const previousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(cardData.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const nextSlide = () => {
        if (currentSlide === cardData.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const fetchProjects = async () => {
        try {
            const response = await request.get({
                endpoint: OPPORTUNITY_ENDPOINT,
            });
            const assets = response.data?.assets || [];

            const updatedCardData = assets.map((asset) => {
                const plant = asset.plants?.[0] || {};
                const investmentTerms = asset.investmentTerms?.[0] || {};

                return {
                    id: asset.listingId,
                    tag: plant.tag || "RESIDENTIAL",
                    headings: "Tata Communications Bangalore",
                    name: `${plant.name}, ${plant.city || ""}`,
                    location: `${plant.city || ""}, ${plant.state || ""}`,
                    solarSize: `${parseInt(plant.capacity || "0") / 1000} KWp`,
                    progress: Math.min(
                        100,
                        Math.round(
                            (parseFloat(asset.fundRaised || 0) /
                                parseFloat(asset.fundLimit || 1)) *
                                100
                        )
                    ),
                    thumbnail: asset.thumbnail || solarCard,
                    facilities: [
                        {
                            id: 1,
                            name: "Returns (IRR)",
                            value: `${
                                investmentTerms.targetedIRRPercentage || "0"
                            }%`,
                        },
                        {
                            id: 2,
                            name: "Minimum Investment",
                            value: `₹${parseFloat(
                                asset.listingMinLimit || 0
                            ).toLocaleString()}`,
                        },
                        {
                            id: 3,
                            name: "Investment term",
                            value: `${
                                investmentTerms.installments
                                    ? investmentTerms.installments / 12
                                    : "5"
                            } years`,
                        },
                    ],
                };
            });

            setCardData(updatedCardData);
        } catch (error) {
            toast.error(error || ERROR_MSG);
        }
    };

    return (
        <Section className="pt-4.8 xl:pt-[90px] 2xl:pt-[113px] 2xl:mb-0 bg-gray-50 pb-4">
            <Container>
                <H2 className="mb-1.6 text-gray">
                    Making a Difference, One Project at a Time
                </H2>
                <SubHeading className=" mb-2.8 md:mb-3.8 xl:mb-5.6">
                    Maximize your investment potential with our solar projects.
                </SubHeading>{" "}
                {/* @DESC:: counter @habib610 Fri September 06,2024 */}
                <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-4 mb-5.6 gap-2 gap-y-4.8 ">
                    {projects.map((item, i) => (
                        <div
                            className={cn(
                                `flex border-r border-blue-450 xl:border-none ${
                                    i % 2 === 1 && "border-r-0"
                                } md:border-r`,
                                {
                                    "xl:justify-end md:border-r-1": i === 3,
                                }
                            )}
                        >
                            <div className=" xl:mr-8.8">
                                <H3 className="font-sora text-xxl">
                                    <Counter value={item.value} />
                                    {item.unit}
                                </H3>
                                <P>{item.name}</P>
                            </div>

                            <div
                                className={cn(
                                    "h-full w-px mr-2 bg-blue-450 rounded-full hidden xl:block",
                                    {
                                        "xl:hidden": i === 3,
                                    }
                                )}
                            ></div>
                        </div>
                    ))}
                </div>
                {/* @TODO => Tags here @habib610 Fri September 06,2024 */}
                {/* <div className="flex flex-nowrap overflow-x-auto w-full xl:w-[75%] gap-2.4 mb-2.8 xl:mb-3.2 no-scrollbar">
                    { {tags.map((tag, i) => (
                        // <Tab
                        //     key={i}
                        //     text={tag}
                        //     active={tag === active}
                        //     onClick={() => setActive(tag)}
                        // />
                    ))} }
                </div> */}
                {/* @DESC:: slider @habib610 Fri September 06,2024 */}
                {cardData.length > 2 ? (
                    <div className="">
                        <Carousel
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showStatus={false}
                            showIndicators={false}
                            infiniteLoop={true}
                            showThumbs={false}
                            interval={3000}
                            autoPlay={true}
                            stopOnHover={true}
                            transitionTime={500}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                            centerMode={true}
                            centerSlidePercentage={
                                document.body.clientWidth < 1279
                                    ? document.body.clientWidth < 1100
                                        ? document.body.clientWidth < 766
                                            ? document.body.clientWidth < 630
                                                ? 100
                                                : 80
                                            : 60
                                        : 46
                                    : document.body.clientWidth > 1535
                                    ? 25
                                    : 20
                            }
                        >
                            {cardData.map((card) => (
                                <ProjectCard key={card.id} {...card} />
                            ))}
                        </Carousel>

                        <SliderButton
                            currentSlide={currentSlide}
                            onNext={nextSlide}
                            onPrev={previousSlide}
                            data={cardData}
                        />
                    </div>
                ) : (
                    <div className="flex justify-between flex-wrap items-center mt-3">
                        {cardData.map((card, index) => (
                            <ProjectCard key={card.id} {...card} />
                        ))}
                    </div>
                )}
            </Container>
        </Section>
    );
};

export default SolarProjects;
