import React from "react";
import styled from "styled-components";
import Flex from "../flex/Flex";
import ProgressBar from "../progress-bar/ProgressBar";
import Space from "../space/Space";
import Text from "../text/Text";
const Wraper = styled.div`
    padding: 20px;
    background-color: ${(props) => props.theme.color.black};
`;

const INDIA_AVG_EMISSION = 6.92;
const StepPreviewTop = (props) => {
    const { total = 0 } = props;

    const percentage = (
        Number(total) /
        (INDIA_AVG_EMISSION + Number(total))
    ).toFixed(2);
    const avgPercentage = (
        INDIA_AVG_EMISSION /
        (INDIA_AVG_EMISSION + Number(total))
    ).toFixed(2);
    return (
        <Wraper>
            <Space margin={[" 0 0 .5rem "]}>
                <Text weight="700" color="white">
                    Your Total Annual Emissions
                </Text>
            </Space>

            <Flex.Container justifyContent="space-between" alignItems="center">
                <Flex.Item flex="1">
                    <ProgressBar
                        progress={percentage * 100}
                        color="lightGreen"
                        height="6px"
                    />
                </Flex.Item>
                <Space margin={["0 0 0 .5rem"]}>
                    <Text weight="700" color="lightGreen">
                        {total} tons
                    </Text>
                </Space>
            </Flex.Container>
            <Space margin={[" .5rem 0 .5rem "]}>
                <Text weight="700" color="pureWhite">
                    Average Indian Annual Emissions
                </Text>
            </Space>

            <Flex.Container justifyContent="space-between" alignItems="center">
                <Flex.Item flex="1">
                    <ProgressBar
                        progress={avgPercentage * 100}
                        color="pureWhite"
                        height="6px"
                    />
                </Flex.Item>

                <Space margin={["0 0 0 .5rem"]}>
                    <Text weight="700" color="pureWhite">
                        {INDIA_AVG_EMISSION} tons
                    </Text>
                </Space>
            </Flex.Container>
        </Wraper>
    );
};

export default StepPreviewTop;
