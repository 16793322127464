import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { InputComponent } from "../components/input/Input";
const SearchInput = styled(InputComponent)`
    width: 50%;
    padding-left: 60px;
    border: 1px solid transparent;
    background: ${(props) => props.theme.color.greyBg};
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
    &:focus {
        width: 70%;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 80%;
        &:focus {
            width: 100%;
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: 100%;
        &:focus {
            width: 100%;
        }
    }
`;
const SearchWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
`;
const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 25px;
`;

const FAQSearchBox = ({ value, onChange }) => {
    return (
        <SearchWrapper>
            <SearchInput
                value={value}
                onChange={onChange}
                placeholder="Search for a keyword"
            />
            <IconWrapper>
                <FontAwesomeIcon icon={faSearch} />
            </IconWrapper>
        </SearchWrapper>
    );
};

export default FAQSearchBox;
