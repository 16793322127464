import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Space from "../components/Spacing/Space";
import { virtualOTP } from "../components/animation/virtualSolarAnimation";
import { ButtonAlt } from "../components/button/Button";
import Flex from "../components/flex/Flex";
import ModalAlt from "../components/modal/ModalAlt";
import OtpInput from "../components/otp-input/OtpInput";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import {
    COUNTER_TIME,
    ERROR_MSG,
    OTP_LENGTH,
    ROLE,
    TYPE_PASSWORD,
    TYPE_TEXT,
} from "../constants/common";
import {
    CLOSE_SIGNUP_FORM,
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
    SHOW_LOGIN_FORM,
    UPDATE_QUICK_USER,
} from "../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../constants/regularExpression";
import {
    DEFAULT_REF_CODE,
    RESEND_OTP_ENDPOINT,
    SIGNUP_ENDPOINT,
    VALIDATE_API_ENDPOINT,
} from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import {
    validateNameEmailPhone,
    validatePasswordDobOTP,
} from "../helpers/fieldValidation";
import { getURLQueryParams } from "../helpers/helpers";
import DetailsFiled from "../landing/savings/saving-login/DetailsField";
import VirtualLoginSummary from "../landing/savings/saving-login/VirtualLoginSummary";
import auth from "../services/auth";
import request from "../services/request";
import { AuthenticationHead } from "./LoginModal";

const SignupModal = () => {
    const { state, dispatch } = useContextState();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [otp, setOtp] = useState("");
    const [formError, setFormError] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
    });

    const [checkEmail, setCheckEmail] = useState(false);
    const [checkPhone, setCheckPhone] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [existingPhone, setExistingPhone] = useState(false);
    const [emailErr, setEmailErr] = useState(null);
    const [phoneErr, setPhoneErr] = useState(null);
    const [step, setStep] = useState(1);
    const [showSummary, setShowSummary] = useState(false);
    const [passType, setPassType] = useState(TYPE_PASSWORD);
    const [confirmPassType, setConfirmPassType] = useState(TYPE_PASSWORD);
    const [showOtp, setShowOtp] = useState(false);

    const location = useLocation();
    const referral = getURLQueryParams(location, "referral");

    // RESENT OTP FILEDS
    const [resendCount, setResendCount] = useState(0);
    const [counter, setCounter] = useState(0);

    /* @DESC::  close the modal and reset form  */
    const closeModalHandler = useCallback(() => {
        setFormData({
            name: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
        });
        setOtp("");
        setCheckEmail(false);
        setCheckPhone(false);
        setExistingEmail(false);
        setExistingPhone(false);
        setEmailErr(null);
        setPhoneErr(null);
        setStep(1);
        dispatch({ type: CLOSE_SIGNUP_FORM });
    }, [dispatch]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        const updateCounter = () => {
            if (counter === 0) return;
            else {
                let temp = counter - 1;
                setCounter(temp);
            }
        };
        let timer = setInterval(updateCounter, 1000);
        if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [counter]);

    /* @DESC::  Resend OTP   */
    const handleResentOTP = useCallback(
        (type) => {
            request
                .get({
                    endpoint: `${RESEND_OTP_ENDPOINT}?mobile=91${formData.phone}&type=${type}`,
                })
                .then((res) => {
                    if (res?.status === "SUCCESS") {
                        toast.success(
                            res?.message && typeof res?.message === "string"
                                ? res?.message
                                : type === "voice"
                                ? "Voice call has been sent!"
                                : "OTP has sent successfully!"
                        );
                        setLoading(false);
                        setResendCount((prev) => prev + 1);
                        setCounter(COUNTER_TIME * (resendCount + 1));
                    }
                })
                .catch((error) => {
                    setLoading(false);

                    if (error === "otp_expired") {
                        setResendCount(0);
                        setCounter(0);
                        closeModalHandler();
                    }
                    dispatch({
                        type: SET_ERROR,
                        payload: typeof error === "string" ? error : ERROR_MSG,
                    });
                });
        },
        [closeModalHandler, dispatch, formData.phone, resendCount]
    );

    /* @DESC::  validate and check existing email and phone  */
    const handleBlur = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "email") {
            if (!emailRegEx.test(value.toLowerCase())) {
                setEmailErr("Enter a valid email!");
                setCheckEmail(false);
                setExistingEmail(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            email: value,
                            roles: [ROLE.CONSUMER_USER, ROLE.INVESTOR],
                        },
                    })
                    .then((res) => {
                        setCheckEmail(true);
                        setExistingEmail(false);
                        setEmailErr("");
                    })
                    .catch((err) => {
                        setCheckEmail(false);
                        setExistingEmail(true);
                        setEmailErr(
                            typeof err === "string"
                                ? err
                                : "Email is already in use. Click "
                        );
                    });
            }
        } else {
            /* @TODO ==>  Phone validation Mon Oct 03  */
            if (!phoneRegEx.test(value)) {
                setPhoneErr("Enter a valid phone number!");
                setCheckPhone(false);
                setExistingPhone(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            phone: `91${value}`,
                            roles: [
                                ROLE.CONSUMER_USER,
                                ROLE.CONSUMER_OWNER,
                                ROLE.COMPANY_AGENT,
                                ROLE.CONSUMER_ADMIN,
                            ],
                        },
                    })
                    .then((res) => {
                        setCheckPhone(true);
                        setExistingPhone(false);
                        setPhoneErr("");
                    })
                    .catch((err) => {
                        setCheckPhone(false);
                        setExistingPhone(true);
                        setPhoneErr(
                            typeof err === "string"
                                ? err
                                : "Phone is already in use. Click "
                        );
                    });
            }
        }
    };

    /* @DESC::  referral checking  */
    const handleCheckReferralCode = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (referral) {
                    await request.post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            referralCode: referral,
                        },
                    });
                    return resolve(referral);
                } else {
                    return resolve(DEFAULT_REF_CODE);
                }
            } catch (error) {
                return resolve(DEFAULT_REF_CODE);
            }
        });
    };

    /* @DESC:: first call get OTP   */
    /* @TODO => USE this in second time @habib610 Thu July 18,2024 */
    const handleGetOtp = (e) => {
        e.preventDefault();

        const getValidName = validateNameEmailPhone(formData);

        const getValidFormData = validatePasswordDobOTP(formData, 6, false);
        const obj = { ...getValidName, ...getValidFormData };
        setFormError(obj);

        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            const reqData = {
                name: formData.name,
                email: formData.email,
                phone: `91${formData.phone}`,
            };
            let endpoint = SIGNUP_ENDPOINT;
            request
                .post({
                    endpoint,
                    body: reqData,
                })
                .then((response) => {
                    toast.success("OTP has been sent!");
                    setResendCount((prev) => prev + 1);
                    setCounter(COUNTER_TIME);
                    setLoading(false);
                    setShowOtp(true);
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err || ERROR_MSG);
                });
        }
    };

    const handleAuthCredentials = (e) => {
        e.preventDefault();
        const values = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
        };

        const getValidFormData = validateNameEmailPhone(values);
        setFormError(getValidFormData);

        if (Object.keys(getValidFormData).length === 0) {
            setStep(2);
        }
    };

    /* @DESC::  final call submit with carboon and OTP || Complete new user signup  */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = {
            dateOfBirth: formData.dateOfBirth,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
            otp,
        };
        const getValidFormData = validatePasswordDobOTP(values);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            try {
                let refCode = await handleCheckReferralCode(referral);
                let reqData = {
                    name: formData.name,
                    email: formData.email,
                    phone: `91${formData.phone}`,
                    // @TODO ==>   verify REFERRAL CODE Mon Nov  06
                    // referralCode: refCode,
                    dateOfBirth: "18-07-1980",
                    password: formData.password,
                    otp,
                };
                let endpoint = SIGNUP_ENDPOINT;
                await request.post({
                    endpoint,
                    body: reqData,
                });
                // @TODO ==>   some message Mon Jun  26
                await auth.login({
                    username: formData.email,
                    password: formData.password,
                    otpEnable: false,
                });

                await auth.getUserProfile(dispatch);
                const path = state.redirectPath;
                if (path) {
                    history.push(path);
                }
                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });
                setLoading(false);
                // setShowSummary(true);
                closeModalHandler();
            } catch (err) {
                setLoading(false);
                toast.error(err || ERROR_MSG);
                // setShowSummary(false);
            }
        }
    };

    const handleUserLogin = async (type, value) => {
        try {
            await auth.loginOTP({
                username: value,
            });
            toast.success("OTP has been sent");
            dispatch({
                type: UPDATE_QUICK_USER,
                payload: { [type]: value },
            });
            // onSuccess(true);
            closeModalHandler();
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            dispatch({
                type: UPDATE_QUICK_USER,
                payload: { [type]: value },
            });
        }
    };

    const handleBack = () => {
        setStep(1);
        setShowOtp(false);
        setResendCount(0);
        setCounter(0);
        setLoading(false);
    };
    const handleLoginExistingUser = () => {
        setStep(1);
        dispatch({ type: SHOW_LOGIN_FORM });
    };

    const handleCloseSummaryAndLogin = () => {
        setShowSummary(false);
        handleLoginExistingUser();
    };

    const updatePasswordFiledType = (filedType) => {
        if (filedType === "password") {
            setPassType(passType === TYPE_PASSWORD ? TYPE_TEXT : TYPE_PASSWORD);
        } else {
            setConfirmPassType(
                confirmPassType === TYPE_PASSWORD ? TYPE_TEXT : TYPE_PASSWORD
            );
        }
    };
    return (
        <>
            <ModalAlt
                times={false}
                onClose={closeModalHandler}
                isOpen={state.signupForm}
                backgroundColor="primary"
                width={["100%", "600px", "600px"]}
            >
                <Space xxsp={["2.5rem 0 0"]}>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <AuthenticationHead
                            type="Existing User"
                            actionText="Login here"
                            onClick={handleLoginExistingUser}
                        />
                        <Space xlm={["2rem 0 1.5rem "]} xxsm={["3rem 0 1rem"]}>
                            <Text
                                align="center"
                                weight="700"
                                size="xl"
                                color="white"
                            >
                                Sign In
                            </Text>
                        </Space>
                        <AnimatePresence>
                            {step === 1 ? (
                                <>
                                    <DetailsFiled
                                        label="Full Name"
                                        placeholder="Enter your full name"
                                        name="name"
                                        errorMessage={formError.nameError}
                                        value={formData.name}
                                        onChange={handleChange}
                                    />

                                    <DetailsFiled
                                        label="Email Id"
                                        placeholder="Enter Your Email"
                                        name="email"
                                        autoComplete="new-email"
                                        // errorMessage={formError.emailError}
                                        errorMessage={
                                            formError.emailError || emailErr ? (
                                                <>
                                                    {formError.emailError ||
                                                        emailErr}
                                                    {existingEmail && (
                                                        <ExistingBtn
                                                            size="s"
                                                            color="white"
                                                            weight="700"
                                                            onClick={() =>
                                                                handleUserLogin(
                                                                    "email",
                                                                    formData.email
                                                                )
                                                            }
                                                        >
                                                            Login
                                                        </ExistingBtn>
                                                    )}
                                                </>
                                            ) : null
                                        }
                                        value={formData.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <DetailsFiled
                                        label="Phone No."
                                        placeholder=" Enter your phone number"
                                        name="phone"
                                        autoComplete="new-phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMessage={
                                            formError.phoneError || phoneErr ? (
                                                <>
                                                    {formError.emailError ||
                                                        phoneErr}
                                                    {existingPhone && (
                                                        <ExistingBtn
                                                            size="s"
                                                            color="white"
                                                            weight="700"
                                                            onClick={() =>
                                                                handleUserLogin(
                                                                    "phone",
                                                                    `91${formData.phone}`
                                                                )
                                                            }
                                                        >
                                                            Login
                                                        </ExistingBtn>
                                                    )}
                                                </>
                                            ) : null
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <DetailsFiled
                                        label="Password"
                                        placeholder="Enter Password"
                                        name="password"
                                        errorMessage={formError.passwordError}
                                        value={formData.password}
                                        onChange={handleChange}
                                        type={passType}
                                        icon={
                                            passType === TYPE_PASSWORD
                                                ? faEyeSlash
                                                : faEye
                                        }
                                        onIconClick={() =>
                                            updatePasswordFiledType(
                                                TYPE_PASSWORD
                                            )
                                        }
                                    />
                                    <DetailsFiled
                                        label="Confirm Password"
                                        placeholder="Enter Confirm Password"
                                        name="confirmPassword"
                                        errorMessage={
                                            formError.confirmPassError
                                        }
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        type={confirmPassType}
                                        icon={
                                            confirmPassType === TYPE_PASSWORD
                                                ? faEyeSlash
                                                : faEye
                                        }
                                        onIconClick={() =>
                                            updatePasswordFiledType()
                                        }
                                    />

                                    {showOtp && (
                                        <Space
                                            xlm={["0 0 30px "]}
                                            stm={["0 0 25px"]}
                                            xxsm={["0 0 10px"]}
                                        >
                                            <Flex.Container
                                                justifyContent="space-between"
                                                alignItems="center"
                                                as={motion.div}
                                                variants={virtualOTP}
                                                initial="hidden"
                                                animate="visible"
                                                exit="exit"
                                            >
                                                <Flex.Item
                                                    width="25%"
                                                    tabletWidth="100%"
                                                    mobileWidth="100%"
                                                >
                                                    <Text
                                                        color="white"
                                                        weight="bold"
                                                        size="m"
                                                    >
                                                        OTP
                                                    </Text>
                                                </Flex.Item>
                                                <Flex.Item
                                                    width="70%"
                                                    tabletWidth="100%"
                                                    mobileWidth="100%"
                                                >
                                                    <OtpInput
                                                        label={false}
                                                        value={otp}
                                                        errorMessage={
                                                            formError.otpError
                                                        }
                                                        onChange={(e) =>
                                                            setOtp(
                                                                e.target.value
                                                            )
                                                        }
                                                        size={OTP_LENGTH}
                                                        isInputNum={true}
                                                        errorType="alert"
                                                    />
                                                    <Flex.Container
                                                        justifyContent="space-evenly"
                                                        alignItems="center"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {counter === 0 && (
                                                            <>
                                                                <OTPButton
                                                                    onClick={() =>
                                                                        handleResentOTP(
                                                                            "text"
                                                                        )
                                                                    }
                                                                    role="button"
                                                                    size="s"
                                                                    color="white"
                                                                    weight="700"
                                                                >
                                                                    Get OTP Text
                                                                </OTPButton>
                                                                <OTPButton
                                                                    color="white"
                                                                    onClick={() =>
                                                                        handleResentOTP(
                                                                            "voice"
                                                                        )
                                                                    }
                                                                    role="button"
                                                                    weight="700"
                                                                    size="s"
                                                                >
                                                                    Get OTP on
                                                                    Call
                                                                </OTPButton>
                                                            </>
                                                        )}
                                                    </Flex.Container>
                                                </Flex.Item>
                                            </Flex.Container>
                                        </Space>
                                    )}
                                    <Space
                                        xlm={["0 0 25px "]}
                                        stm={["0 0 25px"]}
                                        xxsm={["0 0 10px"]}
                                    >
                                        <Flex.Container
                                            justifyContent="space-evenly"
                                            alignItems="center"
                                        >
                                            {counter === 0 ? (
                                                <>
                                                    {formError.emailError ||
                                                        emailErr}
                                                    {existingEmail && (
                                                        <ExistingBtn
                                                            xxs="s"
                                                            st="s"
                                                            color="primary"
                                                            weight="b"
                                                            onClick={() =>
                                                                handleUserLogin(
                                                                    "email",
                                                                    formData.email
                                                                )
                                                            }
                                                        >
                                                            Login
                                                        </ExistingBtn>
                                                    )}
                                                </>
                                            ) : (
                                                <Text
                                                    weight="700"
                                                    color="white"
                                                    size="s"
                                                >
                                                    Resend OTP in {counter}s
                                                </Text>
                                            )}
                                        </Flex.Container>
                                    </Space>
                                </>
                            )}
                        </AnimatePresence>

                        <Flex.Container
                            justifyContent="center"
                            alignItems="center"
                        >
                            {step === 1 ? (
                                <ButtonAlt
                                    xxs="xs"
                                    st="m"
                                    weight="b"
                                    color="white"
                                    backgroundColor="white"
                                    onClick={handleAuthCredentials}
                                    style={{ borderRadius: "50px" }}
                                    disabled={
                                        loading || !checkEmail || !checkPhone
                                    }
                                    isLoading={loading}
                                >
                                    Continue
                                </ButtonAlt>
                            ) : (
                                <>
                                    <ButtonAlt
                                        onClick={handleBack}
                                        xxs="xs"
                                        st="m"
                                        weight="b"
                                        backgroundColor="lightGrey"
                                        hoverColor="primary"
                                        color="white"
                                        style={{
                                            borderRadius: "50px",
                                            marginRight: "15px",
                                            padding: "1rem 3rem",
                                        }}
                                    >
                                        Back
                                    </ButtonAlt>
                                    <ButtonAlt
                                        onClick={
                                            showOtp
                                                ? handleSubmit
                                                : handleGetOtp
                                        }
                                        xxs="xs"
                                        st="m"
                                        weight="b"
                                        backgroundColor="white"
                                        hoverColor="primary"
                                        color="white"
                                        style={{
                                            borderRadius: "50px",
                                            padding: "1rem 3rem",
                                        }}
                                        disabled={
                                            loading ||
                                            !checkEmail ||
                                            !checkPhone
                                        }
                                        isLoading={loading}
                                    >
                                        {showOtp ? "Submit" : "GET OTP"}
                                    </ButtonAlt>
                                </>
                            )}
                        </Flex.Container>
                    </form>
                </Space>
            </ModalAlt>
            <ModalAlt
                times={false}
                onClose={() => setShowSummary(false)}
                isOpen={showSummary}
                backgroundColor="primary"
                width={["700px", "80%", "100%"]}
            >
                <VirtualLoginSummary
                    summaryErr={false}
                    onClose={closeModalHandler}
                >
                    <Text align="left" size="m" weight="700" color="white">
                        Your profile is created successfully.
                    </Text>
                    <Space xxsm={["1rem 0"]}>
                        <Text
                            color="white"
                            size="m"
                            lineHeight="1.3em"
                            align="left"
                        >
                            You can
                            <LoginBtn
                                onClick={handleCloseSummaryAndLogin}
                                role="button"
                                weight="bold"
                            >
                                Login
                            </LoginBtn>{" "}
                            here
                        </Text>
                    </Space>
                </VirtualLoginSummary>
            </ModalAlt>
        </>
    );
};

export default SignupModal;

export const ExistingBtn = styled(InlineText)`
    text-decoration: underline;
    margin-left: 5px;
    font-weight: ${(props) => props.theme.fontWeight.b};
    :hover {
        cursor: pointer;
        color: ${(props) => props.theme.color.pureWhite};
    }
`;
export const OTPButton = styled(Text)`
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

const LoginBtn = styled(InlineText)`
    color: ${(props) => props.theme.color.lightGreen};
    margin: 0 5px;
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
