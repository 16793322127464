import React from "react";

import H2 from "../../shared/typography/H2";
import SubHeading from "../../shared/typography/SubHeading";
import Tag from "../../shared/ui/tag/Tag";
const OurAim = () => {
    return (
        <div className={`flex flex-col  gap-1.6  mb-1  `}>
            <div>
                <Tag
                    className={
                        "bg-white border-2 border-blue-400 rounded-[3rem]"
                    }
                    textClass={"text-gray-secondary"}
                    text={"Our Aim"}
                />
            </div>
            <H2 className="!font-normal ">Core Values</H2>

            <SubHeading className={"text-justify  "}>
                Our core values drive everything we do, fostering integrity,
                innovation, and sustainability in every aspect of our business.
            </SubHeading>
        </div>
    );
};

export default OurAim;
