import React from "react";
import P from "../../../../shared/typography/P";
import Transaction from "../../../portfoilo/mobile/portfolio-details/transactions";

const MyInvestment = ({ investmentData, transactionData, onClick }) => {
    const transactions = transactionData?.data?.transactions;
    return (
        <div>
            <div className="flex justify-between items-center">
                <P className=" font-semibold md:text-base xl:text-base">
                    Transaction
                </P>
                <P className="underline md:text-sm xl:text-sm text-blue-500 cursor-pointer">
                    View All
                </P>
            </div>
            <div className=" flex flex-col gap-2 mt-2  ">
                <Transaction
                    isScrollDisabled={false}
                    transactions={transactions}
                    onClick={onClick}
                    investedCapital={
                        investmentData?.data?.portfolio?.investedCapital
                    }
                />
            </div>
        </div>
    );
};

export default MyInvestment;
