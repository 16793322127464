import moment from "moment/moment";
import React from "react";
import {
    IoArrowDownCircleOutline,
    IoArrowUpCircleOutline,
    IoFilter,
} from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { PORTFOLIO } from "../../../../../constants/routes";
import cn from "../../../../../lib/cn";
import { check, close, warning } from "../../../../../resources/js/icons";
import P from "../../../../../shared/typography/P";
import SubHeading from "../../../../../shared/typography/SubHeading";
import Accordion from "../../../../../shared/ui/Accordian/Accordian";

export const payoutData = [
    {
        month: "September 2024",
        payouts: [
            {
                id: 1,
                title: "Tata Tele-Communications",
                date: "03/09/2024",
                status: "Active",
                amount: 12234,
                sign: "+",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 2,
                title: "Lorem Ipsum Project",
                date: "05/09/2024",
                status: "Matured",
                amount: 5000,
                sign: "-",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
            {
                id: 3,
                title: "ABC Corp",
                date: "12/09/2024",
                status: "Failed",
                amount: 2400,
                sign: "+",
                statusColor: "text-red-200 bg-red-100",
                icon: close,
            },
            {
                id: 4,
                title: "XYZ Enterprises",
                date: "18/09/2024",
                status: "Active",
                amount: 7500,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 5,
                title: "Tech Solutions",
                date: "25/09/2024",
                status: "Matured",
                amount: 8300,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
        ],
    },
    {
        month: "October 2024",
        payouts: [
            {
                id: 6,
                title: "Green Energy Inc.",
                date: "02/10/2024",
                status: "Failed",
                amount: 2000,
                sign: "+",
                statusColor: "text-red-200 bg-red-100",
                icon: close,
            },
            {
                id: 7,
                title: "Design Studio",
                date: "08/10/2024",
                status: "Active",
                amount: 15000,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 8,
                title: "Market Research",
                date: "15/10/2024",
                status: "Matured",
                amount: 3000,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
            {
                id: 9,
                title: "Digital Marketing Co.",
                date: "20/10/2024",
                status: "Active",
                amount: 4800,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 10,
                title: "Consulting Services",
                date: "28/10/2024",
                status: "Matured",
                amount: 12500,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
        ],
    },
    {
        month: "November 2024",
        payouts: [
            {
                id: 11,
                title: "Cloud Services Ltd.",
                date: "01/11/2024",
                status: "Matured",
                amount: 7000,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
            {
                id: 12,
                title: "Innovative Solutions",
                date: "05/11/2024",
                status: "Active",
                amount: 16000,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 13,
                title: "E-commerce Ventures",
                date: "10/11/2024",
                status: "Failed",
                amount: 9000,
                sign: "+",
                statusColor: "text-red-200 bg-red-100",
                icon: close,
            },
            {
                id: 14,
                title: "Retail Management",
                date: "15/11/2024",
                status: "Active",
                amount: 22000,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 15,
                title: "Investment Fund",
                date: "25/11/2024",
                status: "Matured",
                amount: 31000,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
        ],
    },
    {
        month: "December 2024",
        payouts: [
            {
                id: 16,
                title: "Financial Consulting",
                date: "02/12/2024",
                status: "Failed",
                amount: 1200,
                sign: "+",
                statusColor: "text-red-200 bg-red-100",
                icon: close,
            },
            {
                id: 17,
                title: "Health Services",
                date: "10/12/2024",
                status: "Active",
                amount: 15000,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 18,
                title: "Real Estate Agency",
                date: "18/12/2024",
                status: "Matured",
                amount: 28000,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
            {
                id: 19,
                title: "Travel Agency",
                date: "20/12/2024",
                status: "Active",
                amount: 4500,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 20,
                title: "Event Management Co.",
                date: "30/12/2024",
                status: "Matured",
                amount: 15000,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
        ],
    },
    {
        month: "January 2025",
        payouts: [
            {
                id: 21,
                title: "Software Development Inc.",
                date: "05/01/2025",
                status: "Active",
                amount: 32500,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 22,
                title: "Marketing Group",
                date: "09/01/2025",
                status: "Matured",
                amount: 13500,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
            {
                id: 23,
                title: "Security Services",
                date: "15/01/2025",
                status: "Active",
                amount: 3000,
                sign: "-",
                statusColor: "bg-green-50 text-green-400",
                icon: check,
            },
            {
                id: 24,
                title: "Logistics Company",
                date: "22/01/2025",
                status: "Failed",
                amount: 7000,
                sign: "+",
                statusColor: "text-red-200 bg-red-100",
                icon: close,
            },
            {
                id: 25,
                title: "Fashion Brand",
                date: "30/01/2025",
                status: "Matured",
                amount: 24000,
                sign: "+",
                statusColor: "text-orange bg-orange-100",
                icon: warning,
            },
        ],
    },
];
export const Payout = ({ item, isLast }) => {
    const history = useHistory();
    return (
        <div
            className={`flex justify-between items-center py-1.6  ${
                !isLast ? "border-b border-gray-4000" : ""
            }`}
            role="button"
            onClick={() => history.push(`${PORTFOLIO}/${item.id}`)}
        >
            <div className="flex items-center">
                <span
                    className={`text-2xl mr-2 bg-gray-60 rounded-full text-gray-550`}
                >
                    {item.type === "CREDIT" ? (
                        <IoArrowUpCircleOutline />
                    ) : (
                        <IoArrowDownCircleOutline />
                    )}
                </span>
                <div className="flex flex-col gap-0.5">
                    <SubHeading className="font-medium text-gray  md:text-sm lg:!text-base xl:!text-base">
                        {item?.name}
                    </SubHeading>
                    <P className="text-gray-500 text-sm md:text-sm xl:text-sm">
                        {moment(item?.date * 1000).format("DD/MM/yyyy")}
                    </P>
                </div>
            </div>
            <div className="flex flex-col items-end  gap-1">
                <SubHeading
                    className={cn(
                        `md:text-sm lg:!text-sm xl:!text-sm flex py-0.5 rounded-3xl px-1 gap-0.5 items-center`,
                        {
                            "bg-green-50 text-green-400":
                                item.type === "CREDIT",
                            "text-red-200 bg-red-100": item.type === "DEBIT",
                        }
                    )}
                >
                    <img
                        src={item.type === "CREDIT" ? check : close}
                        alt="type_icon"
                    />
                    {item.type}
                </SubHeading>
                <SubHeading className="mr-1 xl:font-semibold  md:text-sm lg:!text-base xl:!text-base">
                    {item.type === "CREDIT" ? "+" : "-"} ₹ {item.amount}
                </SubHeading>
            </div>
        </div>
    );
};

const PayoutList = ({ transactions, isScrollDisabled, onClick }) => {
    if (!transactions) return null;

    return (
        <div className="px-2 font-vietnam ">
            <div className="flex justify-end gap-1  md:py-0 items-center mb-1">
                <div className="flex text-2xl items-center gap-2 mt-2 lg:mt-0">
                    <IoFilter onClick={onClick} />
                    {/* <FiDownload /> */}
                </div>
            </div>

            <div
                className={`flex flex-col  space-y-2 lg:space-y-2 max-h-screen xl:max-h-[650px]  pb-[250px] xl:pb-4 
                     ${isScrollDisabled ? "" : "overflow-y-auto"}`}
            >
                {Object.keys(transactions).map((item, index) => (
                    <div key={index} className="pb-2 border-b border-gray-550">
                        <Accordion
                            wrapperClass="py-0 mb-0 mt-0 px-0 "
                            isOpen={true}
                            label={item}
                            labelWrapperClass="bg-gray-50 py-1 px-0.5 rounded"
                            contentMargin="mt-0"
                        >
                            {transactions[item].map(
                                (payOutItem, payoutIndex) => (
                                    <Payout
                                        key={payOutItem.id}
                                        item={payOutItem}
                                        isLast={
                                            transactions[item]?.length - 1 ===
                                            payoutIndex
                                        }
                                    />
                                )
                            )}
                        </Accordion>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PayoutList;
