import React from "react";
import {
    documentDownload,
    eye,
    projectFile,
} from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";

const Documents = ({ documents = [] }) => {
    if (documents.length === 0) {
        return (
            <div className="my-2 bg-yellow/5 text-red-300 text-center py-2">
                <P className="text-yellow">No Documents Available</P>
            </div>
        );
    }
    return (
        <div>
            {documents.map((item) => (
                <div
                    className="bg-background rounded-[1.2rem] p-0.8 border border-gray-400 flex justify-between items-center mb-1.6 font-vietnam"
                    key={item}
                >
                    <div className="flex items-center gap-2 ">
                        <img
                            src={projectFile}
                            alt="projectFile"
                            className="h-4.8 "
                        />
                        <div className="">
                            <P className="text-gray mb-0.4">Tata Project</P>
                            <P className="text-gray-550">540 Kb</P>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <img
                            src={eye}
                            alt="projectFile"
                            className="h-2.4 w-full"
                        />{" "}
                        <img
                            src={documentDownload}
                            alt="projectFile"
                            className="h-2.4 w-full"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Documents;
