import {
    CLOSE_LOGIN_FORM,
    CLOSE_SIGNUP_FORM,
    DISABLE_SROT_NOTIFICATION,
    DISABLE_VIDEO_GUIDE,
    DISABLE_VIDEO_LOCAL_GUIDE,
    PUSH_ALL_RECENT_NOTIFICATION_QUEUE,
    PUSH_CONNECT_DISCONNECT_QUEUE,
    PUSH_PROJECTS_NOTIFICATION_QUEUE,
    REMOVE_QUICK_USER,
    REMOVE_REDIRECT_PATH,
    REMOVE_USER,
    RESET_ERROR,
    RESET_JOINABLE_WAITING_LIST,
    RESET_LOADER,
    RESET_REQUEST_MODAL,
    SET_ERROR,
    SET_LOADER,
    SET_REDIRECT_PATH,
    SET_VIRTUAL_DATA,
    SHIFT_PROJECTS_NOTIFICATION_QUEUE,
    SHOW_LOGIN_FORM,
    SHOW_SIGNUP_FORM,
    UPDATE_HELPER_SCREEN,
    UPDATE_JOINABLE_WAITING_LIST,
    UPDATE_QUICK_USER,
    UPDATE_REQUEST_MODAL,
    UPDATE_USER,
} from "../constants/contexConstant";

export const reducer = (state, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case UPDATE_QUICK_USER:
            return { ...state, quickUser: action.payload };

        case REMOVE_USER:
            return { ...state, user: null };
        case REMOVE_QUICK_USER:
            return { ...state, quickUser: null };

        case SHOW_LOGIN_FORM:
            if (state.signupForm) {
                return { ...state, loginForm: true, signupForm: false };
            }
            return { ...state, loginForm: true };
        case CLOSE_LOGIN_FORM:
            return { ...state, loginForm: false };

        case SHOW_SIGNUP_FORM:
            if (state.loginForm) {
                return { ...state, loginForm: false, signupForm: true };
            }
            return { ...state, signupForm: true };
        case CLOSE_SIGNUP_FORM:
            return { ...state, signupForm: false };

        case SET_REDIRECT_PATH:
            return { ...state, redirectPath: action.payload };
        case REMOVE_REDIRECT_PATH:
            return { ...state, redirectPath: null };

        case UPDATE_HELPER_SCREEN:
            return { ...state, isRegHelperScreenEnabled: false };
        case DISABLE_VIDEO_GUIDE:
            return {
                ...state,
                videoGuide: true,
            };
        case DISABLE_VIDEO_LOCAL_GUIDE:
            localStorage.setItem("videoGuide", "true");
            return {
                ...state,
                videoGuide: true,
            };
        case SET_ERROR:
            return { ...state, error: action.payload };
        case RESET_ERROR:
            return { ...state, error: null };
        case SET_LOADER:
            return { ...state, loading: true };
        case RESET_LOADER:
            return { ...state, loading: false };
        case SET_VIRTUAL_DATA:
            return { ...state, virtualData: action.payload };
        case UPDATE_REQUEST_MODAL:
            return { ...state, requestCallback: true };
        case RESET_REQUEST_MODAL:
            return { ...state, requestCallback: false };

        case UPDATE_JOINABLE_WAITING_LIST:
            return { ...state, joinWaitingList: action.payload };
        case RESET_JOINABLE_WAITING_LIST:
            return { ...state, joinWaitingList: null };

        case PUSH_ALL_RECENT_NOTIFICATION_QUEUE:
            return {
                ...state,
                projectsNotifyQueue: action.payload,
            };

        case PUSH_PROJECTS_NOTIFICATION_QUEUE:
            return {
                ...state,
                projectsNotifyQueue: [
                    action.payload,
                    ...state.projectsNotifyQueue,
                ],
            };

        case PUSH_CONNECT_DISCONNECT_QUEUE:
            let tempQueue = state.projectsNotifyQueue.filter(
                (item) => !item.totalUsers
            );
            const data = action.payload;
            if (data.totalUsers > 1) {
                return {
                    ...state,
                    projectsNotifyQueue: [action.payload, ...tempQueue],
                };
            }
            return {
                ...state,
                projectsNotifyQueue: tempQueue,
            };

        case SHIFT_PROJECTS_NOTIFICATION_QUEUE:
            return {
                ...state,
                projectsNotifyQueue: action.payload,
            };

        case DISABLE_SROT_NOTIFICATION:
            sessionStorage.setItem("_srotNotificationDisabled", "true");
            return {
                ...state,
                hideNotification: true,
            };

        default:
            return state;
    }
};
