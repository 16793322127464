import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import { listOfArticles } from "../../../constants/blogData";
import { BLOG } from "../../../constants/routes";
import { learn } from "../../../resources/js/images";
import P from "../../../shared/typography/P";
import PillSlider from "../../../shared/ui/Slider/PillSlider";
import GalleryCard from "./NewUser/GalleryCard";
const LearnMore = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const history = useHistory();
    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };
    const articles = [
        {
            id: 1,
            title: "Article Name One Lorem Ipsum",
            img: learn,
        },
        {
            id: 2,
            title: "Article Name Two Lorem Ipsum",
            img: learn,
        },
        {
            id: 3,
            title: "Article Name Three Lorem Ipsum",
            img: learn,
        },
        {
            id: 4,
            title: "Article Name Four Lorem Ipsum",
            img: learn,
        },
    ];
    return (
        <div className="bg-gray-60 p-3 xl:rounded-3xl mb-3">
            <div className="flex justify-between items-center mb-2">
                <P className=" text-base xl:text-base xl:text-black font-semibold">
                    Learn More
                </P>
                <P className="underline text-sm xl:text-sm text-blue-500 cursor-pointer">
                    View All
                </P>
            </div>
            {listOfArticles.length > 2 || document.body.clientWidth < 779 ? (
                <>
                    <div className="py-1">
                        <CarouselComponent
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showStatus={false}
                            showThumbs={false}
                            showIndicators={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            stopOnHover={true}
                            transitionTime={500}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                            centerMode={true}
                            centerSlidePercentage={
                                document.body.clientWidth < 1279
                                    ? document.body.clientWidth < 1100
                                        ? document.body.clientWidth < 766
                                            ? document.body.clientWidth < 630
                                                ? 60
                                                : 80
                                            : 70
                                        : 50
                                    : document.body.clientWidth > 1535
                                    ? 35
                                    : 30
                            }
                        >
                            {listOfArticles.map((article, index) => (
                                <div
                                    key={index}
                                    className="h-full"
                                    onClick={() =>
                                        history.push(
                                            `${BLOG}/${article?.meta?.blogUrl}`
                                        )
                                    }
                                >
                                    <GalleryCard
                                        key={article.id}
                                        text={article?.meta?.metaTitle}
                                        img={article?.meta?.thumbnail}
                                    />
                                </div>
                            ))}
                        </CarouselComponent>
                    </div>
                    <div className="flex justify-start">
                        <PillSlider
                            currentSlide={currentSlide}
                            className={"my-1"}
                            data={articles}
                            handleSlideChange={handleSlideChange}
                        />
                    </div>
                </>
            ) : (
                <div className="flex gap-3 flex-wrap lg:flex-nowrap items-center mt-16">
                    {listOfArticles.map((article, index) => (
                        <div
                            key={index}
                            className="h-full"
                            onClick={() =>
                                history.push(
                                    `${BLOG}/${article?.meta?.blogUrl}`
                                )
                            }
                        >
                            <GalleryCard
                                key={article.id}
                                text={article?.meta?.metaTitle}
                                img={article?.meta?.thumbnail}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LearnMore;
