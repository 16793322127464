import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import P from "../../../../shared/typography/P";

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    margin: 20px 0;
`;

const SliderTrack = styled.div`
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: #deeafb;
    border-radius: 3px;
    z-index: 1;
`;

const SliderRange = styled.div`
    position: absolute;
    height: 5px;
    background-color: #356bdb;
    border-radius: 3px;
    z-index: 2;
`;

const Thumb = styled.input`
    -webkit-appearance: none;
    width: 100%;
    position: absolute;
    pointer-events: none;
    height: 0;
    outline: none;
    z-index: 30;
    top: 2px;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background-color: #356bdb;
        border: 5px solid white;
        border-radius: 50%;

        cursor: pointer;
        position: relative;
        pointer-events: all;
    }

    &::-moz-range-thumb {
        width: 18px;
        height: 18px;
        background-color: white;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        cursor: pointer;
        position: relative;
        pointer-events: all;
    }
`;

const ValueLabel = styled.div`
    position: absolute;
    color: #356bdb;
    font-size: 12px;
    transform: translate(-50%, -150%); /* Center label above the thumb */

    padding: 2px 5px; /* Adds some padding around the label */

    z-index: 40; /* Ensures label appears above track */
`;

const MultiRangeSlider = ({ min, max, onChange }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const rangeRef = useRef(null);

    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    useEffect(() => {
        if (rangeRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(maxVal);
            rangeRef.current.style.left = `${minPercent}%`;
            rangeRef.current.style.width = `${maxPercent - minPercent}%`;
        }
        onChange({ min: minVal, max: maxVal });
    }, [minVal, maxVal, getPercent, onChange]);

    return (
        <SliderContainer>
            <SliderTrack />
            <SliderRange ref={rangeRef} />
            <Thumb
                type="range"
                min={min}
                max={max}
                value={minVal}
                onChange={(e) => {
                    const value = Math.min(Number(e.target.value), maxVal - 1);
                    setMinVal(value);
                }}
                className="thumb thumb--zindex-3"
            />
            <Thumb
                type="range"
                min={min}
                max={max}
                value={maxVal}
                onChange={(e) => {
                    const value = Math.max(Number(e.target.value), minVal + 1);
                    setMaxVal(value);
                }}
                className="thumb thumb--zindex-4"
            />
            <span className="h-2 w-2 absolute -left-0.3 -top-0.7 bottom-0  bg-white border-5 border-gray-400 rounded-full z-20"></span>
            <span className="h-2 w-2 absolute right-0  -top-0.7 bottom-0  bg-white border-5 border-gray-400 rounded-full z-20"></span>

            <ValueLabel style={{ left: `${getPercent(minVal)}%` }}>
                <P className="xl:text-sm text-blue"> {minVal}</P>
            </ValueLabel>
            <ValueLabel style={{ right: `${100 - getPercent(maxVal)}%` }}>
                <P className="xl:text-sm text-blue"> {maxVal}</P>
            </ValueLabel>
        </SliderContainer>
    );
};

MultiRangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
