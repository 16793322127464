import React from "react";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import Tag from "../../shared/ui/tag/Tag";

const HeroDetails = () => {
    return (
        <Container className="flex flex-col  justify-center  ">
            <div>
                <Tag
                    className={
                        "bg-transparent border border-blue-300 rounded-[3rem]"
                    }
                    textClass={"text-white"}
                    text={"About us"}
                />
                <H1 className="text-white font-normal pt-1 xl:w-5/6 2xl:w-4/6">
                    India’s leading provider of sustainable solar energy
                    solutions
                </H1>
            </div>
        </Container>
    );
};

export default HeroDetails;
