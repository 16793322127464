import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Space from "../components/space/Space";
import Text from "../components/text/Text";
import { LANDING } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import { getNameAvatar } from "../helpers/helpers";
import auth from "../services/auth";
const Wrapper = styled.div`
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    width: 100%;
    box-sizing: border-box;
    padding-right: 50px;
    height: 100%;
    padding: 50px 0px;
    a {
        text-decoration: none;
    }
`;
const Avatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border: 2px solid ${(props) => props.theme.color.orange};
    box-sizing: border-box;
    color: ${(props) => props.theme.color.orange};
    border-radius: 100%;
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    margin-top: ${(props) => props.top || ""};
    margin-bottom: ${(props) => props.bottom || ""};
`;
const RadioCircle = styled.div`
    width: ${(props) => (props.active ? "20px" : "15px")};
    height: ${(props) => (props.active ? "20px" : "15px")};
    transition: all ease-in 0.2s;
    border-radius: 100%;
    background-color: ${(props) =>
        props.active ? props.theme.color.primary : props.theme.color.lightGrey};
    outline: 1px solid
        ${(props) => (props.active ? props.theme.color.primary : "transparent")};
    outline-offset: 3px;
    @media (max-width: ${(props) => props.theme.breakPoints.bigTablet}) {
        width: ${(props) => (props.active ? "15px" : "10px")};
        height: ${(props) => (props.active ? "15px" : "10px")};
    }
`;
const ItemsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
`;
const LogOutBtn = styled(Button)`
    width: 100%;
    box-sizing: border-box;
`;
const NavWrap = styled.div`
    box-sizing: border-box;
    padding-left: 8%;
`;
const ProfileSidebar = () => {
    const history = useHistory();
    const urlPath = history.location.pathname;
    const { dispatch } = useContextState();

    const handleLogout = (e) => {
        e.preventDefault();

        auth.logout(dispatch)
            .then(() => {
                history.push(LANDING);
            })
            .catch((err) => {});
    };
    return (
        <Wrapper backgroundColor="greyBg">
            <Space margin={[" 0 0 40px 0"]}>
                <div>
                    <UserAvatar backgroundColor="grey" fontSize="xml" />
                </div>
            </Space>

            <NavWrap>
                {sidebarData.map((item) => (
                    <NavLink key={item.path} to={`${item.path}`}>
                        <ItemsWrapper active={item.path === urlPath}>
                            <Flex.Item width="15%" tabletWidth="20%">
                                <RadioCircle active={item.path === urlPath} />
                            </Flex.Item>
                            <Text
                                size="m"
                                color={
                                    item.path === urlPath
                                        ? "primary"
                                        : "darkSecondary"
                                }
                                weight={item.path === urlPath ? "700" : "400"}
                            >
                                {item.name}
                            </Text>
                        </ItemsWrapper>
                    </NavLink>
                ))}
            </NavWrap>
            <Flex.Container justifyContent="center">
                <Flex.Item width="80%">
                    <LogOutBtn
                        type="solid"
                        size="s"
                        color="white"
                        backgroundColor="primary"
                        onClick={handleLogout}
                    >
                        Logout
                    </LogOutBtn>
                </Flex.Item>
            </Flex.Container>
        </Wrapper>
    );
};

export const UserAvatar = ({
    backgroundColor = "grey",
    fontSize = "xl",
    size = "100px",
    weight = "normal",
}) => {
    const { state } = useContextState();
    return (
        <Avatar size={size} backgroundColor={backgroundColor}>
            <Text color="white" weight={weight} size={fontSize}>
                {getNameAvatar(state?.user?.name)}
            </Text>
        </Avatar>
    );
};

export default withRouter(ProfileSidebar);

const sidebarData = [
    {
        path: "/profile/user",
        name: "Profile",
    },
    {
        path: "/profile/kyc",
        name: "KYC",
    },
    {
        path: "/profile/bankDetails",
        name: "Bank Details",
    },
    // {
    //     path: "/profile/documents",
    //     name: "Documents",
    // },
    {
        path: "/profile/changePassword",
        name: "Change Password",
    },
];
