import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { REFERRAL, REWARDS } from "../../../constants/routes";
import {
    blueRewards,
    darkPinkRewards,
    greenRewards,
    orangeRewards,
} from "../../../resources/js/icons";
import P from "../../../shared/typography/P";
import Coin from "../../../shared/ui/Coin";
import AppLayout from "../../../shared/ui/layout/AppLayout";
import BorderedTab from "../../../shared/ui/tab/BorderedTab";

import EarnRewards from "./EarnRewards";
import RewardHistory from "./RewardHistory";

const options = ["Earn Rewards", "Reward History"];

const rewardsList = [
    blueRewards,
    darkPinkRewards,
    greenRewards,
    orangeRewards,
    blueRewards,
    darkPinkRewards,
    greenRewards,
    orangeRewards,
];

const Rewards = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const history = useHistory();
    return (
        /* @DESC:: We can reuse same layout structure as one component @habib610 Sat October 12,2024 */
        <AppLayout
            topContent={
                <div>
                    <BorderedTab
                        className=" bg-transparent"
                        onTabClick={(i) => setCurrentTab(i)}
                        options={options}
                        inActiveClass="bg-transparent text-gray-550"
                        currentTab={currentTab}
                    />
                    {currentTab === 0 && (
                        <div className="grid grid-cols-2 gap-2 mt-4">
                            <div className="bg-radial-brown py-1.6 px-2.4 rounded-sm">
                                <P className="text-gray mb-0.8">
                                    Total reward points
                                </P>
                                <Coin value={100000} />
                            </div>

                            <div
                                role="button"
                                onClick={() =>
                                    history.push(`${REWARDS}${REFERRAL}`)
                                }
                                className="bg-linear-button py-1.6 px-2.4 rounded-sm"
                            >
                                <P className="text-white mb-0.8">
                                    Earn on every referral
                                </P>
                                <Coin
                                    value={5000}
                                    textClass="text-white"
                                    className=" bg-linear-button-reverse "
                                />
                            </div>
                        </div>
                    )}
                </div>
            }
            bottomContent={
                currentTab === 0 ? <EarnRewards /> : <RewardHistory />
            }
        />
    );
};

export default Rewards;
