import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Accordion from "../components/accordion/Accordion";
import Spacing from "../components/space/Space";
import { Flex } from "../components/styles/Elements.styles";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";

const FAQWrapper = styled.div`
    margin-bottom: ${(props) => props.marginBottom};
`;

const renderInsideContent = (data, num) => {
    return data.faqs.map(
        (faq, i) =>
            i % 2 === num && (
                <Accordion key={faq.question} label={faq.question} size="s">
                    {faq.answer ? (
                        <div
                            style={{
                                marginBottom: faq.list ? "5px" : "0px",
                            }}
                        >
                            {faq.answer}
                        </div>
                    ) : null}
                    {faq.format ? (
                        <Text
                            dangerouslySetInnerHTML={faq.format}
                            size="s"
                            color="sharkGrey"
                            style={{
                                marginBottom: faq.list ? "5px" : "0px",
                                marginTop: faq.answer ? "5px" : "0px",
                            }}
                        ></Text>
                    ) : null}
                    {faq.list
                        ? faq.list.map((item, index) => (
                              <Flex.Container
                                  key={index}
                                  style={{
                                      marginBottom: "5px",
                                  }}
                              >
                                  <Flex.Item>
                                      <Text
                                          size="s"
                                          color="sharkGrey"
                                          style={{
                                              width: "15px",
                                              marginRight: "10px",
                                          }}
                                      >
                                          {`${index + 1}.`}
                                      </Text>
                                  </Flex.Item>
                                  <Flex.Item style={{ flex: "1" }}>
                                      <Text color="sharkGrey" size="s">
                                          {item}
                                      </Text>
                                  </Flex.Item>
                              </Flex.Container>
                          ))
                        : null}
                </Accordion>
            )
    );
};

export const FAQ = ({ item, queryString, visibilityHandler }) => {
    const faqRef = useRef();
    useEffect(() => {
        let target = faqRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [faqRef, visibilityHandler]);

    return (
        <FAQWrapper
            ref={faqRef}
            marginBottom={queryString ? "0px" : "80px"}
            data-title={item.id}
            id={item.id}
        >
            {queryString ? null : (
                <Spacing margin={["0px 0px 20px 0px"]}>
                    <Text color="black" size="m" textTransform="uppercase">
                        FAQS ON{" "}
                        <InlineText color="sharkGrey">{item.title}</InlineText>
                    </Text>
                </Spacing>
            )}
            <FAQRows data={item} />
        </FAQWrapper>
    );
};

export const FAQRows = ({ data }) => {
    return (
        <Flex.Container justify="space-between">
            <Flex.Item xxs="100%" lg="48%">
                {renderInsideContent(data, 0)}
            </Flex.Item>
            <Flex.Item xxs="100%" lg="48%">
                {renderInsideContent(data, 1)}
            </Flex.Item>
        </Flex.Container>
    );
};

const FAQSections = ({ questions, queryString, visibilityHandler }) => {
    const handleFilterFaqs = () => {
        if (queryString === "") {
            return questions;
        } else {
            const result = questions.reduce((filtered, options) => {
                const optionFilter = options.faqs.filter((option) =>
                    option.question
                        .toLowerCase()
                        .includes(queryString.toLowerCase())
                );
                if (optionFilter.length > 0) {
                    let title = options.title;
                    const faqs = optionFilter;
                    filtered.push({ title, faqs });
                }
                return filtered;
            }, []);
            return result;
        }
    };

    return (
        <>
            {queryString !== "" ? (
                <Spacing margin={["0px 0px 20px"]}>
                    <Text color="sharkGrey" textTransform="uppercase">
                        Showing Result's ON{" "}
                        <InlineText color="lightGrey">{`"${queryString}"`}</InlineText>
                    </Text>
                </Spacing>
            ) : null}
            {handleFilterFaqs().length > 0 ? (
                handleFilterFaqs().map((item) => (
                    <FAQ
                        item={item}
                        key={item.title}
                        queryString={queryString}
                        visibilityHandler={visibilityHandler}
                    />
                ))
            ) : (
                <Text color="sharkGrey" size="m">
                    No matched question found!
                </Text>
            )}
        </>
    );
};

export default FAQSections;
