import { motion } from "framer-motion";
import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";

import { useHistory } from "react-router-dom";
import { listOfArticles } from "../../../constants/blogData";
import { BLOG } from "../../../constants/routes";
import cn from "../../../lib/cn";
import {
    insight1,
    insight2,
    insight3,
    insight4,
} from "../../../resources/js/images";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    slideOpacityRight,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import SubHeading from "../../../shared/typography/SubHeading";
import InsightCard from "../../../shared/ui/Cards/InsightCard";
import PillSlider from "../../../shared/ui/Slider/PillSlider";
import SliderButton from "../../../shared/ui/Slider/SliderButton";
import Tab from "../../../shared/ui/tab/Tab";

/* @TODO => Unused component. Use once blogs are ready @habib610 Sun July 28,2024 */
const cardData = [
    {
        id: 0,
        category: "Sustainability",
        title: "Exploring the Solar Revolution: A Sustainable Energy Journey",
        date: "March 20, 2024",
        imageUrl: insight1,
    },
    {
        id: 1,
        category: "Technology",
        title: "Harnessing Solar Power for a Brighter Future",
        date: "April 10, 2024",
        imageUrl: insight2,
    },
    {
        id: 2,
        category: "Trending",
        title: "Empowering Sustainability with Solar Energy Innovation",
        date: "March 20, 2024",
        imageUrl: insight3,
    },
    {
        id: 3,
        category: "Government Subsidy",
        title: "Stories from the Solar Frontier: Inspiring Renewable Energy Tales",
        date: "April 10, 2024",
        imageUrl: insight4,
    },
    {
        id: 4,
        category: "Solar Energy",
        title: "Exploring the Solar Revolution: A Sustainable Energy Journey",
        date: "March 20, 2024",
        imageUrl: insight2,
    },
    {
        id: 5,
        category: "Co2 Reduced",
        title: "Stories from the Solar Frontier: Inspiring Renewable Energy Tales",
        date: "April 10, 2024",
        imageUrl: insight3,
    },
];

const tags = [
    "Trending",
    "Sustainability",
    "Technology",
    "Government Subsidy",
    "Solar Energy",
    "Co2 Reduced",
];
const InsightCarouselSection = ({ pill = false }) => {
    const history = useHistory();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentCategory, setCurrentCategory] = useState("");
    const previousSlide = () => {
        setCurrentSlide((prev) =>
            prev === 0 ? listOfArticles.length - 1 : prev - 1
        );
    };

    const handleTabClick = (tag) => {
        setCurrentCategory(tag);
    };

    const nextSlide = () => {
        setCurrentSlide((prev) =>
            prev === listOfArticles.length - 1 ? 0 : prev + 1
        );
    };
    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    const filteredCards = currentCategory
        ? listOfArticles.filter((card) => card.category === currentCategory)
        : listOfArticles;

    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="mb-2.4 overflow-x-hidden"
                >
                    <div className="w-full flex flex-col gap-0.8">
                        <SplitText heading="h2" variants={wavyHeadings}>
                            Fuel Your Hub with Srot Insights
                        </SplitText>
                    </div>
                    <motion.div variants={slideOpacityRight}>
                        <SubHeading className="text-gray-secondary">
                            In publishing and graphic design, Lorem ipsum is a
                            placeholder text commonly
                        </SubHeading>
                    </motion.div>
                </motion.div>

                <div className="flex items-start sm:flex-col xl:flex-row lg:gap-1 flex-wrap">
                    <div className="flex flex-nowrap overflow-x-auto w-full xl:w-[75%] gap-2.4 mb-2.4 no-scrollbar">
                        {tags.map((tag, i) => (
                            <Tab
                                key={i}
                                text={tag}
                                active={tag === currentCategory}
                                onClick={() => handleTabClick(tag)}
                            />
                        ))}
                    </div>

                    <a
                        href="#"
                        className={cn(
                            "text-gray-secondary text-sm opacity-0 underline",
                            "xl:opacity-100 lg:text-base ml-1 xl:mt-1"
                        )}
                    >
                        See all articles
                    </a>
                </div>

                {document.body.clientWidth < 1200 ||
                filteredCards.length > 3 ? (
                    <div>
                        <CarouselComponent
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            stopOnHover={true}
                            transitionTime={500}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                            centerMode={true}
                            centerSlidePercentage={
                                document.body.clientWidth < 1279
                                    ? document.body.clientWidth < 1000
                                        ? document.body.clientWidth < 699
                                            ? 100
                                            : document.body.clientWidth < 767
                                            ? 80
                                            : 70
                                        : 55
                                    : document.body.clientWidth > 1535
                                    ? 33
                                    : 40
                            }
                        >
                            {filteredCards.map((card, index) => (
                                <InsightCard
                                    key={index}
                                    category={card.category}
                                    title={card.meta.metaTitle}
                                    date={card.published}
                                    imageUrl={card.meta?.thumbnail}
                                    onActionClick={() =>
                                        history.push(
                                            `${BLOG}/${card?.meta?.blogUrl}`
                                        )
                                    }
                                />
                            ))}
                        </CarouselComponent>
                    </div>
                ) : (
                    <div className="flex gap-3 justify-between flex-wrap xl:flex-nowrap items-center mt-3">
                        {filteredCards.map((card, index) => (
                            <InsightCard
                                key={index}
                                category={card.category}
                                title={card.meta.metaTitle}
                                date={card.published}
                                imageUrl={card.meta.thumbnail}
                                onActionClick={() =>
                                    history.push(
                                        `${BLOG}/${card?.meta?.blogUrl}`
                                    )
                                }
                            />
                        ))}
                    </div>
                )}
                {pill ? (
                    <div className="flex justify-center  ">
                        <PillSlider
                            currentSlide={currentSlide}
                            className={"my-3"}
                            data={listOfArticles}
                            handleSlideChange={handleSlideChange}
                        />
                    </div>
                ) : (
                    (document.body.clientWidth < 1200 ||
                        filteredCards.length > 3) && (
                        <SliderButton
                            currentSlide={currentSlide}
                            onPrev={previousSlide}
                            onNext={nextSlide}
                            data={listOfArticles}
                            className={"justify-start flex"}
                        />
                    )
                )}
            </Container>
        </Section>
    );
};

export default InsightCarouselSection;
