import React from "react";
import Section from "../../shared/Section";
import LeftSide from "./LeftSide";
import Rewards from "./mobile";
import RightSide from "./RightSide";

const RewardsDashBoard = () => {
    return (
        <Section>
            <div className="px-4 hidden lg:block font-vietnam">
                <div className=" mt-5 pt-5 flex justify-between items-start">
                    <div className="w-[65%]">
                        <LeftSide />
                    </div>
                    <div className="w-[35%]">
                        <div className="sticky top-5 lg:top-5">
                            <RightSide />
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <Rewards />
            </div>
        </Section>
    );
};

export default RewardsDashBoard;
