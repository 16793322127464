import React, { useState } from "react";
import {
    funding,
    locationMarker,
    mapMarker,
} from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { INVEST } from "../../../../constants/routes";
import { getCurrencyAmount } from "../../../../helpers/helpers";
import cn from "../../../../lib/cn";
import H4 from "../../../../shared/typography/H4";
import Button from "../../../../shared/ui/Button";
import Line from "../../../../shared/ui/Line";
import BorderedTab from "../../../../shared/ui/tab/BorderedTab";
import Gallery from "./Gallery";
import Overview from "./Overview";

const options = ["Milestones", "Gallery"];
const getOverView = (listing) => {
    return [
        {
            name: "Min. Investment",
            value: `₹${listing?.listingMinLimit}`,
        },
        {
            name: "Tenure",
            value: `${listing.investmentTerms[0]?.installments / 12} years`,
        },
        {
            name: "IRR",
            value: `${listing?.investmentTerms[0].targetedIRRPercentage}%`,
        },
    ];
};

const progress = [
    {
        id: 1,
        name: "Funding",
    },
    {
        id: 2,
        name: "Lorem",
    },
    {
        id: 3,
        name: "Lorem",
    },
    {
        id: 4,
        name: "Completed",
    },
];
const Summary = ({ isScrollDisabled, listing }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const history = useHistory();
    let plants = listing?.plants[0];
    return (
        <div>
            <div className="lg:flex lg:justify-between lg:items-start lg:gap-3">
                <div className="lg:w-[50%]">
                    <P className="text-gray mb-0.8  text-base md:text-sm xl:text-sm font-vietnam">
                        Description
                    </P>
                    <P className="text-gray-550 mb-2.4 font-vietnam md:text-sm xl:text-sm">
                        Lorem ipsum dolor sit amet consectetur. Habitasse morbi
                        massa porttitor nisi egestas ullamcorper et diam. Massa
                        dolor non ornare sed{" "}
                    </P>
                </div>

                <div className="w-full lg:w-[50%]">
                    <Overview data={getOverView(listing)} />

                    <div className="flex justify-between w-full bg-gray-50 rounded-sm p-1.6 my-1.6">
                        <div>
                            <H4 className="font-vietnam md:text-base xl:text-base">
                                {plants?.name}
                            </H4>
                            <div className="text-base flex  items-center  gap-0.2 ">
                                <div className="h-2.4 mr-0.8">
                                    <img src={mapMarker} alt="location" />
                                </div>
                                <P className=" font-vietnam inline-block md:text-sm xl:text-sm ">
                                    {`${plants?.city}, ${plants?.state}`}
                                </P>
                            </div>
                        </div>
                        <div>
                            <H4 className="md:text-base xl:text-base font-medium">
                                {plants?.capacity / 1000} KWp
                            </H4>
                            <P className="text-base md:text-sm xl:text-sm">
                                Plant Size
                            </P>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden lg:flex flex-wrap xl:flex-nowrap justify-between gap-3 mt-2">
                <div className=" w-full xl:w-[50%]">
                    <div className="p-1.6 border border-gray-400 rounded-2">
                        <P className="text-gray mb-0.8  text-base md:text-sm xl:text-sm">
                            MileStones
                        </P>
                        <div className="relative pt-4 ">
                            <div className="w-full h-[5px] rounded-full bg-linear-button relative mb-5">
                                <div className="absolute inset-x-0 inset-y-0 flex items-center justify-between rounded-full">
                                    {progress.map((item, i) => (
                                        <div
                                            key={item.id}
                                            className={cn("h-1 w-1  ", {
                                                "bg-white border-2 rounded-full border-primary":
                                                    i < 3,
                                            })}
                                        >
                                            <div className="relative">
                                                {i === 3 && (
                                                    <div className="absolute bottom-0 right-0">
                                                        <div className="h-4 w-2  flex items-end ">
                                                            <img
                                                                src={
                                                                    locationMarker
                                                                }
                                                                alt="locationMarker"
                                                                className="w-full inline-block object-cover"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <P
                                                    className={cn(
                                                        "absolute -bottom-3 text-xs md:text-xs xl:text-xs lg:text-gray-550",
                                                        {
                                                            "left-0": i < 1,
                                                            "-left-1.5":
                                                                i > 0 &&
                                                                i <
                                                                    progress.length -
                                                                        1,

                                                            "right-0":
                                                                i ===
                                                                progress.length -
                                                                    1,
                                                        }
                                                    )}
                                                >
                                                    {item.name}
                                                </P>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <Line />
                        <div className="grid grid-cols-2 w-full">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                    Project Started
                                </P>
                                <P className="text-sm text-gray md:text-sm xl:text-sm">
                                    {moment(listing.startDate * 1000).format(
                                        "Do MMMM YYYY"
                                    )}
                                </P>
                            </div>
                            <div className="text-right">
                                <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                    Project Completed
                                </P>
                                <P className="text-sm text-gray md:text-sm xl:text-sm">
                                    {moment(listing.endDate * 1000).format(
                                        "Do MMMM YYYY"
                                    )}
                                </P>
                            </div>
                        </div>

                        <div className="flex rounded-full mt-1.6 p-1 items-center justify-center gap-1 bg-orange-200">
                            <img className="h-3" src={funding} alt="funding" />
                            <P className="text-orange">
                                Funding plans are completed
                                {/* @TODO => fix this @habib610 Sat November 30,2024 */}
                            </P>
                        </div>
                        <div className="h-px bg-gray-400 my-1.6"></div>
                        <div className="grid grid-cols-2 w-full">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                    Capital raised
                                </P>
                                <P className="text-sm text-gray md:text-sm xl:text-sm">
                                    ₹ {getCurrencyAmount(listing.fundRaised)}
                                </P>
                            </div>
                            <div className="text-right">
                                <P className="text-xs text-gray-550 mb-1.6 md:text-xs xl:text-xs">
                                    Total investors
                                </P>
                                <P className="text-sm text-gray md:text-sm xl:text-sm">
                                    {" "}
                                    {listing?.investmentTerms[0]?.investorCount}
                                </P>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:w-[50%]">
                    <P className="text-gray mb-0.8  text-base md:text-sm xl:text-sm">
                        Gallery
                    </P>
                    <P className="text-gray-550 mb-2.4 text-justify md:text-sm xl:text-sm">
                        Lorem ipsum dolor sit amet consectetur. Habitasse morbi
                        massa porttitor nisi egestas ullamcorper et diam. Massa
                        dolor non ornare sed{" "}
                    </P>
                    <Gallery images={listing?.images} />
                </div>
            </div>

            <div className="p-1.6 border lg:hidden border-gray-400 rounded-2">
                <BorderedTab
                    className="mb-4"
                    onTabClick={(i) => setCurrentTab(i)}
                    options={options}
                    currentTab={currentTab}
                    tabClass="w-1/2"
                />
                {currentTab === 0 ? (
                    <div>
                        <div className="relative">
                            <div className="w-full h-[5px] rounded-full bg-linear-button relative mb-5">
                                <div className="absolute inset-x-0 inset-y-0 flex items-center justify-between rounded-full">
                                    {progress.map((item, i) => (
                                        <div
                                            key={item.id}
                                            className={cn("h-1 w-1  ", {
                                                "bg-white border-2 rounded-full border-primary":
                                                    i < 3,
                                            })}
                                        >
                                            <div className="relative">
                                                {i === 3 && (
                                                    <div className="absolute bottom-0 right-0">
                                                        <div className="h-4 w-2  flex items-end ">
                                                            <img
                                                                src={
                                                                    locationMarker
                                                                }
                                                                alt="locationMarker"
                                                                className="w-full inline-block object-cover"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <P
                                                    className={cn(
                                                        "absolute -bottom-3 text-xs",
                                                        {
                                                            "left-0": i < 1,
                                                            "-left-1.5":
                                                                i > 0 &&
                                                                i <
                                                                    progress.length -
                                                                        1,

                                                            "right-0":
                                                                i ===
                                                                progress.length -
                                                                    1,
                                                        }
                                                    )}
                                                >
                                                    {item.name}
                                                </P>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <Line />
                        <div className="grid grid-cols-2 w-full">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Project Started
                                </P>
                                <P className="text-sm text-gray">
                                    {moment(listing.startDate * 1000).format(
                                        "Do MMMM YYYY"
                                    )}
                                </P>
                            </div>
                            <div className="text-right">
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Project Completed
                                </P>
                                <P className="text-sm text-gray">
                                    {moment(listing.endDate * 1000).format(
                                        "Do MMMM YYYY"
                                    )}
                                </P>
                            </div>
                        </div>

                        <div className="flex rounded-full mt-1.6 p-1 items-center justify-center gap-1 bg-orange-200">
                            <img className="h-3" src={funding} alt="funding" />
                            <P className="text-orange">
                                Funding plans are completed
                                {/* @TODO => fix this @habib610 Sat November 30,2024 */}
                            </P>
                        </div>
                        <div className="h-px bg-gray-400 my-1.6"></div>
                        <div className="grid grid-cols-2 w-full">
                            <div>
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Capital raised
                                </P>
                                <P className="text-sm text-gray">
                                    ₹ {getCurrencyAmount(listing.fundRaised)}
                                </P>
                            </div>
                            <div className="text-right">
                                <P className="text-xs text-gray-550 mb-1.6">
                                    Total investors
                                </P>
                                <P className="text-sm text-gray">
                                    {" "}
                                    {listing?.investmentTerms[0]?.investorCount}
                                </P>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Gallery images={listing?.images} />
                )}

                <P>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Asperiores cupiditate dolor earum rerum magni? Quasi qui
                    sapiente voluptate maiores magni?
                </P>
            </div>

            <Button
                onClick={() => history.push(`${INVEST}/${listing?.listingId}`)}
                className="bg-gray w-full rounded-full mt-2.4  "
            >
                Invest Now
            </Button>
        </div>
    );
};

export default Summary;
