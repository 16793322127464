import React from "react";
import ContactUSForm from "./ContactUSForm";
import CustomerReviews from "./CustomerReviews";
import EstimateSavings from "./EstimateSavings";
import Faq from "./Faq";
import InvestNow from "./InvestNow";
import CustomerFeedbackVideo from "./Sections/CustomerFeedbackVideo";
import HeroContent from "./Sections/HeroContent";
import InsightCarouselSection from "./Sections/InsightCarouselSection";
import PriceSection from "./Sections/PriceSection";
import SolarProjects from "./SolarProjects";
import SrotBenefit from "./SrotBenefit";

const Home = () => {
    return (
        <>
            <HeroContent />
            <SrotBenefit />
            <InvestNow />
            <EstimateSavings />
            <PriceSection />
            <SolarProjects />
            <CustomerFeedbackVideo />
            <CustomerReviews />
            <ContactUSForm />
            <InsightCarouselSection />
            <Faq />
        </>
    );
};

export default Home;
