import React, { useState } from "react";
import usePayouts from "../../../../../hooks/usePayouts";
import { received } from "../../../../../resources/js/icons";
import Container from "../../../../../shared/Container";
import P from "../../../../../shared/typography/P";
import LinedTab from "../../../../../shared/ui/tab/LinedTab";

const options = ["Completed Payouts", "Upcoming Payouts"];

const PayoutsInvestment = ({ isScrollDisabled }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const { completedPayouts, upcomingPayouts } = usePayouts(
        1720788751,
        60,
        50
    );

    return (
        <>
            <Container className=" py-4 lg:px-5 font-vietnam ">
                <LinedTab
                    className="mb-4  "
                    data={options}
                    activeTab={currentTab}
                    onClick={setCurrentTab}
                />

                <div
                    className={`flex flex-col space-y-4 max-h-screen xl:max-h-[650px]  py-1 pb-[350px] xl:pb-4 ${
                        isScrollDisabled ? "" : "overflow-y-auto"
                    }`}
                >
                    {(currentTab === 0
                        ? completedPayouts
                        : upcomingPayouts
                    ).map((item) => (
                        <div
                            key={item.no}
                            className="mt-1.6 pb-1.6 border-b border-gray-400"
                        >
                            <div className="flex justify-between mb-1.6">
                                <P className="text-gray font-vietnam">
                                    {item.date}
                                </P>
                                <P className="text-gray flex items-center gap-0.8 font-vietnam">
                                    <img src={received} alt="received" />
                                    <span className="font-medium">
                                        ₹ {item.amount}
                                    </span>
                                </P>
                            </div>
                            <P className="text-xs text-gray-550 font-vietnam">
                                {item.no}
                            </P>
                        </div>
                    ))}
                </div>
            </Container>
        </>
    );
};

export default PayoutsInvestment;
