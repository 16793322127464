import React from "react";
import { info, streamLine } from "../../../../resources/js/icons";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";

import StreamlineTree from "../../../portfoilo/mobile/StreamlineTree";
import LearnMore from "../LearnMore";
import NewOpportunities from "../NewOpportunities";
import OfferCarousel from "../OfferCarousel";
import FriendEarningsCard from "./FriendEarningsCard";
import InvestmentCalculator from "./InvestmentCalculator";
const NewUser = ({ profileData }) => {
    return (
        <div className="bg-white ">
            <div className="bg-black-600 rounded-b-[3rem] pt-5 ">
                <div className="flex gap-0.6 mt-1 items-center mx-3 mb-0.5">
                    <P className="text-gray-550  text-base">Good Morning,</P>
                    <H4 className="text-white text-lg">{profileData?.name}</H4>
                </div>
                <OfferCarousel />
                <div className=" pb-1 flex  gap-1 items-center px-2">
                    <div className="flex flex-col items-center gap-1.6  bg-black-400 p-1.6 rounded-t-2">
                        <H4 className="text-gray-500 text-nowrap">
                            Avg Monthly Users
                        </H4>
                        <H4 className="text-white font-semibold">10k+</H4>
                    </div>
                    <div className="flex-1 flex text-center flex-col items-center gap-1.6  bg-black-400 p-1.6 rounded-t-2">
                        <H4 className="text-gray-500">Projects</H4>
                        <H4 className="text-white font-semibold">150+</H4>
                    </div>
                </div>
                <div className="px-2 relative top-3">
                    <StreamlineTree
                        className="bg-linear-blue-white"
                        headingClass="text-white"
                        subText="text-white"
                        descriptionClass="font-semibold"
                    />
                </div>
            </div>

            <InvestmentCalculator />

            <FriendEarningsCard />

            <NewOpportunities />
            <LearnMore />
            <div className="flex justify-between gap-2 items-center p-2 mb-2">
                <div className="flex-1 flex text-center flex-col items-center gap-1.6  bg-gray-50 p-1.6 rounded-t-2">
                    <div className="h-4">
                        <img src={info} alt="stream_line" />
                    </div>
                    <P className=" font-normal">
                        Complete KYC to win rewards!!!
                    </P>
                </div>
                <div className="flex-1 flex text-center flex-col items-center gap-1.6  bg-gray-50 p-1.6 rounded-t-2">
                    <div className="h-4">
                        <img src={streamLine} alt="stream_line" />
                    </div>
                    <P className=" font-normal">
                        Re-calculate your carbon footprint.
                    </P>
                </div>
            </div>
        </div>
    );
};

export default NewUser;
