import React from "react";
import Select from "react-select";
import styled from "styled-components";
import Theme from "../../theme/index";
import Text from "../text/Text";

const Wrapper = styled.div`
    .css-1orcabs-control {
        font-family: ${(props) =>
            props.fontFamily || "'Montserrat', sans-serif"};
    }
`;
const Dropdown = ({ label, options, onChange, placeholder }) => {
    return (
        <Wrapper>
            <Text size="s" style={{ marginRight: "10px" }} color="white">
                {label}
            </Text>
            <Select
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 8,
                    colors: {
                        ...theme.colors,
                        primary: Theme.color.iconBgGrey,
                        primary25: Theme.color.iconBgGrey,
                    },
                })}
            />
        </Wrapper>
    );
};

export default Dropdown;
