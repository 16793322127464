import React from "react";
import styled from "styled-components";
import Flex from "../flex/Flex";
import Text from "../text/Text";

const InputComponent = styled.input`
    width: 12px;
    min-width: 12px;
    height: 12px;
    background: transparent;
    cursor: pointer;
    margin: 0;
    &:hover {
        background-color: #000;
    }
`;

const Container = styled(Flex.Container)`
    flex-wrap: nowrap;
`;

const Radio = (props) => {
    return (
        <Container alignItems="center">
            <InputComponent type="radio" {...props} />
            <Text
                size={props.size}
                weight={props.weight}
                color={props.color}
                style={{ paddingLeft: "5px" }}
            >
                {props.label}
            </Text>
        </Container>
    );
};

export default Radio;
