import React, { useState } from "react";

import H4 from "../../../shared/typography/H4";
import CardComponent from "./Card";
import ContactDetails from "./ContactDetails";
import Faqs from "./Faqs";
import Tickets from "./tickets";
const MobileCustomerSupport = () => {
    const handleButtonClick = () => {
        setTicket(!ticket);
    };
    const [ticket, setTicket] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handlePopupOpen = () => setIsPopupOpen(true);
    const handlePopupClose = () => setIsPopupOpen(false);
    return (
        <div className="bg-black-600 md:bg-white pt-6 font-vietnam md:flex md:flex-col md:justify-center md:items-center">
            <div className="p-2 rounded-t-2 md:w-1/2  bg-white border border-gray-400 md:border-none ">
                {!ticket ? (
                    <>
                        <H4 className="font-medium text-base text-start">
                            Customer Support
                        </H4>
                        <div className="flex justify-between gap-1 items-stretch pt-3">
                            <CardComponent
                                title="Support tickets"
                                description="Easily raise and manage support tickets for any project issues."
                                buttonText="View Tickets"
                                onButtonClick={handleButtonClick}
                            />
                            <CardComponent
                                title="Contact Support"
                                description="Chat with us or give us a call and we will sort out all your difficulties! "
                                buttonText="Call us"
                                onButtonClick={handlePopupOpen}
                            />
                        </div>
                        <div className="my-2 h-[250px] overflow-y-scroll md:h-auto border border-gray-400 rounded-lg">
                            <Faqs />
                        </div>
                    </>
                ) : (
                    <Tickets />
                )}
            </div>
            {isPopupOpen && <ContactDetails onClose={handlePopupClose} />}
        </div>
    );
};

export default MobileCustomerSupport;
