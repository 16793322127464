import React, { useState } from "react";

import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { useHistory, useRouteMatch } from "react-router-dom";
import { login1, login2, login3 } from "../../resources/js/images";
import Container from "../../shared/Container";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
import PillSlider from "../../shared/ui/Slider/PillSlider";

const imgData = [
    {
        id: 1,
        img: login1,
    },
    {
        id: 1,
        img: login2,
    },
    {
        id: 1,
        img: login3,
    },
    {
        id: 1,
        img: login2,
    },
];
const AuthWrapper = ({ children }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { path, url } = useRouteMatch();
    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    const [signUp, setSignUp] = useState(false);
    const [login, setLogin] = useState(false);

    const history = useHistory();
    const handleLogIn = () => {
        setSignUp(false);
        setLogin((prev) => !prev);
    };
    const handleSignUp = () => {
        setLogin(false);
        setSignUp((prev) => !prev);
    };

    const handleOnboarding = () => {
        setLogin(false);
        setSignUp(false);
    };
    return (
        <div className="relative min-h-screen lg:py-4">
            <div className=" hidden md:block absolute top-0 left-0 w-1/2 bg-primary  bottom-0 -z-10"></div>
            <div className=" hidden md:block absolute top-0 right-0 w-1/2 bg-gray-400 bottom-0 -z-10"></div>

            <div>
                <Container className="flex justify-between  h-full md:gap-2 lg:gap-5">
                    <div className="hidden w-1/2  gap-3 md:flex flex-col justify-center items-center ">
                        <div className="pb-3 w-full xl:w-3/3 mx-3 xl:mb-2">
                            <div className="mb-2 ">
                                <H2 className="text-white !font-bold">
                                    Welcome!
                                </H2>
                                <H2 className="text-white !font-bold">
                                    Please Sign in to your{" "}
                                    <span className="underline">Srot</span>{" "}
                                    account
                                </H2>
                            </div>
                            <P className="text-white ">
                                Lorem ipsum dolor sit amet consectetur. Neque
                                felis consectetur aenean mauris. Arcu senectus
                                gravida ac fermentum ut.
                            </P>
                        </div>
                        <div className="w-full md:w-[55%] 2xl:w-1/2">
                            <CarouselComponent
                                selectedItem={currentSlide}
                                onChange={setCurrentSlide}
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                autoPlay={true}
                                infiniteLoop={true}
                                interval={3000}
                                stopOnHover={true}
                                transitionTime={500}
                                swipeable={true}
                                preventMovementUntilSwipeScrollTolerance={true}
                                swipeScrollTolerance={50}
                                emulateTouch={true}
                                centerMode={true}
                                centerSlidePercentage={100}
                            >
                                {imgData.map((image, index) => (
                                    <img
                                        src={image.img}
                                        key={index}
                                        className="px-2 h-[70%] "
                                        alt="slider_images"
                                    />
                                ))}
                            </CarouselComponent>
                            <PillSlider
                                className={"mt-0"}
                                currentSlide={currentSlide}
                                data={imgData}
                                handleSlideChange={handleSlideChange}
                            />
                        </div>
                    </div>
                    <div className="flex-1 md:pt-3 lg:pt-3  xl:pl-5 2xl:pl-8 ">
                        {children}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default AuthWrapper;
