import React from "react";

const VideoCards = ({ url }) => {
    return (
        <div className=" rounded-lg">
            <iframe
                title={url}
                className="w-full aspect-video rounded-2"
                src={url}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default VideoCards;
