import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import { kycBank, kycCard } from "../../resources/js/icons";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import Stepper from "./Stepper";

const MainPage = ({
    handleProceed,
    type,
    handleType,
    Pan,
    Aadhar,
    Bank,
    handeleStart,
}) => {
    const [pan, setPan] = useState(Pan);
    const [aadhar, setAadhar] = useState(Aadhar);
    const [bank, setBank] = useState(Bank);
    useEffect(() => {
        setAadhar(Aadhar);
        setPan(Pan);
        setBank(Bank);
    }, [Pan, Aadhar, Bank]);
    const history = useHistory();
    const handleNavigation = (type) => {
        handleType(type);
        history.push(`/kyc/summary/${type}`);
    };
    return (
        <div className=" md:flex md:flex-col md:justify-center md:items-center">
            <div className="flex flex-col text-center justify-center items-center md:mt-5  md:mb-2">
                <Stepper
                    type={type}
                    handeleStart={handeleStart}
                    handleType={handleType}
                    Pan={pan}
                    Aadhar={aadhar}
                />
                <H4 className="my-2 font-semibold">KYC</H4>
                <P>
                    Just a quick heads up: KYC verification usually takes around
                    10 minutes. Make sure you have your documents handy."
                </P>
            </div>
            <div className="flex flex-col  gap-2  mt-3 w-full ">
                <div
                    className="flex justify-between  items-center border border-gray-400 rounded-xl"
                    role="button"
                    onClick={() => handleNavigation("pan")}
                >
                    <div className="py-1 px-1   my-1  flex   gap-2 items-center ">
                        <img src={kycCard} />
                        <div className="flex flex-col justify-start ">
                            <P className="font-semibold">Pan card</P>
                            <span className="text-gray-500 text-xs">
                                Provide your PAN card details
                            </span>
                        </div>
                    </div>
                    {pan?.status === "FAILED" ? (
                        <P className="bg-red-200 text-white rounded-full text-sm md:text-sm xl:text-sm px-0.4 py-0.4 mr-1">
                            <RxCross1 />
                        </P>
                    ) : pan?.status === "VERIFIED" ? (
                        <P className="bg-green-400 text-white text-sm md:text-sm xl:text-sm rounded-full px-0.4 py-0.4 mr-1">
                            <FaCheck />
                        </P>
                    ) : (
                        <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 "></div>
                    )}
                </div>
                <div
                    className="flex justify-between  items-center border border-gray-400  rounded-xl"
                    role="button"
                    onClick={() =>
                        Pan?.status === "VERIFIED" && handleNavigation("aadhar")
                    }
                >
                    <div className="py-1 px-1   my-1  flex   gap-2 items-center ">
                        <img src={kycCard} />
                        <div className="flex flex-col justify-start  ">
                            <P className="font-semibold">Aadhar card</P>
                            <span className="text-gray-500 text-xs">
                                Provide your aadhar card details
                            </span>
                        </div>
                    </div>
                    {aadhar?.status === "FAILED" ? (
                        <P className="bg-red-200 text-white rounded-full text-sm md:text-sm xl:text-sm px-0.4 py-0.4 mr-1">
                            <RxCross1 />
                        </P>
                    ) : aadhar?.status === "VERIFIED" ? (
                        <P className="bg-green-400 text-white text-sm md:text-sm xl:text-sm rounded-full px-0.4 py-0.4 mr-1">
                            <FaCheck />
                        </P>
                    ) : (
                        <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 "></div>
                    )}
                </div>
                <div
                    className="flex justify-between items-center   border border-gray-400  rounded-xl"
                    role="button"
                    onClick={() =>
                        Aadhar?.status === "VERIFIED" &&
                        handleNavigation("bank")
                    }
                >
                    <div className="py-1 px-1 my-1  flex gap-2 items-center ">
                        <img src={kycBank} />
                        <div className="flex flex-col justify-start  ">
                            <P className="font-semibold">Bank Details</P>
                            <span className="text-gray-500 text-xs">
                                Provide your bank statement & information{" "}
                            </span>
                        </div>
                    </div>
                    {bank?.kycStatus === "FAILED" ? (
                        <P className="bg-red-200 text-white rounded-full text-sm md:text-sm xl:text-sm px-0.4 py-0.4 mr-1 ">
                            <RxCross1 />
                        </P>
                    ) : bank?.kycStatus === "VERIFIED" ? (
                        <P className="bg-green-400 text-white text-sm md:text-sm xl:text-sm rounded-full px-0.4 py-0.4 mr-1 ">
                            <FaCheck />
                        </P>
                    ) : (
                        <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 "></div>
                    )}
                </div>
            </div>
            <Button
                className="w-full mt-2 mb-[8rem] bg-black text-white !rounded-[3rem]"
                onClick={() => handleProceed("pan")}
            >
                Procced
            </Button>
        </div>
    );
};

export default MainPage;
