// @flow
import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import styled from "styled-components";
import { investNow } from "../../resources/js/icons";
import { mobile1, mobile2, mobile3 } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import PillSlider from "../../shared/ui/Slider/PillSlider";
import VerticalPillSlider from "../../shared/ui/Slider/VerticalPillSlider";
const SectionWipes2Styled = styled.div`
    overflow: hidden;

    #pinContainer {
        height: 70vh;
        width: 80vw;
        overflow: hidden;
    }

    #pinContainer .panel {
        position: absolute;
    }
`;
const Card = ({ image }) => {
    return (
        <section className="panel  bg-white ">
            <div className="flex flex-col lg:flex-row items-center gap-5  justify-center lg:justify-between ">
                <img
                    src={image}
                    alt="mobileApp-img"
                    className=" h-[37rem] sm:h-[54rem]  lg:h-[56rem] "
                />
                <div className=" text-start mx-3 ">
                    <div className="flex items-start w-10 mb-0">
                        <img
                            src={investNow}
                            alt="mobileApp-img"
                            className="h-3 md:h-7 "
                        />
                    </div>
                    <H3 className={"mb-0.5 md:mb-2 font-light"}>
                        Select a pre-vetted and risk assessed project
                    </H3>
                    <P className={"mb-1 md:mb-2"}>
                        To get started, simply follow these steps: first,
                        complete your profile by submitting your KYC details to
                        verify your account. Next, load funds into your wallet
                        to ensure you're ready to invest. Once your wallet is
                        funded, explore and invest in available opportunities to
                        begin growing your portfolio.
                    </P>
                    <Button className=" xl:text-sm 2xl:text-xxl">
                        Invest now
                    </Button>
                </div>
            </div>
        </section>
    );
};
const CardData = [
    { id: 1, image: mobile1 },
    { id: 2, image: mobile2 },
    { id: 3, image: mobile3 },
];
const InvestNow = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    return (
        <Section>
            <Container className="flex flex-col-reverse md:flex-row gap-1 pt-3">
                <CarouselComponent
                    selectedItem={currentSlide}
                    onChange={setCurrentSlide}
                    axis={"vertical"}
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    stopOnHover={true}
                    transitionTime={500}
                    swipeable={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    emulateTouch={true}
                >
                    {CardData.map((card, index) => (
                        <Card image={card.image} key={card.id} />
                    ))}
                </CarouselComponent>
                <div className="hidden md:flex">
                    <VerticalPillSlider
                        currentSlide={currentSlide}
                        className={"mx-3 justify-center items-center"}
                        data={CardData}
                        handleSlideChange={handleSlideChange}
                    />
                </div>
                <div className="md:hidden ">
                    <PillSlider
                        currentSlide={currentSlide}
                        className={"mx-3 justify-center items-center"}
                        width="w-9"
                        data={CardData}
                        handleSlideChange={handleSlideChange}
                    />
                </div>
            </Container>
        </Section>
    );
};

export default InvestNow;
