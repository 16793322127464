import styled from "styled-components";

const ImageComponent = styled.img`
    width: ${(props) => props.width || ""};
    max-width: ${(props) => props.maxWidth || "100%"};
    height: ${(props) => props.height || ""};
    border-radius: ${(props) => props.borderRadius || ""};
    max-height: ${(props) => props.maxHeight};
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        height: ${(props) => props.smallLaptopHeight || ""};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.bigTablet}) {
        height: ${(props) => props.bigTabletHeight || ""};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: ${(props) => props.tabletHeight || ""};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        height: ${(props) => props.mobileHeight || ""};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        height: ${(props) => props.smallMobileHeight || ""};
    }
`;

const Image = (props) => {
    return <ImageComponent loading="lazy" {...props} />;
};

export default Image;
