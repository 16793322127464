import moment from "moment";
import React, { useState } from "react";
import cn from "../../../../lib/cn";
import { locationMarker } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";

let payoutsData = [
    {
        id: 1,
        name: "Carbon impact",
        value: "243 tons",
    },
    {
        id: 2,
        name: "Tenure",
        value: "5 years",
    },
    {
        id: 3,
        name: "IRR",
        value: "20.88%",
    },
];

const getPayoutData = (data) => {
    return [
        {
            id: 1,
            name: "Carbon impact",
            value: "243 tons" /* @TODO => fix carbonImpact @habib610 Mon December 09,2024 */,
        },
        {
            id: 2,
            name: "Tenure",
            value: `${data?.installments / 12} Years`,
        },
        {
            id: 3,
            name: "IRR",
            value: `${data?.targetedIRRPercentage}%`,
        },
    ];
};

const progress = [
    {
        id: 1,
        name: "Funding",
    },
    {
        id: 2,
        name: "Lorem",
    },
    {
        id: 3,
        name: "Lorem",
    },
    {
        id: 4,
        name: "Completed",
    },
];
const ProjectDetails = (props) => {
    const listing = props?.listing;
    const investmentTerms = listing.investmentTerms[0];

    const [activeTab, setActiveTab] = useState(0);
    return (
        <div>
            <P className="text-gray mb-1.6">Description</P>
            <P>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Necessitatibus, obcaecati praesentium perspiciatis molestias
                consequuntur eaque maiores enim repellendus debitis aspernatur.
            </P>

            <div className="grid grid-cols-3 gap-x-3.2 gap-y-2.4 mb-4 bg-white mt-2.4 ">
                {getPayoutData(investmentTerms).map((payout) => (
                    <div key={payout.id}>
                        <P className="text-xs text-gray-550 mb-1.6 font-vietnam">
                            {payout.name}
                        </P>
                        <P className="text-sm text-gray font-vietnam">
                            {payout.value}
                        </P>
                    </div>
                ))}
            </div>

            <div className="p-2.4 rounded-2 border border-gray-400">
                <div className="flex mb-4 gap-2 border-b border-b-blue-50">
                    {["Milestone", "Funding"].map((tab, i) => (
                        <div
                            key={tab}
                            onClick={() => setActiveTab(i)}
                            className={`flex items-center border-b-2 gap-1 pb-1 font-vietnam ${
                                activeTab === i
                                    ? "text-blue-500  border-b-blue-500"
                                    : "text-gray-500 border-b-transparent"
                            }`}
                        >
                            <P
                                className={` ${
                                    activeTab === i
                                        ? "text-blue-500 "
                                        : "text-gray-500"
                                }`}
                            >
                                {tab}
                            </P>
                        </div>
                    ))}
                </div>
                {/* @DESC:: Progress @habib610 Sat October 05,2024 */}
                {/* @TODO => Refactor this @habib610 Tue October 08,2024 */}
                <div className="relative">
                    <div className="w-full h-[5px] rounded-full bg-linear-button relative mb-5">
                        <div className="absolute inset-x-0 inset-y-0 flex items-center justify-between rounded-full">
                            {progress.map((item, i) => (
                                <div
                                    key={item.id}
                                    className={cn("h-1 w-1  ", {
                                        "bg-white border-2 rounded-full border-primary":
                                            i < 3,
                                    })}
                                >
                                    <div className="relative">
                                        {i === 3 && (
                                            <div className="absolute bottom-0 right-0">
                                                <div className="h-4 w-2  flex items-end ">
                                                    <img
                                                        src={locationMarker}
                                                        alt="locationMarker"
                                                        className="w-full inline-block object-cover"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <P
                                            className={cn(
                                                "absolute -bottom-3 text-xs",
                                                {
                                                    "left-0": i < 1,
                                                    "-left-1.5":
                                                        i > 0 &&
                                                        i < progress.length - 1,

                                                    "right-0":
                                                        i ===
                                                        progress.length - 1,
                                                }
                                            )}
                                        >
                                            {item.name}
                                        </P>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full h-px bg-gray-400 mb-2.4"></div>
                <div className="grid grid-cols-2">
                    <div>
                        <P className="text-xs text-gray-550 mb-1.6 font-vietnam">
                            Project Started
                        </P>
                        <P className="text-sm text-gray font-vietnam">
                            {moment(listing?.activationDate * 1000).format(
                                "Do MMMM yyyy"
                            )}
                        </P>
                    </div>
                    <div>
                        <P className="text-xs text-gray-550 mb-1.6 font-vietnam">
                            Project Completed
                        </P>
                        <P className="text-sm text-gray font-vietnam">
                            {moment(listing?.endDate * 1000).format(
                                "Do MMMM yyyy"
                            )}
                        </P>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetails;
