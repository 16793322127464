import { toast } from "react-toastify";
import { ERROR_MSG } from "../constants/common";
import {
    GET_PAYMENT_METHOD_ENDPOINT,
    RAZORPAY_LOGO_ENDPOINT,
    RAZOR_SCRIPT_END_POINT,
    UPDATE_PAYMENT_ORDER_ENDPOINT,
} from "../constants/routes";
import theme from "../theme/index";
import request from "./request";

const RAZORPAY_DISPLAY_OPTIONS = {
    blocks: {
        banks: {
            name: "All payment methods",
            instruments: [
                {
                    method: "upi",
                },
                {
                    method: "card",
                    types: ["debit"],
                },
                {
                    method: "netbanking",
                },
            ],
        },
    },
    sequence: ["block.banks"],
    preferences: {
        show_default_blocks: false,
    },
};

export const updateDeposite = async (pay_id, ord_id, sign, callback) => {
    let data = {
        paymentId: pay_id,
        orderId: ord_id,
        signature: sign,
    };
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    try {
        await request.authPost({
            endpoint: UPDATE_PAYMENT_ORDER_ENDPOINT,
            body: data,
            headers,
        });
        callback && callback();
    } catch (error) {
        toast.error(typeof error === "string" ? error : ERROR_MSG);
    }
};

export const loadRazor = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = RAZOR_SCRIPT_END_POINT;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

/* @TODO => Remove if not required or refactor bottom method Sat June 15,2024 */

export async function displayRazor(ordId, user, callback) {
    const [res, paymentMethodOptions] = await Promise.all([
        loadRazor(),
        request.authGet({
            endpoint: GET_PAYMENT_METHOD_ENDPOINT,
        }),
    ]);

    if (res && paymentMethodOptions.status === "SUCCESS") {
        const displayOptions =
            paymentMethodOptions.data?.display || RAZORPAY_DISPLAY_OPTIONS;
        var options = {
            name: "SROT",
            description: "Loading Funds into Wallet",
            image: RAZORPAY_LOGO_ENDPOINT,
            prefill: {
                name: user?.name,
                email: user?.email,
                contact: user?.phoneNumber,
            },
            config: {
                display: displayOptions,
            },
            theme: {
                color: theme.color.darkMehndiGreen,
            },
            order_id: ordId,
        };

        options.theme.image_padding = false;

        options.handler = function (res) {
            updateDeposite(
                res.razorpay_payment_id,
                res.razorpay_order_id,
                res.razorpay_signature,
                callback
            );
        };

        options.modal = {
            ondismiss: function () {
                // This code runs when the popup is closed
            },

            escape: true,

            backdropclose: false,
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    }
    // TODO @prachi : Handle error
    return;
}

// /* @TODO => Redundant code either refactor required Sat June 15,2024 */
export async function createRazorPayOrder(ordId, user, callback) {
    const [res, paymentMethodOptions] = await Promise.all([
        loadRazor(),
        request.authGet({
            endpoint: GET_PAYMENT_METHOD_ENDPOINT,
        }),
    ]);

    if (res && paymentMethodOptions.status === "SUCCESS") {
        var options = {
            currency: "INR",
            name: "Srot App",
            order_id: ordId,
            image: RAZORPAY_LOGO_ENDPOINT,
            prefill: {
                name: user?.name,
                email: user?.email,
                contact: user?.phoneNumber,
            },

            theme: {
                color: "#3399cc",
            },
        };

        options.handler = function (res) {
            callback &&
                callback(
                    res.razorpay_payment_id,
                    res.razorpay_order_id,
                    res.razorpay_signature
                );
        };

        options.modal = {
            ondismiss: function () {
                // This code runs when the popup is closed
            },

            escape: true,

            backdropclose: false,
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    }
    // TODO @habib610 : Handle error
    return;
}
