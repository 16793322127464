const offsetCard = {
    hidden: {
        y: 200,
        opacity: 0,
    },
    visible: (i) => ({
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
        },
    }),
};
const offsetBtn = {
    hidden: {
        y: 200,
        opacity: 0,
    },
    visible: (i) => ({
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            delay: i * 0.1,
        },
    }),
};

const offsetMoreSpring = {
    hidden: {
        scale: 0.2,
        opacity: 0,
        // y: -1000,
    },
    visible: {
        scale: 1,
        opacity: 1,
        // y: 0,
        transition: {
            type: "spring",
            duration: 0.5,
        },
    },
    exit: {
        scale: 0.2,
        opacity: 0,
        // y: -1000,
        transition: {
            type: "spring",
            duration: 0.5,
        },
    },
};

const offsetOTP = {
    hidden: {
        scale: 0.8,
        opacity: 0,
        y: -100,
    },
    visible: {
        scale: 1,
        opacity: 1,
        y: 0,
        // y: 0,
        transition: {
            scale: 0.8,
            opacity: 0,
            y: -100,
        },
    },
    exit: {
        scale: 0.2,
        opacity: 0,
        // y: -1000,
        transition: {
            scale: 0.8,
            opacity: 0,
            y: -100,
        },
    },
};

export { offsetCard, offsetBtn, offsetMoreSpring, offsetOTP };
