import { useCookies } from "react-cookie";
import { SROT_SOCKET_COOKIES_NAME } from "../constants/common";
import { useContextState } from "../context/ContextProvider";
// const MAX_RETRY_TIME = 180000;

const WebSocketConfig = () => {
    const { state, dispatch } = useContextState();
    const [cookies, setCookie] = useCookies([SROT_SOCKET_COOKIES_NAME]);
    // const [connectTime, setConnectTime] = useState(5000);

    // useEffect(() => {
    //     let stompClient = null;
    //     let timerId = null;

    //     const handleListenWebSocket = () => {
    //         let Sock = new SockJS(
    //             `${API_ENDPOINT}${WEB_SOCKET_PIPELINE_ENDPOINT}`
    //         );
    //         stompClient = over(Sock);

    //         stompClient.connect(
    //             {},
    //             (event) => {
    //                 stompClient.subscribe(
    //                     SOCKET_USER_CONNECTION_ENDPOINT,
    //                     (payload) => {
    //                         let body = JSON.parse(payload.body);

    //                         if (body?.body) {
    //                             let data = body?.body?.data?.data;

    //                             if (
    //                                 data?.actionType ===
    //                                     BROADCAST_ACTION.JOIN &&
    //                                 data?.clientId !== cookies._srot_ws_user
    //                             ) {
    //                                 dispatch({
    //                                     type: PUSH_CONNECT_DISCONNECT_QUEUE,
    //                                     payload: data,
    //                                 });
    //                             }
    //                         } else if (body?.data) {
    //                             let data = body?.data?.data;

    //                             dispatch({
    //                                 type: PUSH_CONNECT_DISCONNECT_QUEUE,
    //                                 payload: data,
    //                             });
    //                         }
    //                     }
    //                 );

    //                 stompClient.subscribe(
    //                     SOCKET_PROJECT_NOTIFICATION_ENDPOINT,
    //                     (payload) => {
    //                         let body = JSON.parse(payload.body);
    //                         if (body?.data) {
    //                             let data = body?.data?.data;

    //                             let investorId = state?.user?.investorId;

    //                             if (!investorId) {
    //                                 dispatch({
    //                                     type: PUSH_PROJECTS_NOTIFICATION_QUEUE,
    //                                     payload: data,
    //                                 });
    //                             } else if (investorId !== data.investorId) {
    //                                 dispatch({
    //                                     type: PUSH_PROJECTS_NOTIFICATION_QUEUE,
    //                                     payload: data,
    //                                 });
    //                             }
    //                         }
    //                     }
    //                 );

    //                 if (cookies._srot_ws_user) {
    //                     stompClient.send(
    //                         "/app/public/addUser",
    //                         {},
    //                         JSON.stringify({
    //                             clientId: cookies._srot_ws_user,
    //                             actionType: BROADCAST_ACTION.JOIN,
    //                         })
    //                     );
    //                 } else {
    //                     setCookie(
    //                         SROT_SOCKET_COOKIES_NAME,
    //                         event.headers["user-name"],
    //                         {
    //                             maxAge: 7 * 24 * 60 * 60, // expires after 7 days
    //                         }
    //                     );
    //                     stompClient.send(
    //                         "/app/public/addUser",
    //                         {},
    //                         JSON.stringify({
    //                             clientId: event.headers["user-name"],
    //                             actionType: BROADCAST_ACTION.JOIN,
    //                         })
    //                     );
    //                 }
    //             },
    //             () => {
    //                 stompClient = null;
    //                 timerId = null;
    //             }
    //         );
    //     };

    //     const checkWebSocketConnection = () => {
    //         // setConnectTime((prev) => prev + 5000);
    //         handleListenWebSocket();
    //     };

    //     if (!timerId && !stompClient) {
    //         timerId = setTimeout(checkWebSocketConnection, 5000);
    //     }

    //     if (timerId && stompClient !== null) {
    //         clearInterval(timerId);
    //         timerId = null;
    //     }

    //     // Sock.onclose(() => stompClient.disconnect());

    //     // return;
    // }, []);
    // return null;
};

export default WebSocketConfig;
