import React from "react";
import Flex from "../../components/flex/Flex";
import Input from "../../components/input/Input";
import Space from "../../components/space/Space";
import Text from "../../components/text/Text";

const DetailsFiled = ({ label, onChange, ...rest }) => {
    return (
        <Space
            margin={[" 0 0 30px "]}
            tabletmargin={["0 0 25px"]}
            mobilemargin={["0 0 10px"]}
        >
            <Flex.Container justifyContent="space-between" alignItems="center">
                <Flex.Item width="40%" tabletWidth="100%" mobileWidth="100%">
                    <Text color="white" weight="bold" size="m">
                        {label}
                    </Text>
                </Flex.Item>
                <Flex.Item width="50%" tabletWidth="100%" mobileWidth="100%">
                    <Input
                        onChange={onChange}
                        backgroundColor="white"
                        {...rest}
                    />
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};

export default DetailsFiled;
