import { useHistory } from "react-router-dom";
import {
    infoCircular,
    infoEdit,
    level1,
    level2,
    level3,
} from "../../resources/js/icons";
import Container from "../../shared/Container";
import P from "../../shared/typography/P";
const badges = [
    {
        name: "Level 1",
        icon: level1,
    },
    {
        name: "Level 2",
        icon: level2,
    },
    {
        name: "Level 3",
        icon: level2,
    },
    {
        name: "Level 4",
        icon: level3,
    },
    {
        name: "Level 5",
        icon: level3,
    },
];

const Badges = () => {
    const history = useHistory();
    return (
        <div className="bg-linear-cyan-gray lg:w-[54%]  xl:w-[50%] rounded-t-2 md:rounded-none md:bg-none">
            <Container className="md:bg-linear-cyan-gray md:rounded-2 ">
                <div className="flex items-center justify-between gap-1 flex-wrap mt-3.2 pt-1.6 pb-1.6">
                    <div className="flex items-center gap-0.8">
                        <div className="w-3.2 h-3.2 lg:h-2 lg:w-2">
                            {/* @DESC:: info @habib610 Mon October 21,2024 */}
                            <img src={infoCircular} alt="info" />
                        </div>
                        <div>
                            <P className="font-vietnam lg:text-sm xl:text-sm">
                                Complete KYC Verification
                            </P>
                            <P className="text-xs mt-0.4 font-vietnam lg:text-sm xl:text-sm">
                                Start investing after verifying your account!
                            </P>
                        </div>
                    </div>
                    <div
                        className="w-2 h-2 hover:cursor-pointer"
                        onClick={() => history.push("/kyc/summary")}
                    >
                        <img src={infoEdit} alt="kyc_info_edit" />
                    </div>
                </div>
            </Container>
            {/* <div className="bg-white rounded-t-2 md:rounded-none">
                <Container className="pt-2.4">
                    <P className="text-gray mb-1.6 font-vietnam">Badges</P>

                    <div className="grid grid-cols-3 gap-1">
                        {badges.map((item, index) => (
                            <div
                                key={index}
                                className="flex items-center flex-col"
                            >
                                <img
                                    src={item.icon}
                                    className="w-4.8"
                                    alt={item.name}
                                />
                                <P className="text-gray text-center font-vietnam">
                                    {item.name}
                                </P>
                            </div>
                        ))}
                    </div>
                </Container>
            </div> */}
        </div>
    );
};

export default Badges;
