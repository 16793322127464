import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useContextState } from "../../context/ContextProvider";
import useAuth from "../../hooks/useAuth";
import cn from "../../lib/cn";
import Container from "../Container";
import P from "../typography/P";
import tabData from "./tabData";

const handleActiveTab = (location, path) => {
    if (location.slice(1).toLowerCase().includes(path.slice(1).toLowerCase())) {
        return true;
    } else return false;
};

const TabNavigator = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { isAuth } = useAuth(pathname);
    const { state } = useContextState();

    if (!(isAuth && state?.user)) return null;

    return (
        <div className=" lg:hidden fixed inset-x-0 bottom-0 bg-white py-1.6  border-t border-gray-400  flex items-center z-100 ">
            <Container>
                <div className="flex justify-between ">
                    {tabData.map((item) => (
                        <div
                            key={item.id}
                            className="flex flex-col items-center hover:cursor-pointer"
                            role="button"
                            onClick={() => history.push(item.path)}
                        >
                            <div
                                className={cn("h-[24px]  ", {
                                    "text-primary": handleActiveTab(
                                        pathname,
                                        item.name
                                    ),
                                })}
                            >
                                {item.icon}
                            </div>

                            <P
                                className={cn({
                                    "text-primary": handleActiveTab(
                                        pathname,
                                        item.name
                                    ),
                                })}
                            >
                                {item.name}
                            </P>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default TabNavigator;
