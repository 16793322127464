import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import DatePicker from "../components/date-picker/DatePicker";
import { ErrorText } from "../components/input/Input";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import theme from "../theme";

export const BottomInputWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    font-family: ${(props) => props.fontFamily || "'Karla', sans-serif"};
    border-bottom: 1px solid
        ${(props) =>
            props.error ? props.theme.color.error : props.theme.color.grey600};
    :focus-within {
        border-color: ${(props) => props.theme.color.primary};
    }
    input {
    }
    input,
    textarea {
        border: none;
        padding-bottom: 7px;
        padding-top: 7px;
        background-color: ${(props) => props.theme.color.white};
        :focus {
            background-color: ${(props) => props.theme.color.grey200};
        }
        font-size: ${(props) => props.theme.fontSize.default.m};
        font-family: ${(props) => props.fontFamily || "'Karla', sans-serif"};
        outline: none;
        font-family: "Karla", sans-serif;
        box-sizing: border-box;
        width: 100%;
        padding-right: 30px;
        color: ${(props) =>
            props.error
                ? props.theme.color.error
                : props.theme.color.darkSecondary};
        transition: all ease-in 0.3s;
        @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.tablet[props.size]
                    : props.theme.fontSize.tablet.m};
        }
        @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.mobile[props.size]
                    : props.theme.fontSize.mobile.m};
        }
        ::placeholder {
            color: ${(props) => props.theme.color.lightGrey};
            opacity: 1;
        }
        :-ms-input-placeholder {
            color: ${(props) => props.theme.color.error};
        }

        ::-ms-input-placeholder {
            color: ${(props) => props.theme.color.error};
        }
    }

    position: relative;
    span {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        color: ${(props) => props.theme.color.lightGrey};
        top: 0;
        right: 0;
        bottom: 0;
        font-size: ${(props) => props.theme.fontSize.default.m};
        @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.tablet[props.size]
                    : props.theme.fontSize.tablet.m};
        }
        @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.mobile[props.size]
                    : props.theme.fontSize.mobile.m};
        }
    }
`;
export const BottomContainer = styled.div`
    margin: ${(props) => props.margin};
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        margin: ${(props) => props.tabletmargin};
    }
`;

const BottomLineInput = ({
    label,
    onChange,
    icon = "",
    error = "",
    datePicker,
    Component = "input",
    margin = "0px 0 40px 0",
    tabletmargin = " 0 0 20px 0",

    ...rest
}) => {
    return (
        <BottomContainer margin={margin} tabletmargin={tabletmargin}>
            <Text color="darkSecondary" size="m">
                {label}
            </Text>
            {datePicker ? (
                <BottomInputWrapper>
                    <DatePicker />
                </BottomInputWrapper>
            ) : (
                <BottomInputWrapper margin={margin} error={error} size="m">
                    <Component
                        onChange={onChange}
                        {...rest}
                        autoComplete="off"
                    />
                    {icon && (
                        <InlineText size="m">
                            <FontAwesomeIcon
                                color={theme.color.black}
                                icon={icon}
                            />
                        </InlineText>
                    )}
                </BottomInputWrapper>
            )}
            {error && <ErrorText size="xs">{error}</ErrorText>}
        </BottomContainer>
    );
};

export default BottomLineInput;
