import React from "react";
import styled from "styled-components";
import Text from "../text/Text";
const MessageText = styled(Text)`
    padding: 10px 20px;
    border-radius: 3px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.mobile.m};
    }
`;
const Message = styled(MessageText)`
    background: ${(props) => props.theme.color[props.backgroundColor]};
    border: ${(props) =>
        props.borderColor &&
        `1px solid ${props.theme.color[props.borderColor]}`};
    border-radius: 0.3rem;
`;
const AlertNew = ({
    backgroundColor = "grey300",
    color = "black800",
    borderColor,
    children,
}) => {
    return (
        <Message
            size="l"
            align="center"
            color={color}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
        >
            {children}
        </Message>
    );
};

export default AlertNew;
