import React, { useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import {
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import { LOGIN, SIGNUP_CONSUMER_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { validate } from "../../helpers/fieldValidation";
import { handleRedirection } from "../../helpers/routingHelper";
import { handleResentOTP } from "../../services/apiRequest";
import auth from "../../services/auth";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import LabeledInput from "../../shared/ui/Form/LabeledInput";
import OTP from "../../shared/ui/OTP";

const validateSignupForm = (formData, getOtp) => {
    const newErrors = {};
    if (!formData.Name.trim()) {
        newErrors.Name = "Name is required";
    }

    if (!formData.email.trim()) {
        newErrors.email = "Email is required";
    } else if (!emailRegEx.test(formData.email)) {
        newErrors.email = "Invalid Email";
    }
    if (!formData.phoneNumber) {
        newErrors.phoneNumber = "Phone Number is required";
    } else if (!phoneRegEx.test(formData.phoneNumber)) {
        newErrors.phoneNumber = "Invalid Phone Number ";
    }
    if (!getOtp) {
        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
        }
        if (!formData.confirmPassword.trim()) {
            newErrors.confirmPassword = "Password is required";
        } else if (formData.confirmPassword !== formData.password) {
            newErrors.confirmPassword = "Password does not match";
        }
    } else {
        if (!formData.otp.trim()) {
            newErrors.otp = "OTP is required";
        }
    }
    return newErrors;
};

const SignUpPage = ({ handleLogIn, handleOnboarding }) => {
    const { state, dispatch } = useContextState();
    const history = useHistory();

    const [formData, setFormData] = useState({
        Name: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        otp: "",
    });

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };

    const resentOtpHandler = async (mode) => {
        const rules = {
            Name: { required: true },
            password: { required: true },
            email: { required: true, pattern: /\S+@\S+\.\S+/ },
            phoneNumber: { required: true, pattern: /^\d{10}$/ },
        };

        const validationErrors = validate(formData, rules);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            let credentials = `91${formData.phoneNumber}`;
            await handleResentOTP(mode, credentials, setLoading, dispatch);
        }
    };
    const handleGetOTP = async () => {
        const validationErrors = validateSignupForm(formData, otp);

        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            let val = `91${formData.phoneNumber}`;
            try {
                await auth.loginOTP({
                    username: val,
                });
                setOtp(true);
                toast.success("OTP has been sent", {
                    containerId: "otpContainer",
                });

                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateSignupForm(formData, otp);

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            try {
                let reqData = {
                    name: formData.Name,
                    email: formData.email,
                    phone: `91${formData.phone}`,
                    referralCode: null,
                    password: formData.password,
                    otp,
                };
                let endpoint = SIGNUP_CONSUMER_ENDPOINT;
                await request.post({
                    endpoint,
                    body: reqData,
                });

                await auth.login({
                    username: formData.email,
                    password: formData.password,
                    otpEnable: false,
                });

                const profile = await auth.getUserProfile(dispatch);
                let status = profile?.data?.investor_profile?.plant?.status;
                const roles = profile?.data?.investor_profile?.roles;

                handleRedirection(state, history, roles, status);

                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });
                setLoading(false);

                handleLogIn();
            } catch (err) {
                setLoading(false);
                toast.error(err || ERROR_MSG);
            }
        }
    };
    const [otp, setOtp] = useState(false);
    return (
        <div>
            <div className="flex items-start gap-2">
                <P
                    className="text-2xl md:text-4.0xl xl:text-4.0xl xl:font-light mb-2"
                    onClick={() => (otp ? setOtp(false) : history.goBack())}
                >
                    <IoIosArrowDropleft />
                </P>
                {!otp && <H4 className="font-semibold">Create your account</H4>}
            </div>
            {otp ? (
                <div className="flex flex-col gap-2">
                    <H4 className="font-semibold">Verify OTP</H4>

                    <OTP
                        otp={formData.otp}
                        onChange={handleChange}
                        error={errors?.otp ? true : false}
                        errorMessage={errors.otp}
                        handleResentOTP={resentOtpHandler}
                    />
                    <Button
                        className={
                            "bg-black text-white mt-3 !rounded-[3rem] w-full"
                        }
                        onClick={handleSubmit}
                    >
                        Verify
                    </Button>
                </div>
            ) : (
                <div className="flex flex-col gap-2">
                    <LabeledInput
                        label={"Name"}
                        value={formData?.Name}
                        onChange={handleChange}
                        name="Name"
                        type={"text"}
                        errorMsg={errors?.Name}
                        required={true}
                    />
                    <LabeledInput
                        label={"Phone Number"}
                        value={formData?.phoneNumber}
                        onChange={handleChange}
                        name={"phoneNumber"}
                        type={"text"}
                        errorMsg={errors?.phoneNumber}
                        required={true}
                    />
                    <LabeledInput
                        label={"Email"}
                        value={formData?.email}
                        onChange={handleChange}
                        name={"email"}
                        type={"text"}
                        errorMsg={errors?.email}
                    />
                    <LabeledInput
                        label="New password"
                        inputWrapper="rounded-l-md w-full"
                        name="password"
                        onChange={handleChange}
                        iconChange={() => setShow((prev) => !prev)}
                        type={show ? "text" : "password"}
                        icon={show ? <IoEyeOutline /> : <IoEyeOffOutline />}
                        iconWrapperClass={
                            "  item-center flex justify-center py-[1.5rem] px-[2rem] text-xl rounded-r-md bg-white "
                        }
                        errorMsg={errors?.password}
                    />
                    <LabeledInput
                        label="Confirm password"
                        inputWrapper="rounded-l-md  w-full"
                        onChange={handleChange}
                        iconChange={() => setShowConfirm((prev) => !prev)}
                        type={showConfirm ? "text" : "password"}
                        name={"confirmPassword"}
                        icon={
                            showConfirm ? <IoEyeOutline /> : <IoEyeOffOutline />
                        }
                        iconWrapperClass={
                            "  item-center flex justify-center py-[1.5rem] px-[2rem] text-xl rounded-r-md bg-white "
                        }
                        errorMsg={errors?.confirmPassword}
                    />
                    <Button
                        className={
                            "bg-black text-white mt-1 !rounded-[3rem] w-full"
                        }
                        onClick={handleGetOTP}
                    >
                        Continue
                    </Button>
                    <div className="flex items-center justify-end mb-2">
                        <P className="flex items-center gap-0.2">
                            Already have account?
                            <span
                                className="text-blue underline"
                                role="button"
                                onClick={() => history.push(LOGIN)}
                            >
                                Sign in
                            </span>
                        </P>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SignUpPage;
