import styled from "styled-components";

const FlexContainer = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || ""};
    align-items: ${(props) => props.alignItems || ""};
    flex-wrap: wrap;
    flex-direction: ${(props) => props.direction || ""};
`;

const FlexItem = styled.div`
    width: ${(props) => props.width || "auto"};
    height: ${(props) => props.height || "auto"};
    align-self: ${(props) => props.alignSelf || ""};
    flex: ${(props) => props.flex || ""};
    @media (max-width: ${(props) => props.theme.breakPoints.bigTablet}) {
        width: ${(props) => props.bigTabletWidth || props.width || "auto"};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: ${(props) => props.tabletWidth || "auto"};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: ${(props) => props.mobileWidth || "auto"};
    }
`;

const Flex = {
    Container: FlexContainer,
    Item: FlexItem,
};

export default Flex;
