import React from "react";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import P from "../../../shared/typography/P";
import Tag from "../../../shared/ui/tag/Tag";
import H4 from "../../typography/H4";
const InsightCard = ({ category, title, date, imageUrl, onActionClick }) => {
    return (
        <div
            className="bg-gray-50 pb-1  max-w-[448.82px] rounded cursor-pointer "
            onClick={onActionClick}
        >
            <img
                src={imageUrl}
                alt={title}
                className="rounded w-full h-[229px] object-cover"
            />
            <div className="px-2 mt-1.6 md:mt-2.4 xl:mt-3">
                <div className=" flex flex-col gap-1.6 xl:gap-2 items-start w-full ">
                    <Tag text={category} />
                    <H4 className="text-start">{title}</H4>
                </div>
                <div className="flex justify-between items-center mt-1">
                    <P className="text-gray-500">{date}</P>
                    <P className="text-xl md:text-xl xl:text-xl">
                        <IoArrowUpCircleOutline className="origin-center rotate-45 text-2xl" />
                    </P>
                </div>
            </div>
        </div>
    );
};

export default InsightCard;
