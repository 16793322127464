// RangeInput.js
import React, { useState } from "react";

const RangeInput = () => {
    const [value, setValue] = useState(5000); // Initial value

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    const thumbPosition = ((value - 1000) / (10000 - 1000)) * 100;
    return (
        <div className="flex flex-col ">
            <div className="relative w-full max-w-lg">
                <input
                    type="range"
                    min="1000"
                    max="10000"
                    value={value}
                    onChange={handleChange}
                    className="w-full h-2 bg-gradient-to-r from-red-200 to-red-300 bg-no-repeat appearance-none rounded-3xl cursor-pointer disabled:bg-white"
                    style={{
                        backgroundSize: `${
                            ((value - 1000) / (10000 - 1000)) * 100
                        }% 100%`,
                    }}
                    disabled
                />
                <div
                    className="absolute -top-0.6 transform -translate-x-1/3 text-sm bg-linear-redish-orange rounded-xl font-vietnam px-3 py-0.5 "
                    style={{ left: `${thumbPosition}%` }}
                >
                    {value}
                </div>
            </div>
        </div>
    );
};

export default RangeInput;
