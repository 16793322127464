// CompanyCard.js
import React from "react";
import H4 from "../../../../shared/typography/H4";

const CompanyCard = ({
    companyName,
    industry,
    plantSize,
    tenure,
    irr,
    onClick,
}) => {
    return (
        <div className="bg-black-400 lg:bg-white  text-white  text-start px-2 py-2 h-full rounded-3xl font-vietnam ">
            <H4 className="text-white lg:text-black-600 font-semibold mb-2 text-base md:text-base xl:text-base">
                {companyName}
            </H4>
            <span className="bg-black-600 text-blue lg:bg-gray-50   px-1 py-0.6 text-sm  rounded-lg">
                {industry}
            </span>
            <div className="flex justify-between gap-2 items-center mt-3">
                <div className="flex flex-col gap-0.6">
                    <p className="text-xs text-gray-550">Plant Size:</p>
                    <span className=" text-sm lg:text-black font-semibold">
                        {plantSize}
                    </span>
                </div>
                <div className="flex flex-col gap-0.6">
                    <p className="text-xs text-gray-550">Tenure</p>
                    <span className="text-sm lg:text-black font-semibold">
                        {tenure}
                    </span>
                </div>
                <div className="flex flex-col gap-0.6">
                    <p className="text-xs text-gray-550">IRR</p>
                    <span className="text-sm lg:text-black font-semibold">
                        {irr}
                    </span>
                </div>
            </div>
            <div className="justify-end  items-baseline">
                <div
                    role="button"
                    onClick={() => onClick()}
                    className="text-sm w-full text-gray-550 border py-1 text-center border-gray-500 rounded-[3rem] mt-2 "
                >
                    Show details
                </div>
            </div>
        </div>
    );
};

export default CompanyCard;
