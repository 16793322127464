import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Image from "../components/image/Image";
import { ContainerMain } from "../components/styles/Container";
import Text from "../components/text/Text";
import { LANDING } from "../constants/routes";

const bgStars = "./images/stars_background.png";
const mountain = "./images/404_mountain.png";
const moon = "./images/404_moon.png";
const Container = styled(ContainerMain)`
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100vh;
    background: url(${(props) => props.bg});
    background-position: center top;
    background-size: contain;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 10%; */
`;

const Mountain = styled(Image)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
`;
const Moon = styled(Image)`
    position: absolute;
    top: 20%;
    left: 25%;
    transform: translate(-20%, -25%);
    width: 100px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 80px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 70px;
    }
`;
const ButtonStyled = styled(Button)`
    border-radius: 50px;
    margin-top: 100px;
    padding: 1rem 4rem;
`;
const Status = styled(Text)`
    font-size: 120px;
    margin-bottom: 10px;

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 100px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 90px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: 80px;
    }
`;
const ThankYou = () => {
    const history = useHistory();
    return (
        <Container bg={bgStars}>
            <Flex.Container
                style={{
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: 9,
                }}
            >
                <Status
                    align="center"
                    color="white"
                    weight="bold"
                    fontFamily="'Great Vibes', cursive"
                >
                    Thank You
                </Status>
                <Text align="center" size="l" weight="bold" color="white">
                    For signing up, you can start investing now!
                </Text>

                <ButtonStyled
                    weight="bold"
                    color="white"
                    textTransform="uppercase"
                    backgroundColor="secondary"
                    type="solid"
                    fontSize="xl"
                    onClick={() => history.push(LANDING)}
                >
                    Login Now
                </ButtonStyled>
            </Flex.Container>
            <Mountain src={mountain} alt="mountain" />
            <Moon src={moon} alt="moon" />
        </Container>
    );
};

export default ThankYou;
