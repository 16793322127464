import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { Flex } from "../styles/Elements.styles";
import ModalTimes from "./ModalTimes";
import ModalTitle from "./ModalTitle";

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 115;
    padding: 75px 0;
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        padding: ${(props) => props.topPadding || "100px"} 0;
    }
`;

const Container = styled.div`
    width: 95%;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        width: 84%;
    }
    margin-inline: auto;
    box-sizing: border-box;
    @media (max-width: 365px) {
        max-width: 95%;
    }
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: ${(props) => props.theme.fontSize.mobile.m};
    cursor: pointer;
    color: ${(props) =>
        props.color ? props.theme.color[props.color] : props.theme.color.white};
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.tablet.m};
    }
`;
const FlexItem = styled(Flex.Item)`
    width: ${(props) => props.width[0]};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: ${(props) => props.width[1]};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: ${(props) => props.width[2]};
    }
`;

const Content = styled.div`
    background-color: ${(props) => props.theme.color[props.backgroundColor]};
    padding: ${(props) => props.xxsp};
    border-radius: 10px;
    position: relative;
    overflow-x: ${(props) => props.overflowx};
    overflow-y: ${(props) => props.overflowy};
    max-height: 76vh;
    .innerTimes {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: ${(props) => props.xsp};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        padding: ${(props) => props.stp};
        .innerTimes {
            top: 15px;
            right: 15px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        padding: ${(props) => props.xlp};
    }
`;

const ModalAlt = ({
    isOpen,
    title,
    onClose,
    times,
    innerTimes = true,
    children,
    backgroundColor = "white",
    bgTheme = "light",
    width = ["100%", "100%", "60%"],
    topPadding,
    overflowx = "hidden",
    overflowy = "auto",
    xxsp = "2rem 1rem",
    xsp = "2rem 1.8rem",
    stp = "2.5rem 2rem",
    xlp = "2.5rem 2rem",
}) => {
    return isOpen ? (
        <AnimatePresence>
            <Overlay topPadding={topPadding}>
                <Container>
                    <Flex.Container justify="center">
                        <FlexItem width={width}>
                            <ModalTitle
                                times={times}
                                onClick={onClose}
                                title={title}
                            />
                            <Content
                                as={motion.div}
                                backgroundColor={backgroundColor}
                                layout
                                initial={{ scale: 0.8, opacity: 0, y: -1000 }}
                                animate={{ scale: 1, opacity: 1, y: 0 }}
                                exit={{ scale: 0.8, opacity: 0, y: 1000 }}
                                transition={{ type: "spring", duration: 0.5 }}
                                overflowx={overflowx}
                                overflowy={overflowy}
                                xxsp={xxsp}
                                xsp={xsp}
                                stp={stp}
                                xlp={xlp}
                            >
                                {children}
                                {innerTimes && (
                                    <div className="innerTimes">
                                        <ModalTimes
                                            bgTheme={bgTheme}
                                            onClose={onClose}
                                        />
                                    </div>
                                )}
                            </Content>
                        </FlexItem>
                    </Flex.Container>
                </Container>
            </Overlay>
        </AnimatePresence>
    ) : null;
};

export default ModalAlt;
