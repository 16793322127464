import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AlertNew from "../components/alert/AlertNew";
import Flex from "../components/flex/Flex";
import Image from "../components/image/Image";
import Loading from "../components/loading/Loading";
import Space from "../components/space/Space";
import { ContainerMain, ContainerStyled } from "../components/styles/Container";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { ERROR_MSG } from "../constants/common";
import { loginData } from "../constants/loginData";
import {
    ERROR_SCREEN,
    LANDING,
    // LOGIN,
    PORTFOLIO,
    VERIFY_EMAIL_ENDPOINT,
} from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import { getURLQueryParams } from "../helpers/helpers";
import useAuth from "../hooks/useAuth";
import auth from "../services/auth";
import request from "../services/request";

const VerifyEmail = () => {
    const location = useLocation();

    const code = getURLQueryParams(location, "code");

    const history = useHistory();
    const {
        location: { pathname },
    } = history;
    const { state, dispatch } = useContextState();

    if (code === undefined || code === "") {
        history.push(ERROR_SCREEN);
    }

    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const { isAuth } = useAuth(pathname, dispatch);

    useEffect(() => {
        if (isAuth === null) return;

        const endpoint = VERIFY_EMAIL_ENDPOINT;
        setLoading(true);
        setMessage("");
        setError(false);

        const updateEmail = async () => {
            try {
                const response = await request.get({
                    endpoint: `${endpoint}?code=${code}`,
                });
                if (response?.status === "SUCCESS") {
                    toast.success("Email confirmed successfully");
                    setError(false);
                    setMessage("Email confirmed successfully");
                    setLoading(false);
                    if (isAuth) {
                        // this is to update email verification msg
                        await auth.getUserProfile(dispatch);
                        history.push(PORTFOLIO);
                    } else {
                        let timeOut = setTimeout(
                            () => history.push(LANDING),
                            3000
                        );
                        return () => clearTimeout(timeOut);
                    }
                }
            } catch (error) {
                setError(true);
                setMessage(typeof error === "string" ? error : ERROR_MSG);
                setLoading(false);
            }
        };
        updateEmail();
    }, [code, dispatch, history, isAuth]);

    return (
        <ContainerMain
            bg={loginData.background.bg}
            justifyContent="center"
            alignItems="center"
        >
            <Flex.Item width="60%" tabletWidth="100%" mobileWidth="100%">
                <Space
                    bigtabletpadding={["0px 5% 0px 0px"]}
                    tabletpadding={["0px 10% 0px 0px"]}
                >
                    <Text size="xl" weight="bold">
                        {loginData.subHeadings[0]}
                        <InlineText textTransform="uppercase" color="green">
                            {loginData.inlineText}{" "}
                        </InlineText>
                        {loginData.subHeadings[1]}
                        <InlineText
                            textTransform="uppercase"
                            color="green"
                            weight="bold"
                        >
                            {loginData.inlineText}
                        </InlineText>
                    </Text>
                </Space>
                <Space
                    bigtabletpadding={["0px 5% 0px 0px"]}
                    tabletpadding={["0px 10% 0px 0px"]}
                >
                    <Text size="xl">{loginData.subHeadings[2]}</Text>
                </Space>

                <ContainerStyled
                    displayMobile="none"
                    justifyMobile="center"
                    justifyTablet="center"
                >
                    <Space
                        margin={["5% 0px"]}
                        tabletmargin={["8% 0"]}
                        mobilemargin={["10% 0px"]}
                    >
                        <Image
                            src={loginData.background.image}
                            borderRadius="5%"
                            alt="banner_image"
                            width="60%"
                            tabletWidth="100%"
                        />
                    </Space>
                </ContainerStyled>
            </Flex.Item>
            <Flex.Item width="40%" tabletWidth="100%" mobileWidth="100%">
                <Space mobilemargin={["50px 0px 0px 0px"]}>
                    <>
                        {loading ? (
                            <Flex.Container justifyContent="center">
                                <Text size="l" align="cneter" color="primary">
                                    <Loading />
                                </Text>
                            </Flex.Container>
                        ) : message ? (
                            <AlertNew
                                backgroundColor={error ? "danger" : "success"}
                                borderColor={
                                    error ? "dangerBorder" : "successBorder"
                                }
                            >
                                {message}
                            </AlertNew>
                        ) : null}
                    </>
                </Space>
            </Flex.Item>
        </ContainerMain>
    );
};

export default VerifyEmail;
