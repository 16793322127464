import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import P from "../../../../shared/typography/P";
import SubHeading from "../../../../shared/typography/SubHeading";
import PillSlider from "../../../../shared/ui/Slider/PillSlider";
import InvestmentCard from "./InvestmentCard";
const MyInvestment = ({ investmentData }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className="p-3">
            <div className="flex justify-between items-center">
                <SubHeading className=" text-base xl:text-base font-semibold">
                    My Investments
                </SubHeading>
                <P className="underline text-sm text-blue-500 cursor-pointer">
                    View All
                </P>
            </div>
            <div className=" py-1">
                <CarouselComponent
                    selectedItem={currentSlide}
                    onChange={setCurrentSlide}
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    stopOnHover={true}
                    transitionTime={500}
                    swipeable={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    emulateTouch={true}
                >
                    {investmentData?.data?.portfolio?.investments.map(
                        (data, index) => (
                            <div key={index} className="h-full">
                                <InvestmentCard
                                    status={data?.status}
                                    companyName={data.name}
                                    amount={data.monthlyPayout}
                                    investment={data.investmentAmount}
                                    percentage={data.irr}
                                />
                            </div>
                        )
                    )}
                </CarouselComponent>
            </div>
            <div className="flex justify-start">
                <PillSlider
                    currentSlide={currentSlide}
                    className={"my-1"}
                    data={investmentData?.data?.portfolio?.investments}
                    handleSlideChange={handleSlideChange}
                />
            </div>
        </div>
    );
};

export default MyInvestment;
