import React, { useEffect, useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../../constants/common";
import { OPPORTUNITY_ENDPOINT, PROJECTS } from "../../../constants/routes";
import request from "../../../services/request";
import P from "../../../shared/typography/P";
import SubHeading from "../../../shared/typography/SubHeading";
import PillSlider from "../../../shared/ui/Slider/PillSlider";
import { ACTIVE } from "../../project/mobile";
import CompanyCard from "./NewUser/CompanyCard";
const NewOpportunities = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [companies, setCompanies] = useState([]);
    const history = useHistory();
    useEffect(() => {
        fetchProjects();
    }, []);
    const fetchProjects = async () => {
        try {
            const response = await request.get({
                endpoint: OPPORTUNITY_ENDPOINT,
            });
            const assets = response.data?.assets || [];

            const updatedCardData = assets.map((asset) => {
                const plant = asset.plants?.[0] || {};
                const investmentTerms = asset.investmentTerms?.[0] || {};

                return {
                    id: asset.listingId,
                    industry: asset.tag || "Telecom Industry",
                    companyName: `${plant.name}, ${plant.city || ""}`,
                    name: `${plant.name}, ${plant.city || ""}`,
                    location: `${plant.city || ""}, ${plant.state || ""}`,
                    plantSize: `${parseInt(plant.capacity || "0") / 1000} KWp`,
                    status: "Funding",
                    remains: "10",
                    category: ACTIVE,
                    progress: Math.min(
                        100,
                        Math.round(
                            (parseFloat(asset.fundRaised || 0) /
                                parseFloat(asset.fundLimit || 1)) *
                                100
                        )
                    ),
                    tenure: `${
                        investmentTerms.installments
                            ? investmentTerms.installments / 12
                            : "5"
                    } years`,
                    irr: `${investmentTerms.targetedIRRPercentage || "0"}%`,
                };
            });

            setCompanies(updatedCardData);
        } catch (error) {
            toast.error(error || ERROR_MSG);
        }
    };

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };
    return (
        <div className="bg-black-600 lg:bg-gray-60 lg:rounded-2xl p-3 mt-3">
            <div className="flex justify-between items-center">
                <SubHeading className="text-white lg:text-black-600 text-base   xl:!text-base font-semibold">
                    New Opportunities
                </SubHeading>
                <div
                    className="cursor-pointer"
                    role="button"
                    onClick={() => history.push(PROJECTS)}
                >
                    <P className="underline text-sm xl:text-sm text-blue-500 cursor-pointer">
                        View All
                    </P>
                </div>
            </div>
            {companies?.length > 2 ? (
                <>
                    {" "}
                    <div className="px-0 py-1">
                        <CarouselComponent
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showStatus={false}
                            showThumbs={false}
                            showIndicators={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            stopOnHover={true}
                            transitionTime={500}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                            centerMode={true}
                            centerSlidePercentage={
                                document.body.clientWidth < 1279
                                    ? document.body.clientWidth < 1100
                                        ? document.body.clientWidth < 766
                                            ? document.body.clientWidth < 630
                                                ? 85
                                                : 80
                                            : 70
                                        : 55
                                    : document.body.clientWidth > 1535
                                    ? 50
                                    : 40
                            }
                        >
                            {companies.map((company, index) => (
                                <div key={index} className="mx-1 h-full">
                                    <CompanyCard
                                        key={index}
                                        companyName={company.companyName}
                                        industry={company.industry}
                                        plantSize={company.plantSize}
                                        tenure={company.tenure}
                                        irr={company.irr}
                                        onClick={() => {
                                            history.push(
                                                `${PROJECTS}/${company.id}`
                                            );
                                        }}
                                    />
                                </div>
                            ))}
                        </CarouselComponent>
                    </div>
                    <div className="flex justify-start">
                        <PillSlider
                            currentSlide={currentSlide}
                            className={"my-1"}
                            data={companies}
                            handleSlideChange={handleSlideChange}
                        />
                    </div>
                </>
            ) : (
                <div className="flex justify-between flex-wrap items-center mt-2">
                    {companies.map((company, index) => (
                        <div key={index} className="mx-2 h-full">
                            <CompanyCard
                                key={index}
                                companyName={company.companyName}
                                industry={company.industry}
                                plantSize={company.plantSize}
                                tenure={company.tenure}
                                irr={company.irr}
                                onClick={() => {
                                    history.push(`${PROJECTS}/${company.id}`);
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default NewOpportunities;
