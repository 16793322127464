import React from "react";

import H4 from "../../../shared/typography/H4";
import SubHeading from "../../../shared/typography/SubHeading";
import ExpandableText from "./ExpandableText";

const Faqs = () => {
    const faqData = [
        {
            title: "How is IRR calculated?",
            content:
                "Solar energy is generated by converting sunlight into electricity using photovoltaic (PV) cells or through concentrated solar power (CSP) systems. PV cells directly convert sunlight into electricity.",
        },
        {
            title: "What is the prerequisite for investment into opportunity?",
            content:
                "The prerequisites for investment into an opportunity may include a thorough understanding of the market, financial stability, and a clear business plan.",
        },
        {
            title: "What is the prerequisite for investment into opportunity?",
            content:
                "The prerequisites for investment into an opportunity may include a thorough understanding of the market, financial stability, and a clear business plan.",
        },
        {
            title: "What is the prerequisite for investment into opportunity?",
            content:
                "The prerequisites for investment into an opportunity may include a thorough understanding of the market, financial stability, and a clear business plan.",
        },
        {
            title: "What is the prerequisite for investment into opportunity?",
            content:
                "The prerequisites for investment into an opportunity may include a thorough understanding of the market, financial stability, and a clear business plan.",
        },
    ];
    return (
        <div className="bg-white    p-2">
            <H4 className="font-medium text-nowrap mb-1">
                Frequently Asked Question
            </H4>
            <SubHeading className="text-black mb-2 md:text-sm lg:text-sm xl:text-sm 2xl:text-sm">
                Confused about how to invest, how payouts are calculated and
                about projects?
            </SubHeading>

            <div>
                {faqData.map((item, index) => (
                    <ExpandableText
                        key={index}
                        title={item.title}
                        content={item.content}
                        hasBottomBorder={index < faqData.length - 1}
                    />
                ))}
            </div>
        </div>
    );
};

export default Faqs;
