import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { yellowRewards } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";
import SubHeading from "../../../../shared/typography/SubHeading";
import PillSlider from "../../../../shared/ui/Slider/PillSlider";
const RewardsList = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    const RewardData = [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3,
        },
        {
            id: 4,
        },
    ];

    return (
        <div className="p-3">
            <div className="flex justify-between items-center">
                <SubHeading className=" text-base text-black-600 font-semibold">
                    Rewards
                </SubHeading>
                <P className="underline text-sm text-blue-500 cursor-pointer">
                    View All
                </P>
            </div>
            <div className="px-1 py-1">
                <CarouselComponent
                    selectedItem={currentSlide}
                    onChange={setCurrentSlide}
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    stopOnHover={true}
                    transitionTime={500}
                    swipeable={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    emulateTouch={true}
                    centerMode={true}
                    centerSlidePercentage={60}
                >
                    {RewardData.map((data, index) => (
                        <div className="bg-gray-400 py-1 rounded-t-3xl mx-1">
                            <img src={yellowRewards} className="h-7 mb-1" />
                            <P>Rewards {data.id}</P>
                        </div>
                    ))}
                </CarouselComponent>
            </div>
            <div className="flex justify-center md:justify-start">
                <PillSlider
                    currentSlide={currentSlide}
                    className={"my-1"}
                    data={RewardData}
                    handleSlideChange={handleSlideChange}
                />
            </div>
        </div>
    );
};

export default RewardsList;
