import { motion } from "framer-motion";
import React from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import styled from "styled-components";
import { cityBig, citySmall } from "../assets";

import Flex from "../components/flex/Flex";
import Icon from "../components/Icon/Icon";
import Image from "../components/image/Image";
import Section from "../components/section/Section";
import Space from "../components/space/Space";
import Text from "../components/text/Text";

const Container = styled(Section)`
    padding: 0;
    padding-top: 50px;
    height: 100vh;
    padding-right: 8%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    background: ${(props) => props.theme.color.white};
    @media (max-width: 900px) {
        align-items: flex-start;
        padding-right: 0;
    }
`;

const FlexDesk = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    @media (max-width: 900px) {
        display: none;
    }
`;

const FlexMobile = styled.div`
    display: none;
    @media (max-width: 900px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        height: auto;
        flex-direction: column;
        background: ${(props) => props.theme.color.white};
        align-items: center;
        justify-content: flex-start;
    }
`;
const HeroImg = styled.div`
    display: none;
    @media (max-width: 900px) {
        display: block;
    }
    @media (max-width: 900px) and (min-width: 720px) {
        margin-top: -80px;
    }
`;
const FlexContainer = styled.div`
    display: none;
    @media (max-width: 900px) {
        display: flex;
    }
`;

const OffsetHero = () => {
    return (
        <Container>
            <FlexDesk>
                <Flex.Container alignItems="center">
                    <Flex.Item flex="1">
                        <Image width="100%" src={cityBig} />
                    </Flex.Item>
                    <Flex.Item>
                        <HeroText />
                    </Flex.Item>
                </Flex.Container>
            </FlexDesk>
            <FlexMobile>
                <HeroImg>
                    <Image width="100%" src={citySmall} />
                </HeroImg>
                <FlexContainer>
                    <HeroText />
                </FlexContainer>
            </FlexMobile>
        </Container>
    );
};

export default OffsetHero;

const HeroText = () => {
    return (
        <Flex.Container>
            <Space margin={["4rem 0 0"]} tabletmargin={["1rem 0 0"]}>
                <Flex.Item>
                    <Text
                        lineHeight="1.3em"
                        weight="700"
                        size="xl"
                        color="primary"
                    >
                        Calculate {"&"} Offset
                    </Text>
                    <Text
                        lineHeight="1.3em"
                        weight="700"
                        size="xml"
                        color="darkGrey"
                    >
                        Carbon Footprint
                    </Text>
                    <Flex.Container justifyContent="center">
                        <motion.div
                            initial={{
                                y: 25,
                            }}
                            animate={{
                                y: -5,
                            }}
                            transition={{
                                duration: 2,
                                yoyo: Infinity,
                            }}
                        >
                            <Icon
                                size="xl"
                                align="center"
                                component={BsChevronDoubleDown}
                            />
                        </motion.div>
                    </Flex.Container>
                </Flex.Item>
            </Space>
        </Flex.Container>
    );
};
