import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { SHOW_LOGIN_FORM } from "../../constants/contexConstant";
import { OPPORTUNITY, PROJECTS } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import { Step } from "./Step";
import StepPreviewBottom from "./StepPreviewBottom";
import StepPreviewTop from "./StepPreviewTop";

const StepperContainer = styled(Flex.Container)`
    padding: 25px 0 0;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        flex-direction: column;
    }
`;

const StepsWrapper = styled(Flex.Item)`
    flex: 2;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 100%;
    }
`;

const PreviewWrapper = styled(Flex.Item)`
    height: 100%;
    flex: 1;
    padding: 30px;
    background: ${(props) => props.theme.color.white};
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: rgb(0 0 0 / 24%) 0px 4px 6px 2px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 100%;
        margin: 50px 0 0;
    }
`;

const PreviewCard = styled.div`
    margin: 0 0 20px;
    padding: 10px;
    background: ${(props) => props.theme.color.pureWhite};
    border-radius: 5px;
`;

const ActionButton = styled(Button)`
    width: 100%;
    height: 100%;
`;

export const StepContainer = (props) => {
    const {
        data,
        carbonDioxideLevel,
        currentStep,
        updateCurrStep,
        onAnswerSelect,
        answers,
        calculate,
        fireAction,
        loading,
    } = props;

    const history = useHistory();
    const [isComplete, setIsComplete] = useState(false);
    const { state, dispatch } = useContextState();

    const isCalculating = !!Object.keys(answers).length;

    const calculateTotal = () => {
        const carbonFootprint = Object.keys(answers).reduce((acc, ans) => {
            return !isNaN(Number(answers[ans]))
                ? acc + Number(answers[ans])
                : acc;
        }, 0);
        return carbonFootprint.toFixed(2);
    };

    const handleCarbon = () => {
        if (fireAction) {
            fireAction();
        } else {
            history.push(OPPORTUNITY);
        }
    };

    const handleOffsetCarbonFootprint = (e) => {
        e.preventDefault();
        if (!!state.user) {
            history.push(PROJECTS);
        } else {
            dispatch({ type: SHOW_LOGIN_FORM });
        }
    };

    const completeHandler = () => {
        setIsComplete(true);
        calculate && calculate();
    };

    return (
        <StepperContainer alignItems="center">
            <StepsWrapper>
                {data.map((category, index) => {
                    const stepIndex = index + 1;
                    const { isNextEnabled, isPrevEnabled } =
                        category.questions.reduce(
                            (flags, question) => {
                                if (question.categories) {
                                    flags.isNextEnabled =
                                        question.categories.reduce(
                                            (acc, cat) => {
                                                return !answers[cat.id] &&
                                                    answers[cat.id] !== 0
                                                    ? false
                                                    : acc;
                                            },
                                            flags.isNextEnabled
                                        );
                                } else if (
                                    !answers[question.id] &&
                                    answers[question.id] !== 0
                                ) {
                                    flags.isNextEnabled = false;
                                }
                                return flags;
                            },
                            {
                                isNextEnabled: true,
                                isPrevEnabled: true,
                            }
                        );

                    return (
                        <Step
                            key={category.id}
                            data={category}
                            stepIndex={stepIndex}
                            isLastStep={data.length === stepIndex}
                            isOpen={currentStep === stepIndex}
                            onAnswerSelect={onAnswerSelect}
                            isNextEnabled={isNextEnabled}
                            isPrevEnabled={isPrevEnabled}
                            updateCurrStep={updateCurrStep}
                            calculate={completeHandler}
                            carbonFootprintAnswer={answers}
                        />
                    );
                })}
            </StepsWrapper>
            <PreviewWrapper id="carbon-calculator-results">
                <PreviewCard>
                    <StepPreviewTop
                        answers={answers}
                        isComplete={isComplete}
                        total={calculateTotal()}
                    />
                    <StepPreviewBottom
                        // progress={getProgress}
                        total={calculateTotal()}
                    />
                </PreviewCard>
                {carbonDioxideLevel ? (
                    <Flex.Container>
                        <Flex.Item
                            width="100%"
                            mobileWidth="100%"
                            tabletWidth="100%"
                            style={{
                                boxSizing: "border-box",
                                paddingTop: "10px",
                                paddingRight: "10px",
                            }}
                        >
                            <ActionButton
                                size="s"
                                type="solid"
                                color="white"
                                backgroundColor="primary"
                                // onClick={handleCarbon}
                                onClick={handleOffsetCarbonFootprint}
                                isLoading={loading}
                                disabled={loading}
                            >
                                Offset Carbon Footprint
                            </ActionButton>
                        </Flex.Item>
                    </Flex.Container>
                ) : null}
            </PreviewWrapper>
        </StepperContainer>
    );
};
