import React from "react";
import { Route, Switch } from "react-router-dom";
import Adhaar from "./Adhaar";
import BankAccount from "./BankAccount";
import PanCard from "./PanCard";
import Stepper from "./Stepper";

const StepView = ({
    type,
    handleNext,
    handleSubmit,
    path,
    handleType,
    Pan,
    Aadhar,
    checkStatus,
    formData,
    handeleStart,
    Bank,
}) => {
    return (
        <div className="h-full w-full lg:pt-5">
            <Stepper
                type={type}
                handleType={handleType}
                handeleStart={handeleStart}
                Pan={Pan}
                Aadhar={Aadhar}
            />
            <Switch>
                <Route
                    path={`${path}/pan`}
                    component={() => (
                        <PanCard
                            handleNext={handleNext}
                            Pan={Pan}
                            form={formData}
                        />
                    )}
                />
                <Route
                    path={`${path}/aadhar`}
                    component={() => (
                        <Adhaar
                            handleNext={handleNext}
                            Aadhar={Aadhar}
                            form={formData}
                        />
                    )}
                />
                <Route
                    path={`${path}/bank`}
                    component={() => (
                        <BankAccount
                            handleSubmit={handleSubmit}
                            Bank={Bank}
                            form={formData}
                            checkStatus={checkStatus}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default StepView;
