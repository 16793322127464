import React from "react";

const PillSlider = ({
    currentSlide,
    data,
    className,
    handleSlideChange,
    length,
    width = "w-5",
}) => {
    return (
        <div className={`flex items-center justify-center  ${className}`}>
            <div>
                <div className="h-1.4 bg-transparent border border-gray-400 rounded-full">
                    <div className="flex   w-full ">
                        {Array.from({ length: data?.length | length }).map(
                            (_, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleSlideChange(index)}
                                    className={`${width} h-1 border-l cursor-pointer border-l-gray-400 rounded-xl  ${
                                        currentSlide === index
                                            ? "bg-linear-blue-white"
                                            : "bg-transparent "
                                    }
                                ${
                                    currentSlide === index - 1 || index === 0
                                        ? "border-none"
                                        : "border-l border-l-gray-400"
                                }
                                  transition duration-200`}
                                ></div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PillSlider;
