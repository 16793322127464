import React, { useRef, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { MdDeleteForever, MdKeyboardArrowLeft } from "react-icons/md";
import { upload } from "../../../../../resources/js/icons";
import H4 from "../../../../../shared/typography/H4";
import P from "../../../../../shared/typography/P";
import SubHeading from "../../../../../shared/typography/SubHeading";
import Tag from "../../../../../shared/ui/tag/Tag";

const RaiseTicket = ({ onSubmit, onClose }) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };
    const removeFile = () => {
        setFile(null);
    };
    const [description, setDescription] = useState("");
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedIssue, setSelectedIssue] = useState("");

    const projectOptions = [
        "Tata Communication #4729",
        "Project Alpha",
        "Project Beta",
    ];

    const issueOptions = [
        "Payout issues",
        "Technical issues",
        "General inquiries",
    ];
    const handleNewRaiseTicket = () => {
        const ticketData = {
            project: selectedProject,
            issue: selectedIssue,
            description: description,
            file: file,
        };
        onSubmit(ticketData);
    };
    return (
        <div>
            {!selectedProject ? (
                <div>
                    <div className="flex items-center gap-1 mb-1">
                        <P
                            className="text-gray-550 text-lg "
                            onClick={() => onClose()}
                        >
                            <MdKeyboardArrowLeft />
                        </P>
                        <H4 className="font-medium text-base ">Raise ticket</H4>
                    </div>
                    <SubHeading className="font-semibold  mb-1">
                        Choose a Project
                    </SubHeading>
                    {projectOptions.map((project, index) => (
                        <div
                            key={index}
                            className={`flex justify-between items-center py-2 px-1 border-b border-gray-400  cursor-pointer   
                           `}
                            onClick={() => setSelectedProject(project)}
                        >
                            <P>{project}</P>
                            <P className="text-gray-600">
                                <IoIosArrowForward />
                            </P>
                        </div>
                    ))}
                </div>
            ) : !selectedIssue ? (
                <div>
                    <div className="flex items-center gap-1 mb-1">
                        <P
                            className="text-gray-550 text-lg "
                            onClick={() => setSelectedProject("")}
                        >
                            <MdKeyboardArrowLeft />
                        </P>
                        <H4 className="font-medium text-base ">Raise ticket</H4>
                    </div>
                    <SubHeading className="font-semibold  mb-1">
                        Choose an Issue
                    </SubHeading>
                    {issueOptions.map((issue, index) => (
                        <div
                            key={index}
                            className={`flex justify-between items-center px-1 py-2  border-b border-gray-400  cursor-pointer   
                              `}
                            onClick={() => setSelectedIssue(issue)}
                        >
                            <P>{issue}</P>
                            <P className="text-gray-600">
                                <IoIosArrowForward />
                            </P>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div className="flex items-center gap-1 mb-1">
                        <P
                            className="text-gray-550 text-lg "
                            onClick={() => setSelectedIssue("")}
                        >
                            <MdKeyboardArrowLeft />
                        </P>
                        <H4 className="font-medium text-base ">Raise ticket</H4>
                    </div>
                    <Tag
                        text={selectedProject}
                        className={"mb-1 rounded-full"}
                    />
                    <Tag text={selectedIssue} className={"mb-1 rounded-full"} />

                    <div className="mb-3">
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Write a brief description of your query"
                            className="w-full text-sm border border-gray-400 text-gray-600 !placeholder-gray-600 rounded-lg p-1 "
                            rows="6"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="fileUpload">
                            <P className="mb-1">
                                Attach Supporting documents (if any)
                            </P>
                        </label>
                        {!file ? (
                            <div
                                role="button"
                                className="border border-gray-400 text-gray-600 rounded-lg p-3 w-full text-center flex items-center justify-center cursor-pointer"
                                onClick={handleFileUploadClick}
                            >
                                <P className="flex font-normal flex-col items-center gap-1">
                                    <img src={upload} className="w-3" />
                                    <p>
                                        Drag and drop file or{" "}
                                        <span className="underline">
                                            Choose file
                                        </span>
                                    </p>
                                </P>
                            </div>
                        ) : (
                            <div className="flex justify-between items-center border border-blue-400 rounded-lg p-1">
                                <P className="text-secondary">{file.name}</P>
                                <button onClick={removeFile}>
                                    <P className=" text-lg text-red">
                                        <MdDeleteForever />
                                    </P>
                                </button>
                            </div>
                        )}
                        {/* Hidden File Input */}
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            className="hidden"
                        />
                    </div>
                    <div
                        onClick={handleNewRaiseTicket}
                        className=" bg-white text-black text-sm border text-center w-full border-blue-700 font-normal py-1 px-3 rounded-full "
                    >
                        Raise new Ticket
                    </div>
                </>
            )}
        </div>
    );
};

export default RaiseTicket;
