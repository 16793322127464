import React from "react";
import styled from "styled-components";

const Container = styled.div`
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "6px"};
    border-radius: 4px;
    background: ${(props) =>
        props.theme.color[props.bgColor] || props.theme.color.lightGrey};
`;

const Progress = styled.div`
    transition: all 1s;
    width: ${(props) => `${props.progress}%` || "0%"};
    height: ${(props) => props.height || "6px"};
    border-radius: 4px;
    background: ${(props) =>
        props.theme.color[props.color] || props.theme.color.lightPrimary};
`;

const ProgressBar = ({ bgColor, color, progress }) => {
    return (
        <Container bgColor={bgColor}>
            <Progress progress={progress} color={color} />
        </Container>
    );
};

export default ProgressBar;
