import React from "react";
import { impactBg } from "../../resources/js/images";
import Hero from "../contact-us/Hero";
import ImpactContent from "./ImpactContent";

const ImpactHero = () => {
    return (
        <div>
            <Hero
                containerClass={""}
                wrapperClass="bg-custom-gradient-1"
                content={<ImpactContent />}
                url={impactBg}
            />
        </div>
    );
};

export default ImpactHero;
