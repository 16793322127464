import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import cn from "../../../lib/cn";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import SubHeading from "../../../shared/typography/SubHeading";
import Ledger from "./portfolio-details/ledger";
import PayoutHead from "./portfolio-details/payouts/PayoutHead";
import PayoutsInvestment from "./portfolio-details/payouts/PayoutsInvestment";

import useHeaderScroll from "../../../hooks/useHeaderScroll";
import LedgerDash from "../desktop/LeftSide/LedgerDash";
import PayoutGraph from "./portfolio-details/payouts/PayoutGraph";
import Transaction from "./portfolio-details/transactions";
import StreamlineTree from "./StreamlineTree";

let HEADER_HEIGHT = 60;
let TAB_HEIGHT = 79;

let getTransactionValues = (portfolioValue, investedCapital) => {
    if (portfolioValue && investedCapital) {
        return [
            { label: "Invested Capital", amount: investedCapital },
            { label: "Received Payout", amount: portfolioValue },
        ];
    } else
        return [
            { label: "Invested Capital", amount: 0 },
            { label: "Received Payout", amount: 0 },
        ];
};

const getTotalTransaction = (transaction) => {
    if (!transaction) return 0;
    else {
        return Object.keys(transaction).reduce(
            (prev, el) => transaction[el].length + prev,
            0
        );
    }
};

const LinedTabs = ({ data, activeTab, onClick }) => {
    return (
        <div className="flex  bg-black-600 lg:bg-white  justify-between lg:justify-start lg:gap-2 items-center  lg:mt-1 py-2.4 lg:py-0  transition-all ease-in-out duration-500  lg:border-b lg:border-blue-50">
            {data.map((tab, i) => (
                <div
                    key={tab}
                    onClick={() => onClick(i)}
                    className={`flex items-center hover:cursor-pointer border-b-2 gap-1 pb-2 mt-1   ${
                        activeTab === i
                            ? "text-blue-500  border-b-blue-500"
                            : "text-gray-500 border-b-transparent"
                    }`}
                >
                    <P
                        className={` md:text-sm  xl:text-sm font-vietnam ${
                            activeTab === i
                                ? "text-blue-500   "
                                : "text-gray-500"
                        }`}
                    >
                        {tab}
                    </P>
                </div>
            ))}
        </div>
    );
};

const Portfolio = ({
    handleActiveTab,
    activeTab,
    investmentData,
    transactionData,
    onClick,
}) => {
    const onClickTab = useCallback(
        (index) => {
            handleActiveTab(index);
        },
        [handleActiveTab]
    );

    const ref = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1080 && activeTab === 2) {
                onClickTab(0);
            }
        };
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [activeTab, onClickTab]);

    const [isScrollDisabled, setScrollDisabled] = useState(true);
    const { visible } = useHeaderScroll();

    const tabAndHeaderHeight = useMemo(
        () => (visible ? HEADER_HEIGHT + TAB_HEIGHT : TAB_HEIGHT),
        [visible]
    );

    useEffect(() => {
        const onIntersection = (entries, observer) => {
            if (entries[0].isIntersecting) {
                setScrollDisabled(false);
            } else {
                setScrollDisabled(true);
            }
        };
        const observer = new IntersectionObserver(onIntersection, {
            rootMargin: `0px 0px -${
                window.innerHeight - tabAndHeaderHeight
            }px 0px`,
        });
        observer.observe(document.getElementById("second-container"));
        return () => {
            observer.disconnect();
        };
    }, [tabAndHeaderHeight]);

    const renderInvestmentData = (data) => {
        return (
            <>
                <div className="lg:hidden">
                    <div className="flex  item-center justify-between  ">
                        <div className="flex flex-col items-center gap-1.6  bg-black-600 p-1.6 rounded-t-2">
                            <H4 className="text-gray-500 font-vietnam text-sm ">
                                Portfolio Value
                            </H4>
                            <H4 className="text-white font-semibold font-vietnam text-sm">
                                ₹ {data?.portfolioValue}
                            </H4>
                        </div>
                        <div className="flex flex-col items-center gap-1.6  bg-black-600 p-1.6 rounded-t-2">
                            <H4 className="text-gray-500 text-sm font-vietnam">
                                Invested Capital
                            </H4>
                            <H4 className="text-white font-semibold text-sm font-vietnam">
                                ₹ {data?.investedCapital}
                            </H4>
                        </div>
                    </div>
                    <StreamlineTree className="my-1.6" />

                    {/* @TODO => fix progress @habib610 Wed December 04,2024 */}
                    <div className="w-full h-[28px] bg-white rounded-3xl relative mb-1.6">
                        <div
                            className={cn(
                                ` h-full rounded-3xl bg-linear-blue-white`
                            )}
                            style={{
                                width: `${
                                    (data?.distributedPayout * 100) /
                                    data?.totalPayout
                                }%`,
                            }}
                        ></div>
                    </div>
                    <div className="flex flex-col gap-2 mb-1">
                        <div className="flex items-center ">
                            <div className="w-2 h-2  bg-white rounded-0.7 mr-1"></div>
                            <H4 className="text-gray-500 font-medium font-vietnam">
                                Total Payout
                            </H4>
                            <H4 className="text-gray-500 ml-auto">
                                ₹ {data?.totalPayout}
                            </H4>
                        </div>
                        <div className="flex items-center">
                            <div className="w-2 h-2 bg-blue rounded-0.7 mr-1"></div>
                            <H4 className="text-gray-500 font-medium  font-vietnam">
                                Distributed Payout
                            </H4>
                            <H4 className="text-gray-500 ml-auto font-vietnam">
                                ₹ {data?.distributedPayout}
                            </H4>
                        </div>
                    </div>
                    {/* @TODO => fix upcoming payout @habib610 Mon December 09,2024 */}
                    {data?.upcomingPayout?.length && (
                        <div className="flex items-center justify-between gap-1.6   bg-black-600 p-1.6 rounded-2">
                            <div className="flex flex-col gap-0.5">
                                <SubHeading className="text-gray-500 font-vietnam">
                                    Upcoming Payout
                                </SubHeading>
                                <H4 className="text-white font-vietnam ">
                                    04/10/24
                                </H4>
                            </div>

                            <H4 className="text-white font-semibold font-vietnam">
                                ₹ 122351
                            </H4>
                        </div>
                    )}
                </div>
                <div className="hidden lg:block">
                    <LedgerDash onClick={onClickTab} data={data} />
                </div>
            </>
        );
    };

    const transactions = transactionData?.data?.transactions;
    let portfolio = investmentData?.data?.portfolio;

    return (
        <div className="bg-black-600 lg:bg-white">
            <Container
                className={cn(
                    "sticky z-100 transition-all ease-in-out duration-500"
                )}
                style={{
                    top: `${visible ? HEADER_HEIGHT - 1 : -1}px`,
                }}
            >
                <div ref={ref}>
                    <div className="lg:hidden">
                        <LinedTabs
                            activeTab={activeTab}
                            data={["Ledger", "Payouts", "Transactions"]}
                            onClick={onClickTab}
                        />
                    </div>

                    <div className="hidden lg:block">
                        <LinedTabs
                            activeTab={activeTab}
                            data={["Ledger", "Payouts"]}
                            onClick={onClickTab}
                        />
                    </div>
                </div>
            </Container>
            <Section className=" mb-2 bg-black-600 lg:bg-white py-1 lg:py-0 lg:mb-0 xl:mb-0">
                <Container>
                    <div className="bg-blue-600 lg:bg-white rounded-2  py-2.4 lg:py-1.4 px-1.8">
                        {activeTab === 0 ? (
                            <>
                                {investmentData && (
                                    <>{renderInvestmentData(portfolio)}</>
                                )}
                            </>
                        ) : activeTab === 1 ? (
                            <>
                                <PayoutHead />
                                <PayoutGraph />
                            </>
                        ) : (
                            <>
                                <div className="flex items-center gap-1 justify-between lg:hidden">
                                    <div className="flex flex-col items-center gap-1.6  bg-black-600 p-1.6 rounded-t-2">
                                        <H4 className="text-gray-500 font-vietnam text-sm ">
                                            Portfolio Value
                                        </H4>
                                        <H4 className="text-white font-semibold font-vietnam text-sm">
                                            ₹ {portfolio?.portfolioValue}
                                        </H4>
                                    </div>
                                    <div className="flex flex-col items-center gap-1.6  bg-black-600 p-1.6 rounded-t-2">
                                        <H4 className="text-gray-500 text-sm font-vietnam">
                                            Invested Capital
                                        </H4>
                                        <H4 className="text-white font-semibold text-sm font-vietnam">
                                            ₹{portfolio?.investedCapital}
                                        </H4>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Container>
            </Section>
            <div
                id="second-container"
                style={{
                    height: `calc(100vh - ${tabAndHeaderHeight}px)`,
                    overflow: "hidden",
                    top: tabAndHeaderHeight,
                    zIndex: 100,
                }}
                className="bg-white rounded-t-[4rem] lg:!h-full lg:!top-0  sticky transition-all ease-in-out duration-500"
            >
                <div className="hidden lg:block">
                    {activeTab === 0 ? (
                        <Ledger
                            isScrollDisabled={false}
                            investments={
                                investmentData?.data?.portfolio?.investments ||
                                []
                            }
                        />
                    ) : (
                        <PayoutsInvestment isScrollDisabled={false} />
                    )}
                </div>

                <div className=" lg:hidden">
                    {activeTab === 0 ? (
                        <Ledger
                            isScrollDisabled={isScrollDisabled}
                            investments={
                                investmentData?.data?.portfolio?.investments ||
                                []
                            }
                        />
                    ) : activeTab === 1 ? (
                        <PayoutsInvestment
                            isScrollDisabled={isScrollDisabled}
                        />
                    ) : (
                        <Transaction
                            isScrollDisabled={isScrollDisabled}
                            onClick={onClick}
                            transactions={transactions}
                            investedCapital={
                                investmentData?.data?.portfolio?.investedCapital
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;

export const DrawerContainer = ({ children }) => {
    return (
        <div
            className={cn(
                `w-full   bg-white rounded-t-2 pb-6 overflow-y-auto  h-[calc(100vh-105px)]`
            )}
        >
            <div className="mt-2 px-3 ">{children}</div>
        </div>
    );
};
