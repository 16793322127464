import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { greenSlate } from "../assets";

import Button from "../components/button/Button";
import Card from "../components/card/Card";
import Flex from "../components/flex/Flex";
import Line from "../components/line/Line";
import WarningMessage from "../components/message/WarningMessage";
import Section from "../components/section/Section";
import Text from "../components/text/Text";
import { useContextState } from "../context/ContextProvider";
import { getNameAvatar } from "../helpers/helpers";
import auth from "../services/auth";
import BankDetails from "./BankDetails";
import ChangePassword from "./ChangePassword";

import { useLocation } from "react-router-dom";
import Space from "../components/Spacing/Space";
import Container from "../components/styles/Container.styles";
import { PROFILE } from "../constants/routes";
import KYCVerification from "./KYCVerification";
import PersonalDetails from "./PersonalDetails";
import ProfileMobile from "./ProfileMobile";
import ProfileSidebar from "./ProfileSidebar";
const CardStyled = styled(Card)`
    display: flex;
    min-height: 80vh;
    width: 100%;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        min-height: auto;
    }
`;
const MobileNone = styled(Flex.Item)`
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: none;
    }
`;
const MobileHome = styled.div`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: block;
    }
`;
const ReturnButton = styled(Button)`
    border: none;
    box-shadow: none;
    margin-bottom: 20px;
    padding: 0;
`;
const FlexWrapper = styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Profile = () => {
    const history = useHistory();
    const location = useLocation();
    const { state, dispatch } = useContextState();

    useEffect(() => {
        auth.getUserProfile(dispatch);
    }, [dispatch, history.location.pathname]);
    const isReturnButton = location.pathname !== PROFILE;

    return (
        <Section height="auto">
            <Container>
                <Space xxsm={["110px 0 60px"]} stm={["120px 0 80px"]}>
                    <div>
                        {isReturnButton && (
                            <MobileHome>
                                <Space
                                    xxsm={[
                                        `${
                                            state?.user?.isEmailConfirmed
                                                ? "1rem 0 2rem"
                                                : "1rem 0 2rem"
                                        }`,
                                    ]}
                                    xsm={[
                                        `${
                                            state?.user?.isEmailConfirmed
                                                ? "1rem 0 2rem"
                                                : "3rem 0 2rem"
                                        }`,
                                    ]}
                                    stm={[
                                        `${
                                            state?.user?.isEmailConfirmed
                                                ? "1rem 0 2rem "
                                                : "4rem 0 2rem"
                                        }`,
                                    ]}
                                >
                                    <ReturnButton
                                        onClick={() => history.push("/profile")}
                                        color="black"
                                        backgroundColor="transparent"
                                    >
                                        <Text size="m">
                                            <FontAwesomeIcon
                                                style={{ marginRight: "5px" }}
                                                icon={faArrowLeft}
                                            />{" "}
                                            Back to Profile
                                        </Text>
                                    </ReturnButton>
                                </Space>
                            </MobileHome>
                        )}
                        <FlexWrapper>
                            <CardStyled
                                borderRadius="5px"
                                shadow="rgb(0 0 0 / 25%) 0px 0px 5px 1px"
                                backgroundColor="pureWhite"
                            >
                                <MobileNone width="20%">
                                    <ProfileSidebar />
                                </MobileNone>
                                <Flex.Item
                                    width="80%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                >
                                    <Space
                                        xlp={["50px 50px"]}
                                        stp={["5rem 3rem"]}
                                        xxsp={["5%"]}
                                    >
                                        <div>
                                            <Switch>
                                                <Route exact path="/profile/">
                                                    <MaxTabletNone>
                                                        <ProfileBanner />
                                                    </MaxTabletNone>

                                                    <MobileHome>
                                                        <ProfileMobile />
                                                    </MobileHome>
                                                </Route>
                                                <Route
                                                    exact
                                                    path="/profile/user"
                                                >
                                                    <PersonalDetails title="Personal Details" />
                                                </Route>
                                                <Route path="/profile/bankDetails">
                                                    <BankDetails title="Bank Details" />
                                                </Route>
                                                <Route path="/profile/kyc">
                                                    <KYCVerification title="KYC Verification" />
                                                </Route>
                                                {/* <Route path="/profile/documents">
                                        <Documents title="Documents" />
                                    </Route> */}
                                                <Route path="/profile/changePassword">
                                                    <ChangePassword title="Change Password" />
                                                </Route>
                                            </Switch>
                                        </div>
                                    </Space>
                                </Flex.Item>
                            </CardStyled>
                        </FlexWrapper>
                    </div>
                </Space>

                <WarningMessage />
            </Container>
        </Section>
    );
};

export default Profile;

const BannerWrapper = styled.div`
    background-image: url(${(props) => props.bg});
    width: 100%;
    height: 20vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    margin-bottom: 20px;
    padding: 5%;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
`;

const BannerAvatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid ${(props) => props.theme.color.pureWhite};
    border-radius: 100%;
    width: 80px;
    height: 80px;
    margin-right: 50px;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        margin-right: 5px;
        border: 3px solid ${(props) => props.theme.color.pureWhite};
        width: 60px;
        height: 60px;
    }
    @media (max-width: 360px) {
        margin-right: 5px;

        width: 50px;
        height: 50px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        margin-right: 10px;
    }
`;

const ProfileBanner = () => {
    const { state } = useContextState();
    const getGreeting = () => {
        const time = new Date().getHours();
        if (time >= 5 && time < 13) {
            return "Good Morning!";
        } else if (time >= 13 && time < 17) {
            return "Good Afternoon!";
        } else if (time >= 17 && time < 19) {
            return "Good Evening!";
        } else {
            return "Good Night!";
        }
    };
    return (
        <>
            <BannerWrapper bg={greenSlate}>
                <Flex.Container style={{ width: "100%" }} alignItems="center">
                    <Flex.Item>
                        <BannerAvatar>
                            <Text
                                size="xl"
                                color="pureWhite"
                                textTransform="uppercase"
                            >
                                {getNameAvatar(state?.user?.name)}
                            </Text>
                        </BannerAvatar>
                    </Flex.Item>
                    <Flex.Item>
                        <Text color="white" size="l">
                            {getGreeting()}
                        </Text>
                        <Text white="l" color="white">
                            {state?.user?.name}
                        </Text>
                    </Flex.Item>
                </Flex.Container>
            </BannerWrapper>
        </>
    );
};
const MaxTabletNone = styled.div`
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: none;
    }
`;
export const ProfileTitle = ({ title }) => {
    return (
        <MaxTabletNone>
            <Space xxsm={["0px 0px 30px 0px"]}>
                <Flex.Container alignItems="center">
                    <Text weight="700" size="xl" color="darkSecondary">
                        {title}
                    </Text>
                    <Line
                        height="1px"
                        flex="1"
                        width="100%"
                        backgroundColor="lightGrey"
                        margin="0px 0px 0px 3%"
                    />
                </Flex.Container>
            </Space>
        </MaxTabletNone>
    );
};

// remove nominee and nationality and date of birth and 2FA
// show Message in singup and login
// Kyc hello name removed
// Remove the NOTE from the up
// Remove the change acccount button
// remove document button
// update button remove from profile
//isVarified:  false : msg: not verified
// timer date: 2nd April
