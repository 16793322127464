import React from "react";
import cn from "../../lib/cn";
import Counter from "../../shared/animation/typography/Counter";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
import LinkButton from "../../shared/ui/LinkButton";

const projects = [
    { value: "120", unit: "", name: "Carbon warriors" },
    { value: "24", unit: "", name: "Active investments" },
    { value: "20", unit: "", name: "Projects executed" },
    { value: "4800", unit: "Tons", name: "CO2 reduced yearly" },
];
const OurImpact = () => {
    return (
        <Section className={"py-5 mb-0 lg:mb-0 xl:mb-0 2xl:mb-0"}>
            <Container className="my-5">
                <div>
                    <div className="flex justify-between items-center mb-2 sm:mb-3 xl:mb-5">
                        <H2 className="!font-normal !leading-none">
                            Our Impact
                        </H2>
                        <LinkButton className="text-lg">
                            See all articles
                        </LinkButton>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-4 mb-6 gap-2 gap-y-4">
                        {projects.map((item, i) => (
                            <div
                                key={i}
                                className={cn(
                                    `flex border-r border-blue-450 xl:border-none ${
                                        i % 2 === 1 && "border-r-0"
                                    } md:border-r`,
                                    {
                                        "xl:justify-end md:border-r-1": i === 3,
                                    }
                                )}
                            >
                                <div className="xl:mr-8">
                                    <H2 className="font-nunito  !font-normal text-gray">
                                        <Counter value={item.value} />+
                                        {item.unit && (
                                            <span className=" ml-2">
                                                {item.unit}
                                            </span>
                                        )}{" "}
                                    </H2>
                                    <P>{item.name}</P>
                                </div>

                                <div
                                    className={cn(
                                        "h-full w-px mr-2 bg-blue-450 rounded-full hidden xl:block",
                                        {
                                            "xl:hidden": i === 3,
                                        }
                                    )}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default OurImpact;
