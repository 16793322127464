import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { OfferBg } from "../../../resources/js/images";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import PillSlider from "../../../shared/ui/Slider/PillSlider";
const OfferCard = () => {
    return (
        <div className="w-full  ">
            <div
                className="bg-cover bg-center h-80 rounded-3xl "
                style={{
                    backgroundImage: `url(${OfferBg})`,
                }}
            >
                <div className=" h-full flex flex-col  items-start w-2/3 px-1 py-2 ">
                    <P>Almost there!</P>
                    <H4 className={"text-green-500 text-left font-semibold"}>
                        Enjoy Instant Profit Investment
                    </H4>
                    <Button className="bg-green-500 my-1 text-white !text-xs !rounded-[3rem]">
                        Invest Now
                    </Button>
                    <span className="text-xs text-nowrap  text-gray-550">
                        *All Terms and conditions are applied
                    </span>
                </div>
            </div>
        </div>
    );
};
const OfferCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const data = [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3,
        },
        {
            id: 4,
        },
    ];
    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };
    return (
        <div className="px-2 py-0.5">
            <CarouselComponent
                selectedItem={currentSlide}
                onChange={setCurrentSlide}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                autoPlay={true}
                infiniteLoop={true}
                interval={3000}
                stopOnHover={true}
                transitionTime={500}
                swipeable={true}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                emulateTouch={true}
            >
                {data.map((item, index) => (
                    <div key={index} className="mx-1">
                        <OfferCard />
                    </div>
                ))}
            </CarouselComponent>
            <PillSlider
                currentSlide={currentSlide}
                className={"my-1"}
                data={data}
                handleSlideChange={handleSlideChange}
            />
        </div>
    );
};

export default OfferCarousel;
