import React from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { LOGIN, SIGNUP } from "../../constants/routes";
import { google } from "../../resources/js/icons";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Line from "../../shared/ui/Line";
import AuthWrapper from "./AuthWrapper";

/* @DESC:: Unused component @habib610 Fri November 29,2024 */
/* @TODO => Remove if not been used @habib610 Fri November 29,2024 */
const OnboardingPage = () => {
    const history = useHistory();
    return (
        <AuthWrapper>
            <div className="flex justify-center items-center h-full w-full">
                <div className="bg-white pt-3 md:p-1 box-border lg:p-3  flex flex-col gap-2 rounded-xl  w-full ">
                    <div className="flex flex-col gap-2">
                        <P className=" text-2xl md:text-4.0xl xl:text-4.0xl xl:font-light">
                            <IoIosArrowDropleft />
                        </P>
                        <H4 className="font-semibold">Create your account</H4>
                        <div
                            role="button"
                            onClick={() => history.push(`${SIGNUP}/email`)}
                            className="rounded-lg border flex text-center items-center justify-center border-blue-50 border-1.3 px-3 py-2"
                        >
                            <P>Signup with Phone/ Email</P>
                        </div>
                        <div className="flex gap-1 items-center">
                            <Line className={"w-1/3 bg-gray-800"} />
                            <P className="text-nowrap">or continue with</P>
                            <Line className={"w-1/3  bg-gray-800"} />
                        </div>
                        <div className="flex justify-center items-center my-3 gap-3">
                            {/* <div className="flex px-3 py-1 justify-center items-center border border-gray-light rounded-2xl">
                    <img src={facebook} alt="facebook" />
                </div> */}
                            <div className="flex px-3 py-1 justify-center items-center border border-gray-light rounded-2xl">
                                <img src={google} alt="google" />
                            </div>
                            {/* <div className="flex px-3 py-1 justify-center items-center border border-gray-light rounded-2xl">
                    <img src={apple} alt="apple" />
                </div> */}
                        </div>

                        <div
                            onClick={() => history.push(LOGIN)}
                            role="button"
                            className="rounded-[3rem] border flex text-center items-center justify-center border-black px-3 py-1"
                        >
                            <P>Already have account? Sign in</P>
                        </div>
                    </div>
                </div>
            </div>
        </AuthWrapper>
    );
};

export default OnboardingPage;
