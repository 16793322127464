import React from "react";
import { AboutUsBg } from "../../resources/js/images";

import Hero from "./Hero";
import HeroDetails from "./HeroDetails";
import { MapContactUs } from "./MapContact";

const ContactUS = () => {
    return (
        <div>
            <Hero
                url={AboutUsBg}
                content={<HeroDetails />}
                containerClass={"bg-custom-gradient-1"}
                wrapperClass={
                    "min-h-[200vh] sm:min-h-[110vh]   md:max-h-[100vh]  xl:min-h-[100vh] "
                }
            />

            <MapContactUs />
        </div>
    );
};

export default ContactUS;
