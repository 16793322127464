import styled from "styled-components";

const Section = styled.div`
    width: 100%;
    height: ${(props) => props.height || "100vh"};
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    position: relative;
    box-sizing: border-box;
    min-height: ${(props) => (props.height === "auto" ? "" : "600px")};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        min-height: ${(props) => (props.height === "auto" ? "" : "620px")};
    }
`;

export default Section;
