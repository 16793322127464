import React from "react";
import cn from "../../lib/cn";
import { indianCarbonEmission } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
const emissionDetails = [
    {
        value: "0.56 tons",
        name: "Average Individual Emissions",
        bg: "bg-primary",
    },
    {
        value: "1.98 tons",
        name: "Average Household Emissions",
        bg: "bg-secondary",
    },
    {
        value: "0.56 tons",
        name: "Annual Indian Emissions",
        bg: "bg-blue-400",
    },
];

const EmissionDetails = () => {
    return (
        <Section className="">
            <Container>
                <div className=" p-2 xl:p-7 bg-gray-50 border border-y-gray-400 rounded">
                    <H2 className="xl:mb-5 mb-2 xl:mt-4">
                        Did you know the average Indian carbon emissions?
                    </H2>
                    <div className="flex justify-between items-center flex-wrap gap-y-4">
                        <div className="w-full md:w-5/12 xl:w-5/12 2xl:w-5/12">
                            {/* @TODO => delete img files as well once verified @habib610 Wed October 23,2024 */}
                            <img
                                src={indianCarbonEmission}
                                alt="indian_carbon-emission-details"
                            />
                        </div>

                        <div className="w-full md:w-5/12 xl:w-6/12 2xl:w-5/12">
                            {emissionDetails.map((item, index) => (
                                <div>
                                    <div className="flex items-center gap-1">
                                        <div
                                            className={cn(
                                                `w-2 h-2 xl:w-3.2 xl:h-3.2 rounded-full`,
                                                item.bg
                                            )}
                                        ></div>
                                        <H1 className="flex-1">{item.value}</H1>
                                    </div>
                                    <P className=" font-nunito font-thin xl:text-3xl text-gray mb-1 md:mb-2 xl:mb-5">
                                        {item.name}
                                    </P>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default EmissionDetails;
