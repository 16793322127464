import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Accordion from "../../components/accordion/Accordion";
import { householdHouseElectricityMultiplier } from "../../constants/questionnaire";
import Button from "../button/Button";
import Circle from "../circle/Circle";
import Flex from "../flex/Flex";
import Input from "../input/Input";
import Radio from "../radio/Radio";
import { P } from "../styles/Typography.styles";
import Text from "../text/Text";

const StepContainer = styled.div`
    flex: 1;
    padding: 0 10px 0 0;
`;

const StepHeading = styled.div``;

const StepAction = styled(Flex.Container)`
    margin-top: 24px;
    button {
        margin: auto 8px;
    }
`;

const StepNum = styled.div`
    width: 34px;
    height: 34px;
    text-align: center;
    background-color: ${(props) => props.theme.color.primary};
    border-radius: 50%;
    /* margin: 2px 12px; */
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StepperHeader = styled.div`
    display: flex;
    align-items: center;
`;

const StepperBody = styled.div`
    margin-left: 14px;
    padding-left: 12px;
    border-left: ${(props) =>
        props.isLastStep ? "" : `${props.theme.color.secondary} solid 2px`};
`;

const StepQuestionWrapper = styled.div``;

const StepQuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0;
`;

const AnswersWrapper = styled.div`
    margin: 0 20px;
    display: flex;
    flex-direction: column;
`;

const AnswerCheckBox = styled.label`
    padding-top: 5px;
`;

const ConditionalRadio = styled(Circle)`
    border: ${(props) => (props.noBorder ? "" : "solid 1px #000")};
    background: ${(props) => (props.shouldShow ? "#62ba46" : "#fff")};
`;

const StepInputType = ({ type, ...props }) => {
    return (
        <AnswerCheckBox>
            {type === "input" ? (
                <Flex.Container direction="row" alignItems="center">
                    <P
                        size={props.size}
                        lg="s"
                        st="s"
                        xxs="s"
                        weight={props.weight}
                        color={props.color}
                        style={{ paddingLeft: "5px" }}
                    >
                        {props.label}
                    </P>
                    <Flex.Item style={{ paddingLeft: "20px" }} width="30%">
                        <Input padding="4px" {...props} />
                    </Flex.Item>
                </Flex.Container>
            ) : type === "checkbox" ? (
                <Radio type={type} {...props} />
            ) : (
                <Radio {...props} />
            )}
        </AnswerCheckBox>
    );
};

const ConditionalAnswer = ({
    children,
    answer,
    onClickHandler,
    show,
    pIndex,
}) => {
    return (
        <>
            <div
                onClick={() => onClickHandler(pIndex)}
                style={{
                    display: "inline-flex",
                    paddingTop: "5px",
                    alignItems: "center",
                }}
            >
                <ConditionalRadio size="12px" shouldShow={show} noBorder>
                    <ConditionalRadio size="8px" shouldShow={show} />
                </ConditionalRadio>
                <P
                    lg="s"
                    st="s"
                    xxs="s"
                    style={{
                        paddingLeft: "5px",
                    }}
                >
                    {answer.text}
                </P>
            </div>
            {show ? children : null}
        </>
    );
};

const StepQuestionAnswers = ({
    questionId,
    answers,
    onChangeHandler,
    categories,
    questionAnswer,
}) => {
    const [showAnswer, setShowAnswer] = useState(null);

    const clickHandler = (e) => {
        e.stopPropagation();
        if (!e.target?.dataset?.isconditionalchild) {
            setShowAnswer(null);
        } else {
            setShowAnswer(
                `${questionId}-${e.target?.dataset?.conditionpindex}`
            );
        }
        let value = e.target.value;
        if (e.target.type === "text") {
            value = value * householdHouseElectricityMultiplier;
        }
        onChangeHandler(
            e.target.id,
            value,
            e.target.type,
            e.target.checked,
            false
        );
    };

    const conditionAnswerClickHandler = (index) => {
        setShowAnswer(`${questionId}-${index}`);
        onChangeHandler(questionId, null, null, null, true);
    };

    return (
        <AnswersWrapper>
            {categories &&
                categories.length &&
                categories.map((category, index) => {
                    return (
                        <div key={index}>
                            <Text weight="600" size="s">
                                {category.text}
                            </Text>
                            <StepQuestionAnswers
                                questionId={category.id}
                                onChangeHandler={onChangeHandler}
                                answers={category.answers}
                            />
                        </div>
                    );
                })}
            {answers &&
                answers.length &&
                answers.map((answer, pIndex) => {
                    return answer.followUpAnswers ? (
                        <div key={pIndex}>
                            <Text
                                weight="600"
                                size="s"
                                style={{ paddingTop: "5px" }}
                            >
                                {answer.text}
                            </Text>
                            <StepQuestionAnswers
                                questionId={questionId}
                                onChangeHandler={onChangeHandler}
                                answers={answer.followUpAnswers}
                            />
                        </div>
                    ) : (
                        <>
                            {!!answer?.conditionalAnswers?.length ? (
                                <ConditionalAnswer
                                    answer={answer}
                                    onClickHandler={conditionAnswerClickHandler}
                                    show={
                                        showAnswer === `${questionId}-${pIndex}`
                                    }
                                    pIndex={pIndex}
                                >
                                    <Flex.Container direction="row">
                                        {answer.conditionalAnswers.map(
                                            (ans, index) => (
                                                <div
                                                    style={{
                                                        paddingLeft: "20px",
                                                    }}
                                                >
                                                    <StepInputType
                                                        key={index}
                                                        id={questionId}
                                                        name={questionId}
                                                        label={ans.text}
                                                        value={ans.coEmission}
                                                        size="s"
                                                        weight="400"
                                                        onChange={clickHandler}
                                                        type={ans.type}
                                                        data-isconditionalchild={
                                                            true
                                                        }
                                                        data-conditionpindex={
                                                            pIndex
                                                        }
                                                        {...(ans.type ===
                                                            "checkbox" ||
                                                        ans.type === "input"
                                                            ? {}
                                                            : {
                                                                  checked:
                                                                      questionAnswer
                                                                          ? +questionAnswer ===
                                                                            +ans.coEmission
                                                                          : ans?.checked,
                                                              })}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </Flex.Container>
                                </ConditionalAnswer>
                            ) : (
                                <StepInputType
                                    key={pIndex}
                                    id={questionId}
                                    name={questionId}
                                    label={answer.text}
                                    value={answer.coEmission}
                                    size="s"
                                    {...(answer.type === "checkbox" ||
                                    answer.type === "input"
                                        ? {}
                                        : {
                                              checked: questionAnswer
                                                  ? +questionAnswer ===
                                                    +answer.coEmission
                                                  : answer?.checked,
                                          })}
                                    weight="400"
                                    onChange={clickHandler}
                                    type={answer.type}
                                    data-isconditionalchild={false}
                                />
                            )}
                        </>
                    );
                })}
        </AnswersWrapper>
    );
};

const StepQuestion = ({ question, onAnswerSelect, questionAnswer }) => {
    const onChangeHandler = useCallback((id, value, type, checked, reset) => {
        onAnswerSelect(id, value, type, checked, reset);
    });
    return (
        <StepQuestionContainer>
            <Text weight="600" size="s">
                {question.text}
            </Text>
            <StepQuestionAnswers
                questionId={question.id}
                onChangeHandler={onChangeHandler}
                answers={question.answers}
                categories={question.categories}
                questionAnswer={questionAnswer}
            />
        </StepQuestionContainer>
    );
};

export const Step = (props) => {
    const {
        stepIndex,
        data,
        isOpen,
        isLastStep,
        onAnswerSelect,
        isNextEnabled,
        isPrevEnabled,
        updateCurrStep,
        calculate,
        carbonFootprintAnswer,
    } = props;

    const nextActionHandler = useCallback(() => {
        updateCurrStep(stepIndex + 1);
    });

    const prevActionHandler = useCallback(() => {
        updateCurrStep(stepIndex - 1);
    });
    return (
        <StepContainer>
            <Accordion
                isControlled
                isOpen={isOpen}
                label={
                    <StepperHeader>
                        <StepNum>
                            <P color="white" weight="b">
                                {stepIndex}
                            </P>
                        </StepNum>
                        <StepHeading>
                            <P style={{ lineHeight: 1 }} weight="b">
                                {data.category}
                            </P>
                        </StepHeading>
                    </StepperHeader>
                }
            >
                <StepperBody isLastStep={isLastStep}>
                    <StepQuestionWrapper>
                        {data.questions.map((question, index) => (
                            <StepQuestion
                                key={index}
                                question={question}
                                onAnswerSelect={onAnswerSelect}
                                questionAnswer={
                                    carbonFootprintAnswer &&
                                    carbonFootprintAnswer[question.id]
                                }
                            />
                        ))}
                        <StepAction>
                            {stepIndex === 1 ? null : (
                                <Button
                                    size="s"
                                    disabled={!isPrevEnabled}
                                    onClick={prevActionHandler}
                                    color="white"
                                    backgroundColor="darkGrey"
                                    type="solid"
                                >
                                    Prev
                                </Button>
                            )}
                            {isLastStep ? (
                                <Button
                                    size="s"
                                    disabled={!isNextEnabled}
                                    onClick={calculate}
                                    type="solid"
                                    color="white"
                                    backgroundColor="primary"
                                >
                                    Invest Now
                                </Button>
                            ) : (
                                <Button
                                    size="s"
                                    disabled={!isNextEnabled}
                                    onClick={nextActionHandler}
                                    type="solid"
                                    color="white"
                                    backgroundColor="primary"
                                >
                                    Next
                                </Button>
                            )}
                        </StepAction>
                    </StepQuestionWrapper>
                </StepperBody>
            </Accordion>
        </StepContainer>
    );
};
