import React from "react";
import { kite } from "../../../../resources/js/images";
import SubHeading from "../../../../shared/typography/SubHeading";

const FriendEarningsCard = () => {
    return (
        <div className="bg-radial-sea border border-blue-300 rounded-3xl p-1 mx-1   my-0 flex gap-2 ">
            <div className="flex flex-col gap-1 px-2 py-1">
                <SubHeading className="font-semibold md:font-normal md:text-sm xl:font-normal xl:!text-base">
                    Real friends earn together.
                </SubHeading>
                <p className="text-gray-600 xl:text-xs">
                    Get 500 points every time a friend becomes a Srot investor.
                </p>
                <a href="#" className=" text-blue-500 underline mt-auto">
                    Click here...
                </a>
            </div>
            <div className="">
                <img
                    src={kite}
                    alt="Paper Plane"
                    className="w-9 h-full rounded-r-3xl"
                />
            </div>
        </div>
    );
};

export default FriendEarningsCard;
