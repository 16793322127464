import React from "react";
import styled from "styled-components";
import { carIcon, cutleryIcon, flashIcon } from "../../assets";

import Flex from "../flex/Flex";
import Image from "../image/Image";
import ProgressBar from "../progress-bar/ProgressBar";
import Space from "../space/Space";
import Text from "../text/Text";
const Wraper = styled.div`
    padding: 20px;
    background-color: ${(props) => props.theme.color.lighterGrey};
`;
const FlexContainer = styled(Flex.Container)`
    margin: ${(props) => props.margin};
`;

const CATEGORY_MAPPER = {
    lifeStyle: {
        lifeStyleExpenses: true,
        lifeStyleFood: true,
    },
    mobility: {
        mobilityDailyCommute: true,
        mobilityAirLongDistance: true,
        mobilityRailwayLongDistance: true,
    },
    household: {
        householdFamilyMembers: true,
        householdHouseElectricity: true,
        householdHouseCookingFoodType: true,
        householdHouseServiceFromGovernment: true,
    },
};

const StepPreviewTop = ({ answers, isComplete, total }) => {
    const calculateCategory = (category) => {
        return Object.keys(CATEGORY_MAPPER[category]).reduce((acc, key) => {
            return !isNaN(Number(answers[key]))
                ? acc + Number(answers[key])
                : acc;
        }, 0);
    };

    const calculateCategoryPercentage = (categoryVal) => {
        return Math.round((categoryVal / total) * 100);
    };

    const lifeStyleCategory = calculateCategory("lifeStyle");
    const mobilityCategory = calculateCategory("mobility");
    const householdCategory = calculateCategory("household");

    const lifeStyleCategoryPercent =
        calculateCategoryPercentage(lifeStyleCategory);
    const mobilityCategoryPercent =
        calculateCategoryPercentage(mobilityCategory);
    const householdCategoryPercent =
        calculateCategoryPercentage(householdCategory);

    const lifeStyleCategoryValue = isComplete
        ? `${lifeStyleCategoryPercent}%`
        : `${lifeStyleCategory.toFixed(2)} tons`;
    const mobilityCategoryValue = isComplete
        ? `${mobilityCategoryPercent}%`
        : `${mobilityCategory.toFixed(2)} tons`;
    const householdCategoryValue = isComplete
        ? `${householdCategoryPercent}%`
        : `${householdCategory.toFixed(2)} tons`;

    return (
        <Wraper>
            <ContentItem
                isComplete={isComplete}
                title="Lifestyle"
                value={lifeStyleCategoryValue}
                margin="0 0 1rem"
                img={cutleryIcon}
                color="orange"
                percentage={lifeStyleCategoryPercent}
            />
            <ContentItem
                isComplete={isComplete}
                title="Mobility"
                value={mobilityCategoryValue}
                margin="0 0 1rem"
                img={carIcon}
                color="lightPrimary"
                percentage={mobilityCategoryPercent}
            />
            <ContentItem
                isComplete={isComplete}
                title="Household"
                value={householdCategoryValue}
                img={flashIcon}
                color="darkSecondary"
                percentage={householdCategoryPercent}
            />
        </Wraper>
    );
};

export default StepPreviewTop;

const ContentItem = ({
    img,
    title,
    value,
    margin = "0",
    color,
    isComplete,
    percentage = 0,
}) => {
    return (
        <FlexContainer
            margin={margin}
            alignItems="center"
            justifyContent="space-between"
        >
            <Flex.Container
                alignItems="center"
                direction="row"
                style={{ flex: 1 }}
            >
                <Space margin={["0 1rem 0 0"]} mobilemargin={["0 .5rem 0 0"]}>
                    <Image width="30px" src={img} alt={title} />
                </Space>
                {isComplete ? (
                    <Flex.Item style={{ flex: 1 }}>
                        <ProgressBar
                            progress={percentage}
                            color={color}
                            height="6px"
                        />
                    </Flex.Item>
                ) : (
                    <Text>{title}</Text>
                )}
            </Flex.Container>

            <Space margin={["0 0 0  1rem"]} mobilemargin={["0 0 0  .5rem"]}>
                <Text>{value}</Text>
            </Space>
        </FlexContainer>
    );
};
