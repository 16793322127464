import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import Flex from "../../../components/flex/Flex";
import Space from "../../../components/space/Space";
import Text from "../../../components/text/Text";
import { ERROR_MSG } from "../../../constants/common";
import { useContextState } from "../../../context/ContextProvider";

const FlexCenter = styled(Flex.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;

    background-size: cover;
    padding: 1rem;
    border-radius: 10px;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 0.5rem;
    }
`;

const VirtualLoginSummary = ({ summaryErr = true, children }) => {
    const { state } = useContextState();

    return (
        <Flex.Container>
            <FlexCenter width="100%" tabletWidth="100%" mobileWidth="100%">
                <Space margin={["20px 0"]}>
                    <Flex.Container alignItems="center">
                        <MessageIcon error={summaryErr}>
                            {summaryErr ? <FaTimes /> : <FaCheck />}
                        </MessageIcon>
                        <Flex.Item style={{ flex: "1" }}>
                            {summaryErr ? (
                                <Text
                                    align="left"
                                    size="m"
                                    weight="700"
                                    color="white"
                                >
                                    {ERROR_MSG}
                                </Text>
                            ) : (
                                <>{children}</>
                            )}
                        </Flex.Item>
                    </Flex.Container>
                </Space>
            </FlexCenter>
        </Flex.Container>
    );
};

export default VirtualLoginSummary;

const Title = styled(Text)`
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.mobile.m};
        line-height: 20px;
        text-align: center;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) => props.theme.fontSize.smallMobile.s};
    }
`;

const MessageIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    padding: 10px;
    color: ${(props) =>
        props.error ? props.theme.color.error : props.theme.color.lightPrimary};
    border-radius: 100px;
    border: 5px solid
        ${(props) =>
            props.error
                ? props.theme.color.error
                : props.theme.color.lightPrimary};
    margin-right: 10px;
    font-size: 5rem;

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 80px;
        height: 80px;
        font-size: 4rem;
    }

    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: 60px;
        height: 60px;
        font-size: 3rem;
    }
`;
