import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { setKey } from "react-geocode";
import { GOOGLE_MAP_API_KEY } from "../../constants/routes";
import P from "../typography/P";
setKey(GOOGLE_MAP_API_KEY);
const placesLibrary = ["places"];

const ReusableMap = ({
    center,
    dimensions = "w-[100%] h-[500px]",
    callback,
}) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
        libraries: placesLibrary,
    });

    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(center);

    const handlePlaceChanged = useCallback(async () => {
        setMarkerPosition(center);
    }, [center]);

    useEffect(() => {
        handlePlaceChanged();
    }, [center, handlePlaceChanged]);

    useEffect(() => {
        if (isLoaded) callback && callback();
    }, [isLoaded, callback]);

    const onLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    return (
        <div className="border rounded-md  border-blue-300">
            {isLoaded ? (
                <GoogleMap
                    mapContainerClassName={`${dimensions} rounded-lg`}
                    center={markerPosition}
                    zoom={15}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: true,
                    }}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    <MarkerF draggable position={markerPosition}></MarkerF>
                </GoogleMap>
            ) : (
                <P>Loading...</P>
            )}
        </div>
    );
};

export default ReusableMap;
