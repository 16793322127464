import React from "react";
import H3 from "../../../../shared/typography/H3";

const ListItem = ({ ticket, onClick }) => {
    return (
        <div
            className=" rounded-lg p-2 mb-2 bg-gray-50 "
            onClick={() => onClick()}
        >
            <div className="flex justify-between items-start mb-0.5">
                <div>
                    <H3 className="font-medium text-sm mb-1">{ticket.title}</H3>
                    <p className="text-gray-500 text-xs mb-1">{ticket.date}</p>
                </div>
                <span
                    className={` inline-block px-2 py-0.5  text-sm font-normal rounded-full ${
                        ticket.status === "In progress"
                            ? "bg-orange-100 text-orange"
                            : "bg-green-50 text-green-400"
                    }`}
                >
                    {ticket.status}
                </span>
            </div>

            <p className="text-gray-secondary text-xs">{ticket.description}</p>
        </div>
    );
};

export default ListItem;
