import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";

const ExpandableText = ({ title, content, hasBottomBorder = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            className={`${hasBottomBorder ? "border-b  border-gray-200 " : ""}`}
        >
            <div
                className="flex justify-between items-center py-1 cursor-pointer"
                onClick={toggleOpen}
            >
                <H3
                    className={`text-xs  md:text-sm lg:text-sm xl:text-sm 2xl:text-sm ${
                        isOpen ? "text-blue" : "text-gray-secondary"
                    }`}
                >
                    {title}
                </H3>
                <P className={isOpen ? "text-blue" : "text-gray-550"}>
                    {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </P>
            </div>
            {isOpen && <p className="text-black pb-2 md:text-xs">{content}</p>}
        </div>
    );
};

export default ExpandableText;
