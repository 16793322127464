import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { bgStars, moon, mountain } from "../assets";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Image from "../components/image/Image";
import { ContainerMain } from "../components/styles/Container";
import Text from "../components/text/Text";
import { LANDING } from "../constants/routes";

const Container = styled(ContainerMain)`
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100vh;
    background: url(${(props) => props.bg});
    background-position: center top;
    background-size: contain;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Mountain = styled(Image)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
`;
const Moon = styled(Image)`
    position: absolute;
    top: 20%;
    left: 25%;
    transform: translate(-20%, -25%);
    width: 100px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 80px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 70px;
    }
`;
const ButtonStyled = styled(Button)`
    border-radius: 50px;
    margin-top: 100px;
    padding: 1rem 4rem;
`;
const Status = styled(Text)`
    font-family: "Montserrat", sans-serif;
    font-size: 120px;
    margin-bottom: 10px;

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 100px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 90px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: 80px;
    }
`;
const NotFound = () => {
    const history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: LANDING } };
    return (
        <Container bg={bgStars}>
            <Flex.Container
                style={{
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: 9,
                }}
            >
                <Status align="center" color="white" weight="bold">
                    404
                </Status>
                <Text align="center" size="xl" weight="bold" color="white">
                    It seems you got lost
                </Text>

                <ButtonStyled
                    weight="bold"
                    color="white"
                    textTransform="uppercase"
                    backgroundColor="secondary"
                    type="solid"
                    fontSize="xl"
                    onClick={() => history.push(from)}
                >
                    Go Back
                </ButtonStyled>
            </Flex.Container>
            <Mountain src={mountain} alt="mountain" />
            <Moon src={moon} alt="moon" />
        </Container>
    );
};

export default NotFound;
