import {
    ABOUTUS,
    ABOUTUS_LABEL,
    CONTACTUS,
    CONTACTUS_LABEL,
    IMPACT_LABEL,
    SIGNUP,
    SIGNUP_LABEL,
} from "../../constants/routes";

export const unAuthenticLinks = [
    // { name: ABOUTUS_LABEL, path: ABOUTUS },
    { name: IMPACT_LABEL, path: IMPACT_LABEL },
    { name: CONTACTUS_LABEL, path: CONTACTUS },
    { name: SIGNUP_LABEL, path: SIGNUP },
];

const DROP_DOWN_LINKS = [{ name: CONTACTUS_LABEL, path: CONTACTUS }];

export const profileMenu = [
    {
        name: ABOUTUS_LABEL,
        path: ABOUTUS,
        icon: (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_1_13829)">
                    <path
                        opacity="0.4"
                        d="M10.1014 10.6498C10.0431 10.6414 9.96807 10.6414 9.9014 10.6498C8.43473 10.5998 7.26807 9.39976 7.26807 7.92474C7.26807 6.41641 8.48473 5.19141 10.0014 5.19141C11.5097 5.19141 12.7347 6.41641 12.7347 7.92474C12.7264 9.39976 11.5681 10.5998 10.1014 10.6498Z"
                        stroke="currentColor"
                        strokeWidth="1.40625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        opacity="0.34"
                        d="M15.6157 16.1498C14.1323 17.5081 12.1657 18.3331 9.99902 18.3331C7.83232 18.3331 5.86566 17.5081 4.38232 16.1498C4.46566 15.3665 4.96566 14.5998 5.85732 13.9998C8.14066 12.4831 11.874 12.4831 14.1407 13.9998C15.0324 14.5998 15.5323 15.3665 15.6157 16.1498Z"
                        stroke="currentColor"
                        strokeWidth="1.40625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.0008 18.3317C14.6031 18.3317 18.3341 14.6007 18.3341 9.99837C18.3341 5.396 14.6031 1.66504 10.0008 1.66504C5.39844 1.66504 1.66748 5.396 1.66748 9.99837C1.66748 14.6007 5.39844 18.3317 10.0008 18.3317Z"
                        stroke="currentColor"
                        strokeWidth="1.40625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1_13829">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
    },
    {
        name: CONTACTUS_LABEL,
        path: CONTACTUS,
        icon: (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.1667 10.0013C16.4678 10.0013 18.3333 8.13582 18.3333 5.83464C18.3333 3.53345 16.4678 1.66797 14.1667 1.66797C11.8655 1.66797 10 3.53345 10 5.83464C10 6.50117 10.1565 7.13115 10.4347 7.68984C10.5087 7.8383 10.5333 8.00799 10.4905 8.16823L10.2423 9.09572C10.1346 9.49839 10.5029 9.86672 10.9056 9.75897L11.8331 9.5108C11.9933 9.46797 12.163 9.49255 12.3115 9.56655C12.8702 9.8448 13.5002 10.0013 14.1667 10.0013Z"
                    stroke="currentColor"
                    strokeWidth="1.25"
                />
                <path
                    d="M6.69734 6.09681L7.23818 7.06592C7.72627 7.94048 7.53033 9.08775 6.76161 9.8565C6.76161 9.8565 5.82925 10.789 7.51978 12.4796C9.20977 14.1696 10.1428 13.2378 10.1428 13.2378C10.9116 12.469 12.0588 12.2731 12.9334 12.7612L13.9025 13.302C15.2232 14.039 15.3791 15.891 14.2183 17.0518C13.5208 17.7493 12.6663 18.2921 11.7218 18.3278C10.1317 18.3882 7.43125 17.9858 4.72243 15.2769C2.01363 12.5681 1.61119 9.86767 1.67148 8.27758C1.70728 7.333 2.25002 6.47853 2.94753 5.78103C4.10832 4.62023 5.96033 4.7762 6.69734 6.09681Z"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                />
            </svg>
        ),
    },
];
