import React, { useState } from "react";
import usePayouts from "../../../../hooks/usePayouts";
import { received } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";

const DetailsPayout = ({ startDate, installments, investment }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { completedPayouts, upcomingPayouts } = usePayouts(
        startDate,
        installments,
        investment
    );

    return (
        <div className="p-2.4 rounded-2 border border-gray-400">
            <div className="flex mb-4 justify-between border-b border-b-blue-50">
                {["Completed Payouts", "Upcoming Payouts"].map((tab, i) => (
                    <div
                        key={i}
                        onClick={() => setActiveTab(i)}
                        className={`flex items-center border-b-2 gap-1 pb-1 font-vietnam ${
                            activeTab === i
                                ? "text-blue-500  border-b-blue-500"
                                : "text-gray-500 border-b-transparent"
                        }`}
                    >
                        <P
                            className={` ${
                                activeTab === i
                                    ? "text-blue-500 "
                                    : "text-gray-500"
                            }`}
                        >
                            {tab}
                        </P>
                    </div>
                ))}
            </div>
            {/* @TODO => dropdown @habib610 Thu September 26,2024 */}
            {(activeTab === 0 ? completedPayouts : upcomingPayouts).map(
                (item) => (
                    <div
                        key={item.no}
                        className="mt-1.6 pb-1.6 border-b border-gray-400"
                    >
                        <div className="flex justify-between mb-1.6">
                            <P className="text-gray font-vietnam">
                                {item.date}
                            </P>
                            <P className="text-gray flex items-center gap-0.8 font-vietnam">
                                <img src={received} alt="received" />
                                <span className="font-medium">
                                    ₹ {item.amount}
                                </span>
                            </P>
                        </div>
                        <P className="text-xs text-gray-550 font-vietnam">
                            {item.no}
                        </P>
                    </div>
                )
            )}
        </div>
    );
};

export default DetailsPayout;
