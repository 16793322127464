import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { InlineText } from "../text/HeadingText";

const Container = styled.div`
    padding: 5px 10px;
    box-sizing: border-box;
`;

const TabIcon = styled(InlineText)`
    color: ${(props) =>
        props.isActive
            ? props.theme.color.primary
            : props.theme.color.darkSecondary};
    transition: all linear 0.3s;
`;
const TabLabel = styled(InlineText)`
    color: ${(props) =>
        props.isActive
            ? props.theme.color.primary
            : props.theme.color.darkSecondary};
    font-weight: 700;
    transform: scale(${(props) => (props.isActive ? 1.2 : 1)});
    transition: all linear 0.3s;
`;

const Tab = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TabItem = ({ path, name, Icon, isActive }) => {
    const history = useHistory();
    return (
        <Container role="button" onClick={() => history.push(path)}>
            <Tab>
                <TabIcon isActive={isActive} size="xl">
                    <Icon />
                </TabIcon>
                <TabLabel isActive={isActive} size="xs" color="white">
                    {name}
                </TabLabel>
            </Tab>
        </Container>
    );
};

export default TabItem;
