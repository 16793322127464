import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/button/Button";
import { Flex } from "../components/styles/Elements.styles";
import Text from "../components/text/Text";
import { LANDING } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import auth from "../services/auth";
const MobileNavItem = styled(Flex.Container)`
    padding: 20px 0px;
    border-bottom: 2px solid ${(props) => props.theme.color.white};
    cursor: pointer;
`;
const LogoutBtn = styled(Button)`
    width: 100%;
`;
const ProfileMobile = () => {
    const history = useHistory();
    const { state, dispatch } = useContextState();

    const handleLogout = (e) => {
        e.preventDefault();

        auth.logout(dispatch)
            .then(() => {
                history.push(LANDING);
            })
            .catch((err) => {});
    };
    return (
        <>
            {mobileData.map((item) => (
                <Link
                    key={item.path}
                    to={`${item.path}`}
                    style={{ textDecoration: "none" }}
                >
                    <MobileNavItem justify="space-between" align="center">
                        <Flex.Item xxs="95%">
                            <Text weight="700" size="m">
                                {item.name}
                            </Text>
                            <Text size="xs" color="lightGrey">
                                {item?.subText}
                            </Text>
                        </Flex.Item>
                        <Flex.Item width="5%">
                            <Text size="m">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Text>
                        </Flex.Item>
                    </MobileNavItem>
                </Link>
            ))}
            <LogoutBtn
                onClick={handleLogout}
                color="white"
                backgroundColor="primary"
                type="solid"
            >
                Logout
            </LogoutBtn>
        </>
    );
};

export default ProfileMobile;

const mobileData = [
    {
        path: "/profile/user",
        name: "Profile",
        subText: "Edit your name, contact, email and profile pic ",
    },
    {
        path: "/profile/kyc",
        name: "KYC",
    },
    {
        path: "/profile/bankDetails",
        name: "Bank Details",
    },
    // {
    //     path: "/profile/documents",
    //     name: "Documents",
    // },
    {
        path: "/faq",
        name: "FAQ",
    },
    {
        path: "/profile/changePassword",
        name: "Change Password",
    },
];
