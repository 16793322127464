import React, { useState } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { LuTableOfContents } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/button/Button";
import Link from "../../components/link/Link";
import Space from "../../components/space/Space";
import { Flex, Section } from "../../components/styles/Elements.styles";

import { listOfArticles } from "../../constants/blogData";
import { NOT_FOUND } from "../../constants/routes";
import cn from "../../lib/cn";
import { quotesWhite } from "../../resources/js/icons";
import Container from "../../shared/Container";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import BottomDrawer from "../../shared/ui/Drawer/BottomDrawer";
import Hero from "../contact-us/Hero";

const TOCWrapper = styled.div`
    position: sticky;
    margin-top: 5rem;
    top: 0;

    align-self: flex-start;
`;

const TOCItem = styled.div`
    margin: 1.5rem 0;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const Quotes = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.color.primary};
    padding: 1rem;
    border-radius: 0.8rem;
    margin: 2rem 0;

    .icon {
        width: 50px;
        margin-bottom: 1rem;
    }
    ${H3} {
        line-height: 1.5;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        flex-direction: row;
        align-items: center;
        .icon {
            width: 80px;
            margin: 0 2rem 0 0;
        }
    }
`;

const Tag = styled(P)`
    padding: 0.2rem 0.8rem;
    background: ${(props) => props.theme.color.grey};
    margin: 1rem 0.5rem 0 0;
    border-radius: 50px;
`;

const LinkedinButton = styled(Button)`
    :active,
    :focus {
        box-shadow: none;
    }
`;
const FloatingButton = styled(Button)`
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 1000;
    background-color: ${(props) => props.theme.color.primary};
    color: white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Subscribe = () => {
    return (
        <Link
            isExternal={true}
            to="https://www.linkedin.com/newsletters/7034791305847160832/?displayConfirmation=true"
        >
            <LinkedinButton type="solid" size="s">
                <Space xxsm={["0 .21rem 0 0"]}>
                    <Icon size="s" component={FaLinkedinIn} color={"white"} />
                </Space>
            </LinkedinButton>
        </Link>
    );
};
const FlexItem = styled(Flex.Item)`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const BlogDetails = () => {
    const { blogId } = useParams();
    const history = useHistory();
    let articlesDetails1 = listOfArticles.find(
        (article) => article.meta.blogUrl === blogId
    );
    const [showModal, setShowModal] = useState(false);
    const handleFloatingButtonClick = () => {
        setShowModal((prev) => !prev);
    };
    const location = useLocation();
    if (!articlesDetails1) {
        const blogId = location?.pathname?.split("/")[2];
        articlesDetails1 = listOfArticles.find(
            (article) => article.meta.blogUrl === blogId
        );
        if (!blogId) {
            history.push(NOT_FOUND);
            return null;
        }
    }

    const renderContent = (content) => {
        return content.map((item, index) => {
            switch (item.type) {
                case "text":
                    return (
                        <P
                            key={index}
                            className={cn("my-1 text-justify", item?.className)}
                            dangerouslySetInnerHTML={{
                                __html: item.value,
                            }}
                        />
                    );
                case "quote":
                    return (
                        <Quotes key={index}>
                            <div className="icon">
                                <img
                                    src={quotesWhite}
                                    alt="Quote Icon"
                                    className=" w-2 sm:w-8 h-3"
                                />
                            </div>
                            <div>
                                <P className="text-white italic font-medium">
                                    {item.value}
                                </P>
                            </div>
                        </Quotes>
                    );
                case "list":
                    return (
                        <div key={index} className="my-2">
                            {item.title && (
                                <P className="mb-1 md:text-lg xl:text-lg  font-semibold">
                                    {item.title}
                                </P>
                            )}
                            <ul className={cn(" text-justify  pl-2")}>
                                {item.value.map((li, idx) => (
                                    <li
                                        className={cn(
                                            item.listClass,
                                            "list-disc mb-0.5  marker:text-lg"
                                        )}
                                        key={idx}
                                    >
                                        <P
                                            dangerouslySetInnerHTML={{
                                                __html: li,
                                            }}
                                            className="text-gray-secondary"
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                case "table":
                    return (
                        <div key={index} className="overflow-x-auto my-2">
                            <table className="table-auto border-collapse w-full text-left">
                                <thead className="bg-gray-100">
                                    <tr>
                                        {item.value.headers.map(
                                            (header, idx) => (
                                                <th
                                                    key={idx}
                                                    className="px-4 py-2 border"
                                                >
                                                    <P className="font-semibold">
                                                        {header}
                                                    </P>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.value.rows.map((row, rowIndex) => (
                                        <tr
                                            key={rowIndex}
                                            className="odd:bg-white border even:bg-gray-50"
                                        >
                                            {row.map((cell, cellIndex) => (
                                                <td key={cellIndex}>
                                                    <P
                                                        className="px-4 py-2 "
                                                        dangerouslySetInnerHTML={{
                                                            __html: cell,
                                                        }}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                case "img":
                    return (
                        <div key={index} className="my-6">
                            <img
                                src={item.value}
                                alt={item.altText}
                                className="w-full rounded"
                            />
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    const {
        meta,
        title,
        titleClass,
        bannerImage,
        sections,
        published,
        readingTime,
    } = articlesDetails1;

    return (
        <>
            <Hero
                content={
                    <Container className="flex items-center justify-start ">
                        <div>
                            <H2 className={cn("my-2 w-full", titleClass)}>
                                {title}
                            </H2>

                            <Space xxsm={["0 0 2rem"]}>
                                <Flex.Container
                                    align="center"
                                    justify="space-between"
                                >
                                    <FlexItem>
                                        <P className="text-white">
                                            {published}
                                        </P>

                                        <P className="text-white">|</P>

                                        <P className="text-white">
                                            {readingTime}
                                        </P>
                                    </FlexItem>
                                </Flex.Container>
                            </Space>
                        </div>
                    </Container>
                }
                url={bannerImage.value}
                type={"image"}
                containerClass={"mb-0 xl:mb-0"}
                wrapperClass="  max-h-[60vh]  2xl:h-screen"
            />
            <div className="flex">
                <TOCWrapper className="hidden md:block ml-5 mr-2 md:ml-[8rem] md:mr-[5rem] ">
                    <H3 className={"text-nowrap"}>Table of Contents</H3>
                    {sections.map((section, index) => (
                        <TOCItem
                            key={index}
                            onClick={() => {
                                const element = document.getElementById(
                                    `section-${index}`
                                );
                                if (element) {
                                    element.scrollIntoView({
                                        behavior: "smooth",
                                    });
                                }
                            }}
                        >
                            <p className="text-sm font-thin ">
                                {section.title}
                            </p>
                        </TOCItem>
                    ))}
                </TOCWrapper>

                <Space xxsm={["0 0 0"]} xlm={["0 0 0rem"]}>
                    <Section height="auto">
                        <Container>
                            <div>
                                {sections.map((section, index) => (
                                    <div
                                        key={index}
                                        id={`section-${index}`}
                                        className="mb-5"
                                    >
                                        <H4 className=" text-black font-semibold sm:font-semibold xl:font-semibold 2xl:font-semibold">
                                            {section.title}
                                        </H4>
                                        {renderContent(section.content)}
                                    </div>
                                ))}
                            </div>
                            <Space xxsm={["3rem 0"]}>
                                <Flex.Container align="center">
                                    {meta?.tags?.map((tag, index) => (
                                        <Tag key={index}>{tag}</Tag>
                                    ))}
                                </Flex.Container>
                            </Space>
                            <div className="flex w-full justify-end">
                                <Subscribe />
                            </div>
                        </Container>
                    </Section>
                </Space>
            </div>
            <div className="md:hidden">
                <FloatingButton onClick={handleFloatingButtonClick}>
                    <LuTableOfContents />
                </FloatingButton>
            </div>
            <div className="md:hidden">
                <BottomDrawer
                    isOpen={showModal}
                    onClick={() => handleFloatingButtonClick()}
                >
                    <div className="bg-white rounded-t-2 p-3 absolute bottom-0">
                        <H3 className={"text-nowrap"}>Table of Contents</H3>
                        {sections.map((section, index) => (
                            <TOCItem
                                key={index}
                                onClick={() => {
                                    const element = document.getElementById(
                                        `section-${index}`
                                    );
                                    if (element) {
                                        element.scrollIntoView({
                                            behavior: "smooth",
                                        });
                                    }
                                    handleFloatingButtonClick();
                                }}
                            >
                                <p className="text-sm font-thin ">
                                    {section.title}
                                </p>
                            </TOCItem>
                        ))}
                    </div>
                </BottomDrawer>
            </div>
        </>
    );
};

export default BlogDetails;
