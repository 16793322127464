import React, { forwardRef } from "react";

const TextField = forwardRef(
    (
        {
            label,
            value,
            onChange,
            type = "text",
            name,
            helperMsg,
            errorMsg,
            required,
            icon,
            focus = true,
            disabled,
            iconChange = () => {},
            errorButton,
            size,
            wrapper,
            gradient = "bg-custom-gradient-2",
            inputWrapper = "w-full rounded-md",
            iconWrapperClass = "right-3 flex items-center rounded-lg",
            ...rest
        },
        ref
    ) => {
        return (
            <div className={`flex flex-col ${size}`}>
                {label && (
                    <label
                        className="text-gray-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8"
                        htmlFor={name}
                    >
                        {label}{" "}
                        {required && <span className="text-red">*</span>}
                    </label>
                )}
                <div
                    className={`relative px-px py-px flex ${wrapper} ${
                        errorMsg?.length > 0
                            ? "border border-red"
                            : disabled
                            ? "bg-gray-100"
                            : gradient
                    } rounded-lg focus:outline-none ${
                        focus ? "focus:ring-2 focus:ring-blue-500" : ""
                    } text-btn-s`}
                >
                    <input
                        ref={ref} // Use the ref here
                        className={`border-none ${inputWrapper} px-1 py-1 lg:py-1.2 font-normal text-sm sm:text-base xl:text-base 2xl:text-xl ${
                            disabled ? "bg-white text-gray-550" : "text-gray"
                        }`}
                        value={value}
                        name={name}
                        type={type}
                        onChange={onChange}
                        required={false}
                        disabled={disabled}
                        {...rest}
                    />
                    {icon && (
                        <div
                            className={`cursor-pointer ${iconWrapperClass}`}
                            onClick={iconChange}
                        >
                            {icon}
                        </div>
                    )}
                </div>
                {errorMsg && (
                    <span className="text-red text-sm xl:text-base flex items-center flex-wrap gap-0.5">
                        {errorMsg} {errorButton}
                    </span>
                )}
                {helperMsg && (
                    <span className="text-xs text-gray-secondary">
                        {helperMsg}
                    </span>
                )}
            </div>
        );
    }
);

export default TextField;
