/* eslint-disable import/no-anonymous-default-export */

const householdHouseServiceFromGovernmentAns = 1.2;
export const householdHouseElectricityMultiplier = 0.0012;

export default [
    {
        category: "Lifestyle",
        id: "lifeStyle",
        questions: [
            {
                id: "lifeStyleExpenses",
                text: "Your monthly Expense for Clothing / Electronics / Furniture/ Leisure/culture/health ?",
                title: "Expenses",
                answers: [
                    {
                        text: "< Rs. 5000 per Month",
                        coEmission: 2.8,
                    },
                    {
                        text: "< Rs. 15000 per Month",
                        coEmission: 3.57,
                    },
                    {
                        text: "< Rs. 25000 per Month",
                        coEmission: 4.48,
                    },
                    {
                        text: "> Rs. 45000 per Month",
                        coEmission: 6.23,
                    },
                ],
            },
            {
                id: "lifeStyleFood",
                text: "What type of food do you consume?",
                title: "Food Habits",
                answers: [
                    {
                        text: "Only Vegan",
                        coEmission: 1.1,
                    },
                    {
                        text: "Vegetarian",
                        coEmission: 1.53,
                    },
                    {
                        text: "Non-veg alternatively",
                        coEmission: 1.92,
                    },
                    {
                        text: "Mostly Non-veg",
                        coEmission: 2.67,
                    },
                ],
            },
        ],
    },
    {
        category: "Mobility",
        id: "mobility",
        questions: [
            {
                id: "mobilityDailyCommute",
                text: "How do you commute daily?",
                title: "Transport",
                answers: [
                    {
                        text: "Usually, Walk occasionally Public transport",
                        coEmission: 0.313,
                    },
                    {
                        text: "More Usage of Public Transport",
                        coEmission: 0.42,
                    },
                    {
                        text: "Usually car occasionally Pubilc Transport",
                        coEmission: 1.79,
                    },
                    {
                        text: "Personal Car",
                        conditionalAnswers: [
                            {
                                text: "Car Diesel",
                                coEmission: 4.39,
                            },
                            {
                                text: "Car Petrol",
                                coEmission: 3.98,
                            },
                            {
                                text: "Car CNG",
                                coEmission: 2.4,
                            },
                        ],
                    },
                    // new data added from here
                    {
                        text: "Only 2 Wheeler",
                        coEmission: 2.1,
                    },
                    {
                        text: "Electric car or 2 wheeler",
                        coEmission: 0,
                    },
                ],
            },
            {
                id: "mobilityLongDistance",
                text: "Do you travel long distances?",
                title: "Long Distance Travel",
                categories: [
                    {
                        id: "mobilityAirLongDistance",
                        text: "Air Travel",
                        answers: [
                            {
                                text: "No Air travel",
                                coEmission: 0,
                            },
                            {
                                text: "Domestic once or twice /year",
                                coEmission: 0.98,
                            },

                            {
                                text: "Moderate (5-10 travels / year)",
                                coEmission: 3.31,
                            },
                            {
                                text: "High (more than 10 travel / year)",
                                coEmission: 8.125,
                            },
                        ],
                    },
                    {
                        id: "mobilityRailwayLongDistance",
                        text: "Railways",
                        answers: [
                            {
                                text: "Less than 5 trips / year",
                                coEmission: 0.5,
                            },
                            {
                                text: "More than 5 trips / year",
                                coEmission: 1.2,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        category: "Household",
        id: "household",
        questions: [
            {
                id: "householdFamilyMembers",
                text: "How many members of your Family live together?",
                title: "House Type",
                answers: [
                    {
                        text: "2-4 members",
                        coEmission: 0.11,
                    },
                    {
                        text: "more then 5 members",
                        coEmission: 1.44,
                    },
                ],
            },
            // {
            //     id: "householdHouseConstructionType",
            //     text: "How old is your house?",
            //     title: "Construction Type",
            //     answers: [
            //         {
            //             text: "New & Energy Efficient < 10 yrs",
            //             coEmission: 0.05,
            //         },
            //         {
            //             text: "Older Structure > 10yr",
            //             coEmission: 0.86,
            //         },
            //     ],
            // },
            // {
            //     id: "householdHouseEnergyType",
            //     text: "Type of energy used at your home?",
            //     title: "Household Energy Type",
            //     answers: [
            //         {
            //             text: "Use Renewable energy at home",
            //             coEmission: 0,
            //         },
            //         {
            //             text: "Conventional Energy",
            //             coEmission: 3,
            //         },
            //     ],
            // },
            // new added
            {
                id: "householdHouseElectricity",
                text: "Electricity Usage",
                title: "Household Electricity",
                answers: [
                    {
                        text: "Electricity Bill Monthly",
                        type: "input",
                        multiplier: householdHouseElectricityMultiplier,
                    },
                ],
            },
            {
                id: "householdHouseCookingFoodType",
                text: "How do you cook your food?",
                title: "Household Food Type",
                answers: [
                    {
                        text: "LPG / Pipeline Gas",
                        coEmission: 0.246,
                        type: "checkbox",
                    },
                    {
                        text: "Electric stove or microwave",
                        coEmission: 0.345,
                        type: "checkbox",
                    },
                ],
            },
            {
                id: "householdHouseServiceFromGovernment",
                text: "Services from Government",
                title: "Services from Government",
                answers: [
                    {
                        text: "Contribution due to usage of Public services like roads, watersupply, railways, hospitals, waste segregation etc.",
                        checked: true,
                        coEmission: householdHouseServiceFromGovernmentAns, // values needed to be change
                    },
                ],
            },
        ],
    },
];

export const defaultQuessionareAnswer = {
    householdHouseServiceFromGovernment: householdHouseServiceFromGovernmentAns,
};

export const lifeStyleQuestion = {
    category: "Lifestyle",
    id: "lifeStyle",
    questions: [
        {
            id: "lifeStyleExpenses",
            name: "Your monthly Expense for Clothing / Electronics / Furniture/ Leisure/culture/health ?",
            title: "Expenses",
            answers: [
                {
                    name: "Select Expense",
                    coEmission: 0,
                },
                {
                    name: "< Rs. 5000 per Month",
                    coEmission: 2.8,
                },
                {
                    name: "< Rs. 15000 per Month",
                    coEmission: 3.57,
                },
                {
                    name: "< Rs. 25000 per Month",
                    coEmission: 4.48,
                },
                {
                    name: "> Rs. 45000 per Month",
                    coEmission: 6.23,
                },
            ],
        },
        {
            id: "lifeStyleFood",
            name: "What type of food do you consume?",
            title: "Food Habits",
            answers: [
                {
                    name: "Select Habits",
                    coEmission: 0,
                },
                {
                    name: "Only Vegan",
                    coEmission: 1.1,
                },
                {
                    name: "Vegetarian",
                    coEmission: 1.53,
                },
                {
                    name: "Non-veg alternatively",
                    coEmission: 1.92,
                },
                {
                    name: "Mostly Non-veg",
                    coEmission: 2.67,
                },
            ],
        },
    ],
};

export const mobilityQuestion = {
    category: "Mobility",
    id: "mobility",
    questions: [
        {
            id: "mobilityDailyCommute",
            name: "How do you commute daily?",
            title: "Transport",
            answers: [
                {
                    name: "Select transport",
                    coEmission: 0,
                },
                {
                    name: "Usually, Walk occasionally Public transport",
                    coEmission: 0.313,
                },
                {
                    name: "More Usage of Public Transport",
                    coEmission: 0.42,
                },
                {
                    name: "Usually car occasionally Pubilc Transport",
                    coEmission: 1.79,
                },
                // {
                //     name: "Personal Car",
                //     conditionalAnswers: [
                //         {
                //             name: "Car Diesel",
                //             coEmission: 4.39,
                //         },
                //         {
                //             name: "Car Petrol",
                //             coEmission: 3.98,
                //         },
                //         {
                //             name: "Car CNG",
                //             coEmission: 2.4,
                //         },
                //     ],
                // },
                // new data added from here
                {
                    name: "Only 2 Wheeler",
                    coEmission: 2.1,
                },
                {
                    name: "Electric car or 2 wheeler",
                    coEmission: 0,
                },
            ],
        },
        {
            id: "mobilityLongDistanceRailways",
            name: "Do you travel long distances by Railways?",
            title: "Long Distance Travel by Railways",
            answers: [
                {
                    name: "Select railways",
                    coEmission: 0,
                },
                {
                    name: "Less than 5 trips / year",
                    coEmission: 0.5,
                },
                {
                    name: "More than 5 trips / year",
                    coEmission: 1.2,
                },
            ],
        },
        {
            id: "mobilityLongDistanceAir",
            name: "Do you travel long distances by Air?",
            title: "Long Distance Travel by Air",
            answers: [
                {
                    name: "Select air travel",
                    coEmission: 0,
                },
                {
                    name: "No Air travel",
                    coEmission: 0,
                },
                {
                    name: "Domestic once or twice /year",
                    coEmission: 0.98,
                },

                {
                    name: "Moderate (5-10 travels / year)",
                    coEmission: 3.31,
                },
                {
                    name: "High (more than 10 travel / year)",
                    coEmission: 8.125,
                },
            ],
        },
    ],
};

export const householdQuestion = {
    category: "Household",
    id: "household",
    questions: [
        {
            id: "householdFamilyMembers",
            name: "How many members of your Family live together?",
            title: "House Type",
            answers: [
                {
                    name: "Select members",
                    coEmission: 0,
                },
                {
                    name: "2-4 members",
                    coEmission: 0.11,
                },
                {
                    name: "more then 5 members",
                    coEmission: 1.44,
                },
            ],
        },
        {
            id: "householdHouseCookingFoodType",
            name: "How do you cook your food?",
            title: "Household Food Type",
            answers: [
                {
                    name: "Select cooking food type",
                    coEmission: 0,
                    type: "checkbox",
                },
                {
                    name: "LPG / Pipeline Gas",
                    coEmission: 0.246,
                    type: "checkbox",
                },
                {
                    name: "Electric stove or microwave",
                    coEmission: 0.345,
                    type: "checkbox",
                },
            ],
        },
        // {
        //     id: "householdHouseConstructionType",
        //     name: "How old is your house?",
        //     title: "Construction Type",
        //     answers: [
        //         {
        //             name: "New & Energy Efficient < 10 yrs",
        //             coEmission: 0.05,
        //         },
        //         {
        //             name: "Older Structure > 10yr",
        //             coEmission: 0.86,
        //         },
        //     ],
        // },
        // {
        //     id: "householdHouseEnergyType",
        //     name: "Type of energy used at your home?",
        //     title: "Household Energy Type",
        //     answers: [
        //         {
        //             name: "Use Renewable energy at home",
        //             coEmission: 0,
        //         },
        //         {
        //             name: "Conventional Energy",
        //             coEmission: 3,
        //         },
        //     ],
        // },
        // new added
        // {
        //     id: "householdHouseElectricity",
        //     name: "Electricity Usage",
        //     title: "Household Electricity",
        //     answers: [
        //         {
        //             name: "Electricity Bill Monthly",
        //             type: "input",
        //             multiplier: householdHouseElectricityMultiplier,
        //         },
        //     ],
        // },
        // {
        //     id: "householdHouseCookingFoodType",
        //     name: "How do you cook your food?",
        //     title: "Household Food Type",
        //     answers: [
        //         {
        //             name: "LPG / Pipeline Gas",
        //             coEmission: 0.246,
        //             type: "checkbox",
        //         },
        //         {
        //             name: "Electric stove or microwave",
        //             coEmission: 0.345,
        //             type: "checkbox",
        //         },
        //     ],
        // },
    ],
};
