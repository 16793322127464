import React, { useState } from "react";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { COMMON_SUCCESS_MSG } from "../../constants/common";
import { KYC_VERIFY_BankDetails } from "../../constants/routes";
import request from "../../services/request"; // Assumes you have a custom `request` utility for API calls
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";

const BankAccount = ({ handleNext, Bank, checkStatus }) => {
    const [disabled, setDisabled] = useState(
        Bank?.kycStatus === "VERIFIED" ||
            Bank?.kycStatus === "FAILED" ||
            Bank?.kycStatus === "INPROCESS"
    );
    const [formData, setFormData] = useState({
        name: Bank?.bankName || "",
        ifsc: Bank?.ifsc || "",
        accountNo: Bank?.userAccountNumber || "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState(false);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "", api: "" });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = "Account name is required.";
        if (!formData.ifsc.trim()) newErrors.ifsc = "IFSC code is required.";
        if (!formData.accountNo.trim())
            newErrors.accountNo = "Account number is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);

        try {
            const response = await request.authPost({
                endpoint: KYC_VERIFY_BankDetails,
                body: {
                    accountName: formData.name,
                    accountNumber: formData.accountNo,
                    ifsc: formData.ifsc,
                },
            });

            if (response?.data?.data?.verificationStatus === "VERIFIED") {
                toast.success(response.message || COMMON_SUCCESS_MSG);
                setSucess(true);
                handleNext(formData);
            } else if (
                response?.data?.data?.verificationStatus === "INPROCESS"
            ) {
                setDisabled(true);
            }
        } catch (error) {
            setErrors({
                api: error,
            });
        } finally {
            setLoading(false);
            setDisabled(true);
        }
    };
    const handleReset = () => {
        setErrors({});
        setDisabled(false);
    };
    return (
        <div className="h-full">
            <H4 className="font-bold my-2">Bank Details</H4>
            <div className="flex flex-col gap-3">
                <TextField
                    label={"Name as per Bank Statement "}
                    value={formData?.name}
                    onChange={handleChange}
                    placeholder="Arthif Khan"
                    name={"name"}
                    type={"text"}
                    inputWrapper="!placeholder-gray-500 w-full rounded-md"
                    required={true}
                    errorMsg={errors.name}
                    disabled={disabled}
                />

                <TextField
                    label={"Enter your IFSC Code"}
                    value={formData?.ifsc}
                    placeholder="SVSAV56"
                    onChange={handleChange}
                    name={"ifsc"}
                    inputWrapper="!placeholder-gray-500 w-full rounded-md"
                    type={"text"}
                    required={true}
                    errorMsg={errors.ifsc}
                    disabled={disabled}
                />

                <TextField
                    label={"Enter your Account Number"}
                    value={formData?.accountNo}
                    placeholder="9999 8888 4444"
                    onChange={handleChange}
                    name={"accountNo"}
                    type={"text"}
                    inputWrapper="!placeholder-gray-500 w-full rounded-md"
                    required={true}
                    errorMsg={errors.accountNo}
                    disabled={disabled}
                />

                {(errors.api || (disabled && Bank?.kycStatus === "FAILED")) && (
                    <>
                        <div className="flex gap-1 items-center mt-1.5">
                            <P className="bg-red-200 text-sm md:text-sm xl:text-sm text-white rounded-full px-0.4 py-0.4 ">
                                <RxCross1 />
                            </P>
                            <P>{errors.api ? errors.api : Bank?.message}</P>
                        </div>
                        <P
                            className="text-blue underline cursor-pointer"
                            onClick={() => {
                                handleReset();
                                setFormData({
                                    name: "",
                                    ifsc: "",
                                    accountNo: "",
                                });
                            }}
                        >
                            Retry to Enter Bank Details
                        </P>
                    </>
                )}

                {(sucess || Bank?.kycStatus === "VERIFIED") && (
                    <div className="flex gap-1 items-center">
                        <P className="bg-green-400 text-sm md:text-sm xl:text-sm text-white rounded-full px-0.4 py-0.4 ">
                            <FaCheck />
                        </P>
                        <P>Bank details are confirmed</P>
                    </div>
                )}
                {Bank?.kycStatus === "INPROCESS" && (
                    <div
                        className="flex gap-1 items-center cursor-pointer"
                        onClick={checkStatus}
                    >
                        <P className="bg-gray-400 text-sm md:text-sm xl:text-sm text-gray-500 rounded-full px-0.4 py-0.4 ">
                            <FaRegClock />
                        </P>
                        <P>Bank Kyc is in Process</P>
                    </div>
                )}
            </div>
            {!disabled &&
                Bank?.kycStatus !== "VERIFIED" &&
                Bank?.kycStatus !== "INPROCESS" && (
                    <Button
                        className="w-full mt-5 mb-[10rem] md:mb-3 bg-black text-white !rounded-[3rem]"
                        onClick={handleSubmit}
                        disabled={loading || disabled}
                        isLoading={sucess}
                    >
                        Proceed
                    </Button>
                )}
        </div>
    );
};

export default BankAccount;
