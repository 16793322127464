import React, { useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { COMMON_SUCCESS_MSG } from "../../constants/common";
import { KYC_OCR_PAN } from "../../constants/routes";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import CameraInput from "../../shared/ui/Form/CameraInput";
import TextField from "../../shared/ui/Form/TextField";

const PanCard = ({ handleNext, Pan, form }) => {
    const [disabled, setDisabled] = useState(
        Pan?.status === "VERIFIED" || Pan?.status === "FAILED"
    );
    const [formData, setFormData] = useState({
        panNumber: Pan?.userPanNumber || "",
        name: Pan?.userPanName || "",
        dob: Pan?.userPandob || "",
        photo: "",
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState(false);
    const panRef = useRef(null);
    const nameRef = useRef(null);
    const dobRef = useRef(null);

    const handleImageSelected = (file) => {
        setFormData({ ...formData, photo: file });
        setErrors({ ...errors, ["photo"]: "", ["api"]: "" });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "", ["api"]: "" });
    };

    const handleFocus = (ref) => {
        ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const handleSubmit = async () => {
        if (!disabled) {
            const { panNumber, name, dob, photo } = formData;

            if (!panNumber || !name || !dob || !photo) {
                setErrors({
                    panNumber: !panNumber ? "PAN number is required" : "",
                    name: !name ? "Name is required" : "",
                    dob: !dob ? "Date of birth is required" : "",
                    photo: !photo ? "PAN document is required" : "",
                });
                return;
            }

            const data = new FormData();
            data.append("panFrontDocument", photo);
            data.append("panNumber", panNumber);
            data.append("name", name);
            data.append("dob", dob);

            setLoading(true);

            try {
                const response = await request.authPost({
                    endpoint: KYC_OCR_PAN,
                    isFormData: true,
                    body: data,
                });
                if (response?.data?.data?.verificationStatus === "VERIFIED") {
                    toast.success(response.message || COMMON_SUCCESS_MSG);
                    setSucess(true);
                    handleNext(formData, "aadhar");
                } else {
                    setErrors({
                        ...errors,
                        api: response?.data?.data?.verificationData?.message,
                    });
                    setDisabled(true);
                }
            } catch (error) {
                setErrors({
                    ...errors,
                    api: error,
                });
            } finally {
                setLoading(false);
                setDisabled((prev) => !prev);
            }
        }
    };
    const cameraInputRef = useRef(null);

    const handleReset = () => {
        cameraInputRef.current.resetImage();
        setErrors({});
        setDisabled(false);
    };

    return (
        <div>
            <H4 className="font-bold my-2">PAN Card</H4>

            <CameraInput
                ref={cameraInputRef}
                onImageSelected={handleImageSelected}
                label="Front side of PAN"
                required={true}
                errorMsg={errors.photo}
                disabled={disabled}
            />
            <div className="flex flex-col gap-2 mt-2">
                <TextField
                    label={"Enter your PAN Number"}
                    placeholder="XXXXXXXXXX"
                    inputWrapper="!placeholder-gray-500 w-full rounded-md"
                    value={formData?.panNumber}
                    onChange={handleChange}
                    onFocus={() => handleFocus(panRef)}
                    ref={panRef}
                    name={"panNumber"}
                    type={"text"}
                    disabled={disabled}
                    errorMsg={errors.panNumber}
                    required={true}
                />
                <TextField
                    label={"Your Name as per PAN"}
                    value={formData?.name}
                    inputWrapper="!placeholder-gray-500 w-full rounded-md"
                    onChange={handleChange}
                    onFocus={() => handleFocus(nameRef)}
                    ref={nameRef}
                    placeholder="XXXXXX XXXXXX"
                    name={"name"}
                    type={"text"}
                    required={true}
                    errorMsg={errors.name}
                    disabled={disabled}
                />
                <TextField
                    label={"Your Date of Birth as per PAN"}
                    value={formData?.dob}
                    inputWrapper="!placeholder-gray-500 w-full rounded-md"
                    onChange={handleChange}
                    onFocus={() => handleFocus(dobRef)}
                    ref={dobRef}
                    name={"dob"}
                    placeholder="DD/MM/YYYY"
                    type={"text"}
                    required={true}
                    errorMsg={errors.dob}
                    disabled={disabled}
                />

                {((disabled && Pan?.status === "FAILED") || errors.api) && (
                    <>
                        <div className="flex gap-1 items-center">
                            <P className="bg-red-200 text-sm md:text-sm xl:text-sm text-white rounded-full px-0.4 py-0.4 ">
                                <RxCross1 />
                            </P>
                            <P>{errors.api ? errors.api : Pan?.message} </P>
                        </div>
                        <P
                            className="text-blue underline cursor-pointer"
                            onClick={() => {
                                handleReset();
                                setFormData({
                                    panNumber: "",
                                    name: "",
                                    dob: "",
                                    photo: "",
                                });
                            }}
                        >
                            Retry to upload PAN Document
                        </P>
                    </>
                )}
                {(Pan?.status === "VERIFIED" || sucess) && (
                    <div className="flex gap-1 items-center mt-1.5">
                        <P className="bg-green-400 text-sm md:text-sm xl:text-sm text-white rounded-full px-0.4 py-0.4 ">
                            <FaCheck />
                        </P>
                        <P>Pan card details are confirmed</P>
                    </div>
                )}
            </div>

            {!disabled && Pan?.status !== "VERIFIED" && (
                <Button
                    className="w-full mt-5 mb-[10rem] md:mb-3 bg-black text-white !rounded-[3rem]"
                    onClick={handleSubmit}
                    disabled={loading || disabled}
                    isLoading={sucess}
                >
                    Proceed
                </Button>
            )}
        </div>
    );
};

export default PanCard;
