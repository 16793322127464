import React, { useCallback } from "react";
import "react-calendar/dist/Calendar.css";
import DatePickerBox from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import styled from "styled-components";
import Text from "../text/Text";

export const ErrorText = styled(Text)`
    color: ${(props) => props.theme.color.error};
    padding: ${(props) =>
        props.errorType === "alert" ? "1px 10px" : "10px 0 0"};
    border-radius: 3px;
    background-color: ${(props) =>
        props.errorType === "alert" ? props.theme.color.danger : "transparent"};
    display: inline-flex;
`;

const InputWrapper = styled.div`
    margin-bottom: ${(props) => props.marginBottom || ""};
    position: relative;
    box-sizing: border-box;
    border: none;
    /* border-radius: 10px; */
    font-size: ${(props) => props.theme.fontSize.default.s};
    font-family: "Montserrat", sans-serif;
    font-weight: 400;

    color: ${(props) =>
        props.theme.color[props.color] || props.theme.color.darkGrey};
    .react-date-picker {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        .react-date-picker__wrapper {
            background: ${(props) => props.theme.color.white};
            box-sizing: border-box;
            border: 1px solid
                ${(props) =>
                    props.isError
                        ? props.theme.color.error
                        : props.theme.color.lightGrey};
            overflow: hidden;
            border-radius: 3px;
            padding: 10px 10px;
            line-height: 1.6em;
            box-shadow: ${(props) =>
                props.shadow ? "rgba(17, 17, 26, 0.20) 0px 0px 12px" : ""};
            .react-date-picker__inputGroup__input {
                color: ${(props) =>
                    props.theme.color[props.color] ||
                    props.theme.color.darkGrey};
            }
        }
    }

    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        .react-date-picker {
            .react-date-picker__wrapper {
                padding: 5px 10px;
            }
        }
    }
`;

const DatePicker = ({
    value,
    onChange,
    errorMessage = "",
    maxDate,
    minDate,
    errorType = "",
}) => {
    const onChangeHandler = useCallback(
        (value) => {
            onChange({
                target: {
                    value,
                    name: "dateOfBirth",
                },
            });
        },
        [onChange]
    );
    return (
        <InputWrapper shadow marginBottom="0px" isError={errorMessage}>
            <DatePickerBox
                onChange={onChangeHandler}
                value={value}
                shadow
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                format="dd/MM/yyyy"
                yearPlaceholder="yyyy"
                maxDate={maxDate}
                minDate={minDate}
            />
            {errorMessage ? (
                <ErrorText errorType={errorType} size="xs">
                    {errorMessage}
                </ErrorText>
            ) : null}
        </InputWrapper>
    );
};
export default DatePicker;
