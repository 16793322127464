import React from "react";

import Section from "../../../../shared/Section";
import ProfileCard from "./ProfileCard";

const LeftPart = () => {
    return (
        <Section className={"lg:mb-0  xl:mb-0"}>
            <div className="mx-2">
                <ProfileCard />
            </div>
        </Section>
    );
};

export default LeftPart;
