import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { KYC_SUMMARY } from "../../constants/routes";
import request from "../../services/request";
import MainPage from "./MainPage";
import StepView from "./StepView";

const KYC = () => {
    const [formData, setFormData] = useState({
        pan: {},
        aadhar: {},
        bank: {},
    });
    const [pan, setPan] = useState("");
    const [aadhar, setAadhar] = useState("");
    const [bank, setBank] = useState("");
    const { path, url } = useRouteMatch();
    const [start, setStart] = useState(false);
    const [end, setEnd] = useState(false);
    const [type, setType] = useState("");
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === "/kyc/summary") {
            setStart(false);
            setType("");
        }
        fetchStatus();
    }, [location]);

    const checkStatus = () => {
        fetchStatus();
    };
    const handleType = (type) => {
        setType(type);
        setStart(true);
    };
    const handeleStart = () => {
        setStart(false);
    };
    const handleProceed = (type) => {
        setStart(true);
        setType(type);

        history.push(`${url}/${type}`);
    };

    const handleNext = (data, nextStepType) => {
        setFormData((prevData) => ({
            ...prevData,
            [type]: data,
        }));
        setType(nextStepType);

        history.push(`${url}/${nextStepType}`);
    };
    useEffect(() => {
        fetchStatus();
    }, []);

    const fetchStatus = async () => {
        try {
            const response = await request.authGet({
                endpoint: KYC_SUMMARY,
            });

            const {
                panVerificationData,
                aadharVerificationData,
                bankVerificationData,
            } = response?.data?.data || {};

            setPan(panVerificationData || {});
            setAadhar(aadharVerificationData || {});
            setBank(bankVerificationData || {});
        } catch (error) {
            toast.error("Error fetching KYC status:", error);
        }
    };
    const handleSubmit = (data) => {
        handleNext(data, "bank");
        setStart(false);
        setEnd(true);
        history.push(`${url}`);
    };

    return (
        <div className="bg-blue-600 md:bg-white pt-6 md:flex md:justify-center md:items-center">
            <div className="bg-white rounded-t-[4rem] p-2 h-full md:mx-5 md:flex md:flex-col md:justify-center md:items-center md:w-[60%] xl:w-[40%]">
                {start ? (
                    <StepView
                        type={type}
                        path={path}
                        formData={formData}
                        handleNext={handleNext}
                        handleSubmit={handleSubmit}
                        handleType={handleType}
                        handeleStart={handeleStart}
                        Pan={pan}
                        Aadhar={aadhar}
                        checkStatus={checkStatus}
                        Bank={bank}
                    />
                ) : (
                    <MainPage
                        handleProceed={handleProceed}
                        handleType={handleType}
                        handeleStart={handeleStart}
                        Pan={pan}
                        Aadhar={aadhar}
                        Bank={bank}
                        type={type}
                    />
                )}
            </div>
        </div>
    );
};

export default KYC;
