import React, { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";

import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";
import Line from "../../../../shared/ui/Line";
import MultiRangeSlider from "./RangeSlider";

const FilterTransactions = ({ onApply }) => {
    const [dateRange, setDateRange] = useState("Last 7 days");
    const [type, setType] = useState("Investments");
    const [status, setStatus] = useState("");
    const [amount, setAmount] = useState([25000, 205000]);
    const [fileType, setFileType] = useState("PDF");

    const handleDateRangeChange = (range) => {
        if (range === dateRange) {
            setDateRange("");
        } else {
            setDateRange(range);
        }
    };
    const handleStatusChange = (newStatus) => {
        if (status === newStatus) {
            setStatus("");
        } else {
            setStatus(newStatus);
        }
    };

    const handleTypeChange = (type) => {
        setType(type);
    };
    const handleFileTypeChange = (type) => {
        setFileType(type);
    };

    const handleRangeChange = ({ min, max }) => {};

    const handleApply = () => {
        let str = "";
        if (status) str = str + `transactionType=${status}`;
        if (str) {
            onApply("?" + str);
        } else {
            onApply("");
        }
    };

    return (
        <div className="p-2  bg-white rounded-lg font-vietnam ">
            <h3 className="text-base font-semibold mb-4">
                Filter Transactions
            </h3>

            <div className="mb-4">
                <P className="font-medium xl:text-base text-gray-secondary font-vietnam">
                    Date Range
                </P>
                <div className="flex flex-wrap gap-3 mt-2">
                    {[
                        "Today",
                        "Last 7 days",
                        "Last 30 days",
                        "Last 90 days",
                        "Last 6 months",
                        "Last 1 year",
                        "Custom",
                    ].map((range) => (
                        <div
                            role="button"
                            key={range}
                            className={`px-1 py-0.5 rounded-3xl font-vietnam ${
                                dateRange === range
                                    ? "bg-gray-150 text-blue"
                                    : "border border-gray-150 text-gray-500"
                            }`}
                            onClick={() => handleDateRangeChange(range)}
                        >
                            <p className="text-nowrap flex items-center gap-1 text-sm">
                                {range}
                                <P className="text-blue font-vietnam">
                                    {dateRange == range && <MdOutlineCancel />}
                                </P>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <Line className={"lg:hidden"} />
            <div className="flex flex-wrap lg:flex-nowrap justify-between gap-2">
                <div className="mb-4">
                    <P className="font-medium xl:text-base text-gray-secondary font-vietnam">
                        Type
                    </P>
                    <div className="flex gap-3 flex-wrap mt-2 font-vietnam">
                        {["Investments", "Payouts"].map((t) => (
                            <div
                                role="button"
                                key={t}
                                className={`px-1 py-0.5 rounded-3xl ${
                                    type === t
                                        ? "bg-gray-150 text-blue"
                                        : "border border-gray-150 text-gray-500"
                                }`}
                                onClick={() => handleTypeChange(t)}
                            >
                                <p className="text-nowrap flex items-center gap-1 text-sm">
                                    {t}
                                    <P className="text-blue ">
                                        {type === t && <MdOutlineCancel />}
                                    </P>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <Line className={"w-full lg:hidden"} />
                <div className="mb-4">
                    <P className="font-medium xl:text-base text-gray-secondary">
                        Status
                    </P>
                    <div className="flex space-x-2 mt-2">
                        {["DEBIT", "CREDIT"].map((s) => (
                            <div
                                role="button"
                                key={s}
                                className={`px-1 py-0.5 rounded-3xl font-vietnam ${
                                    status === s
                                        ? "bg-gray-150 text-blue"
                                        : "border border-gray-150 text-gray-500"
                                }`}
                                onClick={() => handleStatusChange(s)}
                            >
                                <p className="text-nowrap flex items-center gap-1 text-sm">
                                    {s}
                                    <P className="text-blue">
                                        {status === s && <MdOutlineCancel />}
                                    </P>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Line className={"lg:hidden"} />
            <div className="flex flex-wrap-reverse xl:flex-nowrap justify-between items-start gap-5">
                <div className="mb-4">
                    <P className="font-medium xl:text-base text-gray-secondary font-vietnam">
                        File Type
                    </P>
                    <div className="flex space-x-4 mt-2">
                        {["PDF", "CSV"].map((file) => (
                            <label
                                key={file}
                                className="flex items-center text-sm"
                            >
                                <input
                                    type="radio"
                                    value={file}
                                    checked={fileType === file}
                                    onChange={() => handleFileTypeChange(file)}
                                    className="mr-1 accent-black"
                                />
                                {file}
                            </label>
                        ))}
                    </div>
                </div>
                <Line className={"w-full lg:hidden"} />
                <div className=" w-2/3">
                    <P className="font-medium xl:text-base text-gray-secondary mb-5 font-vietnam">
                        Amount
                    </P>
                    <MultiRangeSlider
                        min={0}
                        max={1000}
                        onChange={handleRangeChange}
                    />
                </div>
            </div>

            <div className="flex  gap-3">
                <div
                    role="button"
                    className="bg-white !border !border-black text-base text-black py-1 px-2 rounded-[3rem] font-vietnam"
                >
                    Clear All
                </div>
                <Button
                    onClick={handleApply}
                    className="bg-black !rounded-[3rem] !px-2 !py-1"
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};

export default FilterTransactions;
