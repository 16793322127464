import React, { useState } from "react";
import styled from "styled-components";
import { ProfileTitle } from ".";
import { bank_incomplete, kyc_underprocess } from "../assets";
import AlertNew from "../components/alert/AlertNew";
import Flex from "../components/flex/Flex";
import Image from "../components/image/Image";
import { InputComponent } from "../components/input/Input";
import Space from "../components/space/Space";
import Text from "../components/text/Text";
import { useContextState } from "../context/ContextProvider";

const Input = styled(InputComponent)`
    border: 1px solid ${(props) => props.theme.color.black};
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: ${(props) => props.theme.fontSize.default.m};
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.size
                ? props.theme.fontSize.tablet[props.size]
                : props.theme.fontSize.tablet.m};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) =>
            props.size
                ? props.theme.fontSize.mobile[props.size]
                : props.theme.fontSize.mobile.m};
    }
    color: ${(props) => props.theme.color[props.color]};
    padding: 5px 10px;
`;
const Container = styled.div`
    padding-left: 5%;
    padding-right: 5%;
`;
const BankDetails = ({ title }) => {
    const { state } = useContextState();

    const [accDetails, setAccDetails] = useState({
        accountNumber: state?.user?.bankDisplay?.accountNumber,
        ifsc: state?.user?.bankDisplay?.ifsc,
        bankName: state?.user?.bankDisplay?.bankName,
    });
    const handleChangeBank = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAccDetails({ ...accDetails, [name]: value });
    };

    return (
        <Container>
            <div>
                <ProfileTitle title={title} />
                <Flex.Container
                    style={{ height: "100%" }}
                    justifyContent={
                        state?.user?.bankDisplay?.kycStatus !== "COMPLETED"
                            ? "center"
                            : "space-between"
                    }
                    alignItems="center"
                >
                    {state?.user?.bankDisplay?.kycStatus !== "COMPLETED" ? (
                        <Flex.Item
                            width="60%"
                            tabletWidth="100%"
                            mobileWidth="100%"
                        >
                            <Flex.Container
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Flex.Item
                                    width="85%"
                                    tabletWidth="60%"
                                    mobileWidth="90%"
                                >
                                    <Image src={kyc_underprocess} />
                                </Flex.Item>
                            </Flex.Container>
                        </Flex.Item>
                    ) : (
                        <>
                            <Flex.Item
                                width="55%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <Space margin={["0"]}>
                                    <Image src={bank_incomplete} />
                                </Space>
                            </Flex.Item>
                            <Flex.Item
                                width="40%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <Space
                                    margin={["5% 0px 4% 0px"]}
                                    tabletmargin={["5% 0px 4% 0px"]}
                                    mobilemargin={["5% 0px 4% 0px"]}
                                >
                                    <Text
                                        align="center"
                                        weight="bold"
                                        // color="black"
                                        size="m"
                                    >
                                        Verified Bank Account
                                    </Text>
                                </Space>

                                <Text align="left">Account Number</Text>
                                <Input
                                    onChange={handleChangeBank}
                                    name="accountNumber"
                                    color={
                                        accDetails.accountNumber === null
                                            ? "error"
                                            : "grey"
                                    }
                                    value={
                                        accDetails.accountNumber === null
                                            ? " Please Complete KYC"
                                            : accDetails.accountNumber
                                    }
                                    placeholder="XXXXXXXX895"
                                    readOnly
                                />

                                <Text align="left">IFSC Code</Text>
                                <Input
                                    onChange={handleChangeBank}
                                    name="ifsc"
                                    color={
                                        accDetails.ifsc === null
                                            ? "error"
                                            : "grey"
                                    }
                                    value={
                                        accDetails.ifsc === null
                                            ? " Please Complete KYC"
                                            : accDetails.ifsc
                                    }
                                    placeholder="XXXXXXXX895"
                                    readOnly
                                />
                                <Text align="left">Bank Name</Text>
                                <Input
                                    onChange={handleChangeBank}
                                    name="bankName"
                                    color={
                                        accDetails.bankName === null
                                            ? "error"
                                            : "grey"
                                    }
                                    value={
                                        accDetails.bankName === null
                                            ? " Please Complete KYC"
                                            : accDetails.bankName
                                    }
                                    placeholder="XXXXXXXX895"
                                    readOnly
                                />
                            </Flex.Item>
                        </>
                    )}
                </Flex.Container>

                <Space margin={["2% 0px 2% 0px"]}>
                    <div>
                        <AlertNew
                            backgroundColor={
                                state?.user?.bankDisplay?.kycStatus !==
                                "COMPLETED"
                                    ? "danger"
                                    : "success"
                            }
                            borderColor={
                                state?.user?.bankDisplay?.kycStatus !==
                                "COMPLETED"
                                    ? "dangerBorder"
                                    : "successBorder"
                            }
                            weight="bold"
                        >
                            {state?.user?.bankDisplay?.kycStatus !== "COMPLETED"
                                ? "Please complete your bank KYC"
                                : "Bank KYC is Verified"}
                        </AlertNew>
                    </div>
                </Space>
            </div>
        </Container>
    );
};

export default BankDetails;
