import React, { useCallback } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderGif from "../../assets/images/srot-loader.gif";
import { RESET_ERROR } from "../../constants/contexConstant";
import { useContextState } from "../../context/ContextProvider";
import ErrorDialog from "../error/ErrorDialog";
import Image from "../image/Image";
import Overlay from "../overlay/Overlay";
import Text from "../text/Text";

const RequestHandler = () => {
    const { state, dispatch } = useContextState();
    const resetErrorHandler = useCallback(() => {
        dispatch({ type: RESET_ERROR });
    }, [dispatch]);

    return (
        <>
            {state.error ? (
                <ErrorDialog error={state.error} onClose={resetErrorHandler} />
            ) : null}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                draggable={false}
                pauseOnHover
                style={{ top: "80px" }}
            />
            {state.loading ? (
                <Overlay>
                    <Image src={LoaderGif} width="120px" height="120px" />
                    <Text
                        style={{ paddingTop: "10px" }}
                        color="white"
                        size="s"
                        weight="600"
                    >
                        LOADING
                    </Text>
                </Overlay>
            ) : null}
        </>
    );
};

export default RequestHandler;
