import React from "react";
import MyInvestment from "./MyInvestment";

const RightPart = (props) => {
    return (
        <div className="mx-2">
            <MyInvestment {...props} />
            {/* <BonusCarousel /> */}
        </div>
    );
};

export default RightPart;
