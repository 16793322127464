import React, { useCallback } from "react";
import { IoTimeOutline } from "react-icons/io5";

import moment from "moment";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../../../constants/common";
import {
    GENERATE_DOC_ENDPOINT,
    PORTFOLIO_INVESTMENT_ID,
    PROFILE_API_ENDPOINT,
    VERIFY_GENERATE_DOC,
} from "../../../../constants/routes";
import { mapMarker, streamLine } from "../../../../resources/js/icons";
import { signDocument } from "../../../../services/digio";
import request from "../../../../services/request";
import H2 from "../../../../shared/typography/H2";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import Line from "../../../../shared/ui/Line";
import Tag from "../../../../shared/ui/tag/Tag";
import StreamlineTree from "../StreamlineTree";

const DetailsHead = ({
    name,
    status,
    date,
    totalPayout,
    investmentAmount,
    digioStatus,
    investmentId,
    currentTab,
    plant,
}) => {
    const verifyGenerateDocumentHandler = useCallback(async () => {
        try {
            const verifyResponse = await request.authPost({
                endpoint: VERIFY_GENERATE_DOC,
                body: {
                    investmentId: investmentId,
                },
                errorData: true,
            });
            if (
                verifyResponse.status === "SUCCESS" &&
                verifyResponse.data.investment
            ) {
                // setPortfolioData(verifyResponse);
            }
        } catch (error) {}
    }, [investmentId]);
    const signGeneratedDocumentHandler = useCallback(async () => {
        try {
            const response = await request.authGet({
                endpoint: PROFILE_API_ENDPOINT,
                errorData: true,
            });

            let res = await request.authGet({
                endpoint: `${PORTFOLIO_INVESTMENT_ID}/${investmentId}`,
            });

            let identifier;
            if (
                res?.data?.investment &&
                res?.data?.investment?.investorIdentifierType === "email"
            ) {
                identifier = response?.data?.investor_profile.email;
            } else {
                let phoneNumber = response?.data?.investor_profile?.phoneNumber;
                if (phoneNumber.startsWith("91")) {
                    phoneNumber = phoneNumber.substring(2);
                }
                identifier = phoneNumber.slice(-10);
            }

            await signDocument(
                res?.data?.investment?.digioDocId,
                identifier,
                verifyGenerateDocumentHandler
            );

            return;
        } catch (error) {
            if (error?.data?.code === "EXCEEDED") {
                return;
            }
            toast.error(
                error?.message && typeof error?.message === "string"
                    ? error?.message
                    : ERROR_MSG
            );
            return;
        }
    }, [investmentId, verifyGenerateDocumentHandler]);

    const singDocumentHandler = useCallback(async () => {
        try {
            const response = await request.authPost({
                endpoint: GENERATE_DOC_ENDPOINT,
                body: {
                    investmentId: investmentId,
                    signType: "electronic",
                },
                errorData: true,
            });

            const identifier = response?.data?.investor_identifier;
            await signDocument(
                response?.data?.document_id,
                identifier,
                verifyGenerateDocumentHandler
            );

            return;
        } catch (error) {
            toast.error(
                error?.message && typeof error?.message === "string"
                    ? error?.message
                    : ERROR_MSG
            );
            return;
        }
    }, [investmentId, verifyGenerateDocumentHandler]);
    return (
        <div>
            <H2 className="mb-0.8 text-white font-vietnam">{name}</H2>
            <div className="flex justify-between">
                <P className="text-gray-500 font-vietnam">
                    {date ? moment(date * 1000).format("DD/MM/yyyy") : ""}
                </P>
                <div className="flex items-center  mb-0.8">
                    <Tag
                        text={status}
                        className="mr-1.6 rounded-full "
                        textClass="text-green-400 font-vietnam"
                    />
                    <IoTimeOutline className="text-white text-xxl" />
                </div>
            </div>

            <div className="bg-blue-600 p-1.6 rounded-2">
                {currentTab === 0 ? (
                    <div className=" bg-black-600 p-1.6 rounded-2">
                        <div className="flex items-center gap-1.6 ">
                            <div className="h-4">
                                <img src={streamLine} alt="stream_line" />
                            </div>
                            <div className="flex justify-between items-center gap-0.2 flex-1 ">
                                <div>
                                    <P className=" font-vietnam text-gray-550 text-xs">
                                        Total Payouts
                                    </P>
                                    <P className=" font-vietnam text-white text-sm font-medium">
                                        ₹ {totalPayout}
                                    </P>
                                </div>
                                <div>
                                    <P className=" font-vietnam text-xs text-gray-550">
                                        My Investment
                                    </P>
                                    <P className=" font-vietnam text-white text-sm font-medium">
                                        ₹ {investmentAmount}
                                    </P>
                                </div>
                            </div>
                        </div>

                        <Line className="bg-gray-secondary" />
                        <div className="flex justify-between">
                            <div>
                                <P className="text-gray-550 mb-0.2">
                                    Investment Agreement
                                </P>
                                {digioStatus && (
                                    <P
                                        className={
                                            digioStatus === "SIGNED"
                                                ? "text-green-300"
                                                : "text-orange"
                                        }
                                    >
                                        {digioStatus}
                                    </P>
                                )}
                            </div>
                            <div className="flex items-center">
                                <button
                                    onClick={
                                        digioStatus === "GENERATED"
                                            ? signGeneratedDocumentHandler
                                            : singDocumentHandler
                                    }
                                    className="bg-primary text-white py-0.4 px-0.8 rounded"
                                >
                                    Click to Sign
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    /* @TODO => Fix tre values here @habib610 Sat December 07,2024 */
                    <>
                        <StreamlineTree />
                        <div className="flex justify-between  w-full mt-2.4">
                            <div>
                                <P className=" text-white font-vietnam">
                                    {plant?.city}
                                </P>
                                <P className=" font-vietnam  text-wrap flex items-center text-sm text-gray-550  gap-0.2 ">
                                    <img src={mapMarker} alt="location" />
                                    {plant?.state}
                                </P>
                            </div>
                            <div>
                                <H4 className="text-white font-medium">
                                    {plant?.capacity && plant.capacity / 1000}
                                    KWp
                                </H4>
                                <P className="text-base text-white">
                                    Plant Size
                                </P>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DetailsHead;
