import React from "react";
import { HomeBg } from "../../../resources/js/images";
import Container from "../../../shared/Container";
import H1 from "../../../shared/typography/H1";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import Hero from "../Hero";

const HeroContent = () => {
    return (
        <Hero
            containerClass={"mb-0 xl:mb-0 lg:mb-0 2xl:mb-0 bg-custom-gradient "}
            wrapperClass="bg-gray md:mx-9 min-h-[50vh] sm:min-h-[80vh]   md:max-h-[100vh]  xl:min-h-[100vh] "
            contentClass=" pt-[15rem] md:pt-0 md:items-center"
            content={
                <Container className="text-center md:w-[80%] xl:w-[70%] 2xl:w-[70%] flex flex-col items-center">
                    <H1
                        className={
                            "text-white mb-1.6 xl:mb-2.5 2xl:mb-3.2 leading-[4rem] md:leading-[6rem] xl:leading-[8.8rem]"
                        }
                    >
                        Invest in Pre-leased Solar Assets
                    </H1>
                    <P className="text-white text-sm md:text-base xl:text-xl font-nunito font-light mb-1.6 lg:mb-2 xl:mb-3.2">
                        Earn upto 20% IRR Returns
                    </P>
                    <P className="text-white text-sm md:text-base xl:text-xl font-nunito font-light mb-1.6 lg:mb-2 xl:mb-3.2">
                        SROT is an exclusive community that empowers the
                        reliable to achieve financial growth.
                    </P>
                    <Button className=" xl:text-xl 2xl:text-xxl xl:mb-2">
                        Invest now
                    </Button>
                </Container>
            }
            url={HomeBg}
        />
    );
};

export default HeroContent;
