import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";

const Coulumn = [
    {
        Header: "Date",
        accessor: "date",
    },
    {
        Header: "Mode",
        accessor: "mode",
    },
    {
        Header: "Transaction",
        accessor: "transaction",
    },
    {
        Header: "Particulars",
        accessor: "particulars",
    },
    {
        Header: "Amount",
        accessor: "amount",
        Cell: (row) => {
            return (
                <ShowBalance
                    amount={row.row.values.amount}
                    type={row.row.values.type}
                />
            );
        },
    },
    {
        Header: "Balance",
        accessor: "balance",
    },
];

export default Coulumn;

const BalanceWrap = styled(Text)`
    padding-right: 3.5rem;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        padding-right: 2.5rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding-right: 3rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding-right: 1.2rem;
    }
`;

export const ShowBalance = ({ amount }) => {
    const isCredited = amount.endsWith("Cr");
    return (
        <BalanceWrap>
            <Text align="right" size="s">
                {amount.replace(/Dr|Cr/g, "")} &nbsp;
                <InlineText color={isCredited ? "primary" : "error"}>
                    <FontAwesomeIcon
                        icon={isCredited ? faArrowUp : faArrowDown}
                    />
                </InlineText>{" "}
            </Text>
        </BalanceWrap>
    );
};
