import React from "react";
import P from "../../../../shared/typography/P";

const GalleryCard = ({ text, img }) => {
    return (
        <div className="mx-2 flex flex-col  items-start">
            <img
                src={img}
                alt="learn more"
                className="rounded-3xl w-full h-full  xl:h-[40vh]"
            />
            <P className="text-left text-wrap text-gray-secondary font-medium text-sm xl:text-sm mt-1">
                {text}
            </P>
        </div>
    );
};

export default GalleryCard;
