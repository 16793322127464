import React, { useState } from "react";
import { GoArrowDown } from "react-icons/go";
import { enquiryList } from "../../../constants/EnquiryData";
import { ERROR_MSG } from "../../../constants/common";
import { SET_ERROR } from "../../../constants/contexConstant";
import { ENQUIRY_CONTACT_API_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { validate } from "../../../helpers/fieldValidation";
import { check } from "../../../resources/js/images";
import { handleResentOTP } from "../../../services/apiRequest";
import request from "../../../services/request";
import H4 from "../../typography/H4";
import P from "../../typography/P";
import Button from "../Button";
import Dropdown from "../Dropdown/Dropdown";
import OTP from "../OTP";
import TextField from "./TextField";

const ContactForm = ({
    wrapperClass = " w-full bg-white shadow-md py-5.6 px-4 rounded-[3rem]",
    disabled = false,
    enquiry,
    callback,
}) => {
    const { dispatch } = useContextState();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        enquiry: enquiry ? enquiry : "Looking to Purchase Solar",
        otp: "",
    });
    const [done, setDone] = useState(false);
    const [customEnquiry, setCustomEnquiry] = useState("");
    const [errors, setErrors] = useState({});
    const [getOtp, setGetOtp] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCustomEnquiry = (e) => {
        setCustomEnquiry(e.target.value);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };

    const DropdownHandler = (value) => {
        setFormData({ ...formData, enquiry: value });
        setErrors({ ...errors, enquiry: "" });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const rules = {
            firstName: { required: true },
            lastName: { required: false },
            email: { required: false, pattern: /\S+@\S+\.\S+/ },
            phoneNumber: { required: true, pattern: /^\d{10}$/ },
            enquiry: { required: true },
        };
        if (getOtp) rules.otp = { required: true };

        const validationErrors = validate(formData, rules);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            try {
                let reqData = {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phone: `91${formData.phoneNumber}`,
                    querySource: window.location.href,
                    queryMessage: formData.enquiry,
                    otp: getOtp ? formData.otp : undefined,
                };

                let endpoint = ENQUIRY_CONTACT_API_ENDPOINT;
                setLoading(true);
                let res = await request.post({
                    endpoint,
                    body: reqData,
                });
                setLoading(false);

                if (!getOtp) {
                    setGetOtp(true);
                } else {
                    setDone(true);
                    callback && callback();
                    setTimeout(() => {
                        setDone(false);
                        setFormData({
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNumber: "",
                            enquiry: "Looking to Purchase Solar",
                            otp: "",
                        });
                        setGetOtp(false);
                        callback && callback();
                    }, 5000);
                }
            } catch (error) {
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };

    const handleDone = () => {
        setDone(false);
        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            enquiry: "Looking to Purchase Solar",
            otp: "",
        });
        setGetOtp(false);
    };

    const resentOtpHandler = async (mode) => {
        const rules = {
            firstName: { required: true },
            lastName: { required: true },
            email: { required: true, pattern: /\S+@\S+\.\S+/ },
            phoneNumber: { required: true, pattern: /^\d{10}$/ },
            enquiry: { required: true },
        };

        const validationErrors = validate(formData, rules);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            let credentials = `91${formData.phoneNumber}`;
            await handleResentOTP(mode, credentials, setLoading, dispatch);
        }
    };
    return (
        <div className={` mx-auto ${wrapperClass}  `}>
            {done ? (
                <>
                    <div className="flex flex-col items-center">
                        <img src={check} alt="check" className="w-44 h-44" />
                        <P className={"text-center w-[70%] my-5"}>
                            An email will be sent to your registered email id
                            and we will contact you shortly to confirm your site
                            booking!
                        </P>
                    </div>
                    <div className="mt-2 flex">
                        <Button
                            // type="submit"
                            onClick={handleDone}
                            className="w-full flex justify-center px-1  sm:px-1.8  2xl:px-2 py-0.8 sm:py-1.5 xl:py-1.5 2xl:py-1.6"
                        >
                            Done
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <H4 className={"  mb-1.6"}>
                        Fill out your details and we'll get back to you ASAP!
                    </H4>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2  xl:mt-3.6">
                            <TextField
                                label={"First Name"}
                                value={formData?.firstName}
                                onChange={handleChange}
                                name="firstName"
                                type={"text"}
                                errorMsg={errors?.firstName}
                                required={true}
                            />
                            <TextField
                                label={"Last Name"}
                                value={formData?.lastName}
                                onChange={handleChange}
                                name={"lastName"}
                                type={"text"}
                            />
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 mt-1.6 lg:gap-x-[3rem] xl:gap-x-3.2 xl:mt-3.6 ">
                            <TextField
                                label={"Email"}
                                value={formData?.email}
                                onChange={handleChange}
                                name={"email"}
                                type={"text"}
                                errorMsg={errors?.email}
                            />
                            <TextField
                                label={"Phone Number"}
                                value={formData?.phoneNumber}
                                onChange={handleChange}
                                name={"phoneNumber"}
                                type={"text"}
                                errorMsg={errors?.phoneNumber}
                                required={true}
                            />
                        </div>

                        <div className="my-1.6 xl:my-3.6">
                            <label
                                className="text-gray-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                                htmlFor={"enquiry"}
                            >
                                Select an enquiry
                            </label>
                            <Dropdown
                                selected={formData?.enquiry}
                                onSelect={DropdownHandler}
                                data={enquiryList}
                                icon={<GoArrowDown />}
                                disabled={disabled}
                            />
                        </div>
                        {formData?.enquiry === "Other" && (
                            <div className="my-1.6 xl:my-3.6">
                                <TextField
                                    label={"Enter Enquiry"}
                                    value={customEnquiry}
                                    onChange={handleCustomEnquiry}
                                    name={"enquiry"}
                                    type={"text"}
                                    errorMsg={errors?.enquiry}
                                />
                            </div>
                        )}
                        {getOtp && (
                            <OTP
                                otp={formData.otp}
                                onChange={handleChange}
                                error={errors?.otp ? true : false}
                                errorMessage={errors.otp}
                                handleResentOTP={resentOtpHandler}
                            />
                        )}
                        <div className="mt-2">
                            <Button
                                type="submit"
                                isLoading={loading}
                                disabled={loading}
                                onClick={handleSubmit}
                                className="w-full flex justify-center px-1  sm:px-1.8  2xl:px-2 py-0.8 sm:py-1.5 xl:py-1.5 2xl:py-1.6"
                            >
                                {getOtp ? "Call Back" : "Get OTP"}
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

export default ContactForm;
