import React from "react";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";

import StreamlineTree from "../../../portfoilo/mobile/StreamlineTree";
import OfferCarousel from "../OfferCarousel";

const ProfileCard = ({ investmentHave, profileData, portfolioData }) => {
    return (
        <div className="bg-black-600 p-2 mb-2 rounded-[3rem] gap-2 pt-2 flex flex-wrap xl:flex-nowrap justify-between ">
            <div className=" w-[90%] xl:w-[80%] flex flex-col items-start ">
                <div className="flex gap-0.6  items-center mt-2 ">
                    <P className="text-gray-550 xl:text-base">Good Morning,</P>
                    <H4 className="text-white xl:text-lg">
                        {profileData?.name}
                    </H4>
                </div>

                {investmentHave ? (
                    <div className="flex flex-col gap-0.5  my-2">
                        <P className="text-gray-550 xl:text-sm">
                            Portfolio Value
                        </P>
                        <H4 className="text-white font-medium xl:text-lg">
                            ₹ {portfolioData?.data?.portfolio?.portfolioValue}
                        </H4>
                    </div>
                ) : (
                    <div className=" py-1 flex  gap-2 items-center ">
                        <div className="flex lg:w-[60%] flex-col items-center gap-1.6  bg-black-400 p-1.6 rounded-t-2">
                            <H4 className="text-gray-500 text-nowrap">
                                Avg Monthly Users
                            </H4>
                            <H4 className="text-white font-semibold">10k+</H4>
                        </div>
                        <div className="flex-1 flex text-center flex-col items-center gap-1.6  bg-black-400 p-1.6 rounded-t-2">
                            <H4 className="text-gray-500">Projects</H4>
                            <H4 className="text-white font-semibold">150+</H4>
                        </div>
                    </div>
                )}
                <StreamlineTree
                    className="bg-linear-blue-white w-full "
                    subText="text-white xl:text-sm mb-1"
                    headingText="text-white xl:!text-lg font-normal"
                />
            </div>
            <div className=" w-full xl:w-[50%]">
                <OfferCarousel />
            </div>
        </div>
    );
};

export default ProfileCard;
