import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Space = styled(({ margin, padding, children, ...props }) =>
    React.cloneElement(children, props)
)`
    &&& {
        margin: ${(props) => (props.margin ? `${props.margin}` : "")};
        padding: ${(props) => (props.padding ? `${props.padding}` : "")};
        @media (max-width: ${(props) => props.theme.breakPoints.bigTablet}) {
            margin: ${(props) =>
                props.tabletmargin ? `${props.bigtabletmargin}` : ""};
            padding: ${(props) =>
                props.tabletpadding ? `${props.bigtabletpadding}` : ""};
        }
        @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
            margin: ${(props) =>
                props.tabletmargin ? `${props.tabletmargin}` : ""};
            padding: ${(props) =>
                props.tabletpadding ? `${props.tabletpadding}` : ""};
        }
        @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            margin: ${(props) =>
                props.mobilemargin ? `${props.mobilemargin}` : ""};
            padding: ${(props) =>
                props.mobilepadding ? `${props.mobilepadding}` : ""};
        }
        @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
            margin: ${(props) =>
                props.smallmobilemargin ? `${props.smallmobilemargin}` : ""};
            padding: ${(props) =>
                props.smallmobilepadding ? `${props.smallmobilepadding}` : ""};
        }
`;

Space.propTypes = {
    margin: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    padding: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
};

export default Space;
