import React, { useState } from "react";
import cn from "../../../../lib/cn";
import P from "../../../../shared/typography/P";
import Line from "../../../../shared/ui/Line";
import CalculatorRangeSlider from "../../mobile/project-details/CalculatorRangeSlider";
import Overview from "../../mobile/project-details/Overview";

const overview = [
    {
        name: "Solar assets owned",
        value: "1.5kWp",
    },
    {
        name: "Tenure",
        value: "5 years",
    },
    {
        name: "IRR",
        value: "20.88%",
    },
];
const glance = [
    {
        name: "Investment Tenure",
        value: "5 years",
    },
    {
        name: "IRR",
        value: "19 %",
    },
    {
        name: "Monthly Payout",
        value: "₹433",
    },
    {
        name: "Non taxable payouts",
        value: "₹32000",
    },
];

const rangeValue = {
    min: 0,
    max: 100,
};
const CalculatorDash = () => {
    const [range, setRange] = useState(50);
    const handleChangeInvestment = (e) => {
        let value = e.target.value;
        if (
            !isNaN(value) &&
            rangeValue.max >= value &&
            rangeValue.min <= value
        ) {
            setRange(value);
        }
    };
    const handleSliderChange = (value) => {
        setRange(value);
    };
    const calculateThumbPosition = () => {
        const percentage =
            ((range - rangeValue.min) / (rangeValue.max - rangeValue.min)) *
            100;
        return `calc(${percentage}% - 20px)`; // Adjust -20px to center the text over the thumb if needed
    };
    return (
        <div className="flex flex-wrap xl:flex-nowrap justify-between items-center gap-5">
            <div className=" w-full xl:w-[60%] p-3  bg-gray-50 border border-gray-400 rounded-[1.2rem]">
                <P className="text-gray mb-3 md:text-sm xl:text-sm font-semibold">
                    Calculate your payouts & impact
                </P>
                <div className="flex justify-between mb-4 items-center">
                    <P className="text-gray mb-0.8 md:text-sm xl:text-sm">
                        Enter Investment amount
                    </P>
                    <input
                        className={cn(
                            "py-0.5 px-0.5 text-sm xl:text-base border-2 rounded-lg overflow-hidden w-full sm:w-[200px] outline-none border-primary"
                        )}
                        onChange={handleChangeInvestment}
                        value={range}
                    />
                </div>
                <div className="relative mb-2">
                    <P
                        className="text-primary md:text-sm xl:text-sm text-nowrap z-101 absolute -translate-y-3 -translate-x-1/2 mb-1.6 text-base font-medium transition-all"
                        style={{
                            left: calculateThumbPosition(),
                        }}
                    >
                        ₹ {range}
                    </P>
                    <CalculatorRangeSlider
                        onChange={(e) => handleSliderChange(e.target.value)} // Using the function to handle slider change
                        min={rangeValue.min}
                        max={rangeValue.max}
                        value={range}
                    />
                </div>

                <div className="flex justify-between">
                    <P className="text-gray-550 md:text-sm xl:text-sm">
                        {rangeValue.min}
                    </P>
                    <P className="text-gray-550 md:text-sm xl:text-sm">
                        {rangeValue.max} Cr
                    </P>
                </div>
            </div>
            <div className="w-full xl:w-[50%]">
                <Overview data={overview} className="py-2.4" />
                <Line />
                <div className="grid grid-cols-2 gap-y-2.4">
                    {glance.map((item, i) => (
                        <div key={i}>
                            <P className="text-gray-550 mb-1 text-xs md:text-sm xl:text-sm">
                                {item.name}
                            </P>
                            <P className="text-gray font-medium font-vietnam ">
                                {item.value}
                            </P>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CalculatorDash;
