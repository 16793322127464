import React from "react";
import cn from "../../lib/cn";
import { coin } from "../../resources/js/icons";
import P from "../typography/P";

const Coin = ({ value, className, textClass }) => {
    return (
        <div
            className={cn(
                "bg-linear-orangish-yellow py-0.4 px-0.8 inline-flex items-center gap-0.4 rounded-full ",
                className
            )}
        >
            <img src={coin} alt="referral coin" />
            <P className={cn("font-medium ", textClass)}>{value}</P>
        </div>
    );
};

export default Coin;
