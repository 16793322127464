import React from "react";
import cn from "../../lib/cn";
import Counter from "../../shared/animation/typography/Counter";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";

const details = [
    {
        heading: "Capacity",
        value: 20,
        name: "+ MW (MW)",
        status: "Installed",
    },
    {
        heading: "Projects",
        value: 20,
        name: "20+ Projects",
        status: "Completed",
    },
    {
        heading: "CO2",
        value: 48000,
        name: "+t CO2",
        status: "annually",
    },
];

const ImpactContent = () => {
    return (
        <div className="bg-gray/50 bg-opacity-100 w-full">
            <Container className=" h-full">
                <div className="h-full flex flex-col justify-center">
                    <div className="flex justify-center">
                        <H1 className=" w-full md:w-8/12 xl:w-6/12 text-[3rem] font-bold text-center text-white">
                            Our solar projects Helping the Earth.
                        </H1>
                    </div>
                    <div className="rounded-t-2.5 xl:rounded-t-4  bg-linear-transparent-white grid grid-cols-3  mt-6">
                        {details.map((item, index) => (
                            <div
                                key={index}
                                className={cn(
                                    " border-r border-gray-550 p-1.6 md:py-2 md:px-3.2 xl:py-3 xl:px-4.8 2xl:py-4 2xl:px-6",
                                    {
                                        "border-r-0":
                                            index === details.length - 1,
                                    }
                                )}
                            >
                                <H2 className="text-white font-medium text-base xl:text-4xl mb-0.8">
                                    {item.heading}
                                </H2>
                                <H2 className="text-white text-sm xl:text-3xl 2xl:text-4.0xl ">
                                    <Counter value={item.value} />
                                    {item.name}
                                </H2>
                                <H2 className="text-white text-sm xl:text-3xl 2xl:text-4.0xl">
                                    {item.status}
                                </H2>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ImpactContent;
