import React, { useState } from "react";
import cn from "../../../lib/cn";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Coin from "../../../shared/ui/Coin";
import FriendEarningsCard from "../../home/ExistingUser/NewUser/FriendEarningsCard";
import { referralList } from "../mobile/Referral";
const ALL_REFERRAL = "All referrals";

const INVESTED = "Invested";
const SIGNED_UP = "Signed Up";
const PENDING = "Pending";
const CANCELLED = "Cancelled";
const RightSide = () => {
    const [activeTab, setActiveTab] = useState("All referrals");
    const [activeReferral, setActiveReferral] = useState(referralList);

    const handleReferralTab = (tab) => {
        if (tab === ALL_REFERRAL) {
            setActiveReferral(referralList);
        } else {
            let filtered = referralList.filter(
                (item) => item.status === INVESTED || item.status === SIGNED_UP
            );
            setActiveReferral(filtered);
        }
        setActiveTab(tab);
    };
    return (
        <div className="bg-gray-60 mx-2 xl:mx-3 rounded-3xl py-1 px-1 xl:p-3">
            <H4 className="font-semibold mb-2 md:text-base xl:text-base">
                Referrals
            </H4>
            <FriendEarningsCard />
            <div className="w-10/12 mx-auto gap-1 rounded-b-2 bg-linear-button p-1.6 pt-1 flex justify-between  flex-wrap mb-2.4">
                <div>
                    <P className="text-white mb-1 md:text-sm xl:text-sm">
                        Referral points earned
                    </P>
                    <div>
                        <Coin
                            value={500}
                            className="bg-linear-button-reverse"
                            textClass="text-white md:text-sm  xl:text-sm"
                        />
                    </div>
                </div>
                <div className="w-px h-3 bg-white mt-2"></div>
                <div>
                    <P className="text-white mb-1 md:text-sm xl:text-sm">
                        Friends joined
                    </P>
                    <P className="text-white mb-0.8 font-medium md:text-sm text-base">
                        04
                    </P>
                </div>
            </div>
            <P className="text-gray font-semibold md:text-sm xl:text-sm">
                Share code
            </P>
            <P className="my-0.8 text-gray-550 md:text-sm xl:text-sm">
                Share your unique referral code with friends
            </P>
            <div className="flex   items-center flex-wrap gap-2">
                <div className="text-base w-2/3 bg-gray-400  rounded-sm">
                    <div className=" px-1 line-clamp-1 md:text-sm xl:text-sm  py-0.8 text-gray-550 ">
                        johndoe/referral/INVITE2345
                    </div>
                </div>
                <div className="flex items-center gap-1">
                    <div className="h-4 w-4 rounded-sm border-2 border-gray-550 flex items-center justify-center">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className="h-4 w-4 rounded-sm border-2 border-gray-550 flex items-center justify-center text-gray-550">
                        <div className="">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.96 6.16992C18.96 7.55992 20.34 9.76992 20.62 12.3199"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M3.48999 12.3697C3.74999 9.82973 5.10999 7.61973 7.08999 6.21973"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M8.18994 20.9404C9.34994 21.5304 10.6699 21.8604 12.0599 21.8604C13.3999 21.8604 14.6599 21.5604 15.7899 21.0104"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12.06 7.69965C13.5954 7.69965 14.84 6.455 14.84 4.91965C14.84 3.3843 13.5954 2.13965 12.06 2.13965C10.5247 2.13965 9.28003 3.3843 9.28003 4.91965C9.28003 6.455 10.5247 7.69965 12.06 7.69965Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M4.83005 19.9204C6.3654 19.9204 7.61005 18.6757 7.61005 17.1404C7.61005 15.605 6.3654 14.3604 4.83005 14.3604C3.2947 14.3604 2.05005 15.605 2.05005 17.1404C2.05005 18.6757 3.2947 19.9204 4.83005 19.9204Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M19.1699 19.9204C20.7052 19.9204 21.9499 18.6757 21.9499 17.1404C21.9499 15.605 20.7052 14.3604 19.1699 14.3604C17.6345 14.3604 16.3899 15.605 16.3899 17.1404C16.3899 18.6757 17.6345 19.9204 19.1699 19.9204Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex mb-4 gap-2 border-b border-b-blue-50 mt-2.4">
                {["All referrals", "Successful referrals"].map((tab) => (
                    <div
                        key={tab}
                        onClick={() => handleReferralTab(tab)}
                        className={`flex items-center border-b-2 gap-1 pb-1 ${
                            activeTab === tab
                                ? "text-blue-500  border-b-blue-500"
                                : "text-gray-500 border-b-transparent"
                        }`}
                    >
                        <P
                            className={` md:text-sm xl:text-sm ${
                                activeTab === tab
                                    ? "text-blue-500 "
                                    : "text-gray-500"
                            }`}
                        >
                            {tab}
                        </P>
                    </div>
                ))}
            </div>
            <div className="mt-3.2">
                {activeReferral.map((item, i) => (
                    <div
                        key={i}
                        className="flex justify-between gap-2 items-center mb-2.4  "
                    >
                        <div>
                            <P className="text-gray mb-1 md:text-sm xl:text-sm">
                                {item.name}
                            </P>
                            <P className="md:text-sm xl:text-sm">
                                {item.email}
                            </P>
                        </div>
                        <div
                            className={cn(
                                "inline-block py-0.4 px-0.8 rounded-full",
                                {
                                    "bg-green/60": item.status === INVESTED,
                                    "bg-blue-50/50": item.status === SIGNED_UP,
                                    "bg-red/40": item.status === CANCELLED,
                                    "bg-red/10": item.status === PENDING,
                                }
                            )}
                        >
                            <P className="text-gray md:text-sm xl:text-sm">
                                {item.status}
                            </P>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RightSide;
