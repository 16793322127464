import React from "react";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import SubHeading from "../../shared/typography/SubHeading";
const TeamCard = ({ member }) => {
    return (
        <div className="xl:bg-white lg:shadow-sm lg:rounded-2 lg:p-2">
            <div className="h-72 bg-gray-200 lg:rounded-lg mb-2 flex items-center justify-between">
                {member.imageUrl ? (
                    <img
                        src={member.imageUrl}
                        alt={member.name}
                        className="h-full w-full object-cover rounded-lg"
                    />
                ) : (
                    ""
                )}
            </div>
            <H4 className="font-medium">{member.name}</H4>
            <P className="text-gray-secondary my-0.4 xl:text-xl">
                {member.position}
            </P>
            <P className="text-gray-600 ">{member.description}</P>
        </div>
    );
};

const teamMembers = [
    {
        name: "Pravin Sambhaji Langote",
        position: "Co-Founder & CEO",
        imageUrl: "",
        description:
            "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus.",
    },
    {
        name: "Vikas Rana",
        position: "Co-Founder & CEO",
        imageUrl: "",
        description:
            "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus.",
    },
    {
        name: "Dr. Vinay Hasabnis",
        position: "Co-Founder & CEO",
        imageUrl: "",
        description:
            "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus.",
    },
];
const LeadershipTeam = () => {
    return (
        <Section className={"bg-gray-50 py-3 mb-0 xl:mb-0 lg:mb-0 2xl:mb-0"}>
            <Container className="my-3">
                <div className="mb-1">
                    <H2 className={"text-start !font-normal"}>
                        Leadership team
                    </H2>

                    <SubHeading>
                        Meet our leadership team, a group of experienced
                        professionals dedicated to guiding our vision and
                        driving our success.
                    </SubHeading>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-4 xl:gap-5 2xl:gap-7 mt-3 xl:mt-5 2xl:mt-6">
                    {teamMembers.map((member, index) => (
                        <TeamCard key={index} member={member} />
                    ))}
                </div>
            </Container>
        </Section>
    );
};

export default LeadershipTeam;
