import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FiUpload } from "react-icons/fi";
import styled from "styled-components";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
const InputWarper = styled.div`
    input {
        display: none;
    }
    label {
        display: flex;
        padding: 5px 10px;
        background-color: ${(props) => props.theme.color.grey350};
        border-radius: 5px;
        :hover {
            cursor: pointer;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.33);
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        /* width: 90px;
        height: 90px; */
        /* label {
            font-size: ${(props) => props.theme.fontSize.default.m};
        } */
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        /* width: 70px;
        height: 70px;
        label {
            font-size: ${(props) => props.theme.fontSize.default.m};
        } */
    }
`;
const TrashIcon = styled(Button)`
    padding: 5px;
    border: none;
    margin-right: 10px;
    :hover,
    :active,
    :focus {
        box-shadow: none;
    }
`;

const UploadImage = ({
    name,
    onChange,
    media,
    errorMessage = "",
    onClick,
    label,
}) => {
    return (
        <div
            style={{
                display: "inline-block",
            }}
        >
            <InputWarper>
                <div>
                    <input
                        onChange={onChange}
                        onClick={(e) => (e.target.value = null)}
                        name={name}
                        accept="*"
                        type="file"
                        id={name}
                    />
                    {!media && (
                        <label htmlFor={name}>
                            <Text size="s" color="darkGrey">
                                {label}
                            </Text>{" "}
                            <InlineText size="s" color="darkGrey">
                                <FiUpload style={{ marginLeft: "7px" }} />
                            </InlineText>
                        </label>
                    )}
                </div>
            </InputWarper>
            {errorMessage && (
                <Text color="error" style={{ marginTop: "5px" }} size="xs">
                    {errorMessage}
                </Text>
            )}
            {media && (
                <Flex.Container
                    style={{ marginTop: "5px" }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text size="xs">{media}</Text>
                    <TrashIcon
                        onClick={() => onClick(name)}
                        size="s"
                        color="error"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </TrashIcon>
                </Flex.Container>
            )}
        </div>
    );
};

export default UploadImage;
