import React from "react";
import { useHistory } from "react-router-dom";
import { PORTFOLIO } from "../../../../constants/routes";
import { checkFilled, paymentFailure } from "../../../../resources/js/icons";
import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";

const data = {
    success: {
        title: "Payment successful",
        img: checkFilled,
        message:
            "Congrats on becoming a Carbon Warrior! You will receive an email with confirmation as well as all information related to this project.",
    },
    failure: {
        title: "Payment Unsuccessful",
        img: paymentFailure,
        message:
            " We apologize for the inconvenience. Your payment was unsuccessful. Please try again or contact our support team  for assistance.",
    },
};

const PaymentMessage = ({ success, onClick }) => {
    const payment = success ? data.success : data.failure;

    const history = useHistory();
    return (
        <div className=" flex-1 flex flex-col justify-between h-full pb-1.2">
            <div className="w-full flex flex-col justify-center items-center mt-5 ">
                <img
                    src={payment.img}
                    alt="payment_confirmation"
                    className={`w-[${success ? "140" : "171"}px] mb-2.4`}
                />
                <P className="text-gray mb-1.6 font-medium text-center mb.1.6 font-vietnam">
                    {payment.title}
                </P>
                <P className="text-center mb-4 font-vietnam">
                    {payment.message}
                </P>
            </div>

            <div className="w-full  flex flex-col gap-1.2">
                {success ? (
                    <Button
                        onClick={() => history.push(PORTFOLIO)}
                        className="bg-gray rounded-full w-full  border-solid border border-gray text-white font-vietnam"
                    >
                        Go back to Portfolio
                    </Button>
                ) : (
                    <>
                        <Button
                            onClick={() => onClick(1)}
                            className="bg-gray rounded-full w-full"
                        >
                            Try Again
                        </Button>
                        <Button
                            onClick={() => onClick(1)}
                            className="bg-gray rounded-full w-full"
                        >
                            Go back to Investment
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PaymentMessage;
