import React, { useEffect, useState } from "react";
import Project, { ACTIVE, COMPLETED, UPCOMING } from "./mobile/index";

import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import { OPPORTUNITY_ENDPOINT } from "../../constants/routes";
import request from "../../services/request";
import RightPart from "../project/ProjectDetails/RightSide";
import LeftSide from "./LeftSide";

const Projects = () => {
    const [cardData, setCardData] = useState([]);
    const [tabData, setTabData] = useState([
        { name: ACTIVE, value: 12 },
        { name: UPCOMING, value: 2 },
        { name: COMPLETED, value: 1 },
    ]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                let active = 0;
                let upComing = 0;
                let completed = 0;
                const response = await request.get({
                    endpoint: OPPORTUNITY_ENDPOINT,
                });
                const assets = response.data?.assets || [];

                const updatedCardData = assets.map((asset) => {
                    const plant = asset.plants?.[0] || {};
                    const investmentTerms = asset.investmentTerms?.[0] || {};

                    if (asset.listingStatus === "FUNDING") active++;
                    else completed++;

                    if (new Date().getSeconds() < asset.startDate) upComing++;

                    return {
                        id: asset.listingId,
                        type: asset.tag || "Telecom Industry",
                        headings: "Tata Communications Bangalore",
                        name: `${plant.name}, ${plant.city || ""}`,
                        location: `${plant.city || ""}, ${plant.state || ""}`,
                        plantSize: `${
                            parseInt(plant.capacity || "0") / 1000
                        } KWp`,
                        status: "Funding",
                        remains: "10",
                        category: ACTIVE,
                        progress: Math.min(
                            100,
                            Math.round(
                                (parseFloat(asset.fundRaised || 0) /
                                    parseFloat(asset.fundLimit || 1)) *
                                    100
                            )
                        ),
                        details: [
                            {
                                id: 1,
                                name: "Returns (IRR)",
                                value: `${
                                    investmentTerms.targetedIRRPercentage || "0"
                                }%`,
                            },
                            {
                                id: 2,
                                name: "Minimum Investment",
                                value: `₹${parseFloat(
                                    asset.listingMinLimit || 0
                                ).toLocaleString()}`,
                            },
                            {
                                id: 3,
                                name: "Investment term",
                                value: `${
                                    investmentTerms.installments
                                        ? investmentTerms.installments / 12
                                        : "5"
                                } years`,
                            },
                        ],
                    };
                });

                setTabData([
                    { name: ACTIVE, value: active },
                    { name: UPCOMING, value: upComing },
                    { name: COMPLETED, value: completed },
                ]);

                setCardData(updatedCardData);
            } catch (error) {
                toast.error(error || ERROR_MSG);
            }
        };
        fetchProjects();
    }, []);

    return (
        <>
            <div className="lg:container">
                <div className="hidden mt-5  pt-5 lg:flex font-vietnam justify-between items-start">
                    <div className="w-[65%]">
                        <LeftSide tabData={tabData} cardData={cardData} />
                    </div>
                    <div className="w-[35%]">
                        <div className="sticky top-5 lg:top-5">
                            <RightPart />
                        </div>
                    </div>
                </div>
                <div className="lg:hidden">
                    <Project tabData={tabData} cardData={cardData} />
                </div>
            </div>
        </>
    );
};

export default Projects;
