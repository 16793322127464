import React from "react";
import cn from "../../lib/cn";
import Section from "../../shared/Section";

const Hero = ({
    content,
    url,
    align,
    type,
    wrapperClass,
    containerClass,
    contentClass,
}) => {
    return (
        <Section className={containerClass}>
            <div
                className={cn(
                    "relative h-[593px] sm:h-[993px] xl:h-screen ",
                    wrapperClass
                )}
            >
                {type === "video" ? (
                    <video
                        src={url}
                        className="absolute inset-y-0 inset-x-0 h-full w-full object-cover"
                        autoPlay
                        loop
                        muted
                        style={{ zIndex: 2 }}
                    />
                ) : (
                    <img
                        src={url}
                        className="absolute inset-y-0 inset-x-0 h-full w-full object-cover"
                        alt="hero"
                    />
                )}

                <div
                    className={cn(
                        `absolute inset-x-0 inset-y-0 h-full  flex isolate`,
                        contentClass
                    )}
                >
                    {content}
                </div>
            </div>
        </Section>
    );
};

export default Hero;
