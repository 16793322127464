import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import SplitType from "split-type";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";

const SrotBenefit = ({ containerClass }) => {
    const textRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const char = textRef.current;
        const text = new SplitType(char, { types: "words,chars" });

        gsap.fromTo(
            text.chars,
            {
                opacity: 0.3,
            },
            {
                opacity: 1,
                stagger: 0.02,
                scrollTrigger: {
                    trigger: char,
                    start: "top 80%",
                    end: "top 20%",
                    scrub: true,
                    markers: false,
                },
            }
        );

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => {
                trigger.kill();
            });
        };
    }, []);

    return (
        <Section className="bg-black mb-0 lg:mb-0 xl:mb-0 2xl:mb-0">
            <Container>
                <div className={cn(containerClass)}>
                    <div className="flex flex-col gap-3 py-6 xl:py-[12rem] w-full xl:w-[80%]">
                        <p className="text-white border border-white text-center p-1 rounded-full w-36">
                            Why SROT?
                        </p>
                        <div className="flex gap-3 justify-between md:justify-start items-center">
                            <H2 className="text-white">Up to</H2>
                            <H1 className="text-white">20%</H1>
                            <H2 className="text-white">IRR</H2>
                        </div>

                        <H3 className="text-xxl font-nunito  font-light">
                            <span ref={textRef} className="text-white">
                                We specialize in providing clean and reliable
                                solar energy solutions. Our advanced solar power
                                plants help businesses and communities
                                transition to sustainable energy, reducing both
                                costs and carbon footprints. With a focus on
                                innovation and efficiency, we are dedicated to
                                powering a greener future. Join us in harnessing
                                the power of the sun for a better tomorrow
                            </span>
                        </H3>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default SrotBenefit;
