import React, { useState } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import cn from "../../../lib/cn";
import {
    blueRewards,
    darkPinkRewards,
    greenRewards,
    growth_bonus,
    orangeRewards,
    successStep,
    wealth_booster,
} from "../../../resources/js/icons";
import Container from "../../../shared/Container";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";

export const rewardsList = [
    blueRewards,
    darkPinkRewards,
    greenRewards,
    orangeRewards,
    blueRewards,
    darkPinkRewards,
    greenRewards,
    orangeRewards,
];

const unlocksData = [
    {
        name: "Wealth Booster",
        subHeading: "You’ve unlocked a new reward!",
        desc: "Get additional +0.25% returns on your investment, if you invest in 1st week of every month. This coupon can be claimed multiple time, once per month per user.",
        src: wealth_booster,
    },
    {
        name: "Success Step",
        subHeading: "Rewards Locked",
        desc: "Unlock this reward to get ₹1500 cashback when your investments reach ₹2,00,000.",
        src: successStep,
    },
    {
        name: "Growth Bonus",
        subHeading: "You’ve unlocked a new reward!",
        desc: "Receive cashback of ₹1000 cashback when your investments reach ₹1,00,000 or more.",
        src: growth_bonus,
    },
    {
        name: "Wealth Bonus",
        desc: "Get additional +0.25% returns on your investment, if you invest in 1st week of every month. This coupon can be claimed multiple time, once per month per user.",
        src: wealth_booster,
    },
];
const EarnRewards = () => {
    const [showRewardsStep, setShowRewardsStep] = useState(false);
    return (
        <>
            <div>
                <P className="text-base text-gray mb-3.2">Unlock rewards</P>
                <div className="flex gap-3.2 h-[300px]">
                    <div className="w-4 flex flex-col h-full overflow-y-auto no-scrollbar ">
                        <CheckedItem className="" />
                        <CheckedItem className="" />
                        <CheckedItem className="" />
                        <CheckedItem className="bg-green-300" rounded />
                        <CheckedItem className="bg-green-300" rounded />
                        <CheckedItem className="bg-green-300" rounded />
                        <CheckedItem className="bg-green-300" rounded />
                        <CheckedItem className="bg-green-300" rounded />
                        <CheckedItem className="bg-green-300" rounded />
                        <CheckedItem className="bg-green-300" rounded />
                        <CheckedItem className="bg-green-300" rounded />
                    </div>
                    <div className=" h-full overflow-y-auto no-scrollbar flex-1 grid grid-cols-2  gap-x-2.4 gap-y-3.2">
                        {rewardsList.map((item, i) => (
                            <div
                                role="button"
                                onClick={() => setShowRewardsStep(true)}
                                key={i}
                                className="w-full h-[124px] relative"
                            >
                                <img
                                    src={item}
                                    alt="rewards"
                                    className="w-full h-full"
                                />
                                <div className="text-xs bg-gray-400 absolute left-[50%] -translate-x-[50%] -bottom-1 text-primary inline-block px-1 text-nowrap py-0.2 rounded-full">
                                    2 days left
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    "z-101 fixed transition-all duration-300 bg-black-600/30 flex items-center",
                    {
                        "inset-y-full overflow-hidden h-0": !showRewardsStep,
                        "inset-y-0 inset-x-0 ": showRewardsStep,
                    }
                )}
            >
                <RewardSteps onClick={setShowRewardsStep} />
            </div>
        </>
    );
};

export default EarnRewards;

export const CheckedItem = ({ rounded, className }) => {
    return (
        <div>
            <div
                className={cn(
                    "w-3 h-3 rounded-0.7 text-white text-xl bg-primary flex items-center justify-center mb-2",
                    className,
                    {
                        "rounded-full": rounded,
                    }
                )}
            >
                <IoCheckmarkSharp />
            </div>
        </div>
    );
};

const RewardSteps = ({ onClick }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const handleCurrentStep = () => {
        if (currentStep === unlocksData.length - 1) {
            onClick(false);
            setCurrentStep(0);
        } else {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const activeReStep = unlocksData[currentStep];
    return (
        <div className="bg-white">
            <Container className="py-2.4">
                <div className="flex flex-col items-center">
                    <div className="mb-1.6">
                        <img
                            src={activeReStep?.src}
                            alt="reward_steps"
                            className="w-[93px] h-auto"
                        />
                    </div>
                    {activeReStep?.subHeading && (
                        <P className="text-gray mb-1.6">
                            {activeReStep?.subHeading}
                        </P>
                    )}
                    <H3 className="mb-0.8 text-xl ">{activeReStep.name}</H3>
                    <P className="text-gray-550">{activeReStep.desc}</P>
                    <div className="flex gap-1 w-full mt-1.6">
                        <Button
                            onClick={() => onClick(false)}
                            className="bg-gray bg-transparent border border-solid border-gray rounded-full text-gray w-full"
                        >
                            Skip
                        </Button>
                        <Button
                            onClick={handleCurrentStep}
                            className="bg-gray rounded-full w-full"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};
