import React from "react";
import { emailLight, phoneLight } from "../../resources/js/icons";
import Container from "../../shared/Container";
import H2 from "../../shared/typography/H2";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import ContactForm from "../../shared/ui/Form/ContactForm";
const HeroDetails = () => {
    return (
        <Container>
            <div className=" gap-3 flex justify-between mt-[10rem] md:mx-5 flex-wrap  items-center lg:gap-1 xl:mb-3.8">
                <div className="w-full lg:w-[40%] flex flex-col gap-3.6">
                    <H2 className={"text-white"}>Contact Us</H2>
                    <div className="flex flex-col gap-2.4 md:gap-1.2">
                        <div className="flex gap-1 items-center">
                            <img
                                className="h-3"
                                src={phoneLight}
                                alt={"Phone number"}
                            />
                            <p className={" text-xs text-white lg:text-xl"}>
                                +91 9970079570
                            </p>
                        </div>
                        <div className="flex gap-1 items-center">
                            <img
                                className="h-3"
                                src={emailLight}
                                alt={"Email"}
                            />
                            <p className={" text-xs text-white lg:text-xl"}>
                                marketing@hypersrot.com
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-0.8">
                        <H4 className="text-white">General enquiry</H4>
                        <P className={"text-white xl:w-[60%]"}>
                            Have questions about everything solar? Reach us at
                            marketing@hypersrot.com and we will get back to you
                            shortly.
                        </P>
                    </div>
                    <div className="flex flex-col gap-0.8">
                        <H4 className={"text-white"}>Investor enquiry</H4>
                        <P className={"text-white xl:w-[60%]"}>
                            Want to join us? Reach us at marketing@hypersrot.com
                            and we will get back to you shortly.
                        </P>
                    </div>
                </div>
                <div className="flex-1 lg:w-1/3 2xl:w-1/2">
                    <ContactForm />
                </div>
            </div>
        </Container>
    );
};

export default HeroDetails;
