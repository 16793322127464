import styled from "styled-components";

const Text = styled.div`
    display: ${(props) => (props.isInline ? "inline-block" : "block")};
    font-size: ${(props) =>
        props.size
            ? props.theme.fontSize.default[props.size]
            : props.theme.fontSize.default.m};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.black800};
    opacity: ${(props) => props.opacity || 1};
    text-align: ${(props) => props.align || ""};
    font-family: ${(props) => props.fontFamily || "'Raleway', sans-serif"};
    font-weight: ${(props) => props.weight || ""};
    text-decoration: ${(props) => props.decoration || ""};
    line-height: ${(props) => props.lineHeight || "1.6em"};
    letter-spacing: ${(props) => props.letterSpacing || ""};
    text-transform: ${(props) => props.textTransform || ""};
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.size
                ? props.theme.fontSize.tablet[props.size]
                : props.theme.fontSize.tablet.m};

        color: ${(props) =>
            props.tabletColor || props.color
                ? props.theme.color[props.tabletColor] ||
                  props.theme.color[props.color]
                : props.theme.color.black800};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) =>
            props.size
                ? props.theme.fontSize.mobile[props.size]
                : props.theme.fontSize.mobile.m};

        color: ${(props) =>
            props.mobileColor || props.color
                ? props.theme.color[props.mobileColor] ||
                  props.theme.color[props.color]
                : props.theme.color.black800};
        line-height: ${(props) => props.smobileLineHeight || "1.3em"};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) =>
            props.size
                ? props.theme.fontSize.smallMobile[props.size]
                : props.theme.fontSize.smallMobile.m};

        color: ${(props) =>
            props.smobileColor || props.color
                ? props.theme.color[props.smobileColor] ||
                  props.theme.color[props.color]
                : props.theme.color.black800};
        line-height: ${(props) => props.smobileLineHeight || "1.3em"};
    }
`;

export default Text;
