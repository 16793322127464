import React from "react";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";

const Distribution = ({ className, percentage }) => {
    return (
        <div className={cn("bg-gray-60 rounded-full h-3 relative", className)}>
            <div
                className={`h-full bg-linear-button rounded-full pr-2 flex items-center justify-end `}
                style={{ width: percentage + "%" }}
            ></div>
            <P className="text-orange-300 absolute inset-y-0 h-full w-full inset-x-0 flex items-center justify-center">
                {percentage}% distributed
            </P>
        </div>
    );
};

export default Distribution;
