import styled from "styled-components";
export const Section = styled.section`
    width: 100%;
    min-height: ${(props) => (props.height === "auto" ? "" : "620px")};
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    position: relative;
    box-sizing: border-box;
    padding: ${(props) => props.theme.sizes[props.xxs || "xl4"]} 0;

    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: ${(props) =>
                props.theme.sizes[props.s || props.xs || props.xxs || "xl4"]}
            0;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        height: ${(props) => props.height || "100vh"};
        padding: ${(props) =>
                props.theme.sizes[
                    props.st || props.s || props.xs || props.xxs || "xl5"
                ]}
            0;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: ${(props) =>
                props.theme.sizes[
                    props.t ||
                        props.st ||
                        props.s ||
                        props.xs ||
                        props.xxs ||
                        "xl5"
                ]}
            0;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        padding: ${(props) =>
                props.theme.sizes[
                    props.lg ||
                        props.t ||
                        props.st ||
                        props.s ||
                        props.xs ||
                        props.xxs ||
                        "xl6"
                ]}
            0;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        padding: ${(props) =>
                props.theme.sizes[
                    props.xl ||
                        props.lg ||
                        props.t ||
                        props.st ||
                        props.s ||
                        props.xs ||
                        props.xxs ||
                        "xl6"
                ]}
            0;
    }
`;

export const SuggestedBtn = styled.button`
    background-color: ${(props) => props.theme.color.grey400};
    padding: 0.4rem 0.8rem;
    display: inline-flex;
    align-items: center;
    border-radius: 0.5rem;
    font-size: ${(props) => props.theme.fontSize.default.s};
    div {
        margin-left: 5px;
    }
    transition: ${(props) => props.theme.transition.xs};
    &:hover {
        outline: none;
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
        background-color: ${(props) => props.theme.color.grey600};
    }
`;

const FlexContainer = styled.div`
    width: ${(props) => props.width || "auto"};
    display: flex;
    justify-content: ${(props) => props.justify || ""};
    align-items: ${(props) => props.align || ""};
    flex-wrap: wrap;
    flex-direction: ${(props) => props.direction || ""};
    gap: ${(props) => props.gap || ""};
`;

const FlexItem = styled.div`
    width: ${(props) => props.width || "auto"};
    height: ${(props) => props.height || "auto"};
    align-self: ${(props) => props.alignSelf || ""};
    flex: ${(props) => props.flex || ""};
    width: ${(props) => props.xxs || "auto"};
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        width: ${(props) => props.s || props.xs || props.xxs || "auto"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: ${(props) =>
            props.m || props.s || props.xs || props.xxs || "auto"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: ${(props) =>
            props.st || props.s || props.xs || props.xxs || "auto"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: ${(props) =>
            props.t || props.st || props.s || props.xs || props.xxs || "auto"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        width: ${(props) =>
            props.lg ||
            props.t ||
            props.st ||
            props.s ||
            props.xs ||
            props.xxs ||
            "auto"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: ${(props) =>
            props.xl ||
            props.lg ||
            props.t ||
            props.st ||
            props.s ||
            props.xs ||
            props.xxs ||
            "auto"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
        width: ${(props) =>
            props.xxl ||
            props.xl ||
            props.lg ||
            props.t ||
            props.st ||
            props.s ||
            props.xs ||
            props.xxs ||
            "auto"};
    }
`;

export const ButtonComponent = styled.button`
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    line-height: 1.3;
    min-height: 40px;
    height: auto;
    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.smallMobile[props.xxs]
            : props.theme.fontSize.default.m};
    font-family: ${(props) => props.fontFamily || '"Montserrat", sans-serif'};
    font-weight: ${(props) => props.theme.fontWeight[props.weight || "sm"]};
    padding: ${(props) =>
        props.size === "s" ? ".7rem 1rem" : "1.2rem 1.8rem"};
    text-transform: ${(props) => props.textTransform || ""};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.type === "solid"
            ? props.theme.color.white
            : props.theme.color.white};
    min-width: ${(props) => props.theme.sizes.xl4};
    background-color: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.theme.color.primary};
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    border: ${(props) =>
        props.border
            ? props.border
            : `1px solid
            ${props.backgroundColor}
                ? ${props.theme.color[props.backgroundColor]}
                : ${props.theme.color.primary}`};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    transition: all 0.2s;
    width: ${(props) => (props.fullWidth ? "100%" : "")};
    position: relative;
    overflow: hidden;
    z-index: 1;
    ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: -150px;
        background-image: ${(props) => props.theme.gradient.highlight};
        transform: ${(props) =>
            props.disabled ? "translateX(0)" : "translateX(-100%)"};
        opacity: ${(props) => (props.disabled ? 1 : 0)};
        z-index: -1;
        transition: ${(props) => props.theme.transition.s};
    }

    &:hover {
        outline: none;
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
        ::after {
            transform: ${(props) =>
                props.disabled ? "translateX(0)" : "translateX(0)"};
            opacity: 1;
        }
    }
    &:active {
        ::after {
            background-image: none;
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.laptop.m};
        width: ${(props) => (props.fullWidth ? "100%" : "")};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.laptop.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.laptop.m};
    }
`;

export const Flex = {
    Container: FlexContainer,
    Item: FlexItem,
};
