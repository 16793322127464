import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";
const filterList = ["All", "Referrals", "Blogs", "Quizzes", "Discount"];
const RewardHistory = () => {
    const [activeFilter, setActiveFilter] = useState(filterList[0]);
    return (
        <div>
            <P className="text-gray text-base mb-2.4">History</P>
            <div className="relative flex items-center justify-between border border-gray-secondary py-0.8 px-1 gap-0.5 text-sm rounded-full focus-within:border-primary mb-3.2">
                <FaSearch className="text-gray-550" />
                <input
                    type="search"
                    className="outline-none border-none text-sm flex-1"
                />
            </div>
            <div className="flex gap-0.8 mb-3.2">
                {filterList.map((item, i) => (
                    <div
                        key={i}
                        role="button"
                        onClick={() => setActiveFilter(item)}
                        className={cn(
                            "border p-0.8 rounded-sm border-gray-550 cursor-pointer",
                            {
                                "border-primary": item === activeFilter,
                            }
                        )}
                    >
                        <P
                            className={
                                item === activeFilter
                                    ? "text-primary"
                                    : "text-gray-secondary"
                            }
                        >
                            {item}
                        </P>
                    </div>
                ))}
            </div>
            {[...Array(10).keys()].map((item) => (
                <div className="flex justify-between gap-2 mb-1.6">
                    <div>
                        <P className="text-gray mb-0.8">Referral</P>
                        <P className="text-xs">March 20, 19:05pm</P>
                    </div>
                    <P className="font-medium text-gray">+ 500</P>
                </div>
            ))}
        </div>
    );
};

export default RewardHistory;
