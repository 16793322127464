import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import cn from "../../../lib/cn";

const SliderButton = ({ onPrev, onNext, currentSlide, data, className }) => {
    return (
        <div
            className={cn(
                ` mt-2 flex justify-end gap-1 w-full items-center`,
                className
            )}
        >
            <button
                onClick={onPrev}
                className="text-xl xl:text-3xl 2xl:text-5xl"
            >
                <IoIosArrowBack />
            </button>

            <div className="font-nunito">
                <span className="text-lg font-nunito xl:text-3xl 2xl:text-5xl inline-block ">
                    {currentSlide + 1 > 9
                        ? currentSlide + 1
                        : `0${currentSlide + 1}`}
                </span>
                <span className=" inline-block  text-sm xl:text-base 2xl:text-lg">
                    /{data.length > 9 ? data.length : `0${data.length}`}
                </span>
            </div>

            <button
                onClick={onNext}
                className="text-xl xl:text-3xl 2xl:text-5xl"
            >
                <IoIosArrowForward />
            </button>
        </div>
    );
};

export default SliderButton;
