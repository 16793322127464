import React from "react";
import Faq from "../home/Faq";
import AboutCarbonFootPrint from "./AboutCarbonFootPrint";
import EmissionDetails from "./EmissionDetails";
import ImpactHero from "./ImpactHero";
import MetricsAndFootPrint from "./MetricsAndFootPrint";
import OurVision from "./OurVision";

const Impact = () => {
    return (
        <div>
            <ImpactHero />
            <AboutCarbonFootPrint />
            <EmissionDetails />
            {/* <Calculator /> */}
            {/* <InsightCarouselSection /> */}
            <OurVision />
            <MetricsAndFootPrint />
            <Faq />
        </div>
    );
};

export default Impact;
