import React, { useState } from "react";

import P from "../../../../shared/typography/P";
import Line from "../../../../shared/ui/Line";
import CalculatorDash from "../../ProjectDetails/LeftSide/Calculator";
import CalculatorRangeSlider from "./CalculatorRangeSlider";
import Overview from "./Overview";

const overview = [
    {
        name: "Solar assets owned",
        value: "1.5kWp",
    },
    {
        name: "Tenure",
        value: "5 years",
    },
    {
        name: "IRR",
        value: "20.88%",
    },
];
const glance = [
    {
        name: "Total payouts",
        value: "5th August 2018",
    },
    {
        name: "Monthly Payouts",
        value: "10th August 2024",
    },
    {
        name: "Taxable payouts",
        value: "5th August 2018",
    },
    {
        name: "Non taxable payouts",
        value: "10th August 2024",
    },
];

const rangeValue = {
    min: 0,
    max: 100,
};
const Calculator = () => {
    const [range, setRange] = useState(50);

    return (
        <>
            <div className="lg:hidden">
                <P className="text-gray">Calculate your payouts & impact</P>
                <Overview data={overview} className="py-2.4" />
                <div className="p-1 6 bg-gray-50 border border-gray-400 rounded-[1.2rem]">
                    <P className="text-gray mb-0.8 font-vietnam">
                        Enter Investment amount
                    </P>
                    <P className="text-primary text-center mb-1.6 text-base font-medium font-vietnam">
                        ₹ {range}
                    </P>
                    <CalculatorRangeSlider
                        onChange={(e) => setRange(e.target.value)}
                        min={rangeValue.min}
                        max={rangeValue.max}
                        value={range}
                    />

                    <div className="flex justify-between">
                        <P className="text-gray-550 font-vietnam">
                            {rangeValue.min}
                        </P>
                        <P className="text-gray-550 font-vietnam">
                            {rangeValue.max} Cr
                        </P>
                    </div>

                    <Line />
                    <P className="text-gray  mb-1.6 font-vietnam">
                        At a glance
                    </P>
                    <div className="grid grid-cols-2 gap-y-2.4">
                        {glance.map((item, i) => (
                            <div key={i}>
                                <P className="text-gray-550 mb-1.6 text-xs font-vietnam">
                                    {item.name}
                                </P>
                                <P className="text-gray font-medium font-vietnam font-vietnam ">
                                    {item.value}
                                </P>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="hidden lg:flex">
                <CalculatorDash />
            </div>
        </>
    );
};

export default Calculator;
