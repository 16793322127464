import React from "react";
import { AboutUsBg } from "../../resources/js/images";
import Hero from "../contact-us/Hero";
import CarouselSection from "./CarouselSection";
import ContactUSForm from "./ContactUSForm";
import CoreValues from "./CoreValues";
import HeroDetails from "./HeroDetails";
import LeadershipTeam from "./LeadershipTeam";
import OurImpact from "./OurImpact";
import OurStory from "./OurStory";

const AboutUs = () => {
    return (
        <>
            <Hero
                wrapperClass="bg-custom-gradient-1 min-h-[30vh] h-[400px]  sm:max-h-[100vh] xl:min-h-[100vh]"
                content={<HeroDetails />}
                url={AboutUsBg}
            />
            <OurStory />
            <CarouselSection />
            <CoreValues />
            <OurImpact />
            <LeadershipTeam />
            <ContactUSForm />
        </>
    );
};

export default AboutUs;
