import React, { useRef, useState } from "react";
import { FiSend } from "react-icons/fi";
import ChatBoxItem from "./ChatBoxItem";
const initialMessages = [
    {
        id: 1,
        name: "User Name",
        date: "15 Mar 2022, 10:11 AM",
        subject: "Last Payment delay",
        message:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quas incidunt libero veniam illum voluptate ",
        sender: true,
    },
    {
        id: 2,
        name: "Support Name",
        date: "15 Mar 2022, 10:11 AM",
        subject: "Last Payment delay",
        message:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quas incidunt libero veniam illum voluptate ",
        sender: false,
    },
];
const ChatContainer = ({ onRaiseTicket }) => {
    const [messageList, setMessageList] = useState(initialMessages);
    const chatBoxRef = useRef(null);

    const [currentMessage, setCurrentMessage] = useState("");

    const handleAddNewMessage = (e) => {
        e.preventDefault();
        let newMessage = {
            id: Math.random(),
            name: "Support Name",
            date: "15 Mar 2022, 10:11 AM",
            subject: "Last Payment delay",
            message: currentMessage,
            sender: true,
        };
        setMessageList([...messageList, newMessage]);
        setCurrentMessage("");
        setTimeout(() => {
            chatBoxRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
            });
        }, [10]);
    };
    return (
        <div>
            <div className=" p-1.4 xl:p-1.6 bg-gray-110 rounded-lg flex flex-col ">
                <div className="  h-[350px]   overflow-y-scroll">
                    <div ref={chatBoxRef} className="flex flex-col">
                        {messageList.map((message) => (
                            <ChatBoxItem key={message.id} {...message} />
                        ))}
                    </div>
                </div>
                <>
                    <form
                        onSubmit={handleAddNewMessage}
                        className="flex border border-gray-400 w-full mb-2 rounded-lg transition-all duration-150 overflow-hidden   h-[50px] px-1  py-0.5 relative"
                    >
                        <input
                            type="text"
                            placeholder="Type a reply..."
                            className="w-full h-full outline-none text-sm xl:text-base 2xl:text-lg pr-4 !placeholder-gray-600"
                            value={currentMessage}
                            onChange={(e) => setCurrentMessage(e.target.value)}
                        />
                        <div className="absolute top-0 right-0 bottom-0 w-5 flex items-center">
                            <button
                                type="submit"
                                className="flex w-[40px] h-[40px]     text-blue-100 items-center justify-center text-lg  hover:cursor-pointer duration-200"
                            >
                                <FiSend />
                            </button>
                        </div>
                    </form>
                    <div
                        onClick={() => onRaiseTicket()}
                        className=" bg-white text-black text-sm border text-center w-full border-blue-700 font-normal py-1 px-3 rounded-full "
                    >
                        Raise new Ticket
                    </div>
                </>
            </div>
        </div>
    );
};

export default ChatContainer;
