import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PORTFOLIO } from "../../../../../constants/routes";
import { upArrow } from "../../../../../resources/js/icons";
import P from "../../../../../shared/typography/P";

const InvestmentCard = ({ status, name, amount, rate, src, id }) => {
    const history = useHistory();
    return (
        <div
            role="button"
            onClick={() => history.push(`${PORTFOLIO}/${id}`)}
            className="font-vietnam flex justify-between items-center border-b border-b-gray-300 pb-1.6 bg-white"
        >
            <div className="flex flex-col justify-start gap-1">
                <div>
                    <span
                        className={`text-xs font-semibold  mb-1.6 px-1 py-0.5 rounded-3xl ${
                            status === "PAID"
                                ? "bg-green-50 text-green-300"
                                : "bg-red-100 text-red"
                        }`}
                    >
                        {status}
                    </span>
                </div>
                <h2 className="font-semibold text-sm xl:text-base">{name}</h2>
                <P className="text-gray-550 font-vietnam xl:text-base ">
                    Inv - ₹ {amount.toFixed(2)}
                </P>
            </div>

            <div className="flex flex-col justify-start gap-1">
                <p className="text-sm text-gray-500 xl:text-base font-vietnam">
                    Pending Payouts
                </p>
                <P className="font-bold text-pretty w-full text-sm xl:text-base text-gray flex-1 items-center font-vietnam">
                    ₹ {amount.toFixed(2)}
                </P>

                <P className="text-gray-550 xl:text-base font-vietnam flex items-center text-sm">
                    <img src={src} alt="Arrow" className=" h-1.5 mr-1" />
                    {rate}%
                </P>
            </div>
        </div>
    );
};

const InvestmentList = ({ isScrollDisabled, investments }) => {
    const [activeTab, setActiveTab] = useState(0);

    if (!investments || investments.length === 0) return;

    const filteredInvestments = investments.filter((investment) => {
        if (activeTab === 1)
            return (
                investment.status === "PAID" ||
                investment.status === "PROCESSED"
            );
        if (activeTab === 2)
            return (
                investment.status !== "PAID" &&
                investment.status !== "PROCESSED"
            );
        return true;
    });

    const buildTabs = (investments) => {
        let investmentCount = investments.reduce(
            (prev, el) => {
                if (el.status === "PAID" || el.status === "PROCESSED") {
                    return {
                        active: prev.active + 1,
                        matured: prev.matured,
                    };
                }

                return {
                    active: prev.active,
                    matured: prev.matured + 1,
                };
            },
            {
                active: 0,
                matured: 0,
            }
        );

        return [
            {
                name: "All",
                count: investmentCount.active + investmentCount.matured,
            },
            { name: "Active", count: investmentCount.active },
            { name: "Matured", count: investmentCount.matured },
        ];
    };

    return (
        <div className="px-2 py-4  lg:px-4 font-vietnam ">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold font-vietnam ">
                    Your Investments
                </h1>
                {/* <div className="flex text-2xl items-center gap-2">
                    <CiSearch />
                    <IoFilter onClick={() => onClick(true)} />
                    <FiDownload />
                </div> */}
            </div>

            {/* Tabs */}
            <div className="flex mb-1.6 justify-between lg:justify-start lg:gap-3 border-b border-b-blue-50">
                {buildTabs(investments).map((tab, i) => (
                    <div
                        key={i}
                        onClick={() => setActiveTab(i)}
                        className={`flex items-center md:text-sm xl:text-sm hover:cursor-pointer gap-1 pb-1 ${
                            activeTab === i
                                ? "text-blue-500 border-b-2 border-b-blue-500"
                                : "text-gray-500"
                        }`}
                    >
                        <P
                            className={` ${
                                activeTab === i
                                    ? "text-blue-500"
                                    : "text-gray-500"
                            }`}
                        >
                            {tab.name}
                        </P>
                        <span
                            className={`px-1 py-0.5 rounded-3xl xl:text-sm flex items-end justify-center ${
                                activeTab === i
                                    ? "text-blue-500 bg-gray-400"
                                    : "bg-gray-50 text-gray-500"
                            }`}
                        >
                            {tab.count}
                        </span>
                    </div>
                ))}
            </div>

            {/* Investment List */}
            <div
                className={`flex flex-col space-y-1.6 max-h-screen xl:max-h-[400px]  py-1 pb-[350px] xl:pb-2 ${
                    isScrollDisabled ? "" : "overflow-y-auto"
                }`}
            >
                {filteredInvestments.map((investment, i) => (
                    <InvestmentCard
                        key={i}
                        id={investment?.investmentId}
                        status={investment?.status}
                        name={investment?.name}
                        amount={investment?.investmentAmount}
                        rate={investment?.irr}
                        src={upArrow} // Pass the icon src here
                    />
                ))}
            </div>
        </div>
    );
};

export default InvestmentList;
