import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { OPPORTUNITY_DETAILS_ENDPOINT } from "../../../../constants/routes";
import useNetwork from "../../../../hooks/useNetwork";
import Container from "../../../../shared/Container";
import Section from "../../../../shared/Section";
import DetailsHead from "./DetailsHead";
import DetailsInvestments from "./DetailsInvestments";
/* @TODO => Verify fonts for all the components @habib610 Thu September 26,2024 */
const PortfolioDetails = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { id } = useParams();
    const [listingId] = useState(id);

    const [body] = useState({
        id: 1,
    });

    const { data: investorPortfolioData } = useNetwork(
        `/investment/${listingId}`
    );
    const { data: assetDetails } = useNetwork(
        OPPORTUNITY_DETAILS_ENDPOINT,
        "POST",
        body
    );

    if (!assetDetails?.data?.listing) return;
    const listing = assetDetails?.data?.listing;
    const investmentTerms = listing?.investmentTerms[0];
    const plant = listing?.plants[0];

    const investment = investorPortfolioData?.data?.investment;

    return (
        <Section className="bg-black-600 pt-10.4 m-0 ">
            <Container>
                <DetailsHead
                    name={plant?.name}
                    plant={plant}
                    status={investment?.investment_status}
                    date={investment?.investment_date}
                    totalPayout={
                        investment?.monthly_gross_payout *
                        (investment?.pending_installments +
                            investment?.paid_installments)
                    }
                    investmentAmount={investment?.investment_amount}
                    digioStatus={investment?.digioStatus}
                    investmentId={investment?.investment_id}
                    currentTab={currentTab}
                />
            </Container>
            <div className="bg-white rounded-tr-2 rounded-tl-2 py-2.4 ">
                <Container>
                    <DetailsInvestments
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        investment={investment}
                        listing={listing}
                        investmentTerms={investmentTerms}
                    />
                </Container>
            </div>
        </Section>
    );
};

export default PortfolioDetails;
