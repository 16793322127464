import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { coinImg, coinImgMobile, walletBg, walletMobileBg } from "../assets";
import ImageBanner from "../components/banner/ImageBanner";
import Dropdown from "../components/dropdown/Dropdown";
import Flex from "../components/flex/Flex";
import Pagination from "../components/pagination/index";
import Section from "../components/section/Section";
import Space from "../components/space/Space";
import Container from "../components/styles/Container.styles";
import Table from "../components/tableNew/Table";
import Text from "../components/text/Text";
import { ERROR_MSG } from "../constants/common";
import { CHECK_WALLET_BALANCE_ENDPOINT, WALLET } from "../constants/routes";
import { twoDecimalLocal } from "../helpers/helpers";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Request from "../services/request";

import Column from "./columns";
const Middiv = styled(Flex.Container)`
    background-color: ${(props) => props.theme.color.pureWhite};
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: flex;
        justify-content: space-between;
        padding: 20px 5px;
    }
`;

const DropDiv = styled.div`
    display: flex;

    justify-content: space-between;
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 100%;
    }
`;
const TransacHeading = styled(Text)`
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: none;
    }
`;
const Styles = styled.div`
    table {
        width: 100%;
        text-align: center;
        background-color: #e7e9ea;

        thead {
            background-color: ${(props) => props.theme.color.lightGrey};
        }

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;

            :last-child {
                border-right: 0;
            }
        }
        td {
            height: 80px;
        }
    }
`;
const TabletNone = styled(Flex.Item)`
    display: flex;
    align-items: flex-end;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: none;
    }
`;

export const ImageWrap = styled(TabletNone)`
    @media (max-width: 1680px) {
        width: 55%;
    }
`;
// calculate height and padding here
const BankInfoWarp = styled.div`
    min-height: 45vh;
    max-height: auto;
    padding-top: 140px;
    padding: 120px ${(props) => props.theme.spacing.default.horizontal} 2%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    // background: ${(props) => props.theme.color.darkGreen};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        min-height: 50vh;
        // background-color: ${(props) => props.theme.color.darkSecondary};
        padding-top: 70px;
    }
`;

const BannerSection = styled.div`
    background: ${(props) => props.theme.color.white};
    box-sizing: border-box;
    padding: 1.5rem;
    width: 100%;
    border-radius: 1rem;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 0;
        background: transparent;
    }
`;

const Wallet = (props) => {
    const history = useHistory();
    const [walletBalance, setBalance] = useState({});
    const [vrAccount, setVrAccount] = useState(null);
    const [pageData, setPageData] = useState({});
    const [transactions, setTransac] = useState([]);
    const [transacMob, setTransacMob] = useState([]);
    const [queryDuration, setQueryDuration] = useState("");
    const [queryType, setQueryType] = useState("");
    const [codeData, setCodeData] = useState(null);
    const prepareTableMob = (transactions) => {
        const array = (transactions || [])
            .sort((a, b) => Number(b?.date) - Number(a?.date))
            .map((curr) => {
                const newEntry = {
                    club: {
                        date: moment(Number(curr.date) * 1000).format(
                            "DD/MM/YY"
                        ),
                        mode: curr.status,
                        transaction: `TRX_${curr.transId}`,
                        particulars: curr.particulars,
                    },
                    balance: twoDecimalLocal(curr.currentBalance),
                    amount: `${twoDecimalLocal(curr.amount)} ${
                        curr.type === "CREDIT" ? "Cr" : "Dr"
                    }`,
                    // balance: data.data.wallet.withdrawableBalance,
                };
                return newEntry;
            });

        setTransacMob(array);
    };

    const prepareTable = (transactions) => {
        const array = (transactions || [])
            .sort((a, b) => Number(b?.date) - Number(a?.date))
            .map((curr) => {
                const newEntry = {
                    date: moment(Number(curr.date) * 1000).format("DD/MM/YY"),
                    mode: curr.status,
                    transaction: `TRX_${curr.transId}`,
                    particulars: curr.particulars,
                    balance: twoDecimalLocal(curr.currentBalance),
                    amount: `${twoDecimalLocal(curr.amount)} ${
                        curr.type === "CREDIT" ? "Cr" : "Dr"
                    }`,
                    // balance: data.data.wallet.withdrawableBalance,
                };
                return newEntry;
            });

        setTransac(array);
    };

    const getFilteredTransactions = (data) => {
        const displayTransactions = data?.data?.wallet?.transactions.trx || [];
        return displayTransactions;
    };

    const getData = () => {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        Request.authGet({
            endpoint: CHECK_WALLET_BALANCE_ENDPOINT,
            headers,
        })
            .then((res) => {
                let deposit = res?.data?.wallet?.withdrawableBalance
                    ? Number(res.data.wallet.withdrawableBalance)
                    : 0;
                let bonus = res?.data?.wallet?.nonWithdrawableBalance
                    ? Number(res.data.wallet.nonWithdrawableBalance)
                    : 0;
                let joiningBonus = res?.data?.wallet?.joiningBonus
                    ? Number(res.data.wallet.joiningBonus)
                    : 0;
                let blocked = res?.data?.wallet?.blockedBalance
                    ? Number(res.data.wallet.blockedBalance)
                    : 0;
                let referral = res?.data?.wallet?.referralBonus
                    ? Number(res.data.wallet.referralBonus)
                    : 0;
                setBalance({ deposit, bonus, referral, blocked, joiningBonus });
                setVrAccount(
                    res?.data?.wallet?.virtualAccountDisplay
                        ? res?.data?.wallet?.virtualAccountDisplay
                        : null
                );

                const displayTransactions = getFilteredTransactions(res);
                prepareTable(displayTransactions);
                prepareTableMob(displayTransactions);

                toast.success("Transaction success!");
                history.push(WALLET);
            })
            .catch((err) => {
                toast.error(ERROR_MSG);
            });
    };
    const getGenericData = async (pageNumber = 0) => {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        Request.authGet({
            endpoint:
                CHECK_WALLET_BALANCE_ENDPOINT +
                `?page=${pageNumber}&duration=${queryDuration}&size=6&type=${queryType.toString()}`,

            headers,
        }).then((res) => {
            let deposit = res?.data?.wallet?.withdrawableBalance
                ? Number(res.data.wallet.withdrawableBalance)
                : 0;
            let bonus = res?.data?.wallet?.nonWithdrawableBalance
                ? Number(res.data.wallet.nonWithdrawableBalance)
                : 0;
            let joiningBonus = res?.data?.wallet?.joiningBonus
                ? Number(res.data.wallet.joiningBonus)
                : 0;
            let blocked = res?.data?.wallet?.blockedBalance
                ? Number(res.data.wallet.blockedBalance)
                : 0;
            let referral = res?.data?.wallet?.referralBonus
                ? Number(res.data.wallet.referralBonus)
                : 0.0;
            setVrAccount(
                res?.data?.wallet?.virtualAccountDisplay
                    ? res?.data?.wallet?.virtualAccountDisplay
                    : null
            );
            setBalance({ deposit, bonus, referral, blocked, joiningBonus });
            const displayTransactions = getFilteredTransactions(res);
            prepareTable(displayTransactions);
            prepareTableMob(displayTransactions);

            const paginationData = {
                isFirst: res?.data?.wallet?.transactions.isFirst,
                isLast: res?.data?.wallet?.transactions.isLast,
                pageNumber: res?.data?.wallet?.transactions.pageNumber,
                pageCount: res?.data?.wallet?.transactions.totalPages,
            };
            setPageData(paginationData);
        });
    };
    useEffect(() => {
        getGenericData();
    }, [queryDuration, queryType]);

    const Duration = {
        Duration1: "1 Week",
        Duration2: "30 Days",
        Duration3: "90 Days",
        Duration4: "6 Months",
        Duration5: "1 Year",
    };

    const Type = {
        Type1: "All",
        Type2: "Credit",
        Type3: "Debit",
    };

    const val1 = <Text size="s">{Duration.Duration1}</Text>;
    const val2 = <Text size="s">{Duration.Duration2}</Text>;
    const val3 = <Text size="s">{Duration.Duration3}</Text>;
    const val4 = <Text size="s">{Duration.Duration4}</Text>;
    const val5 = <Text size="s">{Duration.Duration5}</Text>;

    const val6 = <Text size="s">{Type.Type1}</Text>;
    const val7 = <Text size="s">{Type.Type2}</Text>;
    const val8 = <Text size="s">{Type.Type3}</Text>;

    const options1 = [
        { label: val1, value: Duration.Duration1, params: 7 },
        { label: val2, value: Duration.Duration2, params: 30 },
        { label: val3, value: Duration.Duration3, params: 90 },
        { label: val4, value: Duration.Duration4, params: 180 },
        { label: val5, value: Duration.Duration5, params: 365 },
    ];

    const options2 = [
        { label: val6, value: Type.Type1, params: "ALL" },
        { label: val7, value: Type.Type2, params: "CREDIT" },
        { label: val8, value: Type.Type3, params: "DEBIT" },
    ];
    const {
        match: { path },
    } = props;

    const { width } = useWindowDimensions();
    const handleChangePage = useCallback(
        async (event) => {
            getGenericData(event.selected);
        },
        [getGenericData]
    );
    return (
        <Section height="auto" style={{ marginTop: "100px" }}>
            {/* <BankInfoWarp>
                <BannerSection>
                    <Flex.Container justifyContent="center" alignItems="center">
                  
                        {vrAccount !== null && (
                            <WalletBankInfo
                                display={vrAccount}
                                codeData={codeData}
                                setCodeData={setCodeData}
                            />
                        )}
                        <Transaction
                            walletBalance={walletBalance}
                            path={path}
                            callback={getData}
                            display={vrAccount}
                            qrCode={codeData}
                        />
                    </Flex.Container>
                </BannerSection>
            </BankInfoWarp> */}
            <Container>
                <ImageBanner
                    backgroundImage={width < 695 ? walletMobileBg : walletBg}
                    foregroundImage={width < 695 ? coinImgMobile : coinImg}
                    heading="Your Ledger"
                    subHead={
                        "Track all your past transactions in simple and transparent manner."
                    }
                    mobile={width < 695}
                />
                <Styles>
                    <Middiv justifyContent="space-between">
                        <TransacHeading size="xl">Transactions</TransacHeading>

                        <DropDiv>
                            <Space margin={["0 20px 0 0"]}>
                                <div>
                                    <Dropdown
                                        placeholder="Duration"
                                        options={options1}
                                        onChange={(val) =>
                                            setQueryDuration(val.params)
                                        }
                                    />
                                </div>
                            </Space>
                            <div>
                                <Dropdown
                                    placeholder="Type"
                                    options={options2}
                                    onChange={(val) => setQueryType(val.params)}
                                />
                            </div>
                        </DropDiv>
                    </Middiv>
                    {width <= 940 ? (
                        <Table Data={transactions} Column={Column} />
                    ) : (
                        <Table Data={transactions} Column={Column} />
                    )}

                    {transactions && (
                        <Pagination
                            isFirst={pageData?.isFirst}
                            isLast={pageData?.isLast}
                            pageCount={pageData?.pageCount}
                            onChange={handleChangePage}
                        />
                    )}
                </Styles>
            </Container>
        </Section>
    );
};

export default withRouter(Wallet);
