import React from "react";
import Container from "../components/styles/Container";
import CarbonOffsetContainer from "./CarbonOffsetContainer";
import OffsetHero from "./OffsetHero";

const Offset = () => {
    return (
        <>
            <OffsetHero />
            <Container style={{ paddingBottom: "3rem" }} height="auto">
                <CarbonOffsetContainer />
            </Container>
        </>
    );
};

export default Offset;
