import { useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { PROJECTS, tabAuthNavLinks } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import TabItem from "./TabItem";

const TabContainer = styled.div`
    background: ${(props) => props.theme.color.pureWhite};
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 5px ${(props) => props.theme.spacing.default.horizontal};
    display: none;
    justify-content: space-between;
    z-index: 100;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: flex;
    }
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 27%);
    box-sizing: border-box;
`;

const BottomTab = () => {
    const { pathname } = useLocation();

    let match = useRouteMatch(`${PROJECTS}/:id`);

    const { state } = useContextState();
    return state.user && match === null ? (
        <TabContainer>
            {tabAuthNavLinks.map((item) => (
                <TabItem
                    path={item.path}
                    key={item.path}
                    Icon={item.icon}
                    name={item.name}
                    isActive={pathname.search(item.path) >= 0}
                />
            ))}
        </TabContainer>
    ) : null;
};

export default BottomTab;
