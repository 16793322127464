import React from "react";
import { PORTFOLIO_INVESTMENTS_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import useNetwork from "../../../hooks/useNetwork";
import Container from "../../../shared/Container";
import ExistingUser from "./ExistingUserMobile";
import LearnMore from "./LearnMore";
import LeftSide from "./LeftSide";
import NewUser from "./NewUser";
import RightSide from "./RightSide";

const ExistingUserDashBoard = () => {
    const { state } = useContextState();

    const { data: investmentData } = useNetwork(PORTFOLIO_INVESTMENTS_ENDPOINT);

    return (
        <>
            <Container className="hidden lg:block  mt-5 pt-5">
                <div className=" hidden lg:flex  gap-2  justify-between items-start">
                    <div className=" lg:w-[55%]  xl:w-[70%]">
                        <LeftSide
                            profileData={state?.user}
                            portfolioData={investmentData}
                            investmentHave={
                                investmentData?.data?.portfolio?.investment
                                    ?.length !== 0
                            }
                        />
                    </div>
                    <div className="lg:w-[45%] xl:w-[30%]">
                        <div className="sticky top-5 lg:top-5">
                            <RightSide
                                portfolioData={investmentData}
                                investmentHave={
                                    investmentData?.data?.portfolio?.investment
                                        ?.length !== 0
                                }
                            />
                        </div>
                    </div>
                </div>
                <LearnMore />
            </Container>
            <div className="lg:hidden">
                {investmentData?.data?.portfolio?.investment?.length !== 0 ? (
                    <ExistingUser
                        portfolioData={investmentData}
                        profileData={state?.user}
                    />
                ) : (
                    <NewUser profileData={state?.user} />
                )}
            </div>
        </>
    );
};

export default ExistingUserDashBoard;
