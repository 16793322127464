import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { IoChevronDown, IoReloadOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import ModalAlt from "../../components/modal/ModalAlt";
import { useContextState } from "../../context/ContextProvider";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import P from "../../shared/typography/P";
import Accordion from "../../shared/ui/Accordian/Accordian";
import Button from "../../shared/ui/Button";
import BottomDrawer from "../../shared/ui/Drawer/BottomDrawer";
import Badges from "./Badges";
import ResetPassword from "./ResetPassword";
import UserInfo from "./UserInfo";
const accordionStyles = {
    wrapperClass: " px-0  mb-0 py-0 mb-1.2",
    labelClass: " border-gray-400",
    labelWrapperClass: "border-b border-gray-400 pb-1.2",
    closedIcon: IoChevronDown,
    openClass: "text-primary",
};

const Profile = () => {
    const { state } = useContextState();

    const [showDetails, setShowDetails] = useState(false);
    return (
        <div className="lg:flex  lg:justify-center">
            <Section className="bg-black-600 md:bg-white w-full lg:w-[80%]  xl:w-[65%]  md:flex md:flex-col   justify-stretch items-center mb-0 pt-7 md:mt-5 ">
                <UserInfo
                    name={state?.user?.name}
                    referralCode={state?.user?.referralCode}
                />
                <Badges />
                <div className="bg-white pt-1.6  md:w-1/2 lg:w-[50%] 2xl:w-1/3 ">
                    <Container className="md:px-0 md:mx-0 ">
                        <div className="pt-1.6 border-t border-gray-400">
                            <Accordion
                                isOpen={true}
                                label="View Profile"
                                {...accordionStyles}
                            >
                                <div className="flex  justify-between mb-1.6">
                                    <P className="font-vietnam">Email id</P>
                                    <P className="text-gray font-medium font-vietnam">
                                        {state?.user?.email}
                                    </P>
                                </div>{" "}
                                <div className="flex justify-between mb-1.6">
                                    <P className="font-vietnam">Mobile</P>
                                    <P className="text-gray font-medium font-vietnam">
                                        {state?.user?.phoneNumber}
                                    </P>
                                </div>
                                <Button
                                    onClick={() => setShowDetails(true)}
                                    className="border border-solid border-primary bg-transparent text-primary w-full font-medium font-vietnam "
                                >
                                    Reset <IoReloadOutline />{" "}
                                </Button>
                            </Accordion>
                            <Accordion
                                isOpen={true}
                                label="Kyc Summary"
                                {...accordionStyles}
                            >
                                <div className="flex justify-between items-center mb-1.5">
                                    <P>Pan Verification</P>
                                    {state?.user?.kycSummary
                                        ?.panVerificationData?.status ===
                                    "FAILED" ? (
                                        <P className="bg-red-200 text-white rounded-full text-sm md:text-sm xl:text-sm px-0.4 py-0.4 mr-1">
                                            <RxCross1 />
                                        </P>
                                    ) : state?.user?.kycSummary
                                          ?.panVerificationData?.status ===
                                      "VERIFIED" ? (
                                        <P className="bg-green-400 text-white text-sm md:text-sm xl:text-sm rounded-full px-0.4 py-0.4 mr-1">
                                            <FaCheck />
                                        </P>
                                    ) : (
                                        <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 "></div>
                                    )}
                                </div>
                                <div className="flex justify-between items-center mb-1.5">
                                    <P>Aadhar Verification</P>
                                    {state?.user?.kycSummary
                                        ?.aadharVerificationData?.status ===
                                    "FAILED" ? (
                                        <P className="bg-red-200 text-white rounded-full text-sm md:text-sm xl:text-sm px-0.4 py-0.4 mr-1">
                                            <RxCross1 />
                                        </P>
                                    ) : state?.user?.kycSummary
                                          ?.aadharVerificationData?.status ===
                                      "VERIFIED" ? (
                                        <P className="bg-green-400 text-white text-sm md:text-sm xl:text-sm rounded-full px-0.4 py-0.4 mr-1">
                                            <FaCheck />
                                        </P>
                                    ) : (
                                        <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 "></div>
                                    )}
                                </div>
                                <div className="flex justify-between items-center mb-1.5">
                                    <P>Bank Verification</P>
                                    {state?.user?.kycSummary
                                        ?.bankVerificationStatus?.status ===
                                    "FAILED" ? (
                                        <P className="bg-red-200 text-white rounded-full text-sm md:text-sm xl:text-sm px-0.4 py-0.4 mr-1">
                                            <RxCross1 />
                                        </P>
                                    ) : state?.user?.kycSummary
                                          ?.bankVerificationStatus?.status ===
                                      "VERIFIED" ? (
                                        <P className="bg-green-400 text-white text-sm md:text-sm xl:text-sm rounded-full px-0.4 py-0.4 mr-1">
                                            <FaCheck />
                                        </P>
                                    ) : (
                                        <div className="w-2 h-2 bg-gray-400 rounded-full mr-1 "></div>
                                    )}
                                </div>
                            </Accordion>

                            {/* <Accordion label="Settings" {...accordionStyles}>
                                <div className="flex  items-center justify-between mb-1.6">
                                    <P className="font-vietnam">
                                        Enable notifications
                                    </P>
                                    <input type="" />
                                    <Switch />
                                </div>
                                <div className="flex  items-center justify-between mb-1.6">
                                    <P className="font-vietnam">
                                        Sound notifications
                                    </P>
                                    <input type="" />
                                    <Switch />
                                </div>
                                <div className="flex  items-center justify-between mb-1.6">
                                    <P className="font-vietnam">Vibration</P>
                                    <input type="" />
                                    <Switch />
                                </div>
                                <div className="flex  items-center justify-between mb-1.6">
                                    <P className="font-vietnam">
                                        Two Factor Authentication
                                    </P>
                                    <input type="" />
                                    <Switch />
                                </div>
                                <LabeledInput
                                    errorMessage=""
                                    label={"Mobile Number"}
                                    placeholder="99XXXXXXXX"
                                />{" "}
                                <LabeledInput
                                    errorMessage=""
                                    label={"Email Id"}
                                    placeholder="Email"
                                />
                                <LinkButton className={"mb-1.6"}>
                                    Update Info
                                </LinkButton>
                                <div className="flex  items-center justify-between mb-1.6">
                                    <P className="font-vietnam">Biometrics</P>
                                    <input type="" />
                                    <Switch />
                                </div>
                            </Accordion> */}

                            <button className="text-sm text-gray font-vietnam font-medium mb-2">
                                Logout
                            </button>
                        </div>
                    </Container>
                </div>
                <div className="hidden lg:block">
                    <ModalAlt
                        times={false}
                        onClose={() => setShowDetails(false)}
                        isOpen={showDetails}
                        backgroundColor="white"
                        width={["50%", "50%", "40%", "58%"]}
                    >
                        <ResetPassword onClick={() => setShowDetails(false)} />
                    </ModalAlt>
                </div>
                <div className="lg:hidden">
                    <BottomDrawer
                        isOpen={showDetails}
                        onClick={() => setShowDetails(false)}
                    >
                        <ResetPassword
                            onClick={() => setShowDetails(false)}
                            className={" absolute bottom-0"}
                        />
                    </BottomDrawer>
                </div>
            </Section>
        </div>
    );
};

export default Profile;
