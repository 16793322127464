import React from "react";
import { indianSolarPanel } from "../../resources/js/images";
import Counter from "../../shared/animation/typography/Counter";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";

const MetricsAndFootPrint = () => {
    return (
        <Section>
            <Container>
                <div className="flex mb-4 xl:mb-6 2xl:mb-7 flex-wrap-reverse items-center justify-between ">
                    <div className="w-full md:w-6/12 xl:w-5/12">
                        <img
                            src={indianSolarPanel}
                            className="w-full"
                            alt="india_solar_panels"
                        />
                    </div>
                    <div className="w-full md:w-5/12 xl:w-5/12">
                        <H2 className="mb-2 xl:mb-3.2">Impact Metrics</H2>
                        <H1 className="text-primary ">
                            <Counter value={4800} />+ Tons
                        </H1>
                        <P className="mb-2  xl:mb-3.2 xl:text-3xl font-thin">
                            Co2 reduced yearly
                        </P>

                        <H1 className="text-primary">
                            <Counter value={120000} /> kW
                        </H1>
                        <P className="mb-3.2 xl:text-3xl font-thin">
                            Energy Production
                        </P>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default MetricsAndFootPrint;
