import { useCallback, useEffect, useState } from "react";
import { ERROR_MSG } from "../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../constants/contexConstant";
import { useContextState } from "../context/ContextProvider";
import request from "../services/request";

const useNetwork = (endpoint, type, body, auth = true) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const { dispatch } = useContextState();

    const sendRequest = useCallback(() => {
        dispatch({ type: SET_LOADER });
        let promise;
        if (auth) {
            promise =
                type === "POST"
                    ? request.authPost({ endpoint, body })
                    : request.authGet({ endpoint });
        } else if (!auth) {
            promise =
                type === "POST"
                    ? request.post({ endpoint, body })
                    : request.get({ endpoint });
        }

        promise
            .then((data) => {
                setData(data);
                setError(null);
            })
            .catch((err) => {
                const checkedErr = typeof err === "string" ? err : ERROR_MSG;
                setError(checkedErr);
                dispatch({
                    type: SET_ERROR,
                    payload: checkedErr,
                });
            })
            .finally(() => {
                setLoading(false);
                dispatch({ type: RESET_LOADER });
            });
    }, [auth, body, dispatch, endpoint, type]);
    useEffect(() => {
        sendRequest();
    }, [sendRequest]);

    return { loading, error, data };
};

export default useNetwork;
