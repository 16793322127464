import React from "react";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import P from "../../shared/typography/P";
import SubHeading from "../../shared/typography/SubHeading";
import OurAim from "./OurAim";

const cardsData = [
    {
        title: "Lorem",
        content:
            "Lorem ipsum dolor sit amet consectetur. Suspendisse justo dui augue egestas quam.",
    },
    {
        title: "Lorem",
        content:
            "Lorem ipsum dolor sit amet consectetur. Suspendisse justo dui augue egestas quam.",
    },
    {
        title: "Lorem",
        content:
            "Lorem ipsum dolor sit amet consectetur. Suspendisse justo dui augue egestas quam.",
    },
    {
        title: "Lorem",
        content:
            "Lorem ipsum dolor sit amet consectetur. Suspendisse justo dui augue egestas quam.",
    },
];

const Card = ({ title, content }) => {
    return (
        <div className="bg-gray-100 p-4 lg:p-4 border border-blue-300 rounded-2 ">
            <SubHeading className="text-lg font-medium">{title}</SubHeading>
            <P className="text-gray-600 ">{content}</P>
        </div>
    );
};

const CoreValues = () => {
    return (
        <Section className={"bg-gray-50  py-3 mb-0 xl:mb-0 lg:mb-0 2xl:mb-0"}>
            <Container className="my-2  xl:my-5">
                <OurAim />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 xl:gap-5 2xl:gap-7 sm:grid-cols-3 lg:grid-cols-4">
                    {cardsData.map((card, index) => (
                        <Card
                            key={index}
                            title={card.title}
                            content={card.content}
                        />
                    ))}
                </div>
            </Container>
        </Section>
    );
};

export default CoreValues;
