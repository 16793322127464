import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import { PROFILE } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import useAuth from "../hooks/useAuth";

const PublicRoute = ({ component: Component, ...rest }) => {
    const { state, dispatch } = useContextState();
    const location = useLocation();
    const { isAuth } = useAuth(location?.pathname, dispatch);

    const isAccessible = isAuth && state?.user;

    if (isAuth === null || (isAuth && state.user === null)) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }
    if (!isAccessible) {
        return <Route {...rest}>{(props) => <Component {...props} />}</Route>;
    } else return <Redirect to={PROFILE} />;
};

export default PublicRoute;
