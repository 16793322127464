import React from "react";

import BorderedTab from "../../../../shared/ui/tab/BorderedTab";
import InvestmentDetails from "./InvestmentDetails";
import ProjectDetails from "./ProjectDetails";

const options = ["Investment Details", "Project Details"];

const DetailsInvestments = (props) => {
    return (
        <div className="pb-6 lg:pb-0">
            <BorderedTab
                className="mb-4"
                onTabClick={(i) => props.setCurrentTab(i)}
                options={options}
                currentTab={props.currentTab}
            />
            {props.currentTab === 0 ? (
                <InvestmentDetails {...props} />
            ) : (
                <ProjectDetails listing={props?.listing} />
            )}
        </div>
    );
};

export default DetailsInvestments;
